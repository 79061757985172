import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderIGtech = ({ className }) => (
  <svg className={className} width="60" height="60" viewBox="0 0 60 60" fill="none">
    <g clipPath="url(#clip-icon-provider-igtech)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.3401 42.6128V40.5464H10.4754C11.1168 40.5464 11.1168 40.0477 11.1168 39.3354V23.0171C11.1168 21.236 10.9028 20.7368 9.12169 20.7368H5.62982V19.0269L16.7464 17.8154V37.6964C16.7464 39.4776 17.0316 40.5469 19.0263 40.5469H22.3042V42.6133L7.3401 42.6128Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0666 30.4274H45.25V40.9022C39.3347 43.3256 36.1994 43.3256 32.6367 43.3256C26.9358 43.3256 16.4611 41.4014 16.4611 31.4254C16.4611 20.4515 26.8644 17.9576 32.0666 17.9576C37.2687 17.9576 43.1831 19.454 45.25 20.3806V24.5848H44.2516C38.4802 21.3782 34.5609 21.3782 32.1375 21.3782C29.7149 21.3782 21.3066 22.0905 21.3066 31.4259C21.3066 38.9793 30.7125 40.2616 32.6367 40.2616C34.5609 40.2616 38.6937 39.9764 40.404 38.9788V33.9193H32.0666V30.4274Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1069 13.5042C15.1069 14.9013 13.6872 16.0335 11.9362 16.0335C10.1847 16.0335 8.76501 14.9009 8.76501 13.5042C8.76501 12.1076 10.1847 10.9745 11.9362 10.9745C13.6872 10.9745 15.1069 12.1071 15.1069 13.5042ZM51.9391 39.7594V41.1016H47.5591V43.0431H46.6178V37.8179H47.5591V39.7594H51.9391ZM47.5591 32.2978V35.7063H48.6776V32.5755H49.6198V35.7063H50.9978V32.1458H51.9391V37.0219H46.6178V32.2978H47.5591ZM48.3591 26.9565C48.0406 27.0637 47.4404 27.2697 47.4404 28.3793C47.4404 29.0234 47.7961 29.9718 49.3004 29.9718C50.2563 29.9718 51.1166 29.5155 51.1166 28.3793C51.1166 27.6365 50.7679 27.1178 50.1155 26.9565V25.6148C51.272 25.8832 52.0796 26.7957 52.0796 28.4059C52.0796 30.1148 51.1236 31.3405 49.2858 31.3405C47.4258 31.3405 46.4765 30.0431 46.4765 28.3877C46.4765 26.4644 47.5738 25.6857 48.3591 25.6144V26.9565ZM49.5525 23.2977H51.9391V24.6398H46.6178V23.2977H48.6333V20.7927H46.6178V19.4506H51.9391V20.7927H49.5525V23.2977Z"
        fill="#D57542"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 30C0 46.5424 13.4581 60 30 60C46.5424 60 60 46.5424 60 30C60 13.4581 46.5424 0 30 0C13.4581 0.000442961 0 13.4585 0 30ZM0.997549 30C0.997549 14.0082 14.0082 0.997549 30 0.997549C45.9922 0.997549 59.0025 14.0082 59.0025 30C59.0025 45.9922 45.9922 59.0025 30 59.0025C14.0082 59.0025 0.997549 45.9922 0.997549 30Z"
        fill="#7387BA"
      />
    </g>
    <clipPath id="clip-icon-provider-igtech">
      <rect width="60" height="60" fill="white" />
    </clipPath>
  </svg>
);

IconProviderIGtech.propTypes = {
  className: PropTypes.string,
};

IconProviderIGtech.defaultProps = {
  className: '',
};
