import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconSearch = ({
  className, color, onClick, size
}) => (
  <svg
    className={className}
    onClick={onClick}
    width={size || 28}
    height={size || 28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="icon-filled-path"
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.8115 12.5324C25.8115 18.4292 21.0312 23.2095 15.1343 23.2095C12.7895 23.2095 10.6212 22.4536 8.85982 21.1723L4.63871 25.3934C4.16978 25.8623 3.40949 25.8623 2.94056 25.3934C2.47163 24.9245 2.47163 24.1642 2.94056 23.6952L7.08651 19.5493C5.44905 17.6728 4.45717 15.2184 4.45717 12.5324C4.45717 6.63553 9.2375 1.85521 15.1343 1.85521C21.0312 1.85521 25.8115 6.63553 25.8115 12.5324ZM23.4099 12.5324C23.4099 17.1029 19.7048 20.808 15.1343 20.808C10.5638 20.808 6.85871 17.1029 6.85871 12.5324C6.85871 7.96187 10.5638 4.25675 15.1343 4.25675C19.7048 4.25675 23.4099 7.96187 23.4099 12.5324Z"
      stroke={color}
      strokeWidth="0.2"
      strokeLinecap="round"
    />
  </svg>
);

IconSearch.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.number,
};

IconSearch.defaultProps = {
  className: '',
  color: colors.gray20,
  onClick: () => {},
  size: null
};
