import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import colors from 'customizations/js/color-variables';
import { withUser, withModalActions, withCoreComponent } from 'core/hocs';
import { USER_FIELDS } from 'core/constants';
import { FormattedTag, RecommendedGames as RecommendedGamesCore } from 'core/components';
import { Logo } from 'components/logo/logo';
import { LoggedIn } from 'components/sidebar/logged-in/logged-in';
import { NotLoggedIn } from 'components/sidebar/not-logged-in/not-logged-in';
import { UserTabs } from 'components/user-tabs/user-tabs';
import { IconSearch } from 'components/icons/icons-games-filters/icon-search';
import { GamesSearchPortal as GamesSearch } from 'components/games-search/games-search-portal';

import { MODAL_IDS } from '../../constants';

import './sidebar.scss';

const {
  USER_DATA, IS_USER_LOGGED_IN, USER_BALANCE, LOYALTY_POINTS,
} = USER_FIELDS;

export class SidebarUI extends Component {
  static propTypes = {
    isUserLoggedIn: PropTypes.bool.isRequired,
    openModalAndCloseOthers: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    userData: PropTypes.shape(),
    userBalance: PropTypes.shape(),
    loyaltyPoints: PropTypes.shape(),
    pageName: PropTypes.string,
    games: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    userData: null,
    userBalance: null,
    loyaltyPoints: null,
    pageName: null,
    games: [],
  };

  state = {
    gameSearchIsOpen: false,
  };

  toggleGameSearch = () => this.setState(prevState => ({ gameSearchIsOpen: !prevState.gameSearchIsOpen }));

  openSignInModal = () => {
    const { openModalAndCloseOthers } = this.props;
    openModalAndCloseOthers(MODAL_IDS.SIGN_IN);
  };

  openSignUpModal = () => {
    const { openModalAndCloseOthers } = this.props;
    openModalAndCloseOthers(MODAL_IDS.SIGN_UP);
  };

  render() {
    const {
      isUserLoggedIn, signOut, userData, userBalance,
      loyaltyPoints, pageName, games: recommendedGames
    } = this.props;
    const { gameSearchIsOpen } = this.state;

    return (
      <div
        className={classNames('sidebar position-fixed d-none d-md-flex flex-column align-items-center pt-2_5 px-2_5 bg-default', { 'sidebar-logged': isUserLoggedIn })}
      >
        <Logo iconClassName="" />

        <Button
          color="transparent"
          size="sm"
          onClick={this.toggleGameSearch}
          className="d-flex justify-content-center align-items-center w-100 font-weight-semi-bold text-gray-10 border-bottom border-gray-10 rounded-0 mt-2 pb-1 text-base"
        >
          <IconSearch className="ml-n0_5 icon-search" color={colors.gray10} size={16} />
          <FormattedTag className="ml-1_5" id="sidebar.search.btn" />
        </Button>

        {isUserLoggedIn
          ? (
            <LoggedIn
              pageName={pageName}
              userData={userData}
              userBalance={userBalance}
              loyaltyPoints={loyaltyPoints}
              signOut={signOut}
            />
          ) : (
            <NotLoggedIn
              pageName={pageName}
              openSignInModal={this.openSignInModal}
              openSignUpModal={this.openSignUpModal}
            />
          )}

        <UserTabs isUserLoggedIn={isUserLoggedIn} isInSidebar coreProps={{ size: 99 }} />

        {gameSearchIsOpen && (
          <GamesSearch
            recommendedGames={recommendedGames}
            onClose={this.toggleGameSearch}
            isModal
            className="d-none d-md-block"
          />
        )}
      </div>
    );
  }
}

export const Sidebar = withCoreComponent(
  RecommendedGamesCore,
  withModalActions(
    withUser(
      SidebarUI,
      [USER_DATA, IS_USER_LOGGED_IN, USER_BALANCE, LOYALTY_POINTS]
    )
  )
);
