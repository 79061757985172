import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderGamomatBig = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="140"
    height="18"
    viewBox="0 0 140 18"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.7274 6.78708H17.2798C17.4118 7.44698 17.5 8.10979 17.4548 8.76969C17.4548 13.5742 13.5756 17.5 8.7274 17.5C3.92219 17.5 0 13.6194 0 8.76969C0 3.92 3.92219 0.0430208 8.7274 0C10.1383 0 11.4166 0.352917 12.6073 0.924583L10.3585 5.28792C9.87365 5.06844 9.30052 4.935 8.7274 4.935C7.71072 4.93616 6.73603 5.34054 6.01714 6.05943C5.29825 6.77833 4.89387 7.75301 4.89271 8.76969C4.89271 10.8865 6.61281 12.6073 8.7274 12.6073C10.1828 12.6073 11.418 11.8103 12.0786 10.621H8.7274V6.78708ZM62.8994 8.77042C62.8994 3.96521 66.7807 0.0430208 71.6268 0.0430208C72.7743 0.0379068 73.9112 0.260127 74.9729 0.696854C76.0339 1.13358 76.9978 1.77616 77.8094 2.58751C78.6209 3.39886 79.2633 4.3629 79.7008 5.42399C80.1376 6.48507 80.36 7.6222 80.3549 8.76969C80.3549 13.5742 76.4765 17.5 71.6268 17.5C66.8238 17.5 62.9001 13.6208 62.8994 8.77042ZM30.9429 0.659896H25.6098L18.8657 16.8372H24.5955L25.4333 14.6781H31.0756L31.9127 16.8372H37.7307L30.9429 0.659896ZM28.2537 6.96354L29.7092 10.8865H26.8005L28.2537 6.96354ZM112.313 0.659896H117.647L124.434 16.8372H118.571L117.733 14.6781H112.096L111.257 16.8372H105.526L112.313 0.659896ZM114.914 6.96427L116.367 10.8865H113.46L114.914 6.96427ZM129.634 5.24563H124.876V0.659896H139.864V5.24563H135.103V16.8802H129.68V5.24563H129.634ZM46.3714 0.659896H40.7291H40.7276V16.8795H46.017V8.85865L49.7642 14.6322H49.8524L53.5989 8.85865V16.8795H58.9765V0.659896H53.3342L49.8531 6.34594L46.3714 0.659896ZM84.28 0.659896H89.9194L93.4011 6.34594L96.8858 0.659896H102.522V16.8795H97.1461V8.85865L93.3997 14.6322H93.3115L89.565 8.85865V16.8795H84.2771V0.659896H84.28ZM71.6268 12.2092C70.7151 12.2078 69.8412 11.8449 69.1967 11.2002C68.5522 10.5554 68.1897 9.68136 68.1888 8.76969C68.1903 7.8583 68.553 6.98462 69.1974 6.34008C69.8418 5.69555 70.7154 5.33267 71.6268 5.33094C72.5385 5.33248 73.4125 5.69522 74.0571 6.33974C74.7017 6.98425 75.0648 7.85801 75.067 8.76969C75.0655 9.68151 74.7024 10.5556 74.0578 11.2003C73.4125 11.845 72.5387 12.2078 71.6268 12.2092Z"
      fill="#A6CE3D"
    />
  </svg>
);

IconProviderGamomatBig.propTypes = {
  className: PropTypes.string,
};
IconProviderGamomatBig.defaultProps = {
  className: null,
};
