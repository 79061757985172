module.exports = {
  firstName: {
    type: 'text',
    label: 'form.fields.first-name',
    step: 1,
    validation: [
      { type: 'required' },
      { type: 'latin-and-turkey-words-with-spaces-and-dashes' },
      { type: 'max-length', value: 50 },
    ],
  },
  lastName: {
    type: 'text',
    label: 'form.fields.last-name',
    step: 1,
    validation: [
      { type: 'required' },
      { type: 'latin-and-turkey-words-with-spaces-and-dashes' },
      { type: 'max-length', value: 50 },
    ],
  },
  gender: {
    type: 'select',
    label: 'form.fields.gender',
    placeholder: 'placeholder.select',
    step: 1,
    validation: [
      { type: 'required' },
    ],
  },

  year: {
    type: 'select',
    placeholder: 'form.fields.year',
    step: 1,
    validation: [
      { type: 'required' },
      { type: 'separate-birth-date' },
    ],
    isReadOnly: true,
  },
  month: {
    type: 'select',
    placeholder: 'form.fields.month',
    step: 1,
    validation: [
      { type: 'required' },
    ],
    isReadOnly: true,
  },
  day: {
    type: 'select',
    placeholder: 'form.fields.day',
    step: 1,
    validation: [
      { type: 'required' },
    ],
    isReadOnly: true,
  },
  country: {
    type: 'select',
    isOptionsLocalized: true,
    label: 'form.fields.country',
    validation: [
      { type: 'required' },
    ],
    step: 2,
  },
  city: {
    type: 'text',
    label: 'form.fields.city',
    step: 2,
    validation: [
      { type: 'required' },
      { type: 'latin-and-turkey-words-with-spaces-and-dashes' },
      { type: 'max-length', value: 30 },
    ],
  },
  postalCode: {
    type: 'text',
    label: 'form.fields.postal-code',
    step: 2,
    validation: [
      { type: 'required' },
      { type: 'latin-and-number' },
      { type: 'max-length', value: 10 },
    ],
  },
  address: {
    type: 'text',
    label: 'form.fields.address',
    step: 2,
    validation: [
      { type: 'required' },
      { type: 'max-length', value: 75 },
    ],
  },
  mobile: {
    type: 'tel',
    label: 'form.fields.mobile',
    placeholder: 'form.fields.mobile',
    step: 2,
    validation: [
      { type: 'required' },
    ],
  },
};
