import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderCandleBets = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <g clipPath="url(#candle-bets-clip-0)">
      <path
        d="M15.7042 18.072C17.7521 17.8006 19.741 17.4603 21.4224 16.0406C22.3318 15.2751 23.4048 16.106 23.4735 17.5257C23.5618 19.338 22.2513 21.6507 20.0789 22.7368C18.8158 23.3684 16.8296 23.4435 16.8296 23.4435C11.8684 24 8.07893 23.3684 3.0263 18.3158C3.0263 18.3158 2.26594 17.5683 2.17761 17.2837C3.62024 17.264 4.56143 15.6548 5.55261 14.5263C7.23077 12.6028 7.51977 10.9815 8.71051 8.8421C8.36207 9.2645 8.71655 12.2741 8.70705 12.8217C8.31777 16.4855 11.7199 21.4708 15.2495 22.377C15.5571 22.4556 15.9791 22.5961 16.3291 22.0728C14.7196 21.7947 14.2093 20.954 14.4743 19.3642C14.6379 18.4842 15.6912 18.8375 15.7042 18.072Z"
        fill="url(#candle-bets-paint-0)"
      />
      <path
        d="M3.49879 4.483C3.90768 4.37185 4.32314 4.28671 4.7255 4.15584C7.4112 3.23008 8.92338 4.07841 9.3421 6.94729C9.3421 6.94729 9.3421 7.57887 8.71053 8.84206C7.37827 11.5066 6.7919 14.1487 5.6022 15.4908C4.41249 16.8329 3.94607 17.2736 2.18701 17.2736C-0.472522 13.3514 0.0476078 8.3038 3.49879 4.483Z"
        fill="url(#candle-bets-paint-1)"
      />
      <path
        d="M3.49878 4.48299C7.58786 -0.00185153 12.8546 -1.17949 18.2195 1.21179C18.7462 1.50661 19.3602 1.60628 19.9533 1.4931C21.5365 1.06451 22.4296 1.90198 23.123 3.12876C23.9517 4.55272 24.2713 6.21617 24.0292 7.8459C23.8199 9.26556 23.0086 10.224 21.5561 10.5381C20.1037 10.8522 19.1747 10.1195 18.5205 8.90253C17.9512 7.8459 17.3427 6.83499 16.3679 6.0728C13.7509 4.03154 11.1339 4.40771 9.38705 7.10657C8.99123 4.21482 7.41121 3.23335 4.71242 4.14939C4.32314 4.2867 3.90769 4.37183 3.49878 4.48299Z"
        fill="url(#candle-bets-paint-2)"
      />
      <path
        d="M3.49878 4.48299C7.58786 -0.00185153 12.8546 -1.17949 18.2195 1.21179C18.7462 1.50661 19.4474 1.89475 19.4474 1.89475C19.4474 1.89475 18.699 1.4785 18.2195 1.21179C17.7131 1.24534 16.9211 1.26317 16.9211 1.26317C16.9211 1.26317 16.5354 1.24426 16.2895 1.26317C15.7687 1.30323 15.0984 1.55823 14.3948 1.89475C13.5925 2.27844 13.1882 2.5947 12.5 3.15791C10.9804 4.4015 11.1339 4.40771 9.38705 7.10657C8.99123 4.21482 7.41121 3.23335 4.71242 4.14939C4.32314 4.2867 3.90769 4.37183 3.49878 4.48299Z"
        fill="url(#candle-bets-paint-3)"
      />
      <path
        d="M14.9486 17.9412C16.921 17.9412 20.7102 15.7895 22.2847 15.7148C25.3424 15.5697 22.9414 21.7806 20.0786 22.7369C18.2257 23.3558 16.2892 22.7369 14.5221 22.1513C11.2364 20.8421 8.07738 18.3158 8.07861 13.2632C8.07892 12 8.71034 6.94733 8.71034 10.1052C8.71034 16.9936 12.9762 17.9412 14.9486 17.9412Z"
        fill="url(#candle-bets-paint-4)"
      />
    </g>
    <defs>
      <linearGradient
        id="candle-bets-paint-0"
        x1="4.28945"
        y1="14.5263"
        x2="15.0263"
        y2="25.2632"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#26DCBA" />
        <stop offset="1" stopColor="#1BB1BA" />
      </linearGradient>
      <linearGradient
        id="candle-bets-paint-1"
        x1="4.94843"
        y1="3.82146"
        x2="4.94843"
        y2="17.2736"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#560057" />
        <stop offset="1" stopColor="#CB043F" />
      </linearGradient>
      <linearGradient
        id="candle-bets-paint-2"
        x1="24.5"
        y1="5.68422"
        x2="9.34212"
        y2="5.68423"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF6823" />
        <stop offset="1" stopColor="#FFA452" />
      </linearGradient>
      <linearGradient
        id="candle-bets-paint-3"
        x1="20.079"
        y1="1.4819e-05"
        x2="2.39475"
        y2="4.42107"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F61F1A" />
        <stop offset="1" stopColor="#FF510B" />
      </linearGradient>
      <linearGradient
        id="candle-bets-paint-4"
        x1="24.5"
        y1="15.7895"
        x2="6.81576"
        y2="11.3684"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32D6AB" />
        <stop offset="1" stopColor="#B0FCE6" />
      </linearGradient>
      <clipPath id="candle-bets-clip-0">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

IconProviderCandleBets.propTypes = {
  className: PropTypes.string,
};
IconProviderCandleBets.defaultProps = {
  className: null,
};
