import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withModalActions } from 'core/hocs';
import { FormattedTag, RouteLink } from 'core/components';
import * as R from 'ramda';

import { menuItemsSidebar } from 'components/header/menu-items';
import { withRouter } from 'react-router-dom';
import {
  MODAL_IDS,
  MODAL_OPEN_CLASS,
  PAGE_NAMES,
  SIDE_MODAL_IDS,
} from '../../../constants';
import { IconProfile } from '../../icons/icon-profile/icon-profile';
import { IconGift } from '../../icons/icon-gift/icon-gift';
import { IconWallet } from '../../icons/icon-wallet/icon-wallet';
import { IconTransactions } from '../../icons/icon-transactions/icon-transactions';

import './sidebar-menu.scss';

const {
  PROFILE, BANKING_HISTORY, WALLET, GIFTS
} = PAGE_NAMES;

const sidebarGridItems = [
  {
    id: 'sidebar.navigation.my-account',
    to: '/profile',
    Icon: IconProfile,
    pages: [PROFILE],
    classNameLink: 'mb-1_75'
  },
  {
    id: 'sidebar.navigation.gifts',
    to: '/gifts',
    Icon: IconGift,
    pages: [GIFTS],
    classNameLink: 'mb-1_75'
  },
  {
    id: 'sidebar.navigation.wallet',
    to: '/wallet',
    Icon: IconWallet,
    pages: [WALLET],
  },
  {
    id: 'sidebar.navigation.transactions',
    to: '/banking-history',
    Icon: IconTransactions,
    pages: [BANKING_HISTORY],
  },
];

class SidebarMenuUI extends Component {
   closeProfileModal = () => {
     const { closeModal } = this.props;

     closeModal(SIDE_MODAL_IDS.SIDE_MODAL_BAR);
     closeModal(MODAL_IDS.PROFILE);

     setTimeout(() => {
       document.body.classList.remove(MODAL_OPEN_CLASS);
     }, 700);
   };

   render() {
     const {
       pageName,
       params,
       isGridView,
       location: {
         search,
       },
     } = this.props;

     const queryParams = R.last(search.split('?'));

     return (
       <div className={classNames('sidebar-menu d-flex flex-wrap', {
         'grid-view flex-wrap justify-content-between mt-2_25 mb-2_5': isGridView,
         'flex-column pt-1 pb-1_5 px-1_75': !isGridView,
       })}
       >
         {isGridView ? (
           sidebarGridItems.map(({
             id, to = '', pages, Icon, classNameLink,
           }) => {
             const isActive = pages.includes(pageName) || false;

             return (
               <RouteLink
                 key={id}
                 onClick={this.closeProfileModal}
                 to={to}
                 className={classNames('menu-item d-flex flex-column align-items-center rounded font-weight-bold pt-2 pb-1_25 pb-md-1 justify-content-between', classNameLink, {
                   'active border border-primary': isActive,
                 })}
               >
                 {Icon && <Icon className="menu-icon" />}
                 <FormattedTag id={id} />
               </RouteLink>
             );
           })) : (
           menuItemsSidebar.map(({
             id,
             to,
             pages,
             Icon,
             IconMobile,
             classNameSidebar,
           }) => (
             <RouteLink
               key={id}
               to={to}
               onClick={this.closeProfileModal}
               className={classNames('menu-item py-1_5 d-flex align-items-center font-weight-semi-bold', classNameSidebar, {
                 active: queryParams ? queryParams === R.last(to.split('?'))
                   : !params && pages.includes(pageName) || pages.includes(`${pageName}/${params}`),
               })}
             >
               <span className="menu-icon-container">
                 {IconMobile ? <IconMobile className="menu-icon" /> : <Icon className="menu-icon" />}
               </span>
               <FormattedTag
                 id={id}
                 className="text-gray-40 ml-2"
               />
             </RouteLink>
           ))
         )}
       </div>
     );
   }
}

SidebarMenuUI.propTypes = {
  pageName: PropTypes.string,
  params: PropTypes.string,
  isGridView: PropTypes.bool,
  location: PropTypes.shape().isRequired,
  closeModal: PropTypes.func.isRequired,
};

SidebarMenuUI.defaultProps = {
  params: null,
  pageName: '',
  isGridView: false,
};

export const SidebarMenu = withModalActions(withRouter(SidebarMenuUI));
