import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconSports = ({ className, color }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="icon-filled-path icon-stroked-path" d="M19.7928 9.65547C19.5726 4.23556 15.1719 0.201385 10.0002 0.201385C4.67748 0.201385 0.358151 4.43218 0.20566 9.7099C0.205545 9.71173 0.205545 9.71353 0.205468 9.71537C0.0481926 15.263 4.48908 19.7987 10.0002 19.7987C15.5284 19.7987 19.9843 15.232 19.7928 9.65547ZM17.742 6.14315C18.102 6.86286 18.365 7.63917 18.5127 8.45525L17.2812 7.56047L17.742 6.14315ZM16.9523 4.85796L16.1456 7.33943L13.5004 8.27298L10.5744 6.12279V3.30583L12.6642 1.7699C14.3959 2.33171 15.8898 3.42548 16.9523 4.85796ZM11.6306 12.2583H8.29329L7.26275 9.0868L9.99638 7.12309L12.6626 9.08232L11.6306 12.2583ZM5.53019 16.2011L6.00848 17.6726C5.26004 17.2817 4.57566 16.7845 3.97534 16.2011H5.53019ZM5.62117 15.0528H2.98296C1.96314 13.6406 1.35861 11.91 1.34988 10.0409L3.49138 8.48526L6.09954 9.22291L7.17269 12.5255C7.14804 12.5638 7.35392 12.2289 5.62117 15.0528ZM2.22782 6.2047L2.69547 7.64414L1.47474 8.53092C1.61571 7.71052 1.8725 6.9293 2.22782 6.2047ZM11.186 1.43131L9.99837 2.30411L8.78839 1.43494C9.58683 1.32253 10.3865 1.32122 11.186 1.43131ZM7.30202 1.78103L9.42603 3.30683V6.11889L6.57788 8.16485L3.81853 7.38444L3.01324 4.90569C4.0708 3.45936 5.56579 2.35238 7.30202 1.78103ZM7.40437 18.2523L6.57359 15.6963L7.97855 13.4066H11.5461L13.4819 15.7051L12.6607 18.2313C10.9865 18.7737 9.1398 18.7994 7.40437 18.2523ZM16.078 16.1494C15.4837 16.7368 14.8049 17.2389 14.0618 17.6357L14.5449 16.1494H16.078ZM17.055 15.0012H14.3904L12.627 12.9075L13.768 9.39623L16.5184 8.42555L18.6507 9.97496C18.6559 11.832 18.0737 13.5685 17.055 15.0012Z" fill={color} stroke={color} strokeWidth="0.4" />
  </svg>
);

IconSports.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconSports.defaultProps = {
  className: null,
  color: colors.gray20
};
