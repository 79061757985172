import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderPlatipusBig = ({ className }) => (
  <svg
    className={className}
    width="130"
    height="63"
    viewBox="0 0 178 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip-platipus-big)">
      <path
        d="M178 0V57.6C178 60.1 176 62.2 173.4 62.2H4.6C2 62.2 0 60.1 0 57.6V14.8C0 12.3 2 10.3 4.6 10.3H167.6L177.1 0H178Z"
        fill="#EB5149"
      />
      <path
        d="M34.3016 30.4C34.3016 35.7 30.3016 39.6 24.9016 39.6H21.1016V46.6H14.1016V21.3H24.9016C30.3016 21.3 34.3016 25.1 34.3016 30.4ZM27.1016 30.4C27.1016 28.6 25.9016 27.4 24.1016 27.4H21.1016V33.5H24.1016C25.9016 33.5 27.1016 32.2 27.1016 30.4Z"
        fill="white"
      />
      <path d="M37.1016 20H43.9016V46.6H37.1016V20Z" fill="white" />
      <path
        d="M68.9 27V46.6H63.7L63.1 45.2C61.5 46.5 59.5 47.2 57.2 47.2C51.3 47.2 47 42.9 47 36.8C47 30.7 51.3 26.4 57.2 26.4C59.5 26.4 61.6 27.2 63.2 28.5L63.9 27H68.9ZM62.5 36.8C62.5 34.3 60.7 32.4 58.2 32.4C55.6 32.4 53.8 34.3 53.8 36.8C53.8 39.4 55.7 41.3 58.2 41.3C60.7 41.3 62.5 39.4 62.5 36.8Z"
        fill="white"
      />
      <path
        d="M86.3031 40.7V46.6H81.7031C77.4031 46.6 74.8031 44 74.8031 39.6V32.2H71.2031V30.7L80.0031 21.3H81.3031V27.1H86.2031V32.2H81.5031V38.5C81.5031 39.9 82.3031 40.7 83.8031 40.7H86.3031Z"
        fill="white"
      />
      <path d="M89.5 27.1H96.2V46.6H89.5V27.1ZM89.5 18.9H96.2V24.6H89.5V18.9Z" fill="white" />
      <path
        d="M122.098 36.8C122.098 42.9 117.898 47.3 111.898 47.3C110.098 47.3 108.498 46.8 107.098 46V53.7H100.398V27.1H104.998L105.898 28.6C107.498 27.2 109.498 26.4 111.898 26.4C117.898 26.4 122.098 30.7 122.098 36.8ZM115.398 36.8C115.398 34.3 113.498 32.4 110.998 32.4C108.498 32.4 106.598 34.3 106.598 36.8C106.598 39.4 108.498 41.3 110.998 41.3C113.498 41.3 115.398 39.4 115.398 36.8Z"
        fill="white"
      />
      <path
        d="M125 38.2V27.1H131.7V37.9C131.7 40 132.8 41.2 134.6 41.2C136.4 41.2 137.5 40 137.5 37.9V27.1H144.3V38.2C144.3 43.7 140.4 47.3 134.6 47.3C128.8 47.2 125 43.7 125 38.2Z"
        fill="white"
      />
      <path
        d="M155.802 47.2C150.402 47.2 147.202 44.4 147.102 40.2H153.602C153.602 41.6 154.602 42.3 155.902 42.3C156.902 42.3 157.802 41.8 157.802 40.8C157.802 39.6 156.302 39.3 154.402 39C151.402 38.5 147.402 37.6 147.402 32.9C147.402 28.9 150.702 26.4 155.802 26.4C160.702 26.4 164.002 29.1 164.002 33H157.702C157.702 31.8 156.902 31.2 155.602 31.2C154.602 31.2 153.802 31.7 153.802 32.6C153.802 33.7 155.302 34 157.202 34.3C160.302 34.9 164.402 35.5 164.402 40.5C164.402 44.6 160.902 47.2 155.802 47.2Z"
        fill="white"
      />
    </g>
  </svg>
);

IconProviderPlatipusBig.propTypes = {
  className: PropTypes.string,
};

IconProviderPlatipusBig.defaultProps = {
  className: '',
};
