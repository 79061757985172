/**
 * Available validations: required, min-length, max-length, email, username-or-email,
 * password, passwords-equality, date, birth-date, date-range
 *
 * date-range validation values:
 * @prop {Date|fieldName} from
 * @prop {Date|fieldName} to
 * @prop {string} boxDateFormat
 *  * example:
 validation: [
  {
    type: 'date-range',
    value: {
      from: new Date('2020-01-01'),
      to: 'dateEnd',
      boxDateFormat: 'DD/MM/YYYY'
    }
  },
 ],
 */

module.exports = {
  date: {
    type: 'date-picker',
    label: 'profile.history.filter.label.date',
    placeholder: 'profile.history.filter.date-placeholder',
    validation: [
      { type: 'required' },
    ],
  },
  gameHistoryType: {
    type: 'select',
    label: 'profile.history.filter.label.type',
    isOptionsLocalized: false,
    validation: [
      { type: 'required' },
    ],
  },
  status: {
    type: 'select',
    label: 'profile.history.filter.label.status',
    isOptionsLocalized: false,
    validation: [
      { type: 'required' },
    ],
  },
};
