import React from 'react';
import PropTypes from 'prop-types';

export const IconLangEn = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24">
    <g fill="none" fillRule="evenodd">
      <use fill="#D8D8D8" xlinkHref="#icon-lang-en-a" />
      <g fillRule="nonzero" mask="url(#icon-lang-en-b)">
        <path fill="#F0F0F0" d="M-3 .023h35.93v23.953H-3z" />
        <path fill="#D80027" d="M17.21.023h-4.49v9.731H-3v4.491h15.72v9.731h4.49v-9.73h15.72V9.753H17.21z" />
        <g fill="#0052B4">
          <path d="M24.634 16.165l8.296 4.61v-4.61zM18.87 16.165l14.06 7.811v-2.208l-10.084-5.603zM29.185 23.976l-10.315-5.73v5.73z" />
        </g>
        <path fill="#F0F0F0" d="M18.87 16.165l14.06 7.811v-2.208l-10.084-5.603z" />
        <path fill="#D80027" d="M18.87 16.165l14.06 7.811v-2.208l-10.084-5.603z" />
        <g fill="#0052B4">
          <path d="M3.34 16.165L-3 19.687v-3.522zM11.06 17.159v6.817H-1.211z" />
        </g>
        <path fill="#D80027" d="M7.084 16.165L-3 21.768v2.208l14.06-7.81z" />
        <g fill="#0052B4">
          <path d="M5.296 7.834L-3 3.225v4.609zM11.06 7.834L-3 .024v2.208L7.084 7.834zM.745.023L11.06 5.754V.024z" />
        </g>
        <path fill="#F0F0F0" d="M11.06 7.834L-3 .024v2.208L7.084 7.834z" />
        <path fill="#D80027" d="M11.06 7.834L-3 .024v2.208L7.084 7.834z" />
        <g fill="#0052B4">
          <path d="M26.59 7.834l6.34-3.522v3.522zM18.87 6.84V.024h12.271z" />
        </g>
        <path fill="#D80027" d="M22.846 7.834L32.93 2.232V.023L18.87 7.834z" />
      </g>
      <use fill="#000" filter="url(#icon-lang-en-d)" xlinkHref="#icon-lang-en-c" />
    </g>
  </svg>
);

IconLangEn.propTypes = {
  className: PropTypes.string,
};

IconLangEn.defaultProps = {
  className: null,
};
