import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderRedrake = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5217 14.0049L10.559 24H17.5931L14.3148 14.0049C15.6941 13.4965 16.7604 12.7006 17.5136 11.6179C18.2666 10.5366 18.7498 9.32564 18.9617 7.98945C19.0043 7.65029 19.0416 7.3261 19.0738 7.01802C19.1046 6.71121 19.1217 6.40907 19.1217 6.11128C19.1217 4.9016 18.909 3.90502 18.4839 3.11889C18.0593 2.33442 17.4655 1.71328 16.7019 1.25734C15.9383 0.801852 15.0256 0.477202 13.9646 0.286714C12.9039 0.0954202 11.7471 0 10.4956 0H4.22502L0.5 24H6.99351L7.98086 17.6019C8.08666 17.0084 8.1714 16.4195 8.23493 15.8349C8.28473 15.3834 8.3338 14.928 8.3832 14.4696C8.39757 14.3362 8.41197 14.2026 8.42643 14.0687L8.5217 14.0049ZM10.5904 10.8544C10.2291 11.0244 9.83592 11.1088 9.41212 11.1088L8.99906 11.0773L9.92212 5.15656H10.1759C10.9186 5.15656 11.5072 5.33667 11.9428 5.69719C12.3779 6.05852 12.5956 6.62006 12.5956 7.38448C12.5956 7.49008 12.5902 7.59663 12.5799 7.70273C12.5685 7.80878 12.5529 7.92591 12.5321 8.05287C12.4677 8.43476 12.3508 8.8122 12.1821 9.18245C12.0121 9.55441 11.7945 9.88339 11.5292 10.1701C11.2638 10.4565 10.9509 10.684 10.5904 10.8544Z"
      fill="#DB1D31"
    />
  </svg>
);

IconProviderRedrake.propTypes = {
  className: PropTypes.string,
};

IconProviderRedrake.defaultProps = {
  className: '',
};
