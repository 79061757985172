import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderHacksawBig = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="33"
    viewBox="0 0 120 33"
    fill="none"
  >
    <path
      d="M8.28514 13.0571H11.6586V15.8571L17 16V0.314286C17 0.142857 16.9157 0 16.7751 0H11.8835C11.743 0 11.6586 0.114286 11.6586 0.314286V7.74286H8.28514V0.314286C8.28514 0.142857 8.2008 0 8.06024 0H3.2249C3.08434 0 3 0.114286 3 0.314286V15.6571L8.28514 15.8V13.0571Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.1816 0C29.2964 0 29.3824 0.0591304 29.4398 0.236522L34 17L19 16.5861L23.5602 0.236522C23.6176 0.0591304 23.7036 0 23.8184 0H29.1816ZM26.457 4.73043L25.0516 13.9843H27.8337L26.457 4.73043Z"
      fill="white"
    />
    <path
      d="M55.0046 17.0149L57.3782 13.6093L59.1799 17.8593L64.7565 18L60.6384 8.96523L65.929 1.36589C66.072 1.16887 65.9862 1 65.786 1H60.1808C60.0664 1 59.952 1.08444 59.9234 1.16887L55.0332 8.90894V1.3096C55.0332 1.14073 54.9474 1 54.8044 1H50.2288C50.0858 1 50 1.11258 50 1.3096V17.606L55.0332 17.7467L55.0046 17.0149Z"
      fill="white"
    />
    <path
      d="M81 14.6967C81 6.63453 71.4706 9.51789 71.4706 6.32659C71.4706 5.4028 72.2941 5.01089 73.3824 5.01089C74.6765 5.01089 75.9706 5.57076 77.2353 6.83048C77.2941 6.91446 77.4118 6.94246 77.5 6.88647C77.5294 6.85848 77.5588 6.83048 77.5588 6.80249L80.1471 3.38725C80.2353 3.27527 80.2353 3.07932 80.1176 2.96734C78.3235 0.923795 76.1765 0 73.5882 0C70.0588 0 66.2647 1.65163 66.2647 6.94246C66.2647 15.3966 75.9118 12.0373 75.9118 15.5086C75.9118 16.5723 75 17.1042 73.7059 17.1042C72.0882 17.1042 70.4706 16.3204 68.8529 14.2208C68.7647 14.1089 68.6176 14.0809 68.5294 14.2208L66 17.6361L80.3235 18C80.7941 16.9362 81 15.8165 81 14.6967Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.705 1C92.8201 1 92.9065 1.0538 92.964 1.21519L98 18L82 17.6234L87.036 1.21519C87.0935 1.0538 87.1799 1 87.295 1H92.705ZM90 5.3038L88.5612 13.7231H91.3813L90 5.3038Z"
      fill="white"
    />
    <path
      d="M107.5 10.1803L108.922 18.8613L114.815 19L119.982 1.33282C120.04 1.13867 119.953 1 119.808 1H114.96C114.815 1 114.728 1.0832 114.699 1.27735L112.115 11.5393L110.025 1.27735C109.996 1.13867 109.909 1 109.764 1H105.236C105.091 1 105.004 1.11094 104.975 1.27735L102.885 11.5393L100.301 1.27735C100.272 1.11094 100.185 1 100.04 1H95.1923C95.0472 1 94.9601 1.11094 95.0182 1.33282L100.069 18.6394L106.078 18.7781L107.5 10.1803Z"
      fill="white"
    />
    <path
      d="M48 15.3557C48 15.3279 47.9712 15.2721 47.9425 15.2721C47.9137 15.2721 47.885 15.2721 47.8562 15.3C47.166 16.2754 46.0445 16.8607 44.8079 16.8328C42.1622 16.8328 40.8969 14.2689 40.8969 10.8689C40.8969 7.77541 41.9321 5.04426 44.8654 5.04426C46.0732 4.98852 47.1948 5.57377 47.8562 6.52131C47.885 6.54918 47.9425 6.57705 47.9712 6.54918C48 6.52131 48 6.52131 48 6.49344V0.808197C48 0.668852 47.9137 0.557377 47.7987 0.501639C46.7922 0.167213 45.6994 0 44.6354 0C38.7688 0 35.0015 4.98852 35.0015 11.2869C34.9728 13.1541 35.3466 14.9934 36.0943 16.6934L48 17V15.3557Z"
      fill="white"
    />
    <path
      d="M8.03158 22.6809C8.03158 22.8723 8.12632 23 8.28421 23H13.7474C13.9053 23 14 22.8723 14 22.6809V17.1596L8 17L8.03158 22.6809Z"
      fill="white"
    />
    <path
      d="M0.253968 23H5.71429C5.87302 23 6 22.8776 6 22.6939V17.1224L0 17V22.6939C0 22.8776 0.0952381 23 0.253968 23Z"
      fill="white"
    />
    <path
      d="M15.2389 23H20.2864C20.4323 23 20.5198 22.8689 20.549 22.7377L21.1909 19.5902H26.8511L27.493 22.7377C27.5222 22.9016 27.6389 23 27.7556 23H32.8031C32.9781 23 33.0365 22.8361 32.9781 22.5738L31.6944 17.459L16.4352 17L15.0055 22.541C14.9764 22.8361 15.0639 23 15.2389 23Z"
      fill="white"
    />
    <path
      d="M58.5698 22.7603C58.5973 22.8973 58.7075 23 58.8176 23H63.8023C63.9676 23 64.0502 22.8288 63.9676 22.5548L62.3703 18.1712L57 18L58.5698 22.7603Z"
      fill="white"
    />
    <path
      d="M47.2273 23H51.7727C51.9148 23 52 22.8701 52 22.6753V18.1623L47 18V22.6429C47 22.8701 47.0852 23 47.2273 23Z"
      fill="white"
    />
    <path
      d="M64.0671 19.0429C65.9151 21.5276 68.7765 23 71.8167 23C74.8867 23 77.7481 21.4663 79 18.3988L64.4844 18L64.0671 18.6135C63.9776 18.7362 63.9776 18.9202 64.0671 19.0429Z"
      fill="white"
    />
    <path
      d="M78.2195 23H83.2642C83.41 23 83.5266 22.872 83.5558 22.744L84.1973 19.672H89.8545L90.496 22.744C90.5251 22.904 90.6418 23 90.7876 23H95.8032C95.9781 23 96.0365 22.84 95.9781 22.584L95.1616 19.448L78.9485 19L78.0153 22.584C77.957 22.84 78.0737 23 78.2195 23Z"
      fill="white"
    />
    <path
      d="M107.414 22.73C107.443 22.88 107.532 23 107.68 23H112.025C112.172 23 112.261 22.91 112.291 22.73L113 20.15L107 20L107.414 22.73Z"
      fill="white"
    />
    <path
      d="M99.0435 22H103.304C103.449 22 103.536 21.8879 103.565 21.7477L104 19.1402L98 19L98.8116 21.7757C98.8116 21.9159 98.8986 22 99.0435 22Z"
      fill="white"
    />
    <path
      d="M41.6957 23C42.7391 23 43.7826 22.8469 44.7681 22.5102C44.913 22.449 45 22.3265 45 22.1735V17.3367L33 17C34.5362 20.6735 37.5797 23 41.6957 23Z"
      fill="white"
    />
    <path
      d="M8 29.0601H6.00613V28.2876C6.00613 28.1588 5.91411 28.0815 5.73006 28.0815H5.33129C5.14724 28.0815 5.05521 28.1588 5.05521 28.2876V31.7124C5.05521 31.8412 5.14724 31.9185 5.33129 31.9185H5.76074C5.94479 31.9185 6.03681 31.8412 6.03681 31.7124V30.7082H5.45399V29.6781H8V31.8412C8 32.6137 7.44785 33 6.31288 33H4.68712C3.55215 33 3 32.6137 3 31.8412V28.1588C3 27.3863 3.55215 27 4.68712 27H6.31288C7.44785 27 8 27.3863 8 28.1588V29.0601Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.8529 32.0948L28.0882 33H30L28.1765 27H25.8235L24 33H25.9118L26.1471 32.0948H27.8529ZM27.5882 30.931H26.4706L27.0588 28.7586L27.5882 30.931Z"
      fill="white"
    />
    <path
      d="M46.6018 33H45V27H46.9276L48.0136 30.2069L49.0724 27H51V33H49.4253V29.1983L48.6109 31.7328H47.3891L46.5747 29.2241L46.6018 33Z"
      fill="white"
    />
    <path d="M68 27H70V33H68V27Z" fill="white" />
    <path d="M90.2353 27H92V33H90.3529L88.7647 29.8448V33H87V27H88.6471L90.2353 30.2069V27Z" fill="white" />
    <path
      d="M113 29.0601H111.006V28.2876C111.006 28.1588 110.914 28.0815 110.73 28.0815H110.301C110.117 28.0815 110.025 28.1588 110.025 28.2876V31.7124C110.025 31.8412 110.117 31.9185 110.301 31.9185H110.699C110.883 31.9185 110.975 31.8412 110.975 31.7124V30.7082H110.393V29.6781H113V31.8412C113 32.6137 112.448 33 111.313 33H109.687C108.552 33 108 32.6137 108 31.8412V28.1588C108 27.3863 108.552 27 109.687 27H111.313C112.448 27 113 27.3863 113 28.1588V29.0601Z"
      fill="white"
    />
  </svg>
);

IconProviderHacksawBig.propTypes = {
  className: PropTypes.string,
};
IconProviderHacksawBig.defaultProps = {
  className: null,
};
