import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Input from 'reactstrap/lib/Input';
import { IconMobileSearch } from 'components/icons/icon-mobile-search/icon-mobile-search';
import { IconSearchClose } from 'components/icons/icon-search-close/icon-search-close';

import './game-search.scss';

export class GameSearchInput extends Component {
  handleClick = (e) => {
    const { onClick } = this.props;
    e.preventDefault();

    setTimeout(() => onClick(), 50);
  }

  render() {
    const {
      onChange,
      searchValue,
      clearSearchValue,
      className,
      focused,
    } = this.props;

    return (
      <div className={classNames('position-relative d-flex align-items-center', className)}>
        <FormattedMessage id="games.search.search">
          {placeholder => (
            <Input
              onChange={onChange}
              value={searchValue}
              className={classNames('search-input px-5 py-1 border-1', { 'pr-4_5': searchValue, 'search-input-focused': focused })}
              placeholder={placeholder}
              onClick={this.handleClick}
              focused={focused}
            />
          )}
        </FormattedMessage>

        <IconMobileSearch className="icon-search position-absolute ml-1_5" />
        <IconSearchClose onClick={clearSearchValue} className="icon-close position-absolute dropdown-menu-right mr-1 cursor-pointer" />
      </div>
    );
  }
}

GameSearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  clearSearchValue: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  focused: PropTypes.bool,
};

GameSearchInput.defaultProps = {
  className: '',
  onClick: null,
  focused: false,
};

