import { IconGamblersAnonymous } from 'components/icons/icons-footer/icon-gamblers-anonymous';
import { IconGamecare } from 'components/icons/icons-footer/icon-gamecare';
import { IconVisa } from 'components/icons/icons-footer/icon-visa';
import { IconPaybol } from 'components/icons/icons-footer/icon-paybol';
import { IconCrypto } from 'components/icons/icons-footer/icon-crypto';
import { IconJeton } from 'components/icons/icons-footer/icon-jeton';
import { IconBankTransferWhite } from 'components/icons/icons-footer/icon-bank-transfer-white';
// import { IconFacebook } from 'components/icons/icons-social/icon-facebook';
import { IconInstagram } from 'components/icons/icons-social/icon-instagram';
import { IconTwitter } from 'components/icons/icons-social/icon-twitter';
import { IconTiktok } from 'components/icons/icons-social/icon-tiktok';
// import { IconPinterest } from 'components/icons/icons-social/icon-pinterest';
import { IconTelegram } from 'components/icons/icons-social/icon-telegram';
import { IconYouTube } from 'components/icons/icons-social/icon-youtube';
// import { IconReddit } from 'components/icons/icons-social/icon-reddit';
import { IconPopypara } from 'components/icons/icons-footer/icon-popypara';
import { IconPaypay } from 'components/icons/icons-footer/icon-paypay';
import { IconParazula } from 'components/icons/icons-footer/icon-parazula';
import { IconPayco } from 'components/icons/icons-footer/icon-payco';
import { IconPep } from 'components/icons/icons-footer/icon-pep';
import { IconPapara } from 'components/icons/icons-footer/icon-papara';
import { IconPayfix } from 'components/icons/icons-footer/icon-payfix';

import { MODAL_IDS } from '../../constants';

export const footerMenu = [
  {
    title: 'footer.link.games',
    link: '/games',
  },
  {
    title: 'footer.link.live',
    link: '/games/live',
  },
  {
    title: 'footer.link.sports',
    link: '/sportsbook',
  },
  {
    title: 'footer.link.in-play',
    link: '/in-play?page=/live',
  },
  {
    title: 'footer.link.slot-games',
    link: '/games/slot',
  },
  {
    title: 'footer.link.card',
    link: '/games/card',
  },
  {
    title: 'footer.link.tournaments',
    link: '/tournaments',
  },
  {
    title: 'footer.link.promos',
    link: '/promos',
  },
];

export const footerInfo = [
  [
    {
      title: 'footer.link.payments',
      link: '/payments',
    },
    {
      title: 'footer.link.support',
      modalId: MODAL_IDS.SUPPORT,
    },
    {
      title: 'footer.link.about',
      link: '/about',
    },
    {
      title: 'footer.link.terms',
      link: '/terms',
    },
    {
      title: 'footer.link.bonus-terms',
      link: '/bonus-terms',
    },
    {
      title: 'footer.link.aml',
      link: '/anti-money-laundering',
    },
    {
      title: 'footer.link.refund-policy',
      link: '/refund-policy',
    },
    {
      title: 'footer.link.privacy-policy',
      link: '/privacy-policy',
    },
  ],
  [
    {
      title: 'footer.link.affiliates',
      link: '/affiliate',
    },
    {
      title: 'footer.link.responsible-gaming',
      link: '/responsible-gaming',
    },
    {
      title: 'footer.link.faq',
      link: '/faq',
    },
    {
      title: 'footer.link.self-exclusion',
      link: '/self-exclusion',
    },
    {
      title: 'footer.link.dispute-resolution',
      link: '/dispute-resolution',
    },
    {
      title: 'footer.link.fairness-and-rng',
      link: '/fairness-and-rng',
    },
  ]
];

export const footerPaymentIcons = [
  {
    title: 'Visa',
    icon: IconVisa,
  },
  {
    title: 'Popypara',
    icon: IconPopypara,
  },
  {
    title: 'PayPay',
    icon: IconPaypay
  },
  {
    title: 'Parazula',
    icon: IconParazula
  },
  {
    title: 'Payco',
    icon: IconPayco
  },
  {
    title: 'Pep',
    icon: IconPep
  },
  {
    title: 'Papara',
    icon: IconPapara
  },
  {
    title: 'Payfix',
    icon: IconPayfix
  },
  {
    title: 'Paybol',
    icon: IconPaybol,
  },
  {
    title: 'Crypto',
    icon: IconCrypto,
  },
  {
    title: 'Jeton',
    icon: IconJeton,
  },
  {
    title: 'BankTransfer',
    icon: IconBankTransferWhite,
  },
];

export const footerIconsWithLink = [
  {
    title: 'Gamecare',
    Icon: IconGamecare,
    link: 'https://www.gamblingtherapy.org/en',
  },
  {
    title: 'GamblersAnonymous',
    Icon: IconGamblersAnonymous,
    link: 'https://www.gamblersanonymous.org.uk',
  },
];

export const getFooterSocialIconsWithLink = formatMessage => [
  // {
  //   id: 'facebook',
  //   Icon: IconFacebook,
  //   link: formatMessage({ id: 'footer.social.url.facebook', defaultMessage: 'none' }),
  // },
  {
    id: 'instagram',
    Icon: IconInstagram,
    link: formatMessage({ id: 'footer.social.url.instagram', defaultMessage: 'none' }),
  },
  {
    id: 'twitter',
    Icon: IconTwitter,
    link: formatMessage({ id: 'footer.social.url.twitter', defaultMessage: 'none' }),
  },
  {
    id: 'telegram',
    Icon: IconTelegram,
    link: formatMessage({ id: 'footer.social.url.telegram', defaultMessage: 'none' }),
  },
  {
    id: 'tiktok',
    Icon: IconTiktok,
    link: formatMessage({ id: 'footer.social.url.tiktok', defaultMessage: 'none' }),
  },
  {
    id: 'youtube',
    Icon: IconYouTube,
    link: formatMessage({ id: 'footer.social.url.youtube', defaultMessage: 'none' }),
  },
  // {
  //   id: 'pinterest',
  //   Icon: IconPinterest,
  //   link: formatMessage({ id: 'footer.social.url.pinterest', defaultMessage: 'none' }),
  // },
  // {
  //   id: 'reddit',
  //   Icon: IconReddit,
  //   link: formatMessage({ id: 'footer.social.url.reddit', defaultMessage: 'none' }),
  // },
];
