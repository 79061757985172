import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderPlaytechLive = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.5503 3.17403C22.3223 0.349137 17.3181 -0.238169 11.9158 1.61597C11.1878 1.27135 9.75591 0.276331 9.75591 0.276331C8.91135 -0.388636 7.90176 0.252062 7.90176 1.08206V3.44584C3.27127 6.12998 0.601693 9.98873 1.77631 12.6826C3.13051 15.7987 9.1055 16.1967 15.1242 13.5757C21.138 10.9449 24.9045 6.29501 23.5503 3.17403ZM9.13462 2.57702C9.13462 2.00427 9.83842 1.56743 10.4257 2.02854L16.0173 5.33882C16.6919 5.65916 16.6434 6.2853 16.0318 6.60565L10.2558 9.93534C9.75591 10.2314 9.12977 9.87224 9.12977 9.29464L9.13462 2.57702ZM9.13462 14.5124C7.61539 14.794 5.90201 14.4979 5.32927 14.1047C3.16934 12.6049 4.21775 10.5518 4.21775 10.5518C4.21775 10.5518 4.17892 12.4059 6.45049 13.1971C10.0277 14.4348 14.6388 12.1487 14.7407 12.3282C14.7796 12.4302 11.6877 14.0368 9.13462 14.5124ZM15.4397 10.8576C11.9547 13.1582 7.86779 13.8863 6.48446 12.0225C5.67874 10.9352 6.30487 9.0471 7.90176 7.24149V10.9061C7.90176 11.7361 8.80942 12.2603 9.52292 11.838L17.8568 6.96968C18.7402 6.49887 18.8033 5.59121 17.8326 5.11554C17.8326 5.11554 15.3572 3.82444 14.4883 3.23713C16.6191 2.67409 18.5752 2.77602 19.4198 3.9652C20.4876 5.48928 18.9295 8.5423 15.4397 10.8576ZM21.4681 4.07198C21.4438 4.2273 21.3419 4.2273 21.2788 4.08654C21.0215 3.5769 19.7741 2.25667 17.1676 2.22269C16.0076 2.19842 14.7407 2.46538 13.7311 2.66924C13.5418 2.52848 13.3331 2.37316 13.1196 2.2324C13.6049 2.05281 14.2554 1.83439 15.1484 1.582C16.8618 1.09662 18.7645 1.18399 19.4634 1.4558C21.138 2.07708 21.57 3.40701 21.4681 4.07198Z"
      fill="white"
    />
    <path d="M4 18H5.2376V22.9109H7.04096V24H4V18Z" fill="white" />
    <path d="M8.42648 18H9.65524V24H8.42648V18Z" fill="white" />
    <path d="M11.085 18H12.3579L13.9933 22.2624L15.6508 18H16.9238L14.5812 24H13.3878L11.085 18Z" fill="white" />
    <path d="M18.4507 18H22V19.1176H19.6795V20.2026H22V21.2998H19.6795V22.8783H22V24H18.4507V18Z" fill="white" />
  </svg>
);

IconProviderPlaytechLive.propTypes = {
  className: PropTypes.string,
};

IconProviderPlaytechLive.defaultProps = {
  className: '',
};
