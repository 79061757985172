import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconSuccess = ({ className, color }) => (
  <svg className={className} width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle r="30.0714" transform="matrix(-1 0 0 1 32.0008 32)" stroke={color} strokeWidth="3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M47.7016 25.5737C48.4826 24.7927 48.4826 23.5264 47.7016 22.7453C46.9206 21.9643 45.6542 21.9643 44.8732 22.7453L29.1442 38.4742L20.5588 29.8882C19.7778 29.1071 18.5114 29.1071 17.7304 29.8881C16.9493 30.6691 16.9492 31.9355 17.7303 32.7165L27.7303 42.7172C28.5113 43.4983 29.7776 43.4984 30.5587 42.7173C30.5693 42.7067 30.5798 42.696 30.5901 42.6853L47.7016 25.5737Z" fill={color} />
  </svg>
);

IconSuccess.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconSuccess.defaultProps = {
  className: null,
  color: colors.green2,
};
