import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderPlayson = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.63875 21.6354V5.11358L18.4993 12.0993L10.6265 15.9803V12.0993C10.6265 11.2122 9.96122 10.5469 9.07414 10.5469C8.18707 10.5469 7.52176 11.2122 7.52176 12.0993V18.6415C7.52176 19.5286 8.18707 20.1939 9.07414 20.1939C9.29591 20.1939 9.51768 20.083 9.73945 19.9721L22.713 13.4299C23.4892 12.9864 23.8218 12.0993 23.3783 11.3231C23.2674 10.9905 22.9347 10.7687 22.713 10.6578L3.64079 1.12173C2.8646 0.678188 1.86664 1.01084 1.53398 1.78703C1.53398 2.11969 1.4231 2.34146 1.4231 2.56323V21.6354C1.4231 22.5225 2.0884 23.1878 2.97548 23.1878C3.86256 23.1878 4.63875 22.5225 4.63875 21.6354Z"
      fill="#FF002A"
    />
  </svg>
);

IconProviderPlayson.propTypes = {
  className: PropTypes.string,
};

IconProviderPlayson.defaultProps = {
  className: '',
};
