import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as R from 'ramda';
import Form from 'reactstrap/lib/Form';
import classNames from 'classnames';
import { FormProcessDeposit as FormProcessDepositCore } from 'core/components';
import { withCoreComponent, withLocale } from 'core/hocs';
import { getConfig, isSumValueValid, isEmptyOrNil } from 'core/helpers';
import { FORM_FIELDS, PAYMENT_METHODS } from 'core/constants';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { FormElement } from 'components/form-element/form-element';
import { DepositSum } from 'components/deposit/deposit-new/deposit-sum/deposit-sum';
import { BonusSelection } from 'components/deposit/deposit-new/bonus-selection/bonus-selection';
import { formatAmountWithCurrency } from '../../../helpers/numbers';
import { GA } from '../../../helpers/ga';
import { YM } from '../../../helpers/ym';

const DEFAULT_BONUS_OPTION = getConfig('PROCESS_DEPOSIT.DEFAULT_BONUS_OPTION');
const STEPS = { START: 0, FINISH: 1 };

const specialFieldsList = [
  FORM_FIELDS.AMOUNT,
  FORM_FIELDS.BONUS_PLAN,
];

export class FormProcessDepositUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    fields: PropTypes.shape().isRequired,
    isInProgress: PropTypes.bool.isRequired,
    currentAmount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number]).isRequired,
    setAmount: PropTypes.func.isRequired,
    userCurrency: PropTypes.string.isRequired,
    minimumDeposit: PropTypes.number,
    maximumDeposit: PropTypes.number,
    activeDepositMethod: PropTypes.string.isRequired,
    depositBonuses: PropTypes.arrayOf(PropTypes.shape({})),
    setBonusPlanId: PropTypes.func.isRequired,
    currentBonusPlanId: PropTypes.string,
    getDepositBonuses: PropTypes.func.isRequired,
    checkErrors: PropTypes.func.isRequired,
    depositBonusesIsInProgress: PropTypes.bool.isRequired,
    isModal: PropTypes.bool.isRequired,
    updateModalAlignment: PropTypes.func,
  };

  static defaultProps = {
    currentBonusPlanId: '',
    depositBonuses: [],
    minimumDeposit: null,
    maximumDeposit: null,
    updateModalAlignment: null,
  };

  state = {
    step: STEPS.START,
    fieldsInState: null,
    optionalFields: {},
    isNecessaryFinishStep: false,
  };

  static getDerivedStateFromProps(props, prevState) {
    const { fields } = props;
    const { fieldsInState } = prevState;

    if (!R.equals(fields, fieldsInState) && !R.isNil(fields)) {
      const optionalFields = R.reject(field => R.includes(R.prop('name', field), specialFieldsList), fields);

      return {
        fieldsInState: fields,
        optionalFields,
        isNecessaryFinishStep: !R.isEmpty(optionalFields),
      };
    }

    return null;
  }

  goToFinish = () => {
    const { checkErrors } = this.props;
    const errorSpecialFieldsList = checkErrors(specialFieldsList);

    if (R.isEmpty(errorSpecialFieldsList)) {
      this.setState({ step: STEPS.FINISH });
    }
  };

  goToStart = () => {
    this.setState({ step: STEPS.START });
  };

  handleChange = (val) => {
    const {
      getDepositBonuses,
      activeDepositMethod,
      maximumDeposit,
      currentAmount,
    } = this.props;

    if (val === '') {
      return null;
    }

    // isSumValueValid check the symbol entered by the user.
    // If val not a number (possibly a fractional number with two decimal places) will happen return
    if (!isSumValueValid(val) || val > maximumDeposit) {
      return false;
    }

    if (val !== String(currentAmount)) {
      getDepositBonuses({
        depositAmount: val,
        paymentMethod: activeDepositMethod,
      });
    }

    return null;
  };

  sumChangeHandler = (val) => {
    const { setAmount } = this.props;

    setAmount(val);
    this.handleChange(val);
  };

  defineCurrentBonus = (bonus) => {
    const { depositBonuses } = this.props;

    if (isEmptyOrNil(depositBonuses) || bonus === DEFAULT_BONUS_OPTION) {
      return null;
    }

    return R.find(R.propEq('bonusPlanId', bonus))(depositBonuses);
  };

  buttonContentWithoutBonus = (titleIntlKey) => {
    const { locale, userCurrency, currentAmount } = this.props;

    return (
      <Fragment>
        <FormattedMessage id={titleIntlKey} />
        <span>&#32;</span>
        {currentAmount !== 0 && (
          <span className="text-uppercase">
            {formatAmountWithCurrency({
              amount: currentAmount,
              currency: userCurrency,
              isIsoFormat: true,
              locale,
            })}
          </span>
        )}
      </Fragment>
    );
  }

  getFieldsElements = () => {
    const { optionalFields } = this.state;

    return Object.values(optionalFields).map(item =>
      <FormElement key={item.name} coreProps={{ item }} />);
  };

  getDepositHint = () => {
    const { activeDepositMethod } = this.props;

    const isHintRequired = [
      PAYMENT_METHODS.TRPAY_BANKWIRE,
      PAYMENT_METHODS.TRPAY_BANKWIRE2,
    ].includes(activeDepositMethod);

    return isHintRequired && (
      <div className="text-danger text-center pt-2 pt-sm-2_5 px-1_5 px-sm-3">
        <FormattedMessage id="profile.deposit-new.hint" />
      </div>
    );
  }

  onSubmit = (e) => {
    const { onSubmit } = this.props;
    GA.event({ category: 'deposit', action: 'click', label: 'DepSecond' });
    YM.event('DepSecond');
    onSubmit(e);
  };

  render() {
    const {
      fields,
      isInProgress,
      currentAmount,
      userCurrency,
      minimumDeposit,
      maximumDeposit,
      currentBonusPlanId,
      setBonusPlanId,
      depositBonuses,
      depositBonusesIsInProgress,
      updateModalAlignment,
      isModal,
      locale,
    } = this.props;

    const { step, isNecessaryFinishStep } = this.state;

    const formWrapperClasses = 'pt-2 pt-sm-2_5 px-1_5 px-sm-3';

    return (
      <Form onSubmit={this.onSubmit} className="form-process-deposit">
        {this.getDepositHint()}
        <div className={classNames('mb-1_5', formWrapperClasses)}>
          {step === STEPS.START && (
            <Fragment>
              {this.getFieldsElements()}

              <DepositSum
                currency={userCurrency}
                sumChangeHandler={this.sumChangeHandler}
                depositSumValue={currentAmount}
                minimumDeposit={minimumDeposit}
                maximumDeposit={maximumDeposit}
              />

              <FormElement
                className="position-relative"
                coreProps={{
                  item: fields.amount,
                  handleChange: this.handleChange,
                }}
                rightContent={userCurrency}
              />

              <BonusSelection
                bonusTypes={fields.requestedBonusPlanId.options}
                depositBonuses={depositBonuses}
                className="mt-1 mt-sm-2_5"
                bonusActive={currentBonusPlanId}
                changeBonusHandler={setBonusPlanId}
                currency={userCurrency}
                isInProgress={depositBonusesIsInProgress}
                isModal={isModal}
                updateModalAlignment={updateModalAlignment}
              />

              <FormElement
                coreProps={{ item: fields.requestedBonusPlanId }}
                classNames="d-none"
              />
            </Fragment>
          )}

          {step === STEPS.FINISH && isNecessaryFinishStep && (
            <Fragment>
              {this.getFieldsElements()}

              <div className="d-none d-sm-block">
                <DepositSum
                  currency={userCurrency}
                  sumChangeHandler={this.sumChangeHandler}
                  className="mt-1 mt-sm-2_5"
                  depositSumValue={currentAmount}
                  minimumDeposit={minimumDeposit}
                  maximumDeposit={maximumDeposit}
                />

                <FormElement
                  className="position-relative"
                  coreProps={{ item: fields.amount }}
                  handleChange={this.handleChange}
                  rightContent={userCurrency}
                />

                <BonusSelection
                  bonusTypes={fields.requestedBonusPlanId.options}
                  depositBonuses={depositBonuses}
                  className="mt-1 mt-sm-2_5"
                  bonusActive={currentBonusPlanId}
                  changeBonusHandler={setBonusPlanId}
                  currency={userCurrency}
                  isInProgress={depositBonusesIsInProgress}
                  isModal={isModal}
                />
                <FormElement
                  coreProps={{ item: fields.requestedBonusPlanId }}
                  classNames="d-none"
                />
              </div>

            </Fragment>
          )}

          {step === STEPS.START && (
            <Fragment>
              <ButtonWithLoader
                isLoading={isInProgress}
                color="primary"
                type="submit"
                className={classNames('d-none d-sm-inline-block py-1 w-100 font-weight-semi-bold h4 button-submit', { 'd-inline-block': !isNecessaryFinishStep })}
              >
                <FormattedMessage id="profile.deposit-new.deposit" />
              </ButtonWithLoader>

              {isNecessaryFinishStep && (
                <ButtonWithLoader
                  isLoading={isInProgress}
                  color="primary"
                  onClick={this.goToFinish}
                  className={classNames('d-inline-block d-sm-none w-100 font-weight-semi-bold h4 button-submit', { 'd-none': !isNecessaryFinishStep })}
                  disabled={isEmptyOrNil(currentAmount) || currentAmount === 0 || R.isNil(currentBonusPlanId)}
                >
                  <FormattedMessage id="profile.deposit-new.deposit" />
                </ButtonWithLoader>
              )}
            </Fragment>
          )}

          {step === STEPS.FINISH && isNecessaryFinishStep && (
            <ButtonWithLoader
              isLoading={isInProgress}
              className="w-100 mt-1 mt-sm-1_5 font-weight-semi-bold button-submit h4"
              color="primary"
              type="submit"
            >
              <FormattedMessage id="profile.deposit-new.deposit" />
            </ButtonWithLoader>
          )}
        </div>
        <div className="d-inline caption pl-1_5 pl-sm-3 font-weight-normal text-gray-40">
          <FormattedMessage id="min" />:
          <span className="mr-2 text-white font-weight-semi-bold">&#32;{formatAmountWithCurrency({ amount: minimumDeposit, currency: userCurrency, locale })}</span>
          <FormattedMessage id="max" />:
          <span className="text-white font-weight-semi-bold">&#32;{formatAmountWithCurrency({ amount: maximumDeposit, currency: userCurrency, locale })}</span>
        </div>
      </Form>
    );
  }
}

export const FormProcessDeposit = withLocale(withCoreComponent(
  FormProcessDepositCore,
  FormProcessDepositUI
));
