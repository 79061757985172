/**
 * Available fields params:
 *    type - type of the field (required)
 *    label - intl key for label
 *    placeholder - intl key for placeholder
 *    validation - array of validations for the field ({ type: 'some-validation', value: 'some-value' })
 *    isReadOnly - flag for readonly
 *    isDisabled - flag for disabled
 *    isOptionsLocalized - flag for localized options (only for the fields with options)
 *    defaultValue - default value
 *
 * Available validations: required, min-length, max-length, email, username-or-email,
 * password, passwords-equality, date, birth-date,
 */
module.exports = {
  email: {
    type: 'text',
    placeholder: 'form.fields.email',
    validation: [
      { type: 'required' },
      { type: 'email' },
    ],
  },
  username: {
    type: 'text',
    placeholder: 'form.fields.username',
    validation: [
      { type: 'min-length', value: 8 },
      { type: 'max-length', value: 16 },
      { type: 'required' },
      { type: 'not-email' },
    ],
  },
  password: {
    type: 'password',
    placeholder: 'form.fields.password',
    validation: [
      { type: 'required' },
      { type: 'min-length', value: 6 },
      { type: 'max-length', value: 16 },
      { type: 'password' },
    ],
  },
  mobile: {
    type: 'tel',
    placeholder: 'form.fields.mobile',
    validation: [
      { type: 'required' },
    ],
  },
  currency: {
    type: 'select',
    isOptionsLocalized: true,
    validation: [
      { type: 'required' },
    ],
  },
  terms: {
    type: 'checkbox',
    label: 'form.fields.terms-and-conditions',
    placeholder: 'form.fields.terms-and-conditions',
    validation: [
      { type: 'required' },
    ],
  },
  receiveEmail: {
    type: 'checkbox',
    label: 'form.fields.receive-email',
    placeholder: 'form.fields.receive-email',
    defaultValue: true,
  },
};
