import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PAYMENT_METHODS_CATEGORIES } from 'core/constants';

import { PaymentsMenu } from 'components/payments-menu/payments-menu';

import './deposit-options.scss';

export const DepositOptions = ({
  className,
  items,
  currency,
  handleDepositOptions,
  isModal,
  handleModalClose,
  activeDepositMethod,
  toggleDepositNew,
  step,
  isTabs,
}) =>
  (
    <div
      className={classNames('deposit-options position-relative d-flex flex-column px-2 px-sm-3', className)}
    >
      {isModal && (
        <button
          type="button"
          onClick={handleModalClose}
          className={classNames('close-button mt-1 d-sm-none')}
        >&times;
        </button>
      )}
      <PaymentsMenu
        isTabs={isTabs}
        step={step}
        activePaymentMethod={activeDepositMethod}
        toggleDepositNew={toggleDepositNew}
        isModal={isModal}
        otherItems={items[PAYMENT_METHODS_CATEGORIES.OTHER]}
        recentlyItems={items[PAYMENT_METHODS_CATEGORIES.RECENTLY]}
        handleSelectedCard={handleDepositOptions}
        currency={currency}
      />
    </div>
  );

DepositOptions.propTypes = {
  isTabs: PropTypes.bool,
  className: PropTypes.string,
  handleDepositOptions: PropTypes.func.isRequired,
  items: PropTypes.shape({
    recently: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        paymentMethod: PropTypes.string,
        minimumDeposit: PropTypes.number,
        feeRate: PropTypes.number,
        conversionRequired: PropTypes.bool,
        maximumDeposit: PropTypes.number,
        popupRequired: PropTypes.bool,
        method: PropTypes.string,
      }),
    ),
    other: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  currency: PropTypes.string.isRequired,
  isModal: PropTypes.bool,
  handleModalClose: PropTypes.func,
  activeDepositMethod: PropTypes.string,
  toggleDepositNew: PropTypes.func,
  step: PropTypes.number,
};

DepositOptions.defaultProps = {
  isTabs: false,
  className: '',
  isModal: false,
  handleModalClose: null,
  activeDepositMethod: null,
  toggleDepositNew: () => {},
  step: null,
};
