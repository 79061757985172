import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NewestGames as NewestGamesCore, FormattedTag } from 'core/components';
import { withCoreComponent } from 'core/hocs';
import { isEmptyOrNil, getStaticUrl } from 'core/helpers';

import { ImageLazyLoad, LAZY_LOAD_TYPES } from 'components/image-lazy-load/image-lazy-load';
import { IconPlayCircle } from 'components/icons/icon-play-circle/icon-play-circle';

import './news.scss';

const DEFAULT_THUMB_PATH = `${getStaticUrl()}/images/default-game-bg/thumb.jpg`;

export class NewsUI extends Component {
  static propTypes = {
    games: PropTypes.arrayOf(PropTypes.shape({})),
    openGame: PropTypes.func.isRequired,
  };

  static defaultProps = {
    games: [],
  };

  openGame = (e) => {
    const {
      dataset: {
        id: gameId,
        producer,
      },
    } = e.currentTarget;

    const { openGame } = this.props;

    openGame({ gameId, producer });
  };

  render() {
    const { games } = this.props;

    if (isEmptyOrNil(games)) {
      return (
        <div className="mt-2_5 px-1_25 text-center">
          <FormattedTag tag="div" id="sidebar.news.empty" className="text-small text-gray-20" />
        </div>
      );
    }

    return (
      <div className="news pb-1">
        {!isEmptyOrNil(games) && games.map(({
          gameId,
          producer,
          thumbPath,
          title,
        }) => (
          <div
            key={`${gameId}-${producer}`}
            className="news-item d-flex px-2 pt-1_75 pb-1_5 mt-1_5 bg-black"
            role="button"
            tabIndex="0"
            title={title}
            data-id={gameId}
            data-producer={producer}
            onClick={this.openGame}
            onKeyPress={this.openGame}
          >
            <div className="rounded">
              <ImageLazyLoad
                src="/assets/public/images/transparent-pixel.png"
                className="news-game-image flex-shrink-0 rounded"
                classNameLoaded="news-game-image-loaded"
                classNameError="news-game-image-error"
                srcLazy={thumbPath}
                type={LAZY_LOAD_TYPES.BACKGROUND}
                srcFallback={DEFAULT_THUMB_PATH}
              >
                <div
                  className="news-game-image-overlay d-flex justify-content-center align-items-center rounded"
                  role="button"
                  tabIndex="0"
                  data-id={gameId}
                  data-producer={producer}
                  onClick={this.openGame}
                  onKeyPress={this.openGame}
                >
                  <IconPlayCircle className="news-game-play-icon" isSmall />
                </div>
              </ImageLazyLoad>
            </div>

            <div className="ml-1_75 text-truncate d-flex flex-column justify-content-between">
              <FormattedTag id="sidebar.new-game" className="text-truncate text-small text-gray-20" />

              <span className="h4 font-weight-semi-bold text-nowrap text-gray-40">{title}</span>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export const News = withCoreComponent(NewestGamesCore, NewsUI);
