import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderFazi = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="107"
    height="41"
    viewBox="0 0 107 41"
    fill="none"
  >
    <path
      d="M106.314 32.3009C105.644 33.1922 104.798 33.4418 103.67 33.4418C96.9022 33.4061 90.1342 33.3704 83.3662 33.4418C81.4275 33.4774 80.7225 32.6574 80.7225 30.8391C80.793 23.9583 80.793 17.0418 80.7225 10.1609C80.6872 8.23566 81.5684 7.62958 83.2957 7.62958C90.0989 7.66523 96.9374 7.66523 103.741 7.62958C104.833 7.62958 105.715 7.91479 106.314 8.91306C106.314 16.6852 106.314 24.4931 106.314 32.3009ZM95.316 28.5218C95.316 24.6357 95.316 20.8922 95.316 17.1131C94.0822 17.1131 92.9189 17.1131 91.7204 17.1131C91.7204 20.9635 91.7204 24.707 91.7204 28.5218C92.9542 28.5218 94.1175 28.5218 95.316 28.5218ZM91.7557 12.0148C91.7557 13.0487 91.7557 13.9757 91.7557 14.9026C93.0247 14.9026 94.188 14.9026 95.3512 14.9026C95.3512 13.8687 95.3512 12.9774 95.3512 11.9078C94.3995 11.9078 93.5535 11.9078 92.6722 11.9078C92.355 11.9078 92.0377 11.9791 91.7557 12.0148Z"
      fill="#E4383F"
    />
    <path
      d="M106.314 4.84868C105.715 5.45477 104.975 5.59737 104.129 5.59737C97.0433 5.56172 89.958 5.59737 82.8728 5.59737C82.5203 5.59737 82.1678 5.63303 81.8153 5.52607C81.216 5.38346 80.7578 4.99129 80.793 4.3139C80.8283 3.6365 81.2865 3.27998 81.9563 3.24433C82.1325 3.24433 82.3088 3.24433 82.485 3.24433C89.8523 3.24433 97.1843 3.24433 104.552 3.20868C105.327 3.20868 105.891 3.42259 106.314 4.06433C106.314 4.3139 106.314 4.56346 106.314 4.84868Z"
      fill="#E4383F"
    />
    <path
      d="M106.314 0.82C105.856 0.926956 105.398 1.06957 104.939 1.06957C97.3605 1.06957 89.7465 1.06957 82.1678 1.06957C81.9915 1.06957 81.8153 1.06957 81.639 1.06957C80.8283 0.998261 80.793 0.926956 81.075 0C89.4998 0 97.9245 0 106.349 0C106.314 0.285217 106.314 0.534782 106.314 0.82Z"
      fill="#E4383F"
    />
    <path
      d="M25.521 27.987C25.521 31.4809 25.521 34.9748 25.521 38.4687C25.521 40.287 24.957 40.8574 23.124 40.8574C16.2503 40.8574 9.3765 40.8574 2.50275 40.8574C0.563999 40.8574 0 40.287 0 38.2548C0 31.3383 0 24.4574 0 17.5409C0 15.58 0.493499 15.0809 2.43225 15.0809C9.306 15.0809 16.1797 15.0809 23.0535 15.0809C25.0627 15.0809 25.4858 15.5087 25.4858 17.5052C25.4858 20.9991 25.4857 24.493 25.521 27.987ZM16.0387 22.0687C16.1092 21.2487 16.215 20.5 16.2855 19.7157C14.523 19.4304 12.831 19.2522 11.3857 20.4287C9.90525 21.6409 9.48225 23.3165 9.87 25.313C9.2355 25.313 8.70675 25.313 8.178 25.313C8.178 26.133 8.178 26.8104 8.178 27.5235C8.8125 27.5948 9.34125 27.6304 10.011 27.7017C10.011 30.34 10.011 32.8713 10.011 35.4026C11.1742 35.4026 12.1965 35.4026 13.3245 35.4026C13.3245 32.7644 13.3245 30.233 13.3245 27.6661C14.1352 27.6304 14.8403 27.5948 15.5453 27.5591C15.5453 26.7748 15.5453 26.0617 15.5453 25.2061C14.7698 25.2061 14.0647 25.2061 13.3597 25.2061C13.0777 22.4965 13.395 22.14 16.0387 22.0687Z"
      fill="#464240"
    />
    <path
      d="M66.693 15.0809C70.1827 15.0809 73.7077 15.0809 77.1975 15.0809C78.7485 15.0809 79.383 15.687 79.383 17.2557C79.383 24.3861 79.4182 31.5165 79.383 38.647C79.383 40.18 78.7132 40.8574 77.1975 40.8574C70.1475 40.8574 63.0975 40.8574 56.0475 40.8574C54.4965 40.8574 53.8972 40.2513 53.8972 38.647C53.8972 31.5165 53.8972 24.3861 53.8972 17.2557C53.8972 15.58 54.3907 15.0809 56.0475 15.0809C59.5725 15.0809 63.1327 15.0809 66.693 15.0809ZM71.5222 22.8887C68.1735 22.8887 64.9657 22.8887 61.7227 22.8887C61.7227 23.7443 61.7227 24.493 61.7227 25.3487C63.5557 25.3487 65.3535 25.3487 67.2922 25.3487C65.5297 27.773 63.9082 30.0904 62.2162 32.3009C61.5112 33.1922 61.3702 34.0835 61.5817 35.1887C65.001 35.1887 68.385 35.1887 71.6985 35.1887C71.6985 34.2974 71.6985 33.513 71.6985 32.6217C69.7245 32.6217 67.8562 32.6217 65.9527 32.6217C65.988 32.4435 65.9527 32.3365 65.988 32.2652C67.6095 30.0191 69.1252 27.7017 70.923 25.6339C71.628 24.6713 71.4165 23.887 71.5222 22.8887Z"
      fill="#464240"
    />
    <path
      d="M52.3815 27.8443C52.3815 31.4452 52.3815 35.0104 52.3815 38.6113C52.3815 40.3226 51.8527 40.8574 50.1607 40.8574C43.2165 40.8574 36.237 40.8574 29.2927 40.8574C27.5302 40.8574 27.0015 40.3226 27.0015 38.54C27.0015 31.4452 27.0015 24.3861 27.0015 17.2913C27.0015 15.58 27.495 15.0809 29.2222 15.0809C36.1665 15.0809 43.146 15.0809 50.0902 15.0809C51.9232 15.0809 52.3815 15.5087 52.3815 17.3626C52.3815 20.8565 52.3815 24.3504 52.3815 27.8443ZM44.7675 35.1887C44.697 33.5487 44.5912 32.087 44.5207 30.6252C44.4855 29.5557 44.5207 28.4861 44.5207 27.4165C44.5207 24.6713 43.6042 23.3522 40.9957 22.7104C38.9512 22.2113 36.942 22.4609 35.3205 24.0652C34.7212 24.6713 34.263 25.3843 34.5802 26.347C35.5672 26.347 36.5542 26.347 37.4707 26.347C38.1052 25.0991 39.057 24.6713 40.1497 25.0991C41.1015 25.4913 41.3482 26.3113 40.9252 27.9513C39.9382 27.9513 38.916 27.8443 37.929 27.987C37.083 28.0939 36.2017 28.3435 35.4262 28.7713C34.0515 29.52 33.7342 30.8391 33.8752 32.3365C33.981 33.8339 34.827 34.7609 36.096 35.1887C37.788 35.7591 39.4095 35.6165 40.7137 34.1191C40.8547 33.9765 40.9957 33.8696 41.2072 33.727C41.454 34.2974 41.6302 34.7252 41.8065 35.1887C42.8287 35.1887 43.8157 35.1887 44.7675 35.1887Z"
      fill="#E4383F"
    />
    <path
      d="M95.316 28.5217C94.0822 28.5217 92.9542 28.5217 91.7205 28.5217C91.7205 24.7426 91.7205 20.9635 91.7205 17.113C92.8837 17.113 94.0822 17.113 95.316 17.113C95.316 20.8922 95.316 24.6356 95.316 28.5217Z"
      fill="#FDFDFD"
    />
    <path
      d="M91.7557 12.0148C92.073 11.9791 92.355 11.9435 92.6722 11.9435C93.5535 11.9435 94.3995 11.9435 95.3512 11.9435C95.3512 12.9774 95.3512 13.9044 95.3512 14.9383C94.188 14.9383 93.0247 14.9383 91.7557 14.9383C91.7557 13.9757 91.7557 13.013 91.7557 12.0148Z"
      fill="#FDFDFD"
    />
    <path
      d="M16.0387 22.0687C13.4302 22.14 13.113 22.4965 13.3597 25.2061C14.0647 25.2061 14.7697 25.2061 15.5452 25.2061C15.5452 26.0617 15.5452 26.7748 15.5452 27.5591C14.805 27.5948 14.1352 27.6304 13.3245 27.6661C13.3245 30.233 13.3245 32.7643 13.3245 35.4026C12.1965 35.4026 11.139 35.4026 10.011 35.4026C10.011 32.8713 10.011 30.34 10.011 27.7017C9.37648 27.6304 8.81248 27.5948 8.17798 27.5235C8.17798 26.8104 8.17798 26.0974 8.17798 25.313C8.70673 25.313 9.23548 25.313 9.86998 25.313C9.51748 23.3522 9.90523 21.6409 11.3857 20.4287C12.831 19.2522 14.4877 19.4304 16.2855 19.7156C16.1797 20.5 16.1092 21.2487 16.0387 22.0687Z"
      fill="#FDFDFD"
    />
    <path
      d="M71.5223 22.8887C71.4518 23.8869 71.6633 24.7069 70.8878 25.5982C69.09 27.7017 67.5743 30.0191 65.9528 32.2295C65.9175 32.3009 65.9175 32.4078 65.9175 32.5861C67.821 32.5861 69.6893 32.5861 71.6633 32.5861C71.6633 33.4774 71.6633 34.2617 71.6633 35.153C68.3498 35.153 64.9658 35.153 61.5465 35.153C61.335 34.0835 61.476 33.1922 62.181 32.2652C63.9083 30.0548 65.4945 27.773 67.257 25.313C65.3183 25.313 63.5558 25.313 61.6875 25.313C61.6875 24.4574 61.6875 23.7087 61.6875 22.853C64.9305 22.8887 68.1383 22.8887 71.5223 22.8887Z"
      fill="#FDFDFD"
    />
    <path
      d="M44.7675 35.1887C43.851 35.1887 42.8287 35.1887 41.7712 35.1887C41.595 34.7252 41.3835 34.2974 41.172 33.727C40.9957 33.8696 40.8195 33.9765 40.6785 34.1192C39.4095 35.5809 37.7527 35.7592 36.0607 35.1887C34.7917 34.7609 33.9457 33.8339 33.84 32.3365C33.7342 30.8392 34.0515 29.52 35.391 28.7713C36.1312 28.3435 37.0477 28.0939 37.8937 27.987C38.8807 27.8444 39.903 27.9513 40.89 27.9513C41.313 26.3113 41.0662 25.4913 40.1145 25.0992C39.0217 24.6713 38.07 25.0992 37.4355 26.347C36.4837 26.347 35.532 26.347 34.545 26.347C34.2277 25.3487 34.686 24.6713 35.2852 24.0652C36.9067 22.4609 38.916 22.2113 40.9605 22.7105C43.569 23.3522 44.4855 24.6357 44.4855 27.4165C44.4855 28.4861 44.4502 29.5557 44.4855 30.6252C44.556 32.1226 44.6617 33.5844 44.7675 35.1887ZM41.031 29.8409C40.185 29.8409 39.3742 29.7339 38.5987 29.8765C37.5765 30.0192 36.9772 30.9818 37.1887 31.9444C37.365 32.8 38.2815 33.2635 39.3037 32.9783C40.5727 32.5505 41.5245 31.9444 41.031 29.8409Z"
      fill="#FDFDFD"
    />
    <path
      d="M41.031 29.8409C41.5245 31.9444 40.5727 32.5505 39.3037 32.9426C38.2815 33.2635 37.365 32.7644 37.1887 31.9087C37.0125 30.9461 37.5765 30.0192 38.5987 29.8409C39.3742 29.7696 40.185 29.8409 41.031 29.8409Z"
      fill="#E4383F"
    />
  </svg>
);

IconProviderFazi.propTypes = {
  className: PropTypes.string,
};

IconProviderFazi.defaultProps = {
  className: '',
};
