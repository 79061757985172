import React from 'react';
import PropTypes from 'prop-types';

export const IconLogoMobile = ({ className }) => (
  <svg className={className} width="86" height="30" viewBox="0 0 86 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#icon-logo-mobile-3)">
      <path fillRule="evenodd" clipRule="evenodd" d="M28.214 11.6767L28.2731 6.73919C30.6241 6.34349 32.8361 7.04664 32.9829 9.19559C33.1547 11.7102 30.4566 11.8999 28.214 11.6767ZM28.3139 0.913815C30.4853 0.860335 32.7561 1.02604 32.5344 3.45847C32.3349 5.64166 30.5318 5.91249 28.2768 5.88167L28.3069 2.52243L28.3139 0.913815ZM25.0136 12.4647C27.2916 12.4515 31.2482 13.0209 32.7048 11.7165C34.5313 10.0813 34.0924 8.00743 32.1148 6.41383L31.8302 6.16698C32.3862 5.54366 33.1359 5.02072 33.3174 3.75827C33.9458 -0.610762 28.1716 -0.0248529 25.1229 0.0894838L25.0136 12.465V12.4647Z" fill="#FFC328" />
      <path fillRule="evenodd" clipRule="evenodd" d="M38.1776 1.13453L41.9179 1.11846L41.8027 0.224313L34.8972 0.185059L34.8613 12.5756L41.8062 12.599L41.8258 11.7033L38.0378 11.6427L38.0329 9.19605L40.8645 9.1294L40.8253 8.27109L38.0547 8.22367L38.1776 1.13453Z" fill="#FFC328" />
      <path fillRule="evenodd" clipRule="evenodd" d="M52.6641 0.461913C51.8947 0.124699 44.4126 0.256423 42.9179 0.274601L42.9815 1.1561L46.2034 1.23593L46.1371 12.6593C47.1376 12.677 48.3971 12.8553 49.337 12.6098L49.4374 1.22802L52.6893 1.25173L52.6643 0.461649L52.6641 0.461913Z" fill="#FFC328" />
      <path fillRule="evenodd" clipRule="evenodd" d="M40.0961 23.6223L43.4042 23.6592L43.4871 28.1228L44.3129 28.1457C44.3786 26.6409 44.5961 16.3883 44.2965 15.7668L43.4909 15.7536L43.4216 22.7039L40.0924 22.7192L40.1238 15.7344L36.9164 15.7607L36.8906 28.1001L40.058 28.0985L40.0961 23.6223Z" fill="#DEDEDD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M60.8711 16.9547C62.9123 16.6006 64.6564 18.763 64.6105 20.672C64.5632 22.6183 62.8439 24.2812 60.8555 24.2427L60.8418 21.0081L60.8711 16.9547ZM57.5762 28.2914L60.7932 28.3391L60.8123 25.1904C66.5347 24.8055 66.9256 18.1481 62.7985 16.3503C61.3304 15.7109 59.3938 15.9502 57.6956 15.9225L57.5762 28.2914Z" fill="#DEDEDD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M79.4267 21.5355L85.7617 29.0459C85.7789 29.0156 85.8329 29.1023 85.908 29.1254L85.998 16.2196L85.1636 16.267C84.8348 16.9704 85.0399 21.9293 84.9747 23.2013C84.4123 22.7218 83.8593 21.9106 83.3871 21.32L80.257 17.4217C79.8082 16.8777 78.9993 16.0065 78.718 15.4116L78.46 21.9583C78.4614 24.0759 78.2858 26.4672 78.4471 28.5596L79.3432 28.5743L79.4267 21.5352V21.5355Z" fill="#DEDEDD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M49.7404 19.6825L51.7634 24.5945L47.8465 24.5708L49.4956 19.9937C49.6301 19.7779 49.611 19.8151 49.7407 19.6825H49.7404ZM45.3672 28.1869C46.6211 28.237 46.4122 28.2454 46.7822 27.1692C46.9822 26.5881 47.2173 25.999 47.442 25.4774L52.1242 25.5261L53.3226 28.227L56.487 28.2644L50.4518 15L45.3675 28.1869H45.3672Z" fill="#DEDEDD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M35.3369 27.096L35.3423 25.9013C32.592 27.1413 33.1219 27.8635 28.8945 26.7496L28.9417 16.9027C32.843 15.6853 33.0479 16.629 35.3967 17.8284L35.4257 16.6777C34.3504 16.2009 33.854 15.63 32.1821 15.4772C28.8671 15.1742 24.98 17.3382 24.9398 21.8012C24.9006 26.1568 28.7337 28.3714 31.8289 28.2225C33.5963 28.1377 34.1423 27.5618 35.3372 27.0963L35.3369 27.096Z" fill="#DEDEDD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M66.3594 28.4039L72.9637 28.4574L72.9156 27.5356L69.5206 27.5011C69.5759 25.9525 69.7603 16.8572 69.5053 16.0137L66.3916 16.0324L66.3594 28.4039Z" fill="#DEDEDD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M73.7657 28.4932L76.9855 28.5098L77.0692 16.13L73.8286 16.1029L73.7657 28.4932Z" fill="#DEDEDD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.9563 6.41693C17.9563 5.98729 17.7587 4.71351 17.5981 4.33326L17.2556 3.5585L15.8821 1.75585C15.6364 1.48955 15.336 1.29789 15.0322 1.06803L13.8868 0.467142C13.5631 0.308414 13.2267 0.259237 12.8905 0.125975L12.8169 0.0967764C12.8041 0.0926051 12.7907 0.0884338 12.7775 0.0847016L12.5612 0.0434279C12.1321 -0.0178241 10.2322 -0.020239 9.83935 0.0748223C9.59417 0.134098 9.77358 0.0919465 9.64943 0.142221C9.60558 0.160004 9.50872 0.195131 9.45883 0.208742C9.19956 0.279435 8.95818 0.318733 8.75349 0.429601L8.28796 0.632237C8.25776 0.644532 8.15307 0.693489 8.11571 0.71698L6.20661 2.24235C6.1999 2.2507 6.19117 2.26321 6.18469 2.27155C6.1782 2.28011 6.17037 2.29329 6.16388 2.30185L5.2977 3.62524C5.29546 3.63161 5.29166 3.6461 5.2892 3.65247C5.28674 3.65905 5.28114 3.67288 5.27824 3.67947C5.27309 3.69111 5.25385 3.72316 5.24759 3.73567C5.17578 3.8775 5.16594 3.94709 5.10128 4.1034L4.93843 4.49485C4.93395 4.50999 4.92098 4.55807 4.91628 4.57432L4.77557 5.25138C4.73128 5.4549 4.74649 5.34579 4.7136 5.44436C4.60399 5.77192 4.61696 6.26018 4.61875 6.61957C4.62591 7.98862 4.61898 9.35966 4.61898 10.7289C4.83239 10.8049 4.63128 10.7419 4.75163 10.8018C5.08428 10.9669 6.32562 11.0174 6.75335 11.0694L7.41148 11.1847C7.67456 11.2152 7.92489 11.1731 8.20765 11.215L12.0628 11.2872C13.0238 11.2872 14.0555 11.1944 15.0125 11.1889C15.2577 11.1876 15.4165 11.1105 15.7756 11.0754C15.9266 11.0604 15.9378 11.0429 16.1469 11.0339C16.4185 11.0225 16.6219 10.9902 16.8928 10.954C17.2997 10.8993 17.9558 10.932 17.9558 10.4249V6.41715L17.9563 6.41693Z" fill="#FFC328" />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.4121 22.0309C14.4617 22.1099 14.5248 22.1692 14.5827 22.2386C14.8239 22.1894 16.0247 21.5106 16.3496 21.313C16.9712 20.935 17.8128 20.8621 18.4191 21.2858L18.5425 21.3793C18.8513 21.6202 19.0662 21.9732 19.151 22.3824C19.1729 22.488 19.1799 22.553 19.1852 22.6287L19.1823 22.8509C19.1237 23.3714 18.858 23.9069 18.3871 24.1851L18.1542 24.3385C17.7289 24.7014 17.7958 25.5023 18.5821 25.6178L18.6376 25.627C18.7354 25.6349 18.8546 25.6066 18.9378 25.5605C19.6626 25.16 19.6624 24.9739 19.985 24.6957C20.1049 24.5923 20.3662 24.0698 20.4465 23.8768C20.4894 23.7739 20.5212 23.6889 20.5487 23.5771C20.6762 23.0619 20.6769 23.0153 20.6635 22.553C20.6617 22.4909 20.6767 22.4557 20.6671 22.3736C20.6328 22.081 20.4944 21.594 20.3599 21.3596C20.2398 21.1499 20.1472 20.9578 19.9781 20.7585L19.8519 20.6074C19.4544 20.1367 18.7654 19.7565 18.1719 19.6265C18.0616 19.6023 17.9761 19.5811 17.8591 19.5677C17.79 19.5598 17.7672 19.565 17.701 19.5565C17.6251 19.5466 17.6589 19.5407 17.5473 19.5387C16.8319 19.5259 16.2433 19.682 15.6429 20.039C15.6111 20.0579 15.5796 20.0728 15.5572 20.0851C15.4348 20.151 15.3284 20.2125 15.2102 20.2864C15.0125 20.4103 14.1027 20.8884 14.0114 20.9918C14.008 20.9956 14.0036 21.001 14.0004 21.005C13.9566 21.0621 13.9367 21.1618 13.9526 21.2384L14.4123 22.0309H14.4121Z" fill="#FFC328" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.598945 29.9869C0.653081 29.9761 0.769631 29.8966 0.818846 29.8655C0.859337 29.84 0.902288 29.8196 0.938528 29.8009L3.5778 28.3034C4.00038 28.0387 4.76545 27.6231 5.25335 27.3388L6.51661 26.6145C6.66023 26.5278 6.78617 26.4569 6.93069 26.3752C7.00697 26.3322 7.05663 26.3003 7.14544 26.2503L9.98851 24.6208C10.0283 24.5991 10.0511 24.5925 10.0959 24.5686C10.1565 24.5363 10.2167 24.4953 10.2925 24.4522C10.4404 24.3684 10.5809 24.2909 10.7073 24.2134C10.7643 24.1785 10.7836 24.1642 10.8205 24.1447C10.865 24.1212 10.8938 24.1122 10.9287 24.0931C11.3723 23.8514 12.2206 23.3271 12.607 23.1299L14.07 22.2913L13.613 21.5269C13.4696 21.5903 13.2779 21.7027 13.1405 21.7883C12.5989 22.1255 11.8005 22.5411 11.2144 22.8953L6.58417 25.5388C6.18933 25.7857 5.41374 26.2005 4.99295 26.4365L4.514 26.6742C4.31714 26.7596 4.20171 26.8628 4.06972 26.955L0.19471 29.1948C-0.212209 29.5287 0.0649612 30.0931 0.599168 29.9869H0.598945Z" fill="#FFC328" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1.82831 11.6423C1.82831 11.8504 1.88983 11.9408 1.98087 12.0504C2.08646 12.1775 2.14731 12.1966 2.3256 12.2702C2.33231 12.273 2.42336 12.3114 2.43343 12.3165L3.11416 12.5615C3.80854 12.7514 4.19645 12.8403 4.94608 12.9448C5.3237 12.9975 5.19506 13.0135 5.40445 13.0533C5.55299 13.0814 5.77446 13.102 5.91383 13.111L7.92605 13.2667C8.11531 13.2691 8.31015 13.2601 8.49918 13.2634C8.7173 13.2673 8.79828 13.3156 8.96203 13.3158H13.6144C13.7571 13.3158 13.8012 13.2651 13.9245 13.2651C15.0065 13.2645 16.0452 13.1569 17.0776 13.0394C17.191 13.0265 17.0689 13.0561 17.2098 13.0241L18.7668 12.7413C19.1276 12.6306 19.8276 12.4574 20.0529 12.3294C20.1981 12.2471 20.3135 12.1632 20.4891 12.149C20.513 11.8691 20.6441 11.9777 20.6443 11.6925C20.6443 11.3373 20.6976 11.1435 20.4108 10.9577C20.0128 10.7004 20.1177 10.9569 20.0238 10.7288H19.8173C19.7565 10.8767 19.8301 10.7599 19.4737 10.8989L18.8194 11.1189C18.8082 11.1235 18.7952 11.1303 18.784 11.1349L18.7118 11.1654C18.5941 11.2069 18.4634 11.2238 18.3312 11.2491C18.0431 11.3046 17.6561 11.3288 17.3736 11.4254C16.8387 11.608 14.7077 11.6622 14.4741 11.7305C14.0533 11.8535 13.9209 11.7766 13.3708 11.8041C12.0811 11.8688 10.9378 11.8449 9.63381 11.8449C9.48125 11.8449 9.28841 11.7924 8.80678 11.7944C7.96162 11.7975 8.41731 11.7095 7.67192 11.6939C7.22877 11.6846 5.54986 11.5373 5.20267 11.4251L2.65557 10.8302C1.96231 10.8302 1.82853 10.9942 1.82853 11.6418L1.82831 11.6423Z" fill="#FFC328" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.01337 19.011C9.12925 19.011 9.10531 18.8753 9.13462 18.775L9.38718 18.0084C9.45653 17.7902 9.60664 17.1952 9.84041 17.1342C9.84041 17.4554 9.86859 17.8793 9.82631 18.1858C9.79276 18.4295 9.73706 18.8756 9.73706 19.1634C10.1442 19.1634 10.3185 19.2648 10.6158 19.2648C12.0421 19.2648 11.7687 19.2721 12.9861 19.1559C13.2237 19.1333 13.6011 19.0556 13.8472 18.9864L14.5606 18.672C15.2057 18.2413 14.9241 17.7574 14.5814 16.8441C14.4677 16.5414 14.3503 16.3012 14.1467 16.0531C13.5074 15.2736 12.5123 15.3622 11.2876 15.3622C10.2833 15.3622 9.49881 15.2509 8.75387 15.8652C8.36217 16.1882 8.26195 16.4856 8.09484 16.9432C7.99238 17.2237 7.72058 17.8406 7.72058 18.0979C7.72058 18.6025 8.61607 19.0112 9.01292 19.011H9.01337Z" fill="#FFC328" />
    </g>
  </svg>
);

IconLogoMobile.propTypes = {
  className: PropTypes.string,
};

IconLogoMobile.defaultProps = {
  className: null,
};
