import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconWallet = ({ className, color }) => (
  <svg className={className} width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="icon-filled-path" d="M20.8168 14.0524C19.0811 14.0524 17.669 15.4645 17.669 17.2002C17.669 18.9359 19.0811 20.348 20.8168 20.348H25.2762C25.294 20.348 25.3113 20.3463 25.3289 20.3454C25.3463 20.3462 25.3635 20.348 25.3811 20.348C25.9606 20.348 26.4304 19.8782 26.4304 19.2987V11.062C26.4304 9.66507 25.7438 8.42614 24.691 7.66285L21.1213 1.80688C20.9758 1.56807 20.7409 1.39725 20.4689 1.3324C20.1968 1.26745 19.9101 1.31388 19.6724 1.4613L17.0399 3.09376L15.8528 1.08472C15.7101 0.843179 15.4766 0.668895 15.2045 0.600693C14.9324 0.53249 14.6442 0.576192 14.4045 0.721831L5.29652 6.86488H3.7169C3.14709 6.86488 2.6819 6.40835 2.66795 5.84185C2.6819 5.27534 3.14709 4.81881 3.7169 4.81881H11.658C12.2376 4.81881 12.7073 4.34905 12.7073 3.76954C12.7073 3.19003 12.2376 2.72027 11.658 2.72027H3.7169C2.23229 2.72027 0.98455 3.75348 0.654607 5.13857C0.59973 5.26585 0.569092 5.40593 0.569092 5.5533V5.81561V5.86808V23.2335C0.569092 25.5478 2.4519 27.4306 4.76617 27.4306H22.2333C24.0428 27.4306 25.6431 26.2779 26.2153 24.5623C26.3156 24.2617 26.4304 19.2987 26.4304 19.2987L24.3318 20.348C24.3318 20.348 24.408 23.3485 24.2247 23.8982C23.9386 24.7558 23.1384 25.332 22.2333 25.332H4.76617C3.60903 25.332 2.66763 24.3906 2.66763 23.2335V8.78342C2.996 8.89989 3.34913 8.96342 3.7169 8.96342H22.2333C23.3904 8.96342 24.3318 9.90483 24.3318 11.062V14.0524H20.8168ZM24.3318 18.2495H20.8168C20.2382 18.2495 19.7675 17.7788 19.7675 17.2002C19.7675 16.6216 20.2382 16.1509 20.8168 16.1509H24.3318V18.2495ZM9.3372 6.86493L14.5866 3.06695L15.7039 4.95778C15.7254 5.00946 15.75 5.0604 15.7805 5.10951C15.8033 5.14628 15.8286 5.18054 15.855 5.21349L16.8308 6.86493H9.3372ZM19.2683 6.86488L18.1078 4.90086L19.8798 3.80201L21.7469 6.86488H19.2683Z" fill={color} />
  </svg>
);

IconWallet.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconWallet.defaultProps = {
  className: '',
  color: colors.gray30,
};
