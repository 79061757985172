import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderBetsoftBig = ({ className }) => (
  <svg
    className={className}
    width="110"
    height="33"
    viewBox="0 0 110 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#bet-soft-big-clip-0)">
      <path
        d="M20.627 15.8163C21.2381 14.9515 21.5504 13.9861 21.5584 12.9166L21.545 5.38501C21.5544 5.2175 21.553 5.06241 21.5396 4.91848C21.4096 3.53252 20.7932 2.36618 19.6889 1.4207C18.5847 0.476461 17.2754 0.0024821 15.7597 0.0024821L10.3644 0L10.3711 15.8163H20.627ZM17.0368 4.05737C17.3987 4.36881 17.601 4.76338 17.6466 5.24108C17.6506 5.28947 17.656 5.33662 17.66 5.38501L17.6734 12.9166C17.668 13.4191 17.4777 13.8434 17.1012 14.1896C16.7246 14.5358 16.2676 14.7095 15.7302 14.7083L14.1958 14.707L14.1931 3.58959L15.7275 3.59083C16.2395 3.59083 16.675 3.74593 17.0368 4.05737Z"
        fill="white"
      />
      <path
        d="M21.9175 19.6242C21.8331 18.7234 21.5369 17.9095 21.0303 17.1824H10.3737L10.3804 32.9962L16.1604 32.9987C17.7524 32.9999 19.11 32.4738 20.2316 31.4229C21.3533 30.3719 21.9215 29.1051 21.9363 27.6223L21.9229 20.0908C21.9322 19.9233 21.9309 19.7682 21.9175 19.6242ZM18.0486 27.6199C18.0446 28.1224 17.8543 28.5467 17.4777 28.8929C17.1011 29.2391 16.6562 29.4128 16.1456 29.4128L14.2279 29.4115L14.2333 18.2941L16.1671 18.2954C16.6575 18.2954 17.0837 18.4517 17.4482 18.7619C17.8127 19.0733 18.0164 19.4679 18.062 19.9456C18.066 19.994 18.0714 20.0411 18.0754 20.0895L18.0486 27.6199Z"
        fill="white"
      />
      <path
        d="M37.4562 3.59828L41.3037 3.59456L41.2984 15.7803H45.1458L45.1512 3.59083L48.6141 3.58587L48.6288 0L37.471 0.0124067L37.4562 3.59828Z"
        fill="white"
      />
      <path d="M45.1365 32.995L45.1445 17.1464H41.297L41.289 32.9987L45.1365 32.995Z" fill="white" />
      <path
        d="M24.722 0L24.7166 15.7803H34.7219L34.7273 14.7033L28.5426 14.7058L28.548 3.58959L34.6911 3.5958L34.7032 0.00992651L24.722 0Z"
        fill="white"
      />
      <path
        d="M34.7277 29.4215L28.5645 29.4079L28.5685 18.2917L34.7116 18.2966L34.717 17.1464H24.717L24.7117 32.99L34.6929 32.9987L34.7277 29.4215Z"
        fill="white"
      />
      <path
        d="M90.5437 3.59083L94.4032 3.58959L94.4019 15.7803H98.2601L98.2628 3.58835L101.735 3.58711L101.75 0L90.5584 0.00372221L90.5437 3.59083Z"
        fill="#FAE307"
      />
      <path d="M98.2575 32.9975L98.2602 17.1464H94.4006L94.3979 32.9987L98.2575 32.9975Z" fill="#FAE307" />
      <path
        d="M78.9803 0.00620431L78.9736 15.7803H89.0687L89.062 14.7033L82.8452 14.707L82.8519 3.59083L89.0674 3.58711L89.0808 0L78.9803 0.00620431Z"
        fill="#FAE307"
      />
      <path
        d="M78.9744 17.1464L78.9677 32.9987L82.8526 32.9962L82.8714 18.2929L89.0882 18.2892L89.0801 17.1464H78.9744Z"
        fill="#FAE307"
      />
      <path
        d="M68.6836 15.7803L68.6971 5.38251C68.7024 4.87999 68.8941 4.45564 69.2746 4.10822C69.6539 3.76204 70.1002 3.58709 70.6148 3.58709C71.128 3.58709 71.5676 3.74219 71.9308 4.05239C72.2939 4.36258 72.4976 4.75715 72.5419 5.2361C72.5459 5.28449 72.5512 5.33164 72.5553 5.38003L72.5486 15.7815H76.3813L76.388 5.37755C76.3974 5.21004 76.3961 5.05494 76.3827 4.91101C76.254 3.52505 75.6416 2.35996 74.5454 1.41572C73.4478 0.47148 72.1465 0.0012207 70.6389 0.0012207C69.0535 0.00246149 67.704 0.529796 66.5877 1.58198C65.4714 2.63541 64.9045 3.90225 64.8884 5.38499L64.8817 15.7803H68.6836Z"
        fill="#FAE307"
      />
      <path
        d="M72.5471 17.1464L72.5404 27.6174C72.535 28.1199 72.3421 28.5442 71.9615 28.8904C71.5809 29.2378 71.1319 29.4116 70.616 29.4116C70.1001 29.4116 69.6592 29.2565 69.296 28.9463C68.9328 28.6361 68.7278 28.2415 68.6836 27.7625C68.6795 27.7154 68.6742 27.667 68.6702 27.6186L68.6836 17.1451H64.883L64.8763 27.6223C64.8656 27.7898 64.8682 27.9449 64.8803 28.0889C65.009 29.4761 65.62 30.6412 66.7176 31.5842C67.8138 32.5284 69.1164 32.9987 70.624 32.9987C72.2081 32.9974 73.5589 32.4713 74.6752 31.4179C75.7915 30.3657 76.3584 29.0976 76.3745 27.6149L76.3812 17.1464H72.5471Z"
        fill="#FAE307"
      />
      <path
        d="M52.8905 15.7803H59.189C58.4962 15.243 57.7993 14.7058 57.0958 14.1685C55.8508 13.1163 55.1553 11.7775 55.0092 10.1521C54.9851 9.88904 54.973 9.61359 54.973 9.32697L55.0025 5.38127C55.0079 4.87875 55.2008 4.4544 55.5787 4.10822C55.9567 3.76204 56.4002 3.58833 56.9122 3.58833C57.4228 3.58833 57.8596 3.74343 58.2201 4.05363C58.5793 4.36506 58.7816 4.75963 58.8245 5.23734C58.8285 5.28573 58.8339 5.33288 58.8379 5.38127L58.8326 7.89138L62.668 7.89014L62.6733 5.38003C62.6841 5.21252 62.6827 5.05742 62.6707 4.91349C62.546 3.52753 61.9363 2.3612 60.8427 1.41696C59.7479 0.47272 58.4466 0.0012207 56.939 0.0012207C55.3536 0.0012207 54.0001 0.528556 52.8811 1.58074C51.7607 2.63417 51.1912 3.90101 51.1698 5.38375L51.159 10.4052C51.1443 10.8122 51.1537 11.2068 51.1885 11.5889C51.3386 13.259 51.9055 14.6574 52.8905 15.7803Z"
        fill="#FAE307"
      />
      <path
        d="M60.8172 17.1464H54.4999C55.2276 17.7085 55.9606 18.2693 56.699 18.8314C57.9507 19.8836 58.5873 21.2224 58.7333 22.8478C58.7575 23.1109 58.7494 23.6903 58.7534 23.673L58.7615 27.6187C58.7548 28.1212 58.5605 28.5455 58.1785 28.8917C57.7966 29.2391 57.3477 29.4116 56.8304 29.4128C56.3131 29.4128 55.8735 29.2577 55.5104 28.9475C55.1472 28.6373 54.9435 28.2428 54.9006 27.7638C54.8966 27.7167 54.8912 27.6683 54.8872 27.6199L54.9314 25.1085L51.096 25.1098L51.0906 27.6211C51.0799 27.7886 51.0813 27.9437 51.0933 28.0877C51.218 29.4749 51.8264 30.64 52.9212 31.5842C54.0161 32.5284 55.316 32.9999 56.825 32.9999C58.4104 32.9999 59.7625 32.4739 60.8829 31.4204C62.0032 30.3682 62.5741 29.1002 62.5942 27.6174L62.6049 22.596C62.6197 22.1902 62.609 21.7944 62.5755 21.4122C62.424 19.7037 61.837 18.2817 60.8172 17.1464Z"
        fill="#FAE307"
      />
      <path d="M-0.000106812 22.5972V10.1943L6.76079 16.4529L-0.000106812 22.5972Z" fill="#FAE307" />
      <path d="M109.999 22.6742V10.27L103.238 16.4715L109.999 22.6742Z" fill="#FAE307" />
    </g>
    <defs>
      <clipPath id="bet-soft-big-clip-0">
        <rect width="110" height="33" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconProviderBetsoftBig.propTypes = {
  className: PropTypes.string,
};
IconProviderBetsoftBig.defaultProps = {
  className: null,
};
