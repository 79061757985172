import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withLocale,
  withModalActions,
  withUser,
  withUserAgent,
} from 'core/hocs';
import { USER_FIELDS } from 'core/constants';
import { wrapRoute2Locale } from 'core/helpers';

import { AUTH_TYPES, MODAL_IDS } from '../../constants';

class SignUp extends Component {
  componentDidMount() {
    const {
      location: {
        search,
      },
      history,
      locale,
      isPwa,
      isUserLoggedIn,
      openModalAndCloseOthers,
    } = this.props;

    const redirectUrl = wrapRoute2Locale(`/${search}${isPwa ? '?launch_from=pwa' : ''}`, locale);

    if (!isUserLoggedIn) {
      openModalAndCloseOthers({
        id: MODAL_IDS.SIGN_UP,
        data: AUTH_TYPES.SIGN_UP,
      });
    }

    history.push(redirectUrl);
  }

  render() {
    return null;
  }
}

SignUp.propTypes = {
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  locale: PropTypes.string.isRequired,
  isPwa: PropTypes.bool.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  openModalAndCloseOthers: PropTypes.func.isRequired,
};

export default withModalActions(
  withLocale(
    withUserAgent(
      withUser(
        SignUp, [
          USER_FIELDS.IS_USER_LOGGED_IN,
        ]
      )
    )
  )
);
