import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderNolimitBig = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="118"
    height="32"
    viewBox="0 0 118 32"
    fill="none"
  >
    <path
      d="M27.9177 25.7515C27.4522 25.7306 27.008 25.9476 26.7382 26.3275C26.4325 26.8013 26.2853 27.3599 26.3177 27.9229C26.3177 29.3461 26.851 30.0577 27.9177 30.0577C28.4746 30.035 29.0242 29.9223 29.5451 29.724V30.8577C28.987 31.0802 28.3905 31.1905 27.7897 31.1823C26.9908 31.2348 26.2106 30.9261 25.664 30.3412C25.1308 29.6506 24.869 28.7887 24.928 27.9183C24.9144 27.3164 25.0379 26.7192 25.2891 26.172C25.5128 25.6958 25.8743 25.2977 26.3268 25.0292C26.8104 24.7522 27.3606 24.6131 27.9177 24.6269C28.5662 24.6345 29.2043 24.7909 29.7828 25.084L29.3257 26.1812C29.0923 26.0685 28.8527 25.9693 28.608 25.884C28.3864 25.8034 28.1534 25.7587 27.9177 25.7515Z"
      fill="white"
    />
    <path d="M46.5325 31.0955V24.7366H47.9039V31.1137L46.5325 31.0955Z" fill="white" />
    <path d="M67.5793 31.0955H66.2079V25.8429H64.4753V24.7366H69.2891V25.8612H67.5611L67.5793 31.0955Z" fill="white" />
    <path
      d="M87.904 27.3469L89.2343 24.7183H90.6926L88.576 28.6132V31.0955H87.232V28.6589L85.12 24.7366H86.5829L87.904 27.3469Z"
      fill="white"
    />
    <path
      d="M91.0035 7.78116H89.5132C89.2435 7.78116 89.2115 7.78116 89.1795 7.5023C89.122 6.91116 88.9676 6.33352 88.7223 5.79258C88.2029 4.58226 87.0549 3.76 85.7417 3.65773C84.9736 3.56114 84.1937 3.6329 83.456 3.86801C82.4268 4.19397 81.5916 4.95395 81.1703 5.94801C80.9606 6.42979 80.822 6.9395 80.7589 7.46116V7.62573C80.7618 7.66738 80.7462 7.70818 80.7162 7.73726C80.6863 7.76634 80.645 7.78073 80.6035 7.77659H77.2983C77.2559 7.77781 77.2147 7.76213 77.1838 7.73297C77.153 7.70382 77.1349 7.6636 77.1337 7.62116C77.1017 7.43373 77.0789 7.24173 77.0332 7.0543C76.8964 6.40206 76.6373 5.78161 76.2697 5.22573C75.6255 4.25228 74.5475 3.65359 73.3806 3.62116C72.858 3.58506 72.3329 3.6251 71.8217 3.74001C70.5653 4.01356 69.5328 4.90489 69.0789 6.10801C68.8943 6.54522 68.7757 7.0074 68.7269 7.47944C68.6995 7.77659 68.6903 7.78116 68.3657 7.78116H65.6229C65.5132 7.78116 65.4035 7.78116 65.2937 7.78116C65.184 7.78116 65.0789 7.74001 65.0972 7.54801C65.1155 7.35601 65.1475 7.14116 65.1795 6.93544C65.3917 5.61099 65.8774 4.34526 66.6057 3.21887C67.6548 1.59729 69.3367 0.490928 71.2412 0.169728C71.8439 0.0558871 72.4564 0.00228988 73.0697 0.00972782C74.5598 0.00775438 76.0044 0.523199 77.1566 1.46801C77.7006 1.91298 78.1914 2.41918 78.6195 2.97658L78.848 3.27373C78.8661 3.30461 78.8991 3.3236 78.9349 3.3236C78.9706 3.3236 79.0037 3.30461 79.0217 3.27373L79.2823 2.95373C79.7304 2.39281 80.2429 1.88648 80.8092 1.44516C81.9845 0.525021 83.4308 0.0188074 84.9235 0.0051564C85.8753 -0.0241788 86.825 0.111937 87.7303 0.407442C89.214 0.893444 90.4773 1.88956 91.296 3.21887C91.971 4.29579 92.4277 5.49492 92.64 6.74801C92.6857 6.9903 92.7132 7.23716 92.7452 7.48401C92.7772 7.73087 92.7726 7.77659 92.4663 7.77659C92.16 7.77659 91.8857 7.77659 91.5977 7.77659L91.0035 7.78116Z"
      fill="#F0D906"
    />
    <path
      d="M20.1782 8.76402C20.6994 8.76402 21.2251 8.76402 21.7508 8.76402C21.9382 8.76402 21.9702 8.79602 21.9748 8.97888C21.9748 9.26688 21.9748 9.55488 21.9748 9.84745C21.9847 10.4737 22.1071 11.093 22.336 11.676C22.7336 12.7322 23.4891 13.6154 24.4708 14.172C25.0882 14.5144 25.7701 14.7246 26.4731 14.7892C27.3117 14.8839 28.1602 14.836 28.9828 14.6474C30.8098 14.253 32.2625 12.8692 32.7451 11.0634C32.8563 10.6801 32.9133 10.2831 32.9142 9.88402C32.9142 9.56859 32.9142 9.24859 32.9142 8.93316C32.9142 8.79145 32.9508 8.75031 33.0788 8.75031H36.3474C36.4754 8.75031 36.5074 8.79145 36.5074 8.93773C36.5726 9.9987 36.4786 11.0635 36.2285 12.0966C35.9279 13.262 35.4014 14.357 34.6788 15.3194C33.7222 16.564 32.4028 17.4809 30.9028 17.9434C29.9164 18.2588 28.8891 18.4283 27.8537 18.4463C26.7774 18.4864 25.7007 18.3787 24.6537 18.1263C22.1489 17.55 20.0847 15.7845 19.1268 13.3994C18.7277 12.5012 18.4776 11.5439 18.3862 10.5652C18.336 10.0212 18.3862 9.46345 18.3862 8.91488C18.3816 8.87278 18.3967 8.83091 18.427 8.80141C18.4574 8.77192 18.4997 8.75812 18.5417 8.76402H19.2868C19.5885 8.76402 19.8902 8.76402 20.2011 8.76402H20.1782Z"
      fill="#F0D906"
    />
    <path
      d="M32.9554 7.76288C32.9007 7.66241 32.8665 7.55209 32.8548 7.43831C32.5848 5.86369 31.4796 4.5606 29.9702 4.03717C29.3373 3.80043 28.6706 3.66617 27.9954 3.63945C27.0992 3.57639 26.1988 3.66134 25.3302 3.89088C24.2095 4.18045 23.2416 4.88679 22.6239 5.86574C22.3058 6.38487 22.0915 6.96088 21.9931 7.56174C21.9611 7.76745 21.9474 7.78117 21.7691 7.78117H18.5691C18.3771 7.78117 18.3405 7.73545 18.3679 7.56174C18.4091 7.30117 18.4548 7.04517 18.5097 6.78917C18.7744 5.54466 19.296 4.36912 20.0411 3.33774C21.0749 1.92198 22.5571 0.897355 24.2468 0.43031C25.0836 0.190893 25.9462 0.052763 26.8159 0.0188814C27.987 -0.0479857 29.1615 0.0629883 30.2994 0.348024C31.8002 0.708363 33.1614 1.50345 34.2125 2.63374C35.0978 3.59454 35.7542 4.74323 36.1325 5.99374C36.2641 6.40039 36.3664 6.81593 36.4388 7.23717C36.461 7.35809 36.4763 7.4802 36.4845 7.60288C36.4845 7.75831 36.4571 7.79945 36.2971 7.80402H33.2342C33.1406 7.79521 33.0475 7.78148 32.9554 7.76288Z"
      fill="#F0D906"
    />
    <path
      d="M3.86737 0.0920218C4.85937 0.0920218 5.85137 0.0920218 6.84337 0.0920218C7.50482 0.0823006 8.16409 0.170101 8.79994 0.352593C9.96065 0.651565 11.0491 1.18177 11.9999 1.91145C13.338 2.93927 14.2379 4.43516 14.5188 6.09888C14.5919 6.47831 14.6194 6.86231 14.6651 7.24631C14.6651 7.34078 14.6651 7.43678 14.6651 7.53431C14.6651 7.75374 14.6651 7.77659 14.4365 7.78574H11.2365C11.1946 7.79164 11.1523 7.77784 11.1219 7.74834C11.0915 7.71885 11.0764 7.67697 11.0811 7.63488C11.0796 7.35839 11.0505 7.08273 10.9942 6.81202C10.8925 6.19789 10.6465 5.61664 10.2765 5.11602C9.70541 4.37972 8.87478 3.88919 7.95423 3.74459C7.62896 3.68076 7.29827 3.64861 6.9668 3.64859H3.73937C3.66795 3.65348 3.61331 3.71419 3.61595 3.78574C3.61595 3.89545 3.61595 4.00516 3.61595 4.11488V7.42459C3.61595 7.79945 3.61595 7.79488 3.25937 7.79488H0.361088H0.109659C0.0518525 7.79028 0.00665347 7.74311 0.0045166 7.68516C0.0045166 7.58916 0.0045166 7.49317 0.0045166 7.39716V0.508022C0.0045166 0.0508789 0.0045166 0.0920218 0.461659 0.0920218H3.86737Z"
      fill="#F0D906"
    />
    <path
      d="M46.1669 18.46H40.7406C40.7063 18.4626 40.6725 18.4507 40.6473 18.4273C40.6222 18.4038 40.6079 18.371 40.608 18.3366C40.608 18.2406 40.608 18.1446 40.608 18.0486V9.23488C40.608 8.72288 40.5623 8.77774 41.0652 8.77774C41.9794 8.77774 42.8572 8.77774 43.7532 8.77774H44.0412C44.0759 8.77523 44.1101 8.78698 44.136 8.81027C44.1619 8.83355 44.1771 8.86638 44.1783 8.90117C44.1783 9.03831 44.1783 9.17545 44.1783 9.3126V14.3412C44.1783 14.8715 44.128 14.8349 44.6674 14.8349H51.3326C51.8446 14.8349 51.7897 14.7983 51.7897 15.292V17.98C51.7897 18.0897 51.7897 18.1995 51.7897 18.3092C51.7898 18.3436 51.7756 18.3764 51.7504 18.3998C51.7252 18.4233 51.6914 18.4351 51.6572 18.4326H46.1669V18.46Z"
      fill="#F0D906"
    />
    <path
      d="M111.003 0.00972672H116.878C116.913 0.00718425 116.948 0.0195389 116.974 0.0438247C117 0.0681104 117.015 0.102134 117.015 0.137727C117.02 0.219947 117.02 0.302364 117.015 0.384584V3.25087C117.02 3.33309 117.02 3.41551 117.015 3.49773C117.016 3.53327 117.003 3.56774 116.977 3.59289C116.952 3.61804 116.918 3.63159 116.882 3.6303H113.225C112.859 3.6303 112.859 3.6303 112.859 3.99144C112.859 5.07944 112.859 6.17201 112.859 7.26001C112.859 7.36973 112.859 7.47944 112.859 7.58916C112.859 7.69887 112.823 7.77658 112.654 7.78116H109.426C109.385 7.78503 109.344 7.77134 109.314 7.74348C109.283 7.71561 109.266 7.67616 109.266 7.63487C109.266 7.51144 109.266 7.38344 109.266 7.26001V4.19716C109.266 4.10116 109.266 4.00516 109.266 3.90916C109.266 3.64858 109.266 3.6303 108.978 3.6303H105.595C104.91 3.6303 105.006 3.71716 105.001 3.04516C105.001 2.1903 105.001 1.33544 105.001 0.480584C105.001 0.366298 105.001 0.256584 105.001 0.14687C105.001 0.112115 105.015 0.078845 105.04 0.0547045C105.065 0.030564 105.099 0.0176283 105.134 0.0188696H111.008L111.003 0.00972672Z"
      fill="#F0D906"
    />
    <path
      d="M97.2297 13.6234C97.2297 12.1194 97.2297 10.6154 97.2297 9.11602C97.2297 8.75488 97.1977 8.75031 97.5863 8.75031H100.69C100.732 8.7514 100.771 8.76971 100.799 8.80089C100.827 8.83207 100.84 8.87333 100.837 8.91488C100.837 8.99716 100.837 9.07945 100.837 9.16173V18.1354C100.837 18.4509 100.837 18.4554 100.512 18.4554H97.4126C97.2617 18.4554 97.2343 18.4189 97.2297 18.2497C97.2251 18.0806 97.2297 18.0257 97.2297 17.916V13.6189V13.6234Z"
      fill="#F0D906"
    />
    <path
      d="M89.1977 13.5915V9.12518C89.1977 8.74118 89.1657 8.75032 89.5863 8.75032H92.3977C92.4922 8.75032 92.5882 8.75032 92.6857 8.75032C92.7446 8.75027 92.793 8.79667 92.7954 8.85547C92.7954 8.97737 92.7954 9.1008 92.7954 9.22575V18.0715C92.7954 18.1446 92.7954 18.2132 92.7954 18.2818C92.7954 18.3503 92.7497 18.4372 92.6126 18.4463H89.5497C89.4675 18.4509 89.3851 18.4509 89.3028 18.4463C89.2458 18.444 89.2 18.3983 89.1977 18.3412C89.1977 18.2178 89.1977 18.0943 89.1977 17.9709V13.5869V13.5915Z"
      fill="#F0D906"
    />
    <path
      d="M80.7543 13.6189V18.044C80.7543 18.4783 80.7771 18.46 80.3611 18.46H77.5086C77.4263 18.4647 77.3439 18.4647 77.2617 18.46C77.2046 18.456 77.1584 18.4118 77.152 18.3549C77.1474 18.2727 77.1474 18.1902 77.152 18.108V9.16631C77.152 8.75488 77.12 8.75031 77.5497 8.75031H80.4023H80.6491C80.7062 8.75264 80.7519 8.79837 80.7543 8.85545C80.7543 8.97888 80.7543 9.10688 80.7543 9.23031V13.6097V13.6189Z"
      fill="#F0D906"
    />
    <path
      d="M56.512 13.6189V9.04745C56.512 8.73659 56.512 8.72745 56.8366 8.72745H59.9817C60.0231 8.72842 60.0622 8.74687 60.0892 8.77824C60.1162 8.80961 60.1286 8.85095 60.1235 8.89202C60.1235 8.96059 60.1235 9.03373 60.1235 9.1023V18.1994C60.1235 18.4097 60.096 18.4326 59.8766 18.4326H56.6766C56.5257 18.4326 56.4983 18.396 56.4892 18.2269C56.48 18.0577 56.4892 18.0029 56.4892 17.8932V13.5914L56.512 13.6189Z"
      fill="#F0D906"
    />
    <path
      d="M3.584 18.2726C3.49793 18.321 3.39849 18.3403 3.30057 18.3275H0.155429C0.064 18.3275 0 18.2772 0 18.1766C0 18.076 0 17.98 0 17.884V9.31718C0 9.19375 0 9.06575 0 8.94232C0 8.81889 0.0365714 8.76404 0.205714 8.75032H3.19543H3.488C3.52273 8.74753 3.55693 8.76012 3.58157 8.78475C3.60621 8.80939 3.61879 8.84359 3.616 8.87832C3.616 8.98804 3.616 9.09775 3.616 9.20746V17.8932C3.63017 18.0205 3.6193 18.1494 3.584 18.2726Z"
      fill="#F0D906"
    />
    <path
      d="M14.6926 13.6783V17.9343C14.6926 18.0303 14.6926 18.1309 14.6926 18.2269C14.6926 18.4372 14.6651 18.46 14.4503 18.46H11.3097H11.1863C11.1286 18.453 11.0853 18.4039 11.0857 18.3457C11.0857 18.2497 11.0857 18.1537 11.0857 18.0577V9.24402C11.0857 8.88744 11.0857 8.88287 11.4514 8.88287H14.5097C14.6606 8.88287 14.6926 8.92402 14.7017 9.09316C14.7108 9.2623 14.7017 9.34002 14.7017 9.46344L14.6926 13.6783Z"
      fill="#F0D906"
    />
    <path
      d="M65.1063 13.6189V9.27601C65.1063 9.15259 65.1063 9.02916 65.1063 8.90116C65.1038 8.86687 65.1156 8.83307 65.1391 8.80791C65.1625 8.78274 65.1954 8.76849 65.2297 8.76859H68.3337C68.3886 8.76859 68.4435 8.76859 68.4983 8.76859C68.6355 8.76859 68.672 8.80973 68.6812 8.93773C68.6903 9.06573 68.6812 9.12973 68.6812 9.22573V18.0806C68.6857 18.1628 68.6857 18.2452 68.6812 18.3274C68.6824 18.3622 68.6695 18.3959 68.6453 18.4209C68.6212 18.4459 68.5879 18.46 68.5532 18.46H65.3257C65.1246 18.46 65.1063 18.4372 65.0972 18.2543C65.0972 18.1446 65.0972 18.0349 65.0972 17.9206V13.6189H65.1063Z"
      fill="#F0D906"
    />
    <path
      d="M112.878 13.66V18.044C112.878 18.5012 112.878 18.46 112.48 18.46H109.422C109.348 18.46 109.289 18.4007 109.289 18.3274C109.289 18.2314 109.289 18.1354 109.289 18.0394V9.2623C109.289 8.70001 109.239 8.76401 109.81 8.76401C110.679 8.76401 111.547 8.76401 112.416 8.76401H112.745C112.78 8.76399 112.813 8.7781 112.837 8.8031C112.861 8.82811 112.874 8.86185 112.873 8.89658C112.873 9.02001 112.873 9.14801 112.873 9.27144V13.6554L112.878 13.66Z"
      fill="#F0D906"
    />
    <path
      d="M100.841 3.90916V7.26002C100.841 7.35602 100.841 7.45201 100.841 7.54802C100.841 7.75373 100.809 7.78116 100.594 7.78116H97.4492C97.2617 7.78116 97.2343 7.74916 97.2297 7.5663C97.2297 7.48401 97.2297 7.39716 97.2297 7.31487V0.508015C97.2297 0.412015 97.2297 0.316015 97.2297 0.220015C97.2297 0.124015 97.2617 0.037158 97.4309 0.0280151H100.631C100.823 0.0280151 100.846 0.0554437 100.855 0.242872C100.855 0.338872 100.855 0.434872 100.855 0.530872L100.841 3.90916Z"
      fill="#F0D906"
    />
    <path
      d="M56.512 3.89546V0.375456C56.512 0.00516987 56.512 0.0097413 56.8731 0.0097413H59.7668C59.849 0.00517575 59.9315 0.00517575 60.0137 0.0097413C60.0706 0.0162066 60.1148 0.0623296 60.1188 0.119456C60.1188 0.215456 60.1188 0.311456 60.1188 0.412027V7.36517C60.1188 7.79031 60.1188 7.7766 59.7165 7.7766H56.9051C56.8076 7.7766 56.7116 7.7766 56.6171 7.7766C56.5583 7.77415 56.5119 7.72574 56.512 7.66688C56.512 7.55717 56.512 7.44746 56.512 7.33774V3.89546Z"
      fill="#F0D906"
    />
    <path
      d="M44.1875 3.89546V7.41089C44.1875 7.7766 44.1875 7.78118 43.8309 7.78118H40.9372H40.7315C40.6967 7.7812 40.6635 7.76709 40.6393 7.74208C40.6152 7.71708 40.6022 7.68334 40.6035 7.6486C40.6035 7.53889 40.6035 7.4246 40.6035 7.31489V0.508032C40.6035 -0.0131107 40.5623 0.0508893 41.0606 0.0508893H44.0366C44.1372 0.0508893 44.1829 0.114889 44.1829 0.210889C44.1829 0.306889 44.1829 0.430318 44.1829 0.540032L44.1875 3.89546Z"
      fill="#F0D906"
    />
  </svg>
);

IconProviderNolimitBig.propTypes = {
  className: PropTypes.string,
};
IconProviderNolimitBig.defaultProps = {
  className: null,
};
