import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Form from 'reactstrap/lib/Form';
import Button from 'reactstrap/lib/Button';
import {
  FormattedTag,
  FormCompleteSignUp as FormCompleteSignUpCore,
} from 'core/components';
import {
  withCoreComponent, withModalActions, withFormDataActions, withUser
} from 'core/hocs';
import { getRandomPhoneNumberWithPrefix } from 'helpers/tel';
import { FORM_FIELDS, FORM_ACTIONS } from 'core/constants';
import { FormElement } from 'components/form-element/form-element';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { IconCheckCircle } from 'components/icons/icon-check-circle/icon-check-circle';
import { checkIsMobileLengthValidate } from '../../../helpers/form';
import { MODAL_IDS } from '../../../constants';

import './form-complete-sign-up.scss';

const STEPS = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
};

const STEPS_TEXTS = {
  [STEPS.FIRST]: {
    title: 'complete-profile',
    descr: 'fill-profile',
  },
  [STEPS.SECOND]: {
    title: 'address-and-phone',
    descr: 'fill-profile',
  },
};

const BIRTH_DATE_FIELDS_NAMES_LIST = [
  FORM_FIELDS.YEAR,
  FORM_FIELDS.MONTH,
  FORM_FIELDS.DAY,
];

export class FormCompleteSignUpUI extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    setFormData: PropTypes.func.isRequired,
    backToPrevStep: PropTypes.func.isRequired,
    openModalAndCloseOthers: PropTypes.func.isRequired,
    goToStep: PropTypes.func.isRequired,
    steps: PropTypes.shape().isRequired,
    countryInfo: PropTypes.shape().isRequired,
    isInProgress: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    isStepFilled: PropTypes.bool.isRequired,
    isSmsConfirmationActive: PropTypes.bool,
    mobile: PropTypes.string,
    userMobilePhone: PropTypes.string,
    step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    addExtraData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    step: null,
    mobile: null,
    userMobilePhone: null,
    isSmsConfirmationActive: false,
  };

  componentDidMount() {
    const {
      setFormData, userMobilePhone, countryInfo: { phonePrefix }, isSmsConfirmationActive
    } = this.props;

    if (isSmsConfirmationActive) {
      // used for users who were registered before the introduction of SMS confirmation
      const randomNumber = getRandomPhoneNumberWithPrefix(phonePrefix);

      setFormData(FORM_ACTIONS.COMPLETE_SIGN_UP, {
        [FORM_FIELDS.MOBILE]: userMobilePhone || randomNumber,
      });
    }
  }

  goToSecondStep = (e) => {
    const { goToStep } = this.props;

    goToStep(e, STEPS.SECOND);
  };

  onSubmit = (e) => {
    const {
      onSubmit, userMobilePhone, addExtraData, isSmsConfirmationActive
    } = this.props;

    if (isSmsConfirmationActive && !userMobilePhone) {
      addExtraData({ isFakeMobile: true });
    }

    onSubmit(e);
  };

  closeModal = () => {
    const { openModalAndCloseOthers } = this.props;
    openModalAndCloseOthers(MODAL_IDS.DEPOSIT);
  };

  render() {
    const {
      isInProgress,
      step,
      steps,
      backToPrevStep,
      mobile,
      isSuccess,
      isStepFilled,
      isSmsConfirmationActive,
      userMobilePhone,
    } = this.props;

    if (isSuccess) {
      return (
        <div className="text-center mt-13 mt-sm-0 pt-5 pt-sm-0">
          <IconCheckCircle className="mb-3 mt-1 mt-sm-0 mt-lg-1" />

          <FormattedTag
            className="text-center d-block font-weight-strong mb-1 text-gray-40 success-header"
            id="form.complete-sign-up.profile-completed"
          />

          <FormattedTag
            className="mb-3 mt-1 px-4 text-base d-block text-gray-20"
            id="form.complete-sign-up.information-saved"
          />

          <Button color="primary" className="w-100 mt-1" onClick={this.closeModal}>
            <FormattedMessage id="form.complete-sign-up.got-it" />
          </Button>
        </div>
      );
    }

    let stepForm = null;
    const currentStep = step || STEPS.FIRST;

    if (currentStep === STEPS.FIRST) {
      stepForm = (
        <Form onSubmit={this.goToSecondStep}>
          {Object.values(steps[STEPS.FIRST]).map(item => !BIRTH_DATE_FIELDS_NAMES_LIST.includes(item.name) && (
            <FormElement
              key={item.name}
              secondaryPlaceholder={item.name === FORM_FIELDS.BIRTH_DATE && 'form.fields.birth-date.mask'}
              coreProps={{ item }}
            />
          ))}

          <FormattedTag
            tag="div"
            id="form.fields.birth-date"
            className="form-element-label text-base d-inline-block text-gray-10 mb-1"
          />

          <div className="complete-sign-up-form-dates d-flex justify-content-between w-100">
            <FormElement
              coreProps={{ item: steps[STEPS.FIRST].year }}
            />

            <FormElement
              className="mx-1_5"
              coreProps={{ item: steps[STEPS.FIRST].month }}
            />

            <FormElement
              coreProps={{ item: steps[STEPS.FIRST].day }}
            />
          </div>

          <ButtonWithLoader
            isLoading={isInProgress}
            className="mt-1 w-100"
            color="primary"
            type="submit"
            disabled={!isStepFilled}
          >
            <FormattedMessage id="continue" />
          </ButtonWithLoader>
        </Form>
      );
    } else if (currentStep === STEPS.SECOND) {
      stepForm = (
        <Form onSubmit={this.onSubmit}>
          <FormElement
            coreProps={{ item: steps[STEPS.SECOND].country }}
          />

          <FormElement
            coreProps={{ item: steps[STEPS.SECOND].city }}
          />

          <FormElement
            coreProps={{ item: steps[STEPS.SECOND].postalCode }}
          />

          <FormElement
            coreProps={{ item: steps[STEPS.SECOND].address }}
          />

          {!isSmsConfirmationActive && !userMobilePhone && (
            <FormElement
              coreProps={{ item: steps[STEPS.SECOND].mobile }}
            />
          )}

          <div className="text-center mt-3_75 d-flex justify-content-between">
            <FormattedTag
              tag={ButtonWithLoader}
              onClick={backToPrevStep}
              id="back"
              className="sign-up-step-button mr-0_5 no-effects btn-outline-gray-5"
              disabled={isInProgress}
              color="white-20"
              outline
            />

            <FormattedTag
              tag={ButtonWithLoader}
              isLoading={isInProgress}
              color="primary"
              type="submit"
              className="sign-up-step-button ml-0_5"
              disabled={!isStepFilled || !checkIsMobileLengthValidate(mobile)}
              id="confirm"
            />
          </div>
        </Form>
      );
    }

    return (
      <Fragment>
        <div className="text-center">
          <FormattedTag className="h2" id={`form.complete-sign-up.${STEPS_TEXTS[currentStep].title}`} />
          <FormattedTag
            className="mb-2_5 mt-1 pt-0_25 px-9 d-block text-base text-gray-20 font-weight-normal line-height-20_18"
            id={`form.complete-sign-up.${STEPS_TEXTS[currentStep].descr}`}
            values={{ mobile }}
            isHtml
          />
        </div>

        {stepForm}
      </Fragment>
    );
  }
}

export const FormCompleteSignUp = withCoreComponent(
  FormCompleteSignUpCore,
  withModalActions(
    withFormDataActions(
      withUser(FormCompleteSignUpUI)
    )
  )
);
