import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { withGameActions } from 'core/hocs';
import { GAME_ITEM_KEYS, GAME_SCREEN_MODE } from 'core/constants';

import { GameCard } from 'components/game-card/game-card';
import './game-search.scss';

const { ID, PRODUCER } = GAME_ITEM_KEYS;

class GamesListUI extends Component {
  static propTypes = {
    games: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isUserLoggedIn: PropTypes.bool.isRequired,
    openGame: PropTypes.func.isRequired,
    toggleFavoriteGame: PropTypes.func.isRequired,
    isInProgress: PropTypes.bool,
    isGamePage: PropTypes.bool,
    setScreenMode: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isInProgress: false,
    isGamePage: false
  };

  setSingleScreenMode = () => {
    const { setScreenMode } = this.props;

    setScreenMode(GAME_SCREEN_MODE.SINGLE);
  }

  render() {
    const {
      games: preparedGamesList,
      isUserLoggedIn,
      toggleFavoriteGame,
      isInProgress,
      openGame,
      isGamePage
    } = this.props;

    return (!!preparedGamesList.length && !isInProgress)
       && (
         <div className="mt-2 pl-2 mr-1 pr-1 games-list">
           {preparedGamesList.map(game => (
             <div
               key={`${game[ID]}-${game[PRODUCER]}-${game.id}`}
             >
               <GameCard
                 {...game}
                 openGame={openGame}
                 setScreenMode={this.setSingleScreenMode}
                 isGamePage={isGamePage}
                 isUserLoggedIn={isUserLoggedIn}
                 toggleFavoriteGame={toggleFavoriteGame}
                 withJackpotAmount
               />
             </div>
           ))}
         </div>
       );
  }
}

export const GamesList = withGameActions(GamesListUI);
