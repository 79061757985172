import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import {
  LoyaltyPoints as LoyaltyPointsCore,
  FormattedTag,
  RouteLink,
} from 'core/components';
import {
  withCoreComponent,
  withModalActions,
  withGlobalEvents,
  withUser,
  withLocale,
} from 'core/hocs';
import { isEmptyOrNil } from 'core/helpers';
import { GLOBAL_EVENTS, USER_FIELDS, LOYALTY_POINTS_STATUSES } from 'core/constants';
import colors from 'customizations/js/color-variables';

import { MODAL_IDS, SIDE_MODAL_IDS } from '../../constants';
import { getAccumulatedPointsPercentage } from '../../helpers/loyalty-points';
import { formatAmountWithCurrency } from '../../helpers/numbers';
import { withPixelRatio } from '../../hocs/with-pixel-ratio';

import './user-info-and-loyalty.scss';

export class UserLoyaltyUI extends Component {
  static propTypes = {
    className: PropTypes.string,
    openModal: PropTypes.func.isRequired,
    openModalAndCloseOthers: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    globalEvents: PropTypes.shape().isRequired,
    loyaltyPointsAllData: PropTypes.shape(),
    accumulatedPoints: PropTypes.number,
    totalPoints: PropTypes.number,
    currentPoints: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
    remainingPoints: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
    nextLevel: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    isHighestLevel: PropTypes.bool,
    userInfoIsFetched: PropTypes.bool,
    withNewLevelEventSubscription: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    userBalance: PropTypes.shape(),
    userData: PropTypes.shape(),
    isSignUpCompleted: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    className: null,
    loyaltyPointsAllData: null,
    nextLevel: null,
    accumulatedPoints: null,
    totalPoints: null,
    currentPoints: null,
    remainingPoints: null,
    isHighestLevel: false,
    userInfoIsFetched: false,
    withNewLevelEventSubscription: true,
    userBalance: {},
    userData: {},
  };

  componentDidMount() {
    const {
      globalEvents,
      withNewLevelEventSubscription,
    } = this.props;

    if (withNewLevelEventSubscription) {
      globalEvents.on(
        GLOBAL_EVENTS.NEW_LEVEL,
        this.openNewLevelModal,
      );
    }
  }

  componentWillUnmount() {
    const {
      globalEvents,
      withNewLevelEventSubscription,
    } = this.props;

    if (withNewLevelEventSubscription) {
      globalEvents.off(
        GLOBAL_EVENTS.NEW_LEVEL,
        this.openNewLevelModal,
      );
    }
  }

  openNewLevelModal = () => {
    const { openModal } = this.props;
    openModal(MODAL_IDS.NEW_LEVEL);
  };

  openDepositModal = () => {
    const { openModalAndCloseOthers, isSignUpCompleted } = this.props;
    openModalAndCloseOthers(isSignUpCompleted ? MODAL_IDS.DEPOSIT : MODAL_IDS.COMPLETE_SIGN_UP);
  };

  closeProfileModal = () => {
    const { closeModal } = this.props;

    closeModal(MODAL_IDS.PROFILE);
    closeModal(SIDE_MODAL_IDS.SIDE_MODAL_BAR);
  };

  render() {
    const {
      className,
      loyaltyPointsAllData,
      accumulatedPoints,
      totalPoints,
      isHighestLevel,
      locale,
      userBalance,
      userData,
      userInfoIsFetched,
    } = this.props;

    const {
      firstName,
      vipStatus = '',
    } = userData || {};

    const {
      totalBalance,
      bonusBalance,
      withdrawableBalance,
      currency
    } = userBalance;

    const targetVipStatus = R.compose(
      R.toLower,
      R.trim,
    )(vipStatus || LOYALTY_POINTS_STATUSES.BRONZE);

    const currentProgress = isHighestLevel
      ? '100'
      : getAccumulatedPointsPercentage(accumulatedPoints, totalPoints);

    return (
      <div className={classNames('d-flex flex-column align-items-center user-info-and-loyalty w-100', className)}>
        <>
          <div className="d-flex align-items-center w-100 mb-3">
            <div className="user-info-and-loyalty-progress position-relative mr-1_75">
              <svg viewBox="0 0 34.91549431 34.91549431" width="72" height="72" xmlns="http://www.w3.org/2000/svg">
                <circle
                  stroke={colors.gray40}
                  fill="transparent"
                  strokeWidth="2"
                  cx="16.91549431"
                  cy="16.91549431"
                  r="15.91549431"
                  transform="translate(0.5, .4)"
                />

                {!isEmptyOrNil(loyaltyPointsAllData) && (
                  <circle
                    stroke={colors.primary}
                    strokeWidth="3"
                    strokeDasharray={`${currentProgress > 100 ? 100 : currentProgress},100`}
                    strokeLinecap="round"
                    fill="none"
                    cx="16.91549431"
                    cy="16.91549431"
                    r="15.91549431"
                    transform="rotate(270 16.91549431 16.91549431) translate(-0.4, 0.5)"
                  />
                )}

                <g>
                  <text
                    x="16.91549431"
                    y="16.5"
                    alignmentBaseline="central"
                    textAnchor="middle"
                    fill={colors.gray40}
                    fontSize="6.5"
                    fontWeight="bold"
                    transform=" translate(0, -1.5)"
                  >
                    {Math.floor(Number(accumulatedPoints) || 0)}LP
                  </text>

                  <text
                    x="16.91549431"
                    y="16.5"
                    alignmentBaseline="central"
                    textAnchor="middle"
                    fill={colors.gray20}
                    fontSize="4.5"
                    transform=" translate(0, 6)"
                  >
                    / {totalPoints}
                  </text>
                </g>
              </svg>
            </div>

            <div>
              <span className="d-block h4 mb-0_25 font-weight-bold text-gray-40">
                <FormattedTag
                  id="sidebar.logged-in.greeting.title"
                  values={{
                    username: !isEmptyOrNil(firstName) ? firstName : 'new user'
                  }}
                />
              </span>

              <span className="font-weight-medium">
                <FormattedTag
                  id="sidebar.logged-in.greeting.description"
                  className="text-gray-20"
                />
                {' '}
                {!isEmptyOrNil(loyaltyPointsAllData) && (
                  <FormattedTag
                    tag={RouteLink}
                    to="/loyalty"
                    id={`loyalty-program.level-type.${targetVipStatus}`}
                    onClick={this.closeProfileModal}
                    className="loyalty-level-link link-with-arrow font-weight-medium"
                  />
                )}
              </span>
            </div>
          </div>

          <div className="balance-item d-flex justify-content-between w-100 mb-1_25 text-gray-40 font-weight-semi-bold">
            <FormattedTag
              id="sidebar.logged-in.balance"
            />

            {!isEmptyOrNil(loyaltyPointsAllData) && (
              <span className="h4">
                {formatAmountWithCurrency({ amount: totalBalance, currency, locale })}
              </span>
            )}
          </div>

          <div className="balance-item d-flex justify-content-between w-100 mb-1_25 text-gray-40 font-weight-semi-bold">
            <FormattedTag
              id="sidebar.logged-in.bonus-balance"
            />

            {!isEmptyOrNil(loyaltyPointsAllData) && (
              <span className="h4">
                {formatAmountWithCurrency({ amount: withdrawableBalance, currency, locale })}
              </span>
            )}
          </div>

          <div className="balance-item d-flex justify-content-between w-100 mb-2_75 text-gray-40 font-weight-semi-bold">
            <FormattedTag
              id="sidebar.logged-in.real-balance"
            />

            {!isEmptyOrNil(loyaltyPointsAllData) && (
              <span className="h4">
                {formatAmountWithCurrency({ amount: bonusBalance, currency, locale })}
              </span>
            )}
          </div>
        </>

        <FormattedTag
          tag={Button}
          disabled={!userInfoIsFetched}
          id="deposit"
          color="primary"
          onClick={this.openDepositModal}
          block
        />
      </div>
    );
  }
}

export const UserInfoAndLoyalty = withCoreComponent(
  LoyaltyPointsCore,
  withModalActions(
    withGlobalEvents(
      withLocale(
        withPixelRatio(
          withUser(
            UserLoyaltyUI, [
              USER_FIELDS.USER_DATA,
              USER_FIELDS.IS_SIGN_UP_COMPLETED,
              'vipStatus',
            ],
          )
        ),
      ),
    ),
  ),
);
