import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconStar = ({ className, color }) => (
  <svg className={className} width="18" height="18" viewBox="0 0 18 18">
    <path className="icon-filled-path" fillRule="evenodd" clipRule="evenodd" d="M9 .242c.333 0 .635.192.776.493l2.247 4.787 5.008.77a.857.857 0 0 1 .483 1.446l-3.648 3.736.863 5.289a.857.857 0 0 1-1.26.888L9 15.18l-4.468 2.47a.857.857 0 0 1-1.26-.888l.862-5.289L.486 7.738A.857.857 0 0 1 .97 6.292l5.008-.77L8.224.735A.857.857 0 0 1 9 .242zm0 2.874L7.335 6.664a.857.857 0 0 1-.646.483l-3.812.586 2.786 2.853c.19.195.277.468.233.737l-.648 3.973 3.337-1.845a.857.857 0 0 1 .83 0l3.338 1.845-.648-3.973a.857.857 0 0 1 .232-.737l2.786-2.853-3.812-.586a.857.857 0 0 1-.645-.483L9 3.116z" fill={color} />
  </svg>
);

IconStar.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};
IconStar.defaultProps = {
  className: '',
  color: colors.gray10,
};
