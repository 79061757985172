import React from 'react';
import PropTypes from 'prop-types';

export const IconErrorCircle = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" className={className}>
    <path fill="url(#icon-error-circle)" d="M61.456 10.544C68.256 17.344 72 26.384 72 36s-3.745 18.656-10.544 25.456C54.656 68.256 45.616 72 36 72s-18.656-3.745-25.456-10.544C3.744 54.656 0 45.616 0 36s3.745-18.656 10.544-25.456C17.344 3.744 26.384 0 36 0s18.656 3.745 25.456 10.544zM36 67.781c17.524 0 31.781-14.257 31.781-31.781S53.524 4.219 36 4.219 4.219 18.476 4.219 36 18.476 67.781 36 67.781zM38.828 36.5l8.132 8.132a2 2 0 1 1-2.828 2.828L36 39.328l-8.132 8.132a2 2 0 1 1-2.828-2.828l8.132-8.132-8.132-8.132a2 2 0 1 1 2.828-2.828L36 33.672l8.132-8.132a2 2 0 1 1 2.828 2.828L38.828 36.5z" />
  </svg>
);

IconErrorCircle.propTypes = {
  className: PropTypes.string,
};

IconErrorCircle.defaultProps = {
  className: null,
};
