import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderEzugiBig = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="41"
    viewBox="0 0 100 41"
    fill="none"
  >
    <path
      d="M29.4437 12.0014L29.1335 26.3269C29.1335 26.3269 28.6704 20.676 29.4437 12.0014Z"
      fill="#272727"
      fillOpacity="0.04"
    />
    <path
      d="M71.8373 29.9833C71.8366 30.177 71.6818 30.3348 71.4882 30.3394C70.5425 30.3658 69.6802 30.4673 68.6565 30.5188C67.6676 30.5675 66.7094 30.605 65.9472 30.765C65.8324 30.7901 65.7126 30.7568 65.6273 30.6759C65.5562 30.6087 65.516 30.5151 65.516 30.4172V28.4701C65.5159 28.3367 65.4413 28.2146 65.3226 28.1536C65.204 28.0927 65.0612 28.1032 64.9528 28.1808C64.1551 28.7777 63.3224 29.3262 62.459 29.8234C61.2615 30.491 60.3936 30.8067 59.2782 30.8067C56.5105 30.8067 54.1544 29.1947 54.1544 24.9485V17.2462C54.1544 15.1599 53.8234 14.7163 52.7914 14.4812L51.8999 14.299C51.7455 14.2648 51.6322 14.1328 51.6217 13.975L51.5897 13.523C51.5825 13.4268 51.6147 13.3318 51.679 13.2599C51.7433 13.188 51.8341 13.1454 51.9305 13.1419C53.1044 13.1001 55.9722 13.0153 57.1322 12.9249C57.2351 12.9165 57.3366 12.9535 57.4098 13.0263C57.4831 13.099 57.5209 13.2002 57.5133 13.3032C57.4256 14.5132 57.4256 16.2351 57.4256 18.5814V23.8887C57.4256 27.388 59.2017 28.331 61.0612 28.331C62.5063 28.331 64.0306 27.7747 65.4034 26.5522C65.4796 26.4838 65.5231 26.3861 65.523 26.2837V17.2462C65.523 15.1599 65.1516 14.6773 63.7872 14.4812L62.587 14.2893C62.4139 14.2619 62.2865 14.1127 62.2866 13.9374V13.4993C62.2857 13.3077 62.4372 13.15 62.6287 13.1433C63.9555 13.1015 67.1989 13.0139 68.3909 12.9207C68.4894 12.9134 68.5866 12.9475 68.6589 13.0148C68.7313 13.0821 68.7722 13.1766 68.7719 13.2754V26.0083C68.7719 27.9736 69.1016 29.0946 70.7191 29.2072L71.491 29.2351C71.6827 29.2418 71.8347 29.3992 71.8345 29.5911L71.8373 29.9833Z"
      fill="#C7C7C7"
    />
    <path
      d="M50.765 25.8526C50.2601 27.1808 49.6829 28.6704 49.153 30.0181C49.0774 30.2099 48.8919 30.3358 48.6857 30.3352H35.7441C35.5533 30.3357 35.3786 30.2279 35.2935 30.057L35.274 30.0153C35.1933 29.8504 35.2093 29.6545 35.3158 29.5049C38.6287 24.8401 41.765 20.1377 44.9263 15.2712C45.0263 15.1172 45.0344 14.9209 44.9476 14.7591C44.8607 14.5973 44.6927 14.4956 44.5091 14.4938H41.758C38.7831 14.4938 38.2462 15.0835 37.089 17.2851L36.7135 17.9805C36.5965 18.1956 36.3415 18.2949 36.1099 18.2156L35.9388 18.1572C35.6828 18.0693 35.5426 17.7943 35.6217 17.5355C36.0084 16.2837 36.4437 14.943 36.7427 13.5647C36.7913 13.3328 36.9954 13.1664 37.2323 13.1655H49.2031C49.4006 13.1657 49.5797 13.2816 49.6607 13.4618C49.732 13.6203 49.7162 13.8045 49.6189 13.9486C46.4534 18.637 43.3547 23.3547 40.1947 28.2615C40.0956 28.4155 40.0878 28.6112 40.1746 28.7725C40.2613 28.9339 40.4288 29.0354 40.612 29.0376H43.7831C46.9166 29.0376 47.7455 28.2156 49.3088 25.8693L49.3283 25.8373L49.6259 25.3157C49.7562 25.0872 50.0408 24.998 50.2782 25.1113L50.5132 25.2226C50.7478 25.3355 50.8571 25.6091 50.765 25.8526Z"
      fill="#C7C7C7"
    />
    <path
      d="M89.7635 29.7997C89.7643 29.5247 89.9726 29.2948 90.2462 29.267C92.5313 29.0361 93.7037 28.6703 93.7037 26.1669V18.0681C93.7037 15.7858 93.5953 15.0306 91.5452 14.6537C91.2895 14.608 91.1028 14.3863 91.1015 14.1265V13.6759C91.1014 13.3861 91.3319 13.1487 91.6217 13.1404C92.516 13.1154 94.4145 13.0514 96.1321 12.9249C96.2806 12.9136 96.4271 12.9649 96.5361 13.0663C96.6452 13.1677 96.707 13.31 96.7065 13.4589V26.1669C96.7065 28.6703 97.1891 29.0361 99.4186 29.2656C99.6927 29.2927 99.9018 29.5229 99.9026 29.7983C99.9026 30.094 99.6629 30.3338 99.3672 30.3338H90.299C90.0038 30.3338 89.7643 30.0949 89.7635 29.7997Z"
      fill="#C7C7C7"
    />
    <path
      d="M4.13074 7.53825L32.2058 0L31.1836 40.1864L0 34.2768L4.13074 7.53825Z"
      fill="url(#ezugi-provider-big-rule-1)"
    />
    <path
      d="M32.2058 0L33.7246 1.10153L32.7371 38.4228L31.1836 40.1864L32.2058 0Z"
      fill="url(#ezugi-provider-big-rule-2)"
    />
    <path
      opacity="0.15"
      d="M4.13073 7.53825L3.72461 9.44923C3.72461 9.53825 3.71765 9.62726 3.71765 9.71766C3.71765 21.3547 13.0139 30.7886 25.2462 30.7886C27.3406 30.7888 29.4254 30.5052 31.4437 29.9458L32.2058 0L4.13073 7.53825Z"
      fill="#272727"
      fillOpacity="0.04"
    />
    <path
      d="M28.7511 27.7635C28.2476 29.3296 26.6816 33.694 26.0515 35.0862L2.83594 31.7107L2.91939 30.8011C7.19477 31.0792 7.60089 30.8359 7.89018 27.9374L9.31855 13.7691C9.64539 10.5702 9.25736 10.5174 5.38809 11.1488L5.4785 10.2086L28.5647 4.72876C28.4256 5.89705 28.3005 9.02083 28.1683 11.3811L26.6092 11.8219C26.3658 9.97911 25.9569 9.07646 25.3811 8.26562C24.7567 7.43112 23.3658 7.44225 19.5898 8.27535L15.6593 9.13627C14.071 9.48119 13.9569 9.61889 13.8373 10.7315L12.9027 19.4436L17.6078 19.1071C22.2379 18.7747 22.5355 18.4117 23.5035 15.3977L25.0126 15.2225L23.9848 23.8261H22.5091C22.2128 20.726 21.886 20.4881 17.4799 20.6954L12.7511 20.9263L11.9834 28.0945C11.7956 29.872 11.9959 30.7371 12.8179 31.2934C13.6788 31.9054 15.3714 32.1154 17.8081 32.42C21.5508 32.8943 22.9861 32.7149 24.2268 31.669C25.1934 30.8345 26.3519 29.2976 27.2866 27.509L28.7511 27.7635Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.3283 13.8748C89.3313 14.2614 89.0325 14.5834 88.6467 14.6092C87.8874 14.6662 87.1641 14.8526 86.847 15.3867C86.7238 15.5277 86.7238 15.738 86.847 15.879C87.4005 16.7576 87.6901 17.7767 87.6815 18.815C87.6815 23.0612 83.9625 25.1057 80.4493 25.1057C79.8907 25.1012 79.3331 25.059 78.7803 24.9792C78.4741 24.9324 78.163 25.0236 77.9305 25.2281C77.5021 25.6148 77.096 26.1433 77.096 26.5591C77.096 27.306 77.8401 27.7761 79.6996 27.7761C80.2335 27.7761 80.7568 27.7602 81.2693 27.7446C82.2253 27.7155 83.1435 27.6876 84.0209 27.7664C86.5661 27.9945 89.4646 30 89.4646 33.1516C89.4646 36.4576 85.4576 38.9152 79.9625 38.9152C75.3352 38.9152 72.5118 36.637 72.5118 34.395C72.5118 32.2224 74.3477 31.2182 75.608 30.5288C75.9524 30.3404 76.2539 30.1755 76.4632 30.0167C76.5546 29.9494 76.6019 29.8375 76.5865 29.725C76.5712 29.6125 76.4956 29.5174 76.3894 29.4771C76.07 29.3505 75.7669 29.2465 75.4876 29.1506L75.4875 29.1505C74.72 28.887 74.1318 28.685 73.8748 28.2476C72.9375 26.6515 74.5415 25.9174 75.7409 25.3685C76.1823 25.1665 76.569 24.9895 76.7538 24.8039C76.8268 24.7328 76.86 24.6302 76.8425 24.5298C76.8249 24.4293 76.759 24.3441 76.6662 24.3018C74.9555 23.4673 73.42 21.6926 73.42 19.2072C73.42 14.765 77.6342 12.6815 80.8164 12.6815H80.8582C81.7171 12.6827 82.5702 12.8236 83.3839 13.0988C83.4918 13.1359 83.605 13.1551 83.7191 13.1558H88.5869C88.9862 13.1558 89.3112 13.477 89.3157 13.8762L89.3283 13.8748ZM75.7914 33.1377C75.7914 35.3004 77.9263 37.1614 80.9833 37.1614C84.7691 37.1614 86.3936 35.5995 86.6718 34.1363C87.007 32.3686 86.0779 31.4006 84.8888 30.6593C83.9555 30.0821 83.2837 29.8248 81.4687 29.7274C77.6203 29.5257 75.7914 32.0362 75.7914 33.1377ZM80.8735 23.8484C78.6009 23.8484 76.9889 21.6398 76.9889 18.58H76.9903C76.9903 15.5522 78.6426 13.9082 80.3783 13.9013C82.7733 13.9013 84.3032 16.1099 84.3032 19.1697C84.3032 22.2754 82.6913 23.8095 80.8735 23.8484Z"
      fill="#C7C7C7"
    />
    <path
      d="M91.9096 8.12658C92.1877 9.34216 93.516 10.0251 94.8776 9.65648C96.2392 9.28792 97.1182 7.9875 96.8414 6.77888C96.5646 5.57025 95.235 4.88041 93.8734 5.24897C92.5118 5.61754 91.6328 6.911 91.9096 8.12658Z"
      fill="#3D91CF"
    />
    <defs>
      <linearGradient
        id="ezugi-provider-big-rule-1"
        x1="13.1706"
        y1="-13.0896"
        x2="-12.5962"
        y2="22.4002"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#70ABDC" />
        <stop offset="0.17" stopColor="#67A6DA" />
        <stop offset="0.46" stopColor="#4D9AD4" />
        <stop offset="0.6" stopColor="#3E93D0" />
        <stop offset="0.99" stopColor="#1B7CC1" />
      </linearGradient>
      <linearGradient
        id="ezugi-provider-big-rule-2"
        x1="31.6042"
        y1="-6.47486"
        x2="23.2343"
        y2="-4.83586"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3E93D0" />
        <stop offset="0.99" stopColor="#1B7CC1" />
      </linearGradient>
    </defs>
  </svg>
);

IconProviderEzugiBig.propTypes = {
  className: PropTypes.string,
};
IconProviderEzugiBig.defaultProps = {
  className: null,
};
