import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { withLocale, withUserAgent } from 'core/hocs';
import { getConfig, isEmptyOrNil, getStaticUrl } from 'core/helpers';
import { withPixelRatio } from 'hocs/with-pixel-ratio';
import { formatAmountWithCurrency } from 'helpers/numbers';
import { IconCheckedFilled } from 'components/icons/icon-checked-filled/icon-checked-filled';

import { isEqualToZero } from 'helpers/utils';
import { getBackgroundStyles } from 'helpers/images';

import { MOBILE } from '../../../../constants';

import './deposit-bonus.scss';

export const DEFAULT_BONUS_OPTION = getConfig('PROCESS_DEPOSIT.DEFAULT_BONUS_OPTION');
const DEFAULT_BG_PATH = `${getStaticUrl()}/images/deposit/default.jpg`;

const FIELDS = {
  BONUS_AMOUNT: 'bonusAmount',
  WAGER: 'wager',
  DEPOSIT: 'deposit',
  AWARD_TYPE_PERCENT: 'percent',
};

export class DepositBonusUI extends Component {
  buttonHandleClick= () => {
    const { handleClick, bonusType } = this.props;
    handleClick(bonusType);
  };

  renderDescription = (field) => {
    const { locale, currentBonus, currency } = this.props;

    if (isEmptyOrNil(currentBonus)) {
      return null;
    }

    const {
      wager, minAmount, maxAmount, flatAmount, percentQuantity,
    } = currentBonus.description || {};

    const isPercent = currentBonus.description.awardType === FIELDS.AWARD_TYPE_PERCENT;

    if (field === FIELDS.WAGER) {
      return !isEqualToZero(wager) && (
        <>
          <FormattedMessage id="profile.deposit-new.wager" values={{ wager }} />
          {' '}
        </>
      );
    }

    if (field === FIELDS.DEPOSIT) {
      const maxBonusValue = isPercent ? maxAmount * percentQuantity / 100 : flatAmount;

      return (
        <>
          {!isEqualToZero(minAmount) && (
            <FormattedMessage
              id="profile.deposit-new.deposit-for-bonus.min-deposit"
              values={{
                minDeposit: formatAmountWithCurrency({
                  amount: minAmount, currency, locale, isIsoFormat: true,
                }),
              }}
            />
          )}

          {!isEqualToZero(maxBonusValue) && (
            <FormattedMessage
              id="profile.deposit-new.deposit-for-bonus.max-bonus"
              values={{
                maxBonus: formatAmountWithCurrency({
                  amount: maxBonusValue,
                  currency,
                  locale,
                  isIsoFormat: true,
                }),
              }}
            />
          )}
        </>
      );
    }

    return null;
  };

  getImageUrl = () => {
    const {
      pixelRatio,
      imagesDataItems,
      currentBonus,
    } = this.props;

    const { bonusPlanId } = currentBonus || {};

    const backgroundUrl = R.compose(
      R.propOr('', 'imgUrl'),
      R.find(
        R.propEq('entityId', bonusPlanId?.toString()),
      ),
    )(imagesDataItems || []);

    return getBackgroundStyles(backgroundUrl, pixelRatio, DEFAULT_BG_PATH);
  };

  render() {
    const {
      className,
      isActive,
      bonusType,
      bonusLabel,
      currentBonus,
      promoValue,
      isLargeSize,
      deviceType,
      isModal,
    } = this.props;

    const { bonusPlanId } = currentBonus || {};
    const isDefaultBonusOption = bonusType === DEFAULT_BONUS_OPTION;
    const isMobile = deviceType === MOBILE;
    const backgroundImage = this.getImageUrl(isMobile);

    return (
      <div
        className={classNames('deposit-bonus position-relative rounded mb-1', {
          'deposit-active text-white': isActive,
          'bg-white-8': isActive && isDefaultBonusOption,
          'bg-white-4': !isActive,
          'card-size-large': isLargeSize,
          'mx-lg-1 w-lg-33': !isModal,
        }, className)}
        tabIndex="0"
        role="button"
        onClick={this.buttonHandleClick}
        onKeyPress={this.buttonHandleClick}
        {...(!isDefaultBonusOption && { style: backgroundImage })}
      >
        <div className={classNames(
          'position-absolute d-flex justify-content-end w-100 h-100 deposit-bonus-layer overflow-hidden', {
            'with-bonus': !isDefaultBonusOption,
            active: isActive,
          }
        )}
        >
          <div className="rounded-circle border border-gray-30 deposit-bonus-radio m-1 d-flex justify-content-center align-items-center">
            {isActive && <IconCheckedFilled />}
          </div>
        </div>
        <div className={classNames(
          'deposit-bonus-content position-relative justify-content-end d-flex flex-column rounded py-2 px-2_5 w-100 h-100', {
            'justify-content-center align-items-center': isDefaultBonusOption,
          }
        )}
        >
          <div className="deposit-bonus-text text-white font-weight-semi-bold mb-0_25">
            <FormattedMessage id={bonusPlanId ? `promo.bonus.title.${bonusPlanId}` : bonusLabel} />
          </div>
          <div className={classNames(
            'deposit-bonus-text font-weight-normal caption h6 text-gray-30 pt-0_25', {
              'text-white': isDefaultBonusOption,
            }
          )}
          >
            {isDefaultBonusOption && (
              <>
                <FormattedMessage id="profile.deposit-new.take-no-bonuses" />
                <br />
              </>
            )}
            {promoValue && bonusType !== DEFAULT_BONUS_OPTION && (
              <>
                <FormattedMessage id="profile.deposit-new.promo" />
                <span>&#32;{promoValue}.&#32;</span>
              </>
            )}
            {this.renderDescription(FIELDS.WAGER)}
            {this.renderDescription(FIELDS.DEPOSIT)}
          </div>
        </div>
      </div>
    );
  }
}

DepositBonusUI.propTypes = {
  locale: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  className: PropTypes.string,
  isLargeSize: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
  bonusType: PropTypes.string.isRequired,
  bonusLabel: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  currentBonus: PropTypes.shape({
    bonusAmount: PropTypes.number,
    bonusPlanId: PropTypes.string,
    bonusPlanName: PropTypes.string,
    description: PropTypes.shape({
      awardType: PropTypes.string,
      maxAmount: PropTypes.oneOfType([
        PropTypes.string, PropTypes.number,
      ]),
      minAmount: PropTypes.oneOfType([
        PropTypes.string, PropTypes.number,
      ]),
      flatAmount: PropTypes.oneOfType([
        PropTypes.string, PropTypes.number,
      ]),
      wager: PropTypes.oneOfType([
        PropTypes.string, PropTypes.number,
      ]),
    }),
  }),
  promoValue: PropTypes.string,
  deviceType: PropTypes.string.isRequired,
  pixelRatio: PropTypes.string.isRequired,
  imagesDataItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

DepositBonusUI.defaultProps = {
  className: '',
  isLargeSize: false,
  handleClick: () => null,
  currentBonus: null,
  promoValue: null,
};

export const DepositBonus = withLocale(
  withUserAgent(
    withPixelRatio(
      DepositBonusUI
    ),
  ),
);
