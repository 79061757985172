import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'reactstrap/lib/Spinner';
import { FavoriteGames as FavoriteGamesCore, FormattedTag } from 'core/components';
import { withCoreComponent } from 'core/hocs';
import { isEmptyOrNil, getStaticUrl } from 'core/helpers';
import { ImageLazyLoad, LAZY_LOAD_TYPES } from 'components/image-lazy-load/image-lazy-load';
import { IconPlayCircle } from 'components/icons/icon-play-circle/icon-play-circle';
import { IconMinus } from 'components/icons/icon-minus/icon-minus';

import './favorite-games-sidebar.scss';
import colors from 'customizations/js/color-variables';

const DEFAULT_THUMB_PATH = `${getStaticUrl()}/images/default-game-bg/thumb.jpg`;

export class FavoriteGamesUI extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape()),
    isInProgress: PropTypes.bool.isRequired,
    openGame: PropTypes.func.isRequired,
    toggleFavoriteGame: PropTypes.func.isRequired,
    // showAll: PropTypes.func.isRequired,
    // isShowedAll: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    items: [],
  };

  openGame = ({ currentTarget: { dataset: { id: gameId, producer } } }) => {
    const { openGame } = this.props;

    openGame({ gameId, producer });
  };

  // openDemoGame = ({ currentTarget: { dataset: { id: gameId, producer } } }) => {
  //   const { openGame } = this.props;
  //
  //   openGame({ gameId, producer, isFun: true });
  // }

  toggleFavorite = ({ currentTarget: { dataset: { id: gameId, provider } } }) => {
    const { toggleFavoriteGame } = this.props;

    toggleFavoriteGame({ gameId, platformCode: provider });
  };

  render() {
    const {
      items,
      isInProgress,
      // showAll,
      // isShowedAll,
    } = this.props;

    if (!isInProgress && isEmptyOrNil(items)) {
      return (
        <div className="mt-2_5 px-1_25 text-center">
          <FormattedTag tag="div" id="sidebar.favorites.empty" className="text-small text-gray-20" />
        </div>
      );
    }

    if (isInProgress) {
      return (
        <div className="mt-2_5 px-1_25 text-center">
          <Spinner />
        </div>
      );
    }

    return (
      <div className="favorite-games mb-4">
        {!isEmptyOrNil(items) && items.map(({
          gameId,
          producer,
          provider,
          title,
          thumbPath,
          // hasFun,
        }) => (
          <div
            key={`${gameId}-${producer}`}
            className="favorite-games-item d-flex align-items-center px-1_5 py-2 mt-1_5 bg-black"
          >
            <div className="bg-black rounded">
              <ImageLazyLoad
                src="/assets/public/images/transparent-pixel.png"
                className="favorite-games-image flex-shrink-0 rounded"
                classNameLoaded="favorite-games-image-loaded"
                classNameError="favorite-games-image-error"
                srcLazy={thumbPath}
                type={LAZY_LOAD_TYPES.BACKGROUND}
                srcFallback={DEFAULT_THUMB_PATH}
              >
                <div
                  className="favorite-games-image-overlay d-flex justify-content-center align-items-center rounded"
                  role="button"
                  tabIndex="0"
                  data-id={gameId}
                  data-producer={producer}
                  onClick={this.openGame}
                  onKeyPress={this.openGame}
                >
                  <IconPlayCircle className="favorite-games-play-icon" isSmall />
                </div>
              </ImageLazyLoad>
            </div>

            <div className="ml-1_75 text-truncate d-flex flex-column justify-content-between align-items-start flex-grow-1">
              <span className="text-truncate text-small text-gray-20 text-capitalize mb-0_5">{producer}</span>

              <span
                role="button"
                tabIndex="0"
                className="h4 font-weight-semi-bold text-truncate w-100 text-gray-40"
                data-id={gameId}
                data-producer={producer}
                onClick={this.openGame}
                onKeyPress={this.openGame}
              >
                {title}
              </span>
            </div>

            <span
              onClick={this.toggleFavorite}
              onKeyPress={this.toggleFavorite}
              data-id={gameId}
              data-provider={provider}
              role="button"
              tabIndex="0"
              className="favorite-games-btn d-flex justify-content-center align-items-center bg-gray-0 ml-2 flex-shrink-0"
            >
              <IconMinus color={colors.gray40} />
            </span>
          </div>
        ))}

        {/* {!isShowedAll && ( */}
        {/*  <FormattedTag */}
        {/*    id="show-all" */}
        {/*    tag={Button} */}
        {/*    size="xs" */}
        {/*    className="d-block mx-auto mt-3" */}
        {/*    onClick={showAll} */}
        {/*  /> */}
        {/* )} */}
      </div>
    );
  }
}

export const FavoriteGamesSidebar = withCoreComponent(FavoriteGamesCore, FavoriteGamesUI);
