import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderSwintt = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1039 1H10.5509L6.30115 8.28727L6.32811 8.33344L8.41439 11.9111L8.41408 11.912H8.41501V11.9123L10.5527 15.5779L10.5524 15.5789L10.224 15.1057L8.22848 12.2327L8.00631 11.9129V11.912H4.18946L2 15.6666L6.27637 23H14.8291L19.1058 15.6666L19.1048 15.665L19.155 15.5789H19.1513L17.0133 11.9129L17.0139 11.912H17.0158L14.8781 8.24606L14.8784 8.24544L17.4245 11.9111V11.912H21.2426H21.2903L21.2918 11.9145L23.3803 8.33344L19.1039 1Z"
      fill="#E84E1C"
    />
  </svg>
);

IconProviderSwintt.propTypes = {
  className: PropTypes.string,
};

IconProviderSwintt.defaultProps = {
  className: '',
};
