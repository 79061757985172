/* eslint-disable global-require */
// `ISO 3166-1 alpha-2` code standard (in lower-cased format) should be used for locales and countries
module.exports = {
  DEFAULT_CURRENCY: 'TRY',

  DEFAULT_COUNTRY: 'tr',

  DEFAULT_LOCALE: 'tr',
  SEARCH_BOT_LOCALE: 'en',
  TARGET_USER_BROWSER_PREFERRED_LOCALES_LIST: ['tr'],

  BRAND_LOCALES: {
    EN: 'en',
    TR: 'tr',
  },

  DEFAULT_THEME: 'light',

  /**
   * {[iso2Code]: [currencyCode]}
   */
  DEFAULT_CURRENCIES_BY_COUNTRY: {
    // SE: 'SEK',
  },

  /**
   * {[iso2Code]: [locale]}
   */
  TARGET_USER_LOCALE_BY_COUNTRY_IP: {
    // TR: 'tr',
  },

  PROCESS_DEPOSIT: {
    // If "NONE" is passed then no bonus will be created.
    // If this is not passed at all or "DEFAULT" is passed then the most appropriate bonus will be selected.
    DEFAULT_BONUS_OPTION: 'NONE',
  },

  FORMS: {
    FIELDS: require('./forms'),
  },

  // TODO: maybe remove to another place
  VERIFICATION: {
    MAX_SIZE: 10 * 1024 * 1024, // 10mb
    ACCEPT: 'image/jpeg, image/png',
  },

  MIN_POINTS_TO_EXCHANGE: 1000,
};
/* eslint-enable global-require */
