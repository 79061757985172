import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { RouteLink, FormattedTag } from 'core/components';

import { withSafariSearchBar } from 'hocs/with-safari-search-bar';
import colors from 'customizations/js/color-variables';

import { IconCasino } from 'components/icons/icon-casino/icon-casino';
import { IconPromos } from 'components/icons/icon-promos/icon-promos';
import { IconLiveCasino } from 'components/icons/icon-live-casino/icon-live-casino';
import { IconSports } from '../icons/icon-sports/icon-sports';
// import { IconTournaments } from 'components/icons/icon-tournaments/icon-tournaments';

import { PAGE_NAMES } from '../../constants';

import './casino-navigation.scss';

const {
  GAMES,
  PROMOS,
  LOYALTY,
  TOURNAMENT,
  // TOURNAMENTS,
  SPORTSBOOK,
} = PAGE_NAMES;

const menuItems = [
  {
    id: 'profile-menu.casino',
    to: '/games',
    Icon: IconCasino,
    pages: [GAMES, `${GAMES}/all`, `${GAMES}/slot`, `${GAMES}/roulette`, `${GAMES}/card`, `${GAMES}/sport`],
  },
  {
    id: 'profile-menu.live-casino',
    to: '/games/live',
    Icon: IconLiveCasino,
    pages: [`${GAMES}/live`],
  },
  {
    id: 'profile-menu.sportsbook',
    to: '/sportsbook',
    Icon: IconSports,
    pages: [SPORTSBOOK],
  },
  // {
  //   id: 'header.tournaments',
  //   to: '/tournaments',
  //   Icon: IconTournaments,
  //   pages: [TOURNAMENTS, TOURNAMENT],
  // },
  {
    id: 'profile-menu.promos',
    to: '/promos',
    Icon: IconPromos,
    pages: [PROMOS, TOURNAMENT, LOYALTY],
  },
];

export const CasinoNavigationUI = ({ pageName, navBarHeight, params }) => (
  <div
    className="casino-navigation position-fixed bg-black d-md-none"
    style={{
      ...!!navBarHeight && ({
        // default min-height = $casino-navigation-height (60px) + $casino-navigation-ios-gap (2px)
        minHeight: navBarHeight + 62, paddingBottom: navBarHeight
      })
    }}
  >
    {menuItems.map(({
      id, to, pages, Icon,
    }) => {
      const isActive = params ? pages.includes(`${pageName}/${params}`) : pages.includes(pageName);

      return (
        <RouteLink
          key={id}
          to={to}
          className="d-flex flex-column flex-sm-row justify-content-end justify-content-sm-center align-items-center pb-1 pb-sm-0 icon-parent"
        >
          <Icon className={classNames('casino-navigation-menu-icon mb-0_5 mb-sm-0', { active: isActive })} color={colors.gray20} />
          <FormattedTag
            id={id}
            className={classNames('casino-navigation-menu-title ml-sm-1_5 font-weight-semi-bold', {
              'text-gray-40': isActive,
              'text-gray-20': !isActive,
            })}
          />
        </RouteLink>
      );
    })}
  </div>
);

CasinoNavigationUI.propTypes = {
  pageName: PropTypes.string,
  navBarHeight: PropTypes.number.isRequired,
  params: PropTypes.string
};

CasinoNavigationUI.defaultProps = {
  pageName: null,
  params: null
};

export const CasinoNavigation = withSafariSearchBar(CasinoNavigationUI);
