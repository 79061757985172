import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import colors from 'customizations/js/color-variables';
import { withDropdownAlignment } from 'hocs/with-dropdown-alignment';
import { IconStatusApproved } from 'components/icons/icon-status-approved/icon-status-approved';
import { IconArrowDown } from 'components/icons/icon-arrow-down/icon-arrow-down';
import { InputText } from 'components/input-text/input-text';
import { getPlaceholder } from '../../../helpers/form-elements';

import './select.scss';

export class SelectUI extends Component {
  state = {
    isDropdownOpen: false,
  };

  toggle = () => {
    const { updateDropdownAlignment, isNotEditable } = this.props;

    if (!isNotEditable) {
      this.setState(({ isDropdownOpen }) => ({
        isDropdownOpen: !isDropdownOpen,
      }),
      updateDropdownAlignment);
    }
  };

  render() {
    const {
      options, name, label, value, className, icon, isAnimatedPlaceholder, placeholder, error, secondaryPlaceholder,
      onChange, isOptionsLocalized, isNotEditable, wrapperClassName, intl, autoFocus, dropdownMenuAlignmentSettings,
      // isReadOnly,
    } = this.props;

    const { isDropdownOpen } = this.state;

    const currentOption = value && R.find(R.propEq('value', value))(options);

    return (
      <div className={classNames('text-right d-flex align-items-center position-relative w-100', wrapperClassName)}>
        <Dropdown
          className={classNames(className, 'w-100')}
          id={`select-${name}`}
          value={value}
          toggle={this.toggle}
          isOpen={isDropdownOpen}
        >
          <DropdownToggle
            tag="div"
          >
            <InputText
              autoFocus={autoFocus}
              id={`input-${name}`}
              isError={!!error}
              animatedPlaceholder={isAnimatedPlaceholder && !!placeholder ? intl.formatMessage({ id: placeholder }) : ''}
              wrapperClassName="dropdown-input"
              onChange={null}
              name={`select-${name}`}
              autoComplete="off"
              placeholder={getPlaceholder(!isAnimatedPlaceholder
                ? placeholder : secondaryPlaceholder, intl.formatMessage)}
              icon={icon}
              rightContent={!isNotEditable && (
                <IconArrowDown
                  color={isDropdownOpen ? colors.white : colors.gray10}
                  className={classNames('dropdown-input-arrow', { 'is-open': isDropdownOpen })}
                />
              )}
              value={currentOption
                ? isOptionsLocalized && currentOption.label
              || intl.formatMessage({ id: currentOption.label }) : null}
              disabled={isNotEditable}
              readOnly
            />
          </DropdownToggle>
          {!isNotEditable && (
            <DropdownMenu
              className={`${name}-dropdown w-100 custom-dropdown-menu`}
              style={dropdownMenuAlignmentSettings}
              persist
            >
              {options.map(({ value: optValue, label: optLabel }) => (
                <DropdownItem
                  className={classNames('text-wrap', {
                    'is-active': currentOption && optValue === currentOption.value,
                  })}
                  key={`${optValue}${label}`}
                  value={optValue}
                  onClick={onChange}
                >
                  {isOptionsLocalized ? optLabel : <FormattedMessage id={optLabel} />}
                </DropdownItem>
              ))}
            </DropdownMenu>
          )}
        </Dropdown>
        {isNotEditable && <IconStatusApproved className="icon-success position-absolute" />}
      </div>
    );
  }
}

SelectUI.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  isOptionsLocalized: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  // size: PropTypes.string,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  error: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isNotEditable: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  isAnimatedPlaceholder: PropTypes.bool,
  intl: intlShape.isRequired,
  updateDropdownAlignment: PropTypes.func.isRequired,
  dropdownMenuAlignmentSettings: PropTypes.shape().isRequired,
  secondaryPlaceholder: PropTypes.string,
  // isReadOnly: PropTypes.bool,
};

SelectUI.defaultProps = {
  secondaryPlaceholder: null,
  options: [],
  value: undefined,
  className: '',
  isOptionsLocalized: false,
  label: null,
  onChange: null,
  // size: '',
  icon: null,
  isNotEditable: false,
  wrapperClassName: null,
  placeholder: null,
  error: null,
  autoFocus: false,
  isAnimatedPlaceholder: false,
  // isReadOnly: false,
};

export const Select = withDropdownAlignment(injectIntl(SelectUI));
