import React from 'react';
import Spinner from 'reactstrap/lib/Spinner';

import './payments-preloader.scss';

export const PaymentsPreloader = () => (
  <div className="preloader payments-preloader d-flex flex-column align-items-center justify-content-center rounded px-2 px-sm-3 pt-1_5 pt-sm-2_5 pb-3">
    <Spinner color="white" />
  </div>
);
