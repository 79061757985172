import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconArrowDown = ({ className, color, onClick }) => (
  <svg className={className} onClick={onClick} width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.35353 4.65794C3.9266 5.10751 3.11111 5.11553 2.67354 4.67251C1.80284 3.79095 0.512129 2.3229 0.0342166 0.946961C-0.142621 0.437832 0.391239 4.76837e-07 1.05408 4.76837e-07L5.93829 4.76837e-07C6.62278 4.76837e-07 7.16049 0.464133 6.95607 0.984767C6.42818 2.3292 5.18898 3.77821 4.35353 4.65794Z" fill={color} />
  </svg>
);

IconArrowDown.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func
};

IconArrowDown.defaultProps = {
  className: null,
  color: colors.gray10,
  onClick: null
};
