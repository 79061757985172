import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderFugasoBig = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="112"
    height="39"
    viewBox="0 0 112 39"
    fill="none"
  >
    <path
      d="M16.2634 27.553L19.6657 21.8062L23.0365 27.6135L29.8976 16.0443H26.5155L29.8888 10.2622H39.8954L23.01 39.0001L16.2634 27.553Z"
      fill="white"
    />
    <path
      d="M12.7603 21.6085L16.2633 27.553L19.6656 21.8062L19.6669 21.8036L16.3238 16.0455H23.1069L26.4802 10.2634H12.8107L10.1539 5.78458L29.1521 5.81606L32.5242 0.0339998L0 0L9.518 16.0455H9.53312L12.7603 21.6085Z"
      fill="#D71A20"
    />
    <path
      d="M51.594 12.3662H46.3887V15.2383H50.6534V17.3625H46.3887V21.7318H44.0391V10.2432H51.594V12.3674V12.3662Z"
      fill="white"
    />
    <path
      d="M63.0123 16.9785C63.0123 20.0596 60.9234 21.9396 58.1381 21.9396C55.3529 21.9396 53.2639 20.0596 53.2639 16.9785V10.2419H55.6135V17.1522C55.6135 18.876 56.7102 19.7637 58.1381 19.7637C59.566 19.7637 60.6628 18.876 60.6628 17.1522V10.2419H63.0123V16.9785Z"
      fill="white"
    />
    <path
      d="M75.8934 12.5223L74.2225 13.8973C73.4569 12.8358 72.4295 12.2088 71.0192 12.2088C68.7905 12.2088 67.3286 13.7928 67.3286 15.9863C67.3286 18.1797 68.8434 19.833 71.1577 19.833C72.4975 19.833 73.5602 19.2765 74.186 18.4051V17.4645H71.0179V15.4285H76.5356V18.8232C75.5257 20.6339 73.5073 21.9396 71.0519 21.9396C67.6056 21.9396 64.9072 19.3634 64.9072 15.9863C64.9072 12.6092 67.588 10.033 71.0519 10.033C73.1056 10.033 74.7942 10.9559 75.8909 12.5223H75.8934Z"
      fill="white"
    />
    <path
      d="M85.1885 20.2334H80.488L79.8446 21.7306H77.3199L82.4548 10.033H83.238L88.3552 21.7306H85.8306L85.1859 20.2334H85.1885ZM82.8389 14.4199L81.3065 18.3019H84.37L82.8376 14.4199H82.8389Z"
      fill="white"
    />
    <path
      d="M97.2528 12.2088L95.6511 13.4453C95.0946 12.6092 94.3982 12.1219 93.4576 12.1219C92.6921 12.1219 92.0298 12.4694 92.0298 13.2363C92.0298 13.8973 92.5347 14.1756 93.3695 14.5596L94.6753 15.1338C96.4859 15.9347 97.5122 16.8576 97.5122 18.4933C97.5122 20.6338 95.772 21.9396 93.4916 21.9396C91.5072 21.9396 89.9056 20.9298 89.0531 19.3986L90.6724 18.1797C91.2994 19.0498 92.1166 19.8506 93.4929 19.8506C94.5028 19.8506 95.1286 19.381 95.1286 18.5802C95.1286 17.8322 94.572 17.5703 93.6667 17.1523L92.4302 16.5957C90.7933 15.8478 89.6449 14.9072 89.6449 13.2879C89.6449 11.3035 91.2982 10.033 93.4576 10.033C95.286 10.033 96.5741 10.9559 97.2528 12.2088Z"
      fill="white"
    />
    <path
      d="M111.16 15.9863C111.16 19.3634 108.41 21.9396 105.033 21.9396C101.656 21.9396 98.9399 19.3634 98.9399 15.9863C98.9399 12.6092 101.656 10.033 105.033 10.033C108.41 10.033 111.16 12.6092 111.16 15.9863ZM101.36 15.9863C101.36 18.215 102.909 19.7638 105.051 19.7638C107.192 19.7638 108.741 18.215 108.741 15.9863C108.741 13.7576 107.157 12.2088 105.051 12.2088C102.944 12.2088 101.36 13.7752 101.36 15.9863Z"
      fill="white"
    />
    <path
      d="M46.0197 25.4011H44.3827V26.5104H45.7414V26.858H44.3827V28.2846H44.0012V25.0599H46.0197V25.4024V25.4011Z"
      fill="white"
    />
    <path
      d="M49.1374 27.004C49.1374 27.864 48.5897 28.3375 47.8619 28.3375C47.1341 28.3375 46.5763 27.864 46.5763 27.004V25.0599H46.9578V27.0292C46.9578 27.6789 47.3582 27.9874 47.8619 27.9874C48.3656 27.9874 48.7559 27.6802 48.7559 27.0292V25.0599H49.1374V27.004Z"
      fill="white"
    />
    <path d="M52.347 25.4011H51.2087V28.2833H50.8272V25.4011H49.694V25.0586H52.347V25.4011Z" fill="white" />
    <path
      d="M55.4585 27.004C55.4585 27.864 54.9107 28.3375 54.1829 28.3375C53.4552 28.3375 52.8973 27.864 52.8973 27.004V25.0599H53.2789V27.0292C53.2789 27.6789 53.6793 27.9874 54.1829 27.9874C54.6866 27.9874 55.0769 27.6802 55.0769 27.0292V25.0599H55.4585V27.004Z"
      fill="white"
    />
    <path
      d="M58.4388 28.2833L57.4377 26.9297H56.749V28.2833H56.3674V25.0586H57.5007C58.1353 25.0586 58.5319 25.4489 58.5319 25.9916C58.5319 26.426 58.2776 26.7635 57.8532 26.8856L58.8845 28.2833H58.44H58.4388ZM56.7477 26.5885H57.4856C57.9112 26.5885 58.1353 26.3442 58.1353 25.9967C58.1353 25.6491 57.9112 25.4011 57.4856 25.4011H56.7477V26.5885Z"
      fill="white"
    />
    <path
      d="M61.5312 25.4011H59.8451V26.3883H61.1345V26.7308H59.8451V27.9421H61.5891V28.2846H59.4636V25.0599H61.5299V25.4024L61.5312 25.4011Z"
      fill="white"
    />
    <path
      d="M66.4949 25.6655L66.2066 25.8909C65.9673 25.5585 65.6198 25.3583 65.1803 25.3583C64.4324 25.3583 63.8998 25.925 63.8998 26.6679C63.8998 27.4108 64.4425 27.9962 65.1992 27.9962C65.6827 27.9962 66.0592 27.757 66.2746 27.4196V26.9852H65.1753V26.6426H66.6561V27.5027C66.3728 28.0013 65.825 28.3375 65.1803 28.3375C64.2372 28.3375 63.5044 27.6097 63.5044 26.6716C63.5044 25.7335 64.2423 25.0057 65.1803 25.0057C65.7319 25.0057 66.1965 25.2551 66.4949 25.6655Z"
      fill="white"
    />
    <path
      d="M69.3331 27.6285H67.7503L67.4771 28.2833H67.0767L68.4743 25.0044H68.6154L70.013 28.2833H69.6076L69.3343 27.6285H69.3331ZM68.5411 25.6982L67.8964 27.286H69.1858L68.5411 25.6982Z"
      fill="white"
    />
    <path
      d="M73.6333 25.0057V28.2846H73.2517V25.9741L72.2205 27.3717H72.0832L71.0469 25.964V28.2846H70.6654V25.0057H70.8027L72.1462 26.8668L73.4998 25.0057H73.632H73.6333Z"
      fill="white"
    />
    <path d="M74.9616 28.2833H74.5801V25.0586H74.9616V28.2833Z" fill="white" />
    <path
      d="M78.4747 25.0586V28.3375H78.3374L76.285 25.8658V28.2846H75.9034V25.0057H76.0407L78.0931 27.4825V25.0586H78.4747Z"
      fill="white"
    />
    <path
      d="M82.2018 25.6655L81.9135 25.8909C81.6742 25.5585 81.3267 25.3583 80.8873 25.3583C80.1393 25.3583 79.6067 25.925 79.6067 26.6679C79.6067 27.4108 80.1494 27.9962 80.9061 27.9962C81.3897 27.9962 81.7661 27.757 81.9815 27.4196V26.9852H80.8822V26.6426H82.363V27.5027C82.0797 28.0013 81.5319 28.3375 80.8873 28.3375C79.9441 28.3375 79.2113 27.6097 79.2113 26.6716C79.2113 25.7335 79.9492 25.0057 80.8873 25.0057C81.4388 25.0057 81.9034 25.2551 82.2018 25.6655Z"
      fill="white"
    />
    <path
      d="M86.4957 25.5522L86.2174 25.7625C86.0462 25.5031 85.7729 25.357 85.4796 25.357C85.1862 25.357 84.9079 25.5081 84.9079 25.8267C84.9079 26.1201 85.132 26.2271 85.4254 26.3694L85.7818 26.5407C86.2514 26.7661 86.5347 27.0053 86.5347 27.4397C86.5347 27.9824 86.0903 28.3388 85.4934 28.3388C84.9809 28.3388 84.5553 28.0706 84.306 27.645L84.5893 27.4347C84.7845 27.7331 85.0729 27.9862 85.4884 27.9862C85.8699 27.9862 86.1381 27.7759 86.1381 27.4485C86.1381 27.1501 85.9177 27.033 85.6004 26.8819L85.2391 26.7106C84.8626 26.5293 84.5113 26.3002 84.5113 25.8456C84.5113 25.3319 84.9608 25.0057 85.4833 25.0057C85.9228 25.0057 86.2942 25.2349 86.4945 25.5535L86.4957 25.5522Z"
      fill="white"
    />
    <path
      d="M90.4733 26.6716C90.4733 27.6147 89.7203 28.3375 88.7974 28.3375C87.8744 28.3375 87.1265 27.6097 87.1265 26.6716C87.1265 25.7335 87.8694 25.0057 88.7974 25.0057C89.7254 25.0057 90.4733 25.7285 90.4733 26.6716ZM87.5218 26.6666C87.5218 27.4196 88.0645 27.9862 88.8024 27.9862C89.5403 27.9862 90.0779 27.4196 90.0779 26.6666C90.0779 25.9136 89.5302 25.357 88.8024 25.357C88.0746 25.357 87.5218 25.9187 87.5218 26.6666Z"
      fill="white"
    />
    <path d="M91.5915 27.9421H93.1213V28.2846H91.2112V25.0599H91.5927V27.9421H91.5915Z" fill="white" />
    <path
      d="M96.2086 27.004C96.2086 27.864 95.6609 28.3375 94.9331 28.3375C94.2053 28.3375 93.6475 27.864 93.6475 27.004V25.0599H94.029V27.0292C94.029 27.6789 94.4294 27.9874 94.9331 27.9874C95.4367 27.9874 95.8271 27.6802 95.8271 27.0292V25.0599H96.2086V27.004Z"
      fill="white"
    />
    <path d="M99.4181 25.4011H98.2798V28.2833H97.8983V25.4011H96.765V25.0586H99.4181V25.4011Z" fill="white" />
    <path d="M100.395 28.2833H100.014V25.0586H100.395V28.2833Z" fill="white" />
    <path
      d="M104.475 26.6716C104.475 27.6147 103.722 28.3375 102.799 28.3375C101.876 28.3375 101.128 27.6097 101.128 26.6716C101.128 25.7335 101.871 25.0057 102.799 25.0057C103.727 25.0057 104.475 25.7285 104.475 26.6716ZM101.523 26.6666C101.523 27.4196 102.066 27.9862 102.804 27.9862C103.542 27.9862 104.079 27.4196 104.079 26.6666C104.079 25.9136 103.532 25.357 102.804 25.357C102.076 25.357 101.523 25.9187 101.523 26.6666Z"
      fill="white"
    />
    <path
      d="M107.781 25.0586V28.3375H107.644L105.592 25.8658V28.2846H105.21V25.0057H105.347L107.4 27.4825V25.0586H107.781Z"
      fill="white"
    />
    <path
      d="M110.674 25.5522L110.396 25.7625C110.225 25.5031 109.951 25.357 109.658 25.357C109.365 25.357 109.086 25.5081 109.086 25.8267C109.086 26.1201 109.31 26.2271 109.604 26.3694L109.96 26.5407C110.43 26.7661 110.713 27.0053 110.713 27.4397C110.713 27.9824 110.269 28.3388 109.672 28.3388C109.159 28.3388 108.734 28.0706 108.484 27.645L108.768 27.4347C108.963 27.7331 109.251 27.9862 109.667 27.9862C110.048 27.9862 110.316 27.7759 110.316 27.4485C110.316 27.1501 110.096 27.033 109.779 26.8819L109.417 26.7106C109.041 26.5293 108.69 26.3002 108.69 25.8456C108.69 25.3319 109.139 25.0057 109.662 25.0057C110.101 25.0057 110.473 25.2349 110.673 25.5535L110.674 25.5522Z"
      fill="white"
    />
  </svg>
);

IconProviderFugasoBig.propTypes = {
  className: PropTypes.string,
};

IconProviderFugasoBig.defaultProps = {
  className: '',
};
