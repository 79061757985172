import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import { withImagesData } from 'core/hocs';
import { isEmptyOrNil, getRemoteConfig } from 'core/helpers';
import { FormattedTag } from 'core/components';
import { Carousel } from 'components/carousel/carousel';

import { DepositBonus, DEFAULT_BONUS_OPTION } from '../deposit-bonus/deposit-bonus';
import { IMAGES_DATA_CATEGORIES } from '../../../../constants';

import './bonus-selection.scss';

const firstDepositBonuses = getRemoteConfig('DEPOSIT.FIRST_BONUSES') || [];
const { BONUS_SELECTION } = IMAGES_DATA_CATEGORIES;

const preloaderItems = [0, 1, 2];

const responsiveSlidesToShow = [
  {
    breakpoint: 719,
    settings: {
      slidesToShow: 2.7,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2.2,
    },
  },
  {
    breakpoint: 500,
    settings: {
      slidesToShow: 1.8,
    },
  },
  {
    breakpoint: 420,
    settings: {
      slidesToShow: 1.6,
    }
  },
  {
    breakpoint: 360,
    settings: {
      slidesToShow: 1.4,
    },
  },
];

export class BonusSelectionUI extends Component {
  static propTypes = {
    currency: PropTypes.string.isRequired,
    className: PropTypes.string,
    bonusTypes: PropTypes.arrayOf(
      PropTypes.shape({
        label: '',
        value: '',
      })
    ).isRequired,
    depositBonuses: PropTypes.arrayOf(PropTypes.shape({})),
    bonusActive: PropTypes.string,
    changeBonusHandler: PropTypes.func,
    isInProgress: PropTypes.bool.isRequired,
    isModal: PropTypes.bool,
    updateModalAlignment: PropTypes.func,
    imagesData: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    className: '',
    bonusActive: '',
    depositBonuses: null,
    isModal: false,
    updateModalAlignment: null,
    changeBonusHandler: () => {},
  };

  state = {
    isShouldGoToSlide: true
  };

  sliderRef = createRef();

  componentDidUpdate(prevProps) {
    const { isInProgress: prevIsInProgress } = prevProps;
    const { isInProgress, updateModalAlignment } = this.props;

    if (!isInProgress && prevIsInProgress) {
      if (updateModalAlignment) {
        updateModalAlignment();
      }

      this.toggleForceGoToSlide(true);
    }
  }

  forceGoToSlide = () => {
    const { bonusTypes, bonusActive } = this.props;
    const slickIndex = R.findIndex(R.propEq('value', bonusActive))(bonusTypes);
    this.sliderRef.current.slickGoTo(slickIndex);
    this.toggleForceGoToSlide(false);
  };

  toggleForceGoToSlide = (val) => {
    this.setState({ isShouldGoToSlide: val });
  };

  defineCurrentBonus = (bonus) => {
    const { depositBonuses } = this.props;

    if (isEmptyOrNil(depositBonuses) || bonus === DEFAULT_BONUS_OPTION) {
      return null;
    }

    return R.find(R.propEq('bonusPlanId', bonus))(depositBonuses);
  };

  render() {
    const {
      className,
      bonusTypes,
      bonusActive,
      changeBonusHandler,
      currency,
      isInProgress,
      isModal,
      imagesData,
    } = this.props;

    const { isShouldGoToSlide } = this.state;

    const isIncludeFirstBonus = !R.compose(
      R.isEmpty,
      R.filter(({ value }) => (
        R.includes(Number(value), firstDepositBonuses)
      )),
    )(bonusTypes);

    const imagesDataItems = R.propOr([], BONUS_SELECTION)(imagesData || {});

    if (isShouldGoToSlide && this.sliderRef.current && bonusActive) {
      this.forceGoToSlide();
    }

    return (
      <div className={classNames('bonus-selection mx-n1_5 mx-sm-n3', className)}>
        <FormattedTag
          id="profile.deposit-new.choose-bonus"
          className="bonus-headline pb-1_5 d-block text-gray-40 mx-sm-3 mx-1_5 h4"
        />

        <div
          className={classNames('d-none d-sm-flex flex-wrap w-100 justify-content-between rounded px-3', {
            'justify-content-lg-start mx-lg-n0_5': !isModal
          })}
        >
          {isInProgress ? (
            preloaderItems.map((id, idx) => (
              <div
                key={id}
                className={classNames('deposit-bonus deposit-bonus-preloader preloader rounded mb-1', {
                  'mx-lg-1 w-lg-33': !isModal,
                  'd-none': isModal && idx === preloaderItems.length - 1
                })}
              />
            ))
          ) : (
            bonusTypes.map(({ value, label }) => (
              <DepositBonus
                key={value}
                currency={currency}
                isActive={value === bonusActive}
                bonusType={value}
                bonusLabel={label}
                handleClick={changeBonusHandler}
                currentBonus={this.defineCurrentBonus(value)}
                isLargeSize={isIncludeFirstBonus}
                isModal={isModal}
                imagesDataItems={imagesDataItems}
              />
            ))
          )}
        </div>

        <div className="d-sm-none rounded text-gray-5">
          <Carousel
            sliderRef={this.sliderRef}
            settings={{
              lazy: true,
              responsive: responsiveSlidesToShow,
              focusOnSelect: true
            }}
          >
            {isInProgress ? (
              [0, 1].map(id => (
                <div
                  key={id}
                  className={classNames(
                    'deposit-bonus deposit-bonus-preloader preloader rounded mb-1 flex-grow-1 mr-1'
                  )}
                />
              ))
            ) : (
              bonusTypes.map(({ value, label }) => (
                <DepositBonus
                  className="flex-grow-1 mr-1"
                  key={value}
                  currency={currency}
                  isActive={value === bonusActive}
                  bonusType={value}
                  bonusLabel={label}
                  handleClick={changeBonusHandler}
                  currentBonus={this.defineCurrentBonus(value)}
                  isLargeSize={isIncludeFirstBonus}
                  isModal={isModal}
                  imagesDataItems={imagesDataItems}
                />
              ))
            )}
          </Carousel>
        </div>
      </div>
    );
  }
}

export const BonusSelection = withImagesData(BonusSelectionUI);
