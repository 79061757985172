import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withUser, withModalActions, withLocale } from 'core/hocs';
import { RouteLink } from 'core/components';
import { USER_FIELDS } from 'core/constants';

import { UserBalance } from 'components/user-balance/user-balance';
import { IconUser } from 'components/icons/icon-user/icon-user';
import { IconMenu } from 'components/icons/icon-menu/icon-menu';
import { IconLogoMobile } from 'components/icons/icon-logo-mobile/icon-logo-mobile';
import colors from 'customizations/js/color-variables';
import { SignInButtons } from '../sign-in-buttons/sign-in-buttons';
import { MODAL_IDS, SIDE_MODAL_IDS } from '../../../constants';

import './casino-header-mobile.scss';

export class CasinoHeaderMobileUI extends Component {
  static propTypes = {
    isUserLoggedIn: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    history: PropTypes.shape({
      prevLocation: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }).isRequired,
    isSportsPage: PropTypes.bool,
  };

  static defaultProps = {
    isSportsPage: false,
  };

  openProfileModal = () => {
    const { openModal } = this.props;
    openModal(MODAL_IDS.PROFILE);
  };

  openSideModalBar = () => {
    const { openModal } = this.props;
    openModal(SIDE_MODAL_IDS.SIDE_MODAL_BAR);
  };

  openSignInModal = () => {
    const { openModal } = this.props;
    openModal(MODAL_IDS.SIGN_IN);
  };

  render() {
    const { isUserLoggedIn, isSportsPage } = this.props;

    return (
      <>
        <div>
          <IconMenu
            onClick={this.openSideModalBar}
            className="cursor-pointer mr-1"
            color={colors.gray40}
          />

          <RouteLink to="/">
            <IconLogoMobile />
          </RouteLink>
        </div>

        <div className="d-flex align-items-center">
          {isUserLoggedIn ? (
            <>
              <span role="button" className="mr-1" tabIndex="0" onClick={this.openProfileModal} onKeyPress={this.openProfileModal}>
                <IconUser />
              </span>

              <UserBalance
                openProfileModal={this.openProfileModal}
                isSportsPage={isSportsPage}
              />
            </>
          ) : (
            <SignInButtons />
          )}
        </div>
      </>
    );
  }
}

export const CasinoHeaderMobile = withModalActions(
  withRouter(withUser(withLocale(CasinoHeaderMobileUI), [USER_FIELDS.IS_USER_LOGGED_IN]))
);
