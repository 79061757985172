import React from 'react';
import PropTypes from 'prop-types';

export const IconTelegram = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M17.1979 3.20621C16.9249 2.96771 16.4963 2.93358 16.053 3.11696H16.0523C15.5861 3.30971 2.85691 8.93622 2.33872 9.1661C2.24447 9.19985 1.42133 9.51635 1.50612 10.2214C1.58181 10.857 2.24338 11.1202 2.32416 11.1506L5.56033 12.2925C5.77503 13.029 6.56651 15.7462 6.74155 16.3267C6.85072 16.6886 7.02867 17.1641 7.34053 17.262C7.61418 17.3707 7.88638 17.2714 8.06251 17.1289L10.041 15.2377L13.235 17.8046L13.311 17.8515C13.5279 17.9505 13.7357 18 13.934 18C14.0872 18 14.2343 17.9704 14.3747 17.9111C14.8533 17.7086 15.0447 17.2387 15.0647 17.1855L17.4504 4.40658C17.596 3.72408 17.3936 3.37683 17.1979 3.20621ZM8.4144 12.7492L7.3227 15.7492L6.231 11.9992L14.6007 5.62421L8.4144 12.7492Z"
      fill="white"
    />
  </svg>
);

IconTelegram.propTypes = {
  className: PropTypes.string,
};

IconTelegram.defaultProps = {
  className: '',
};
