import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

export const IconParazula = ({ className }) => (
  <svg className={classNames('footer-icon', className)} width="96" height="31" viewBox="0 0 96 31">
    <g>
      <path d="M5.79053 13.1396H6.23338C6.49215 13.3888 6.66703 13.7123 6.73381 14.0652C6.92424 18.6532 7.31396 23.2412 6.51238 27.8159C6.48156 27.8798 6.43798 27.9367 6.38435 27.983C6.33068 28.0293 6.26806 28.0642 6.20044 28.0853C6.13277 28.1065 6.06147 28.1136 5.99096 28.1061C5.92046 28.0986 5.85222 28.0767 5.79053 28.0418V13.1396Z" fill="black" />
      <path d="M88.7687 13.126H89.2116V23.2231L88.7687 23.1877C87.6572 19.9259 87.6572 16.3878 88.7687 13.126Z" fill="black" />
      <path d="M5.79053 28.0417L6.26438 27.9974C6.63289 27.6659 7.11542 27.4899 7.6108 27.5064C8.10618 27.523 8.57592 27.7307 8.92153 28.086C8.95855 28.1463 8.97591 28.2166 8.97113 28.2873C8.96639 28.3579 8.93973 28.4253 8.89496 28.4801C7.86753 28.2188 6.69838 29.0425 5.79053 28.0417Z" fill="black" />
      <path d="M70.8594 18.0859C70.8594 19.1798 70.8594 20.2736 70.8594 21.3675C70.8594 23.1699 69.7169 24.0246 68.1669 23.1389C67.4007 22.696 67.1084 22.6252 66.3157 22.9795C63.5302 24.215 60.904 22.634 60.6162 19.5695C60.4656 17.9796 60.6162 16.3588 60.5409 14.7601C60.4656 13.5333 60.6516 12.9665 62.1484 12.9443C63.7117 12.9443 63.7604 13.6308 63.7029 14.7733C63.6409 16.0089 63.6542 17.2533 63.7029 18.4889C63.7649 19.8175 64.3539 20.7829 65.8374 20.7298C67.2147 20.6766 67.7019 19.7201 67.7284 18.5155C67.755 17.3109 67.7993 16.031 67.7284 14.7999C67.6576 13.5688 67.848 12.9931 69.336 12.9709C70.9037 12.9709 70.9569 13.6706 70.886 14.8221C70.8152 15.9735 70.8594 16.9921 70.8594 18.0859Z" fill="black" />
      <path d="M55.3198 15.624C53.9912 15.624 53.1055 15.5709 52.2198 15.624C51.1082 15.7081 51.1171 15.0439 51.0772 14.2467C51.0374 13.3344 51.3075 12.9403 52.304 12.9846C53.8407 13.0554 55.404 12.9846 56.9185 13.02C57.6138 13.0421 58.6634 12.6259 58.9158 13.392C59.1054 13.8698 59.1558 14.3915 59.062 14.8968C58.9681 15.4022 58.7329 15.8708 58.3844 16.2484C57.1577 17.577 56.0417 18.9764 54.6024 20.677C55.8867 20.677 56.8167 20.7213 57.7024 20.677C58.6811 20.615 59.1505 20.8453 59.155 21.9746C59.1594 23.1039 58.7475 23.3253 57.7511 23.3031C56.2144 23.2411 54.6511 23.3341 53.1365 23.2677C52.4722 23.2367 51.4758 23.7371 51.2012 22.8736C50.9267 22.01 50.847 21.0091 51.5511 20.1766C52.7247 18.7816 53.8628 17.3821 55.3198 15.624Z" fill="black" />
      <path d="M76.444 16.5272C76.444 18.3517 76.351 20.1852 76.475 22.0009C76.5724 23.4092 75.7576 23.2719 74.8409 23.2852C73.9241 23.2985 73.1801 23.3605 73.2156 21.9832C73.313 18.3385 73.2909 14.6849 73.2156 11.0313C73.2156 9.86661 73.5876 9.52118 74.7346 9.55218C75.8816 9.58318 76.5591 9.63189 76.4661 11.0535C76.3554 12.8692 76.444 14.7026 76.444 16.5272Z" fill="black" />
      <path d="M31.0025 18.0859C31.0025 17.0673 30.9405 16.0488 31.0246 15.039C31.0777 14.3792 30.5419 13.2676 31.4675 13.1569C32.393 13.0462 33.6065 12.2225 34.2442 13.7548C34.377 14.0781 34.5055 14.0293 35.5107 13.4935C36.0865 13.1879 36.9279 12.6476 37.2822 13.1613C37.6365 13.6751 37.477 14.6715 37.5169 15.4509C37.5656 16.3366 36.9234 16.2613 36.33 16.279C34.7225 16.3278 34.2043 17.2932 34.2397 18.7369C34.2619 19.6226 34.2132 20.4818 34.2397 21.3542C34.2884 22.4968 34.3815 23.3692 32.6322 23.347C30.8829 23.3249 30.9227 22.541 31.0025 21.3542C31.0335 20.2692 31.0025 19.1753 31.0025 18.0859Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.25118 27.9973L8.90832 28.0859L9.19618 22.3066C9.35056 22.3946 9.47133 22.4652 9.57367 22.5252C9.70325 22.601 9.80329 22.6596 9.90475 22.714C12.1013 23.8743 14.1872 23.551 15.6132 21.8283C17.1366 20.0038 17.2163 16.6646 15.7859 14.6895C14.475 12.8915 12.398 12.4398 10.135 13.4583C10.0412 13.5017 9.93819 13.5885 9.83478 13.6755C9.611 13.8639 9.38563 14.0535 9.24932 13.8082C8.68973 12.7865 7.9204 12.9163 7.152 13.0459C6.83792 13.0989 6.52398 13.1519 6.22461 13.1262L6.25118 27.9973ZM11.2422 15.6859C9.65675 15.7302 9.17404 16.8418 9.02789 18.219C9.18289 19.5653 9.74532 20.6858 11.2909 20.6813C12.9162 20.6548 13.6203 19.5388 13.5628 18.0242C13.514 16.607 12.8276 15.6416 11.2422 15.6859Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M49.3636 21.3278V18.2588C49.3636 17.8943 49.364 17.5294 49.3645 17.1642C49.3653 16.4335 49.3667 15.7021 49.3636 14.9728C49.3591 13.1615 47.6984 12.0366 46.547 13.2545C45.8752 13.961 45.6042 13.7791 45.2468 13.5391C45.1471 13.4723 45.0408 13.4009 44.9173 13.343C44.0202 12.8995 42.9979 12.7775 42.0216 12.9976C41.0453 13.2177 40.1743 13.7665 39.5543 14.552C38.0486 16.4076 38.0441 19.7822 39.5543 21.7219C40.1508 22.5229 41.0106 23.0882 41.9825 23.3182C42.9544 23.5482 43.9763 23.4283 44.8686 22.9796C44.9828 22.9277 45.0838 22.8678 45.1786 22.8116C45.5608 22.5847 45.8464 22.4154 46.5381 23.0505C47.8047 24.2196 49.3636 23.139 49.3636 21.3278ZM44.0006 20.6679C45.5196 20.6413 46.0643 19.4943 46.2149 18.1038C46.0599 16.7885 45.4886 15.6902 43.9474 15.6858C42.4063 15.6813 41.6623 16.6689 41.6623 18.0772C41.6401 19.5696 42.3709 20.6945 44.0006 20.6679Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M28.7883 21.3323C28.6878 20.3183 28.6715 19.2977 28.7395 18.281C28.7395 17.8929 28.7405 17.5039 28.7415 17.1146C28.7435 16.3357 28.7454 15.5561 28.7395 14.7824C28.7307 13.1483 27.163 12.1076 25.9761 13.197C25.2697 13.8293 24.9774 13.6755 24.5577 13.4548C24.4512 13.3988 24.3365 13.3384 24.2047 13.2856C22.234 12.4973 20.4537 12.8427 19.0587 14.4769C17.553 16.2217 17.4024 19.4989 18.7575 21.4474C19.9178 23.117 21.5343 23.7193 23.5448 23.2764C23.7981 23.2199 24.0308 23.1053 24.2624 22.9913C24.8655 22.6946 25.4614 22.4013 26.3924 23.1436C27.2338 23.8123 29.178 23.6307 28.7883 21.3323ZM23.35 15.6903C21.8 15.6814 21.1711 16.6557 21.0825 18.095C21.118 19.4856 21.7025 20.5839 23.1773 20.6591C24.8203 20.7477 25.5643 19.6051 25.5643 18.1349C25.5643 16.7886 24.9 15.6991 23.35 15.6903Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M88.7687 23.1877V13.1259C88.6651 13.1085 88.5557 13.0884 88.4418 13.0677C87.6075 12.9152 86.5659 12.7249 86.2931 13.2278C85.8365 14.0261 85.5783 13.8667 85.182 13.6221C85.0903 13.5654 84.9911 13.5042 84.8804 13.4492C82.7901 12.4218 80.7175 12.8691 79.358 14.5564C77.8478 16.4252 77.839 19.7732 79.3358 21.6997C80.691 23.4445 82.6484 23.8697 84.8184 22.8998C84.9296 22.8501 85.0571 22.7633 85.186 22.6755C85.4933 22.4659 85.8095 22.2506 85.9344 22.5189C86.4029 23.5295 87.0885 23.3873 87.7656 23.247C88.1124 23.1751 88.4569 23.1037 88.7687 23.1877ZM83.7733 20.6678C85.3188 20.6457 85.8281 19.4809 86.0141 18.1214C85.806 16.8282 85.2967 15.6945 83.7378 15.6812C82.179 15.6679 81.4748 16.6777 81.4527 18.0682C81.4305 19.5429 82.1258 20.6678 83.7733 20.6678Z" fill="black" />
      <path d="M78.388 5.4161L79.1232 6.22653C79.1497 6.25656 79.1838 6.27923 79.2219 6.29238C79.26 6.30554 79.3008 6.30868 79.3402 6.30159C79.3796 6.29446 79.4168 6.27733 79.4478 6.25177C79.4788 6.22622 79.5027 6.19314 79.5173 6.15567L79.845 5.31424C79.8605 5.27855 79.884 5.24706 79.9141 5.2224C79.9442 5.19777 79.9797 5.18068 80.0177 5.17253L80.8636 5.00867C80.9052 5.0007 80.9438 4.98157 80.9756 4.9534C81.0071 4.92524 81.0306 4.88901 81.0434 4.84862C81.0558 4.80819 81.0576 4.7651 81.0478 4.72392C81.0381 4.68269 81.0173 4.64491 80.9876 4.61453L80.2835 3.89267C80.2401 3.84744 80.2162 3.78702 80.217 3.72439V2.83867C80.2179 2.79543 80.2073 2.75272 80.1856 2.71528C80.1639 2.67784 80.1324 2.64711 80.0944 2.62649C80.0563 2.60587 80.0133 2.59616 79.9699 2.59844C79.927 2.60072 79.8853 2.61489 79.8495 2.63939L79.2737 3.02467C79.2357 3.04963 79.1909 3.06293 79.1453 3.06293C79.0997 3.06293 79.055 3.04963 79.0169 3.02467L78.3172 2.58182C78.2817 2.5597 78.241 2.54749 78.1989 2.54646C78.1573 2.54542 78.1157 2.55559 78.0794 2.57593C78.0426 2.59625 78.012 2.626 77.9908 2.66207C77.9695 2.69815 77.9585 2.73924 77.9585 2.7811V3.78639C77.9585 3.8336 77.9443 3.87976 77.9186 3.91925L77.2277 4.93782C77.2038 4.97254 77.1897 5.01306 77.187 5.05508C77.1843 5.09716 77.1928 5.13918 77.2118 5.17678C77.2308 5.21438 77.2596 5.24613 77.2951 5.26876C77.3305 5.29139 77.3717 5.30406 77.4137 5.30539L78.1843 5.33639C78.2224 5.33214 78.2605 5.33719 78.2959 5.35105C78.3314 5.36495 78.3632 5.38727 78.388 5.4161Z" fill="#F9A619" />
      <path d="M74.2737 7.08532L75.1328 7.18717C75.1638 7.19103 75.1957 7.18695 75.2249 7.17526C75.2541 7.16357 75.2798 7.1447 75.2998 7.12035C75.3197 7.09603 75.333 7.06707 75.3387 7.03616C75.3445 7.00525 75.3423 6.97341 75.3321 6.9436L75.1107 6.26603C75.1005 6.23702 75.0978 6.20585 75.1031 6.17556C75.1089 6.14526 75.1217 6.11692 75.1417 6.09332L75.5845 5.55746C75.6058 5.53138 75.6195 5.4998 75.6244 5.46632C75.6288 5.43284 75.6239 5.39878 75.6098 5.36796C75.596 5.33714 75.5739 5.31079 75.546 5.29183C75.5181 5.27288 75.4853 5.26212 75.4517 5.26075L74.6589 5.18989C74.6346 5.18812 74.6111 5.1813 74.5899 5.16987C74.5686 5.1584 74.55 5.14259 74.5349 5.12346L74.0921 4.59203C74.0704 4.56564 74.0416 4.54575 74.0093 4.53459C73.9769 4.52343 73.9424 4.5214 73.9087 4.52875C73.8755 4.53605 73.8445 4.55244 73.8197 4.57613C73.7954 4.59978 73.7777 4.62981 73.7688 4.66289L73.6227 5.19432C73.6147 5.22873 73.5965 5.25995 73.5704 5.28369C73.5443 5.30747 73.5115 5.32261 73.4765 5.32717L72.8388 5.43346C72.806 5.43877 72.7755 5.45268 72.7498 5.47385C72.7245 5.49497 72.7051 5.52256 72.6935 5.5537C72.6825 5.58487 72.6798 5.61849 72.6856 5.65104C72.6918 5.68363 72.7064 5.71401 72.7281 5.73903L73.2241 6.31475C73.2476 6.34247 73.2631 6.37621 73.2684 6.41217L73.3791 7.3776C73.3831 7.41064 73.3955 7.44208 73.4154 7.46861C73.4353 7.49514 73.4623 7.51582 73.4929 7.52848C73.5239 7.54115 73.5576 7.54531 73.5903 7.54057C73.6231 7.53588 73.6541 7.52242 73.6802 7.5016L74.1585 7.12075C74.1913 7.0955 74.2325 7.08293 74.2737 7.08532Z" fill="#F9A619" />
      <path d="M80.2261 8.85706L80.7398 9.29991C80.7584 9.31563 80.7805 9.32648 80.8044 9.33144C80.8284 9.33636 80.8532 9.33525 80.8762 9.32821C80.8997 9.32117 80.9209 9.30842 80.9382 9.2911C80.955 9.27378 80.9679 9.25244 80.9745 9.22906L81.1251 8.68877C81.1326 8.66548 81.145 8.64417 81.1618 8.6265C81.1787 8.60883 81.1995 8.59528 81.2225 8.58691L81.7318 8.42306C81.757 8.41513 81.7796 8.4006 81.7969 8.38094C81.8146 8.36128 81.8266 8.33723 81.8314 8.31137C81.8363 8.2855 81.8341 8.25876 81.8252 8.234C81.8164 8.2092 81.8009 8.18732 81.7805 8.17063L81.2889 7.77648C81.2734 7.76506 81.261 7.7504 81.2517 7.73357C81.2424 7.71679 81.2371 7.69819 81.2358 7.67906L81.1782 7.13877C81.1756 7.11238 81.1658 7.08718 81.1499 7.06592C81.1339 7.04466 81.1127 7.02814 81.0879 7.01814C81.0635 7.00813 81.0365 7.00498 81.0104 7.00906C80.9842 7.01318 80.9594 7.02434 80.9391 7.04134L80.6114 7.32477C80.5883 7.3404 80.5613 7.34873 80.5339 7.34873C80.5064 7.34873 80.4794 7.3404 80.4564 7.32477L80.0135 7.11663C79.99 7.10476 79.9639 7.09962 79.9378 7.10184C79.9112 7.10401 79.8864 7.11344 79.8651 7.12907C79.8439 7.1447 79.8275 7.16587 79.8178 7.19027C79.808 7.21468 79.8054 7.24129 79.8098 7.2672L79.8806 7.86063C79.8895 7.888 79.8895 7.9174 79.8806 7.94477L79.5308 8.62677C79.5188 8.64993 79.5135 8.67588 79.5148 8.70188C79.5162 8.72792 79.5246 8.75307 79.5392 8.77473C79.5534 8.79643 79.5737 8.81383 79.5972 8.82521C79.6202 8.83655 79.6464 8.84147 79.6725 8.83934L80.1154 8.85706C80.1508 8.84253 80.1906 8.84253 80.2261 8.85706Z" fill="#F9A619" />
      <path d="M75.3854 2.69685L75.6423 2.91828C75.6516 2.9261 75.6631 2.93146 75.675 2.9339C75.687 2.93635 75.6998 2.93579 75.7114 2.9323C75.7233 2.9288 75.7339 2.92247 75.7428 2.91385C75.7517 2.90523 75.7583 2.89458 75.7618 2.88285L75.8371 2.61271C75.8393 2.6001 75.8451 2.58838 75.8535 2.57895C75.8624 2.56953 75.8734 2.56279 75.8858 2.55957L76.1383 2.47985C76.1516 2.47665 76.164 2.46955 76.1737 2.45943C76.183 2.44931 76.1892 2.43663 76.1919 2.42295C76.1941 2.40927 76.1923 2.39519 76.187 2.38248C76.1812 2.36977 76.1719 2.35896 76.1604 2.35142L75.9168 2.15657C75.904 2.14297 75.8947 2.12615 75.8903 2.10785L75.8637 1.83771C75.869 1.82067 75.8681 1.80232 75.861 1.78592C75.854 1.76953 75.8416 1.75616 75.8256 1.74818C75.8097 1.74021 75.7911 1.73815 75.7738 1.74238C75.7565 1.74661 75.7415 1.75686 75.7308 1.77128L75.5714 1.91299C75.559 1.91958 75.5453 1.92303 75.5316 1.92303C75.5178 1.92303 75.5041 1.91958 75.4917 1.91299L75.288 1.83771C75.2765 1.83177 75.2632 1.8292 75.2499 1.8303C75.2371 1.8314 75.2242 1.83612 75.214 1.84393C75.2034 1.85174 75.195 1.86234 75.1901 1.87453C75.1852 1.88673 75.1839 1.90004 75.1861 1.91299L75.2216 2.20971C75.2255 2.22418 75.2255 2.23953 75.2216 2.25399L75.0488 2.59057C75.0422 2.60205 75.0387 2.61522 75.0391 2.62857C75.0391 2.64191 75.0435 2.65489 75.0511 2.66599C75.0581 2.6771 75.0688 2.68589 75.0807 2.69135C75.0931 2.69681 75.1064 2.69872 75.1197 2.69685H75.3588C75.3677 2.69527 75.3766 2.69527 75.3854 2.69685Z" fill="#F9A619" />
      <path d="M76.9886 8.0335L77.2455 8.25493C77.2548 8.26277 77.2663 8.26813 77.2783 8.27057C77.2902 8.273 77.3031 8.27247 77.3146 8.26897C77.3265 8.26547 77.3372 8.25914 77.346 8.2505C77.3549 8.24187 77.3615 8.23124 77.3651 8.2195L77.4403 7.94936C77.4434 7.93825 77.4488 7.92802 77.4563 7.91956C77.4643 7.91105 77.474 7.90459 77.4846 7.90065L77.7415 7.8165C77.7539 7.81265 77.765 7.80548 77.7738 7.79573C77.7822 7.78599 77.788 7.77408 77.7902 7.76124C77.7924 7.74844 77.7915 7.73524 77.7867 7.72315C77.7818 7.71101 77.7738 7.70039 77.7636 7.6925L77.5201 7.49765C77.5116 7.49171 77.5046 7.48374 77.5001 7.47449C77.4953 7.46519 77.493 7.45487 77.4935 7.4445L77.4625 7.17436C77.4612 7.16112 77.4563 7.1485 77.4479 7.13796C77.4399 7.12742 77.4288 7.1194 77.4164 7.11475C77.404 7.11015 77.3907 7.10913 77.3775 7.11183C77.3646 7.11453 77.3527 7.12086 77.3429 7.13008L77.1791 7.27179C77.168 7.27941 77.1547 7.28348 77.1414 7.28348C77.1281 7.28348 77.1148 7.27941 77.1038 7.27179L76.8691 7.1655C76.8571 7.16081 76.8438 7.15935 76.831 7.16125C76.8181 7.16316 76.8057 7.16838 76.7955 7.17644C76.7854 7.18446 76.7774 7.19504 76.7721 7.20709C76.7672 7.21913 76.7654 7.23229 76.7672 7.24522L76.8026 7.5375C76.8075 7.5519 76.8075 7.5674 76.8026 7.58179L76.6255 7.92279C76.6202 7.93479 76.618 7.94803 76.6188 7.96114C76.6202 7.97429 76.625 7.98687 76.6326 7.99763C76.6401 8.00839 76.6503 8.01694 76.6622 8.02248C76.6742 8.02801 76.6875 8.03027 76.7008 8.02907L76.9399 8.00693C76.9585 8.01003 76.9758 8.01938 76.9886 8.0335Z" fill="#F9A619" />
    </g>
  </svg>

);

IconParazula.propTypes = {
  className: PropTypes.string,
};

IconParazula.defaultProps = {
  className: '',
};

