import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedTag } from 'core/components';
import colors from 'customizations/js/color-variables';
import { DomainBarContext } from 'hocs/with-domain-bar';
import { IconCloseThin } from 'components/icons/icon-close-thin/icon-close-thin';

import { IconInfo } from 'components/icons/icon-info/icon-info';
import './domain-bar.scss';

export const DomainBar = ({ className }) => {
  const { closeDomainBar } = useContext(DomainBarContext);

  return (
    <div className={classNames('domain-bar position-fixed d-flex align-items-center justify-content-start justify-content-md-center px-2 py-1 py-sm-0 py-sm-0 bg-primary w-100', className)}>
      <IconInfo className="domain-bar-icon-info mr-1" color={colors.black} />
      <div className="domain-bar-messages d-flex flex-column flex-md-row align-items-start pr-1_5 p-sm-0">
        <FormattedTag id="domain.bar.message1" className="font-weight-semi-bold text-black m-0 ml-sm-0_5" />
        <FormattedTag id="domain.bar.message2" className="font-weight-normal text-black m-0 ml-sm-0_5" />
      </div>

      <div
        role="button"
        tabIndex={0}
        onClick={closeDomainBar}
        onKeyPress={closeDomainBar}
        className="domain-bar-icon-close position-absolute"
      >
        <IconCloseThin color={colors.black} size={14} />
      </div>
    </div>
  );
};

DomainBar.propTypes = {
  className: PropTypes.string
};

DomainBar.defaultProps = {
  className: '',
};
