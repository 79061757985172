import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderQuickSpinBig = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="110"
    height="27"
    viewBox="0 0 110 27"
    fill="none"
  >
    <path
      d="M1.89955 7.62049C2.80517 7.62049 3.55617 6.86949 3.55617 5.96387C3.55617 4.55021 4.79312 3.93174 6.03007 3.93174H6.11842C7.35537 3.93174 8.59232 4.55021 8.59232 5.96387C8.59232 6.86949 9.34332 7.62049 10.2489 7.62049C11.1546 7.62049 11.9277 6.86949 11.9277 5.96387C11.9277 2.8715 9.49794 0.618484 6.14051 0.618484H6.05216C2.67264 0.618484 0.24292 2.84941 0.24292 5.96387C0.24292 6.86949 0.971836 7.62049 1.89955 7.62049Z"
      fill="#F26822"
    />
    <path
      d="M11.9277 17.8916C11.9277 16.9859 11.1767 16.2349 10.271 16.2349C9.36541 16.2349 8.61441 16.9639 8.61441 17.8916C8.61441 19.3052 7.37746 19.9237 6.14051 19.9237H6.05216C4.81521 19.9237 3.57826 19.3052 3.57826 17.8916C3.57826 16.9859 2.82726 16.2349 1.92163 16.2349C1.01601 16.2349 0.24292 16.9639 0.24292 17.8916C0.24292 20.9839 2.67264 23.2369 6.03007 23.2369H6.11842C6.56019 23.2369 7.00196 23.1928 7.39955 23.1265L8.85738 25.9759C9.14453 26.5281 10.0501 26.6606 10.8453 26.241C11.6626 25.8213 12.0823 25.0261 11.7951 24.4739L10.3815 21.6245C11.3534 20.6747 11.9277 19.3715 11.9277 17.8916Z"
      fill="#F26822"
    />
    <path
      d="M3.53419 16.257V7.59841C3.11452 8.01809 2.54022 8.28315 1.87757 8.28315C1.21492 8.28315 0.618531 7.996 0.198853 7.55423V16.2791C0.618531 15.8374 1.21492 15.5502 1.87757 15.5502C2.54022 15.5723 3.11452 15.8153 3.53419 16.257Z"
      fill="#F26822"
    />
    <path
      d="M8.59241 7.55423V16.2791C9.01209 15.8374 9.60847 15.5502 10.2711 15.5502C10.9117 15.5502 11.486 15.8153 11.9057 16.2349V7.59841C11.486 8.01809 10.9117 8.28315 10.249 8.28315C9.60847 8.28315 9.01209 7.996 8.59241 7.55423Z"
      fill="#F26822"
    />
    <path
      d="M37.5946 7.62049C38.5002 7.62049 39.2512 6.86949 39.2512 5.96387C39.2512 4.55021 40.4882 3.93174 41.7251 3.93174H41.8135C43.0504 3.93174 44.2874 4.55021 44.2874 5.96387C44.2874 6.86949 45.0384 7.62049 45.944 7.62049C46.8496 7.62049 47.6006 6.86949 47.6006 5.96387C47.6006 2.8715 45.1709 0.618484 41.8135 0.618484H41.7251C38.3677 0.618484 35.938 2.8715 35.938 5.96387C35.938 6.86949 36.6669 7.62049 37.5946 7.62049Z"
      fill="#F26822"
    />
    <path
      d="M45.966 16.2349C45.0604 16.2349 44.3094 16.9859 44.3094 17.8916C44.3094 19.3052 43.0724 19.9237 41.8355 19.9237H41.7471C40.5102 19.9237 39.2732 19.3052 39.2732 17.8916C39.2732 16.9859 38.5222 16.2349 37.6166 16.2349C36.711 16.2349 35.96 16.9859 35.96 17.8916C35.96 20.9839 38.3897 23.237 41.7471 23.237H41.8355C45.1929 23.237 47.6226 20.9839 47.6226 17.8916C47.6226 16.9639 46.8716 16.2349 45.966 16.2349Z"
      fill="#F26822"
    />
    <path
      d="M39.2289 16.257V7.59841C38.8092 8.01809 38.2349 8.28315 37.5723 8.28315C36.9096 8.28315 36.3132 7.996 35.8936 7.55423V16.2791C36.3132 15.8374 36.9096 15.5502 37.5723 15.5502C38.2349 15.5723 38.8092 15.8374 39.2289 16.257Z"
      fill="#F26822"
    />
    <path
      d="M64.9841 7.62049C65.8898 7.62049 66.6408 6.86949 66.6408 5.96387C66.6408 4.55021 67.8777 3.93174 69.1147 3.93174H69.203C70.44 3.93174 71.6769 4.55021 71.6769 5.96387C71.6769 6.86949 72.4279 7.62049 73.3335 7.62049C74.2391 7.62049 74.9901 6.86949 74.9901 5.96387C74.9901 2.8715 72.5604 0.618484 69.203 0.618484H69.1147C65.7572 0.618484 63.3275 2.8715 63.3275 5.96387C63.3275 6.86949 64.0785 7.62049 64.9841 7.62049Z"
      fill="#F26822"
    />
    <path
      d="M66.243 8.23896C65.6908 8.59238 65.006 8.63655 64.3876 8.43776L70.9478 17.3615C71.0362 16.7209 71.3675 16.1245 71.9418 15.7711C72.494 15.4398 73.1346 15.3735 73.753 15.5502L67.2149 6.69278C67.1265 7.33334 66.7952 7.90764 66.243 8.23896Z"
      fill="#F26822"
    />
    <path
      d="M73.3555 16.2349C72.4499 16.2349 71.6989 16.9859 71.6989 17.8916C71.6989 19.3052 70.4619 19.9237 69.225 19.9237H69.1366C67.8997 19.9237 66.6627 19.3052 66.6627 17.8916C66.6627 16.9859 65.9117 16.2349 65.0061 16.2349C64.1005 16.2349 63.3495 16.9859 63.3495 17.8916C63.3495 20.9839 65.7792 23.237 69.1366 23.237H69.225C72.5824 23.237 75.0121 20.9839 75.0121 17.8916C75.0121 16.9639 74.2611 16.2349 73.3555 16.2349Z"
      fill="#F26822"
    />
    <path
      d="M24.8273 16.2349C23.9217 16.2349 23.1707 16.9859 23.1707 17.8916C23.1707 19.3052 21.9337 19.9237 20.6968 19.9237H20.6084C19.3715 19.9237 18.1124 19.2831 18.1124 17.8916C18.1124 16.9859 17.3614 16.2349 16.4558 16.2349C15.5502 16.2349 14.7992 16.9639 14.7992 17.8916C14.7992 20.9839 17.2289 23.237 20.5863 23.237H20.6747C24.0321 23.237 26.4618 20.9839 26.4618 17.8916C26.4839 16.9639 25.755 16.2349 24.8273 16.2349Z"
      fill="#F26822"
    />
    <path
      d="M24.8274 0.618484C23.9218 0.618484 23.1708 1.36949 23.1708 2.27511V6.82531V8.50403V16.2129C23.5905 15.7711 24.1868 15.4839 24.8495 15.4839C25.4901 15.4839 26.0644 15.749 26.484 16.1687V8.48194V6.8474V2.27511C26.484 1.3474 25.733 0.618484 24.8274 0.618484Z"
      fill="#F26822"
    />
    <path
      d="M18.1345 16.1687V8.48194V6.8474V2.27511C18.1345 1.36949 17.3835 0.618484 16.4779 0.618484C15.5723 0.618484 14.7992 1.3474 14.7992 2.27511V6.82531V8.50403V16.2129C15.2189 15.7711 15.8153 15.4839 16.4779 15.4839C17.1185 15.4839 17.7149 15.749 18.1345 16.1687Z"
      fill="#F26822"
    />
    <path
      d="M32.8897 8.48194V6.8474V2.27511C32.8897 1.36949 32.1387 0.618484 31.233 0.618484C30.3274 0.618484 29.5764 1.36949 29.5764 2.27511V6.82531V8.50403V15.5502V21.5803C29.5764 22.486 30.3274 23.237 31.233 23.237C32.1387 23.237 32.8897 22.486 32.8897 21.5803V15.3514V8.48194Z"
      fill="#F26822"
    />
    <path
      d="M94.4497 8.50403V6.8474V2.27511C94.4497 1.36949 93.6987 0.618484 92.7931 0.618484C91.8875 0.618484 91.1365 1.36949 91.1365 2.27511V6.82531V8.50403V15.5502V21.5803C91.1365 22.486 91.8875 23.237 92.7931 23.237C93.6987 23.237 94.4497 22.486 94.4497 21.5803V15.3514V8.50403Z"
      fill="#F26822"
    />
    <path
      d="M100.436 10.9337L99.7733 9.58636L98.956 7.92973L97.1227 4.21889V6.82531V8.50403V15.5502V21.5803C97.1227 22.486 97.8737 23.237 98.7793 23.237C99.6849 23.237 100.436 22.486 100.436 21.5803V15.3514V10.9337Z"
      fill="#F26822"
    />
    <path
      d="M106.687 14.5562L103.351 7.7972L102.556 6.18475L100.303 1.67873C99.8614 0.773104 98.8232 0.375514 98.0059 0.773104C97.1887 1.17069 96.8794 2.25302 97.3212 3.13656L99.53 7.62049L100.347 9.27712L103.793 16.2129L106.731 22.1546C107.173 23.0602 108.211 23.4578 109.028 23.0602C109.845 22.6627 110.155 21.5803 109.713 20.6968L106.687 14.5562Z"
      fill="#F26822"
    />
    <path
      d="M109.978 15.3514V8.50403V6.8474V2.27511C109.978 1.36949 109.227 0.618484 108.321 0.618484C107.416 0.618484 106.665 1.36949 106.665 2.27511V6.82531V8.50403V13.0321L107.283 14.2912L109.978 19.747V15.3514Z"
      fill="#F26822"
    />
    <path
      d="M52.7471 9.74097L49.9198 13.8273C49.5001 14.4458 49.7651 15.3735 50.5161 15.9036C51.2671 16.4117 52.239 16.3454 52.6587 15.7269L55.5744 11.508L58.7772 6.8474L59.5503 5.72089L61.6928 2.60643C62.1125 1.98796 61.8475 1.06025 61.0965 0.530128C60.3455 0.0220954 59.3736 0.0883605 58.9539 0.706834L56.8334 3.7992L56.0603 4.92571L52.7471 9.74097Z"
      fill="#F26822"
    />
    <path
      d="M60.1467 17.6265L58.0042 13.4297L57.4961 12.4137L56.7672 10.9779L56.1487 11.8835L54.6246 14.0924L55.0664 14.9538L57.2752 19.2831L59.1748 22.9719C59.462 23.5241 60.3676 23.6566 61.1628 23.237C61.9801 22.8173 62.3997 22.0221 62.1126 21.4699L60.1467 17.6265Z"
      fill="#F26822"
    />
    <path
      d="M53.0341 8.1727V6.73696V2.16467C53.0341 1.25905 52.2831 0.508041 51.3774 0.508041C50.4718 0.508041 49.6987 1.25905 49.6987 2.16467V6.71487V8.39358V12.988L52.1947 9.36547L53.0341 8.1727Z"
      fill="#F26822"
    />
    <path
      d="M50.1405 16.4558C49.9859 16.3454 49.8313 16.2129 49.6987 16.0803V21.4699C49.6987 22.3755 50.4497 23.1265 51.3554 23.1265C52.261 23.1265 53.012 22.3755 53.012 21.4699V16.3233C52.6586 16.6988 52.1285 16.9197 51.5542 16.9197C51.0682 16.9197 50.5602 16.743 50.1405 16.4558Z"
      fill="#F26822"
    />
    <path
      d="M84.5984 0.463861L84.6205 0.441772H82.3233H80.8654H78.7891C77.9939 0.441772 77.3313 1.08233 77.3313 2.14258C77.3313 3.20282 77.9939 3.84338 78.7891 3.84338H80.8433H82.3233H84.2008C85.3715 3.93173 85.8795 5.54418 85.8795 6.93575V7.06828C85.8795 8.45984 85.3494 10.2932 84.2008 10.3815H82.3233H80.8654H78.7891C77.9939 10.3815 77.3313 11.0221 77.3313 12.0823C77.3313 13.1426 77.9939 13.7831 78.7891 13.7831H80.8433H82.3233H84.6425C84.6425 13.7611 84.6205 13.761 84.6205 13.739C87.0723 13.4076 88.8172 10.6908 88.8172 7.04619V6.93575C88.8172 3.29117 87.0723 0.795186 84.5984 0.463861Z"
      fill="#F26822"
    />
    <path
      d="M80.6666 14.4679H78.7891C78.2148 14.4679 77.7068 14.247 77.3313 13.8715V15.5502V21.5803C77.3313 22.486 78.0823 23.237 78.9879 23.237C79.8935 23.237 80.6445 22.486 80.6445 21.5803V15.3514V14.4679H80.6666Z"
      fill="#F26822"
    />
    <path
      d="M80.6666 9.71888V8.48193V6.84739V4.52812H78.7891C78.2148 4.52812 77.7068 4.30723 77.3313 3.93173V6.82531V8.50402V10.3153C77.7068 9.93976 78.1927 9.71888 78.7891 9.71888H80.6666Z"
      fill="#F26822"
    />
  </svg>
);

IconProviderQuickSpinBig.propTypes = {
  className: PropTypes.string,
};
IconProviderQuickSpinBig.defaultProps = {
  className: null,
};
