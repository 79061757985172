import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderGalaxsysBig = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="211"
    height="30"
    viewBox="0 0 211 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#icon-provider-galaxsys-big-clip0_1819_74)">
      <path
        d="M17.2195 0.466053C9.31257 0.466053 0 3.93556 0 15.2244C0 26.5132 9.31257 29.9827 17.2195 29.9827H25.9258V15.1812H20.0923V24.2952H17.2195C9.69035 24.2952 6.21131 21.0587 6.21131 15.1812C6.21131 10.3222 9.64642 6.16226 18.2562 6.16226H25.9258V0.466053H17.2195Z"
        fill="#5C6374"
        className="icon-switch-dark"
      />
      <path
        d="M55.155 10.8487C55.1111 10.0201 54.9793 9.21749 54.7773 8.458C54.2589 6.55063 53.2574 4.85903 51.7463 3.37457C49.4357 1.11335 46.4311 0 42.7149 0C39.0865 0 36.0818 1.11335 33.7713 3.37457C31.4695 5.64442 30.2922 8.38032 30.2922 11.66V29.9827H36.1258V21.8872H49.3479V29.9827H55.1814V11.66C55.1814 11.3838 55.1726 11.1162 55.155 10.8487ZM36.1258 16.1479V11.5219C36.1258 8.19045 38.5681 5.6962 42.7149 5.6962C46.8001 5.6962 49.2776 8.25949 49.3391 11.9275V11.9361C49.3479 11.9793 49.3479 12.0224 49.3479 12.0742V16.1479H36.1258Z"
        fill="#5C6374"
        className="icon-switch-dark"
      />
      <path d="M78.4628 29.9827V24.2952H65.3725V0.466053H59.5389V29.9914H78.4628V29.9827Z" fill="#5C6374" className="icon-switch-dark" />
      <path
        d="M102.93 3.3832C100.62 1.12198 97.6063 0.00862885 93.89 0.00862885C90.2704 0.00862885 87.257 1.12198 84.9552 3.3832C82.6534 5.65305 81.4762 8.38032 81.4762 11.6686V29.9914H87.3097V21.8959H100.532V29.9914H106.357V11.6686C106.357 8.38032 105.232 5.65305 102.93 3.3832ZM100.532 16.1565H87.3097V11.5305C87.3097 8.19908 89.7521 5.6962 93.89 5.6962C98.0368 5.6962 100.532 8.33717 100.532 12.0829V16.1565Z"
        fill="#5C6374"
        className="icon-switch-dark"
      />
      <path
        d="M136.209 0.466053H129.251L122.741 10.607L116.231 0.466053H109.273L118.753 15.2244L109.273 29.9914H116.231L122.741 19.8418L129.251 29.9914H136.209L126.73 15.2244L136.209 0.466053Z"
        fill="#5C6374"
        className="icon-switch-dark"
      />
      <path
        d="M147.402 0.483315C140.207 0.483315 137.668 4.50518 137.668 9.04488C137.668 13.2566 139.601 17.7877 146.418 17.7877H150.609C153.622 17.7877 155.265 18.2969 155.265 21.0242C155.265 23.4753 153.622 24.3124 150.609 24.3124H138.986V30H150.609C158.516 30 160.861 26.9448 160.861 21.2054C160.861 16.9505 159.025 12.3677 150.978 12.3677H147.077C144.582 12.3677 143.264 11.7204 143.264 9.36421C143.264 7.1893 144.582 6.17089 147.121 6.17089H158.507V0.483315H147.402Z"
        fill="#5C6374"
        className="icon-switch-dark"
      />
      <path
        d="M187.675 0.43153H181.463L174.268 14.8188L167.064 0.43153H160.853L171.439 20.3251V29.9482H177.273V20.3251L187.675 0.43153Z"
        fill="#5C6374"
        className="icon-switch-dark"
      />
      <path
        d="M197.549 0.466053C190.354 0.466053 187.815 4.48792 187.815 9.02762C187.815 13.2394 189.748 17.7704 196.565 17.7704H200.756C203.77 17.7704 205.412 18.2796 205.412 21.0069C205.412 23.458 203.77 24.2952 200.756 24.2952H189.133V29.9827H200.756C208.663 29.9827 211.009 26.9275 211.009 21.1881C211.009 16.9333 209.173 12.3504 201.125 12.3504H197.224C194.729 12.3504 193.412 11.7031 193.412 9.34695C193.412 7.17204 194.729 6.15363 197.268 6.15363H208.654V0.466053H197.549Z"
        fill="#5C6374"
        className="icon-switch-dark"
      />
    </g>
  </svg>
);

IconProviderGalaxsysBig.propTypes = {
  className: PropTypes.string,
};

IconProviderGalaxsysBig.defaultProps = {
  className: '',
};
