import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Button from 'reactstrap/lib/Button';

import { CookiesPolicy as CookiesPolicyCore, FormattedTag, RouteLink } from 'core/components';
import { withCoreComponent, withUserAgent } from 'core/hocs';
import { withSafariSearchBar } from 'hocs/with-safari-search-bar';

import './cookies-policy.scss';

const CookiesPolicyUI = ({
  isAccepted,
  acceptCookiesPolicy,
  hasNavigation,
  noSidebar,
  navBarHeight,
}) => (
  <div
    style={{ ...(!!navBarHeight && navBarHeight >= 0) && ({ marginBottom: navBarHeight }) }}
    className={classNames('cookies-policy bg-primary text-black rounded position-fixed flex-column flex-md-row align-items-center justify-content-between justify-content-lg-center mx-1_25 px-2 mx-lg-auto', {
      'has-navigation': hasNavigation,
      'cookies-policy-no-sidebar': noSidebar,
      'd-none': isAccepted,
      'd-flex': !isAccepted,
    })}
  >
    <div className="d-md-flex align-items-center align-items-lg-right mr-md-2">
      <div className="mb-1 mb-sm-1_5 mb-md-0 pt-1_5 pt-md-0 p-lg-1_5">
        <FormattedMessage id="cookies.title" />
        {' '}
        <FormattedTag
          tag={RouteLink}
          to="/privacy-policy"
          id="cookies.policy"
          className="policy-link position-relative d-inline-block text-black font-weight-semi-bold"
        />
        .
      </div>
    </div>

    <FormattedTag
      id="accept"
      tag={Button}
      onClick={acceptCookiesPolicy}
      className="cookies-btn flex-shrink-0 ml-auto ml-sm-0 py-1 mb-2 mb-md-0 text-primary"
      color="black"
    />
  </div>
);

CookiesPolicyUI.propTypes = {
  isAccepted: PropTypes.bool.isRequired,
  acceptCookiesPolicy: PropTypes.func.isRequired,
  hasNavigation: PropTypes.bool,
  noSidebar: PropTypes.bool,
  navBarHeight: PropTypes.number.isRequired,
};

CookiesPolicyUI.defaultProps = {
  hasNavigation: false,
  noSidebar: false,
};

export const CookiesPolicy = withCoreComponent(
  CookiesPolicyCore,
  withUserAgent(
    withSafariSearchBar(CookiesPolicyUI)
  )
);
