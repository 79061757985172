import { addLocaleData } from 'react-intl';

import intlEN from 'react-intl/locale-data/en';
import intlRU from 'react-intl/locale-data/ru';
import intlDE from 'react-intl/locale-data/de';
import intlFI from 'react-intl/locale-data/fi';
import intlNO from 'react-intl/locale-data/no';
import intlSV from 'react-intl/locale-data/sv';
import intlHU from 'react-intl/locale-data/hu';
import intlPL from 'react-intl/locale-data/pl';
import intlTR from 'react-intl/locale-data/tr';
import intlIT from 'react-intl/locale-data/it';
import intlTH from 'react-intl/locale-data/th';

import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import 'dayjs/locale/de';
import 'dayjs/locale/fi';
import 'dayjs/locale/sv';
import 'dayjs/locale/nn';
import 'dayjs/locale/hu';
import 'dayjs/locale/pl';
import 'dayjs/locale/tr';
import 'dayjs/locale/it';
import 'dayjs/locale/th';

addLocaleData([
  ...intlEN,
  ...intlRU,
  ...intlDE,
  ...intlFI,
  ...intlNO,
  ...intlSV,
  ...intlHU,
  ...intlPL,
  ...intlTR,
  ...intlIT,
  ...intlTH
]);
