import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import Collapse from 'reactstrap/lib/Collapse';
import { encodeSVG, getPaymentMethodIcon } from 'core/helpers';
import colors from 'customizations/js/color-variables';
import { withModalAlignment } from 'hocs/with-modal-alignment';
import { FormProcessWithdrawal } from 'components/forms/form-process-withdrawal/form-process-withdrawal';
import { IconArrowDown } from 'components/icons/icon-arrow-down/icon-arrow-down';
import { DepositNew } from 'components/deposit/deposit-new/deposit-new';

import './payment-method-card.scss';

class PaymentMethodCardUI extends Component {
  static propTypes = {
    className: PropTypes.string,
    currency: PropTypes.string,
    onClick: PropTypes.func,
    paymentMethod: PropTypes.string.isRequired,
    minimum: PropTypes.number,
    isSmall: PropTypes.bool,
    activePaymentMethod: PropTypes.string,
    updateModalAlignment: PropTypes.func.isRequired,
    toggleDepositNew: PropTypes.func,
    isModal: PropTypes.bool,
    isPaymentsPage: PropTypes.bool,
    isWithdrawal: PropTypes.bool.isRequired,
    activeBonuses: PropTypes.arrayOf(PropTypes.shape()),
    setActiveWithdrawalMethod: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    isSmall: false,
    onClick: null,
    minimum: null,
    currency: '',
    activePaymentMethod: null,
    toggleDepositNew: () => {},
    isModal: false,
    isPaymentsPage: false,
    activeBonuses: {},
    setActiveWithdrawalMethod: () => {},
  };

  state = {
    isOpen: false,
  };

  toggle = (e) => {
    this.props.onClick(e);

    this.setState(({ isOpen }) => ({
      isOpen: !isOpen,
    }));
  };

  render() {
    const {
      paymentMethod,
      onClick,
      currency,
      isSmall,
      minimum,
      className,
      activePaymentMethod,
      updateModalAlignment,
      toggleDepositNew,
      isModal,
      isPaymentsPage,
      isWithdrawal,
      activeBonuses,
      setActiveWithdrawalMethod,
    } = this.props;

    const { isOpen } = this.state;

    const icon = getPaymentMethodIcon(paymentMethod) || null;
    const isActiveMethod = activePaymentMethod === paymentMethod;
    const iconSrc = !R.isNil(icon) && icon.includes('<svg') ? `data:image/svg+xml;utf8,${encodeSVG(icon)}` : icon;

    return (
      <div className={className}>
        <div
          role="button"
          tabIndex="0"
          onClick={this.toggle}
          onKeyPress={this.toggle}
          data-name={paymentMethod}
          className={classNames('d-flex w-100 align-items-center payment-card px-1 px-sm-1_5 bg-black', {
            small: isSmall,
            'without-action': !onClick,
            rounded: !(isOpen && isActiveMethod),
            'rounded-top': isOpen && isActiveMethod,
            'payment-method-card': isPaymentsPage,
            'border-bottom-0': isOpen && isActiveMethod,
            'bg-default': isPaymentsPage
          })}
        >
          <div
            className={classNames('d-flex justify-content-between w-100 h-100 align-items-center', {
              'justify-content-end': currency && minimum
            })}
          >
            {!R.isNil(icon) && (
              <img
                alt={paymentMethod}
                className="mw-100 mh-100"
                src={iconSrc}
              />
            )}

            {currency && minimum && (
              <div
                style={{ width: 30, height: 30 }}
                className="border border-gray-10 rounded-circle d-flex align-items-center justify-content-center"
              >
                <IconArrowDown
                  color={colors.gray40}
                  className={classNames('collapse-icon', {
                    'rotate-180': isOpen && isActiveMethod,
                  })}
                />
              </div>
            )}
          </div>
        </div>

        <Collapse
          className="bg-black border border-dashed border-top-solid border-gray-0 payment-collapse rounded-bottom pb-2"
          isOpen={isOpen && isActiveMethod}
        >
          {isActiveMethod && (
            isWithdrawal ? (
              <FormProcessWithdrawal
                coreProps={{ withoutFormDataClearOnUnmount: true }}
                activeBonuses={activeBonuses}
                setActiveWithdrawalMethod={setActiveWithdrawalMethod}
              />
            ) : (
              <DepositNew
                handlerStep={null}
                handlerPaymentChangeForDesktop={toggleDepositNew}
                updateModalAlignment={updateModalAlignment}
                className={classNames('flex-grow-1', {
                  'bg-transparent': isModal,
                })}
                handlerPaymentChangeForMobile={toggleDepositNew}
                activeDepositMethod={activePaymentMethod}
                isModal={isModal}
              />
            )
          )}
        </Collapse>
      </div>
    );
  }
}

export const PaymentMethodCard = withModalAlignment(PaymentMethodCardUI);
