import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderPragmatic = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M13.7967 9.15608C11.2453 9.31418 12.3909 12.6271 15.2115 12.4523C17.9706 12.2812 16.515 8.98766 13.7967 9.15608Z"
      fill="#F19021"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8595 5.11461C10.8595 5.11461 10.5204 2.8593 12.4718 1.38868C11.4427 3.52202 12.0109 5.72441 12.1708 6.2471C10.7521 6.29615 9.3706 6.71293 8.1626 7.45639C7.98267 6.89498 7.1963 4.80229 5.16922 3.64076C7.61311 3.75562 8.61047 5.80766 8.61047 5.80766C8.61047 5.80766 7.8254 2.49794 8.04027 0C9.63307 1.9404 10.8595 5.11461 10.8595 5.11461ZM12.7196 7.10986C8.18006 7.10986 4.5 10.779 4.5 15.3051C4.5 19.8312 8.18006 23.5004 12.7196 23.5004C17.2592 23.5004 20.9393 19.8312 20.9393 15.3051C20.9393 10.779 17.2592 7.10986 12.7196 7.10986ZM12.7196 22.6415C9.7442 22.642 7.06144 20.8554 5.92243 18.1147C4.78341 15.3741 5.41246 12.2192 7.51623 10.1213C9.62001 8.02338 12.7842 7.39564 15.5332 8.5308C18.2822 9.66595 20.0746 12.3404 20.0746 15.307C20.0746 19.3572 16.7819 22.6408 12.7196 22.6415Z"
      fill="#F19021"
    />
  </svg>
);

IconProviderPragmatic.propTypes = {
  className: PropTypes.string,
};

IconProviderPragmatic.defaultProps = {
  className: '',
};
