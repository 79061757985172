import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderIGtechBig = ({ className }) => (
  <svg className={className} width="141" height="78" viewBox="0 0 141 78" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.5421 55.3967V52.7103H45.618C46.4518 52.7103 46.4518 52.0619 46.4518 51.136V29.9222C46.4518 27.6067 46.1737 26.9578 43.8582 26.9578H39.3188V24.735L53.7703 23.16V49.0053C53.7703 51.3208 54.1412 52.7109 56.7342 52.7109H60.9955V55.3973L41.5421 55.3967Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.6865 39.5557H90.825V53.1728C83.1351 56.3233 79.0592 56.3233 74.4277 56.3233C67.0165 56.3233 53.3994 53.8218 53.3994 40.8531C53.3994 26.587 66.9238 23.345 73.6865 23.345C80.4493 23.345 88.1381 25.2902 90.825 26.4949V31.9603H89.527C82.0243 27.7917 76.9292 27.7917 73.7787 27.7917C70.6294 27.7917 59.6986 28.7176 59.6986 40.8537C59.6986 50.6731 71.9262 52.3402 74.4277 52.3402C76.9291 52.3402 82.3018 51.9693 84.5252 50.6725V44.0951H73.6865V39.5557Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.639 17.5555C51.639 19.3718 49.7934 20.8436 47.517 20.8436C45.2401 20.8436 43.3945 19.3712 43.3945 17.5555C43.3945 15.7399 45.2401 14.2668 47.517 14.2668C49.7934 14.2668 51.639 15.7393 51.639 17.5555ZM99.5209 51.6873V53.4321H93.8269V55.9561H92.6032V49.1633H93.8269V51.6873H99.5209ZM93.8269 41.9871V46.4183H95.2809V42.3482H96.5057V46.4183H98.2972V41.7896H99.5209V48.1285H92.6032V41.9871H93.8269ZM94.8669 35.0435C94.4528 35.1829 93.6726 35.4506 93.6726 36.8931C93.6726 37.7304 94.135 38.9633 96.0905 38.9633C97.3332 38.9633 98.4515 38.3702 98.4515 36.8931C98.4515 35.9274 97.9983 35.2531 97.1501 35.0435V33.2993C98.6537 33.6482 99.7034 34.8345 99.7034 36.9277C99.7034 39.1493 98.4607 40.7427 96.0715 40.7427C93.6536 40.7427 92.4195 39.056 92.4195 36.9041C92.4195 34.4037 93.8459 33.3914 94.8669 33.2987V35.0435ZM96.4182 30.287H99.5209V32.0318H92.6032V30.287H95.2233V27.0306H92.6032V25.2857H99.5209V27.0306H96.4182V30.287Z"
      fill="#D57542"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 39C32 60.5051 49.4955 78 71 78C92.5051 78 110 60.5051 110 39C110 17.4955 92.5051 0 71 0C49.4955 0.00057585 32 17.496 32 39ZM33.2968 39C33.2968 18.2107 50.2107 1.29681 71 1.29681C91.7899 1.29681 108.703 18.2107 108.703 39C108.703 59.7899 91.7899 76.7032 71 76.7032C50.2107 76.7032 33.2968 59.7899 33.2968 39Z"
      fill="#7387BA"
    />
  </svg>
);

IconProviderIGtechBig.propTypes = {
  className: PropTypes.string,
};

IconProviderIGtechBig.defaultProps = {
  className: '',
};
