import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProvider3oaksGaming = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 22C18.5228 22 23 17.5228 23 12C23 6.47716 18.5228 2 13 2C7.47716 2 3 6.47716 3 12C3 17.5228 7.47716 22 13 22ZM13.9793 15.7166C15.5351 14.8181 16.2473 13.782 16.3364 12.0666C15.7047 14.1646 14.7805 15.1792 13.9793 15.7166ZM11.2354 16.6049C10.0699 15.8598 8.80309 15.05 8.66741 13.1973L8.56987 10.979C10.7616 9.8225 15.2426 9.8225 17.4343 10.979L17.3326 13.1973C17.197 15.0456 15.9325 15.8546 14.7679 16.5997C14.039 17.0661 13.3492 17.5074 13 18.1628C12.6513 17.5101 11.9631 17.0701 11.2354 16.6049ZM9.88409 8.378C10.7277 8.16734 11.5756 8.04697 12.4192 8.00828C12.6346 7.2675 13.2031 6.96175 13.7606 6.66197L13.7673 6.65834C14.1687 6.40731 14.3605 6.24969 14.4882 6.14469C14.7303 5.94578 14.7426 5.93569 15.5139 5.83722C15.4376 6.78731 14.8187 7.11834 14.1828 7.45797C13.8945 7.60844 13.6062 7.76322 13.4197 8.00397C14.3184 8.03406 15.2172 8.16303 16.1159 8.378C17.0613 8.60584 17.8837 9.18622 17.8837 10.1707C17.8837 10.4888 17.7989 10.7898 17.6548 11.052L17.6633 10.8457L17.5361 10.7812C17.4216 10.721 17.2987 10.6651 17.18 10.6178C16.8748 10.4932 16.5568 10.3814 16.2388 10.2911C14.3736 9.758 11.6265 9.758 9.76116 10.2911C9.44319 10.3814 9.12525 10.4888 8.82 10.6178C8.80456 10.6245 8.78906 10.6313 8.7735 10.638C8.66947 10.683 8.56347 10.7288 8.46391 10.7812L8.33672 10.8457L8.34522 11.052C8.20106 10.7898 8.11628 10.4888 8.11628 10.1707C8.11628 9.18622 8.93872 8.61444 9.88409 8.378Z"
      fill="url(#3oaks-gaming-paint-0)"
    />
    <defs>
      <linearGradient
        id="3oaks-gaming-paint-0"
        x1="19.5249"
        y1="2.719"
        x2="6.37453"
        y2="20.9065"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8B900" />
        <stop offset="1" stopColor="#E67508" />
      </linearGradient>
    </defs>
  </svg>
);

IconProvider3oaksGaming.propTypes = {
  className: PropTypes.string,
};

IconProvider3oaksGaming.defaultProps = {
  className: '',
};
