import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

export const IconPaypay = ({ className }) => (

  <svg className={classNames('footer-icon', className)} width="77" height="31" viewBox="0 0 77 31" fill="none" xmlns="http://www.w3.org/2000/svg">

    <g>
      <path d="M30.4897 6.04349L30.6696 5.40712C30.718 5.29648 30.7871 5.19964 30.967 5.24115C31.4788 5.40021 31.9355 5.60772 32.3087 5.8844C32.4888 6.02967 32.6271 6.18181 32.7238 6.34091C32.8484 6.54842 32.9176 6.76976 32.9176 7.01875L32.6754 8.42287L32.4817 9.51571C32.4402 9.82007 32.4333 10.0898 32.5025 10.2973C32.5508 10.5602 32.6615 10.7884 32.8828 10.9613C33.1528 11.1965 33.5608 11.2933 34.1281 11.238C34.3907 11.1965 34.6607 11.0927 34.958 10.7469C35.2348 10.3388 35.449 9.77856 35.5944 9.03846L36.1478 6.67291C36.2445 6.3755 36.3899 6.09883 36.6456 5.87749C36.9085 5.66306 37.199 5.4694 37.5933 5.34488C37.9185 5.24806 38.2434 5.17196 38.5754 5.15122L39.1287 5.12354C39.2673 5.16504 39.3156 5.24115 39.2809 5.34488L38.3956 9.27363L37.6141 12.4554L37.0607 14.648C36.9293 15.063 36.791 15.4365 36.632 15.7824C36.4658 16.1074 36.2928 16.391 36.113 16.6262C35.9056 16.9029 35.6911 17.1104 35.463 17.2556C35.2416 17.4078 35.0135 17.5254 34.7921 17.6084C34.4186 17.7536 34.045 17.8435 33.6715 17.8851C33.4154 17.9266 33.1528 17.9404 32.89 17.9266L30.4344 17.9335H28.0688C28.1795 17.311 28.3317 16.7438 28.5945 16.3011C28.8435 15.9138 29.1963 15.561 29.881 15.3397C30.2753 15.229 30.7042 15.1668 31.1676 15.1668H32.6823C33.0905 15.1875 33.4985 15.1668 33.8789 15.0146C34.2456 14.8555 34.55 14.6065 34.7298 14.1569C34.8058 13.9909 34.8541 13.7765 34.8541 13.4998L33.6647 13.5482C33.0766 13.569 32.544 13.5344 32.0669 13.4445C31.5826 13.3476 31.1399 13.1401 30.7388 12.8358C30.3652 12.5384 30.0886 12.1718 29.8949 11.7291C29.6113 11.0858 29.556 10.3803 29.6597 9.62638C29.7289 9.17679 29.8257 8.6788 29.9433 8.1531L30.4897 6.04349Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M30.1512 5.42105L28.09 13.6175L23.8085 13.6313C23.352 13.6244 22.854 13.5967 22.3352 13.5483C21.9064 13.4999 21.5121 13.327 21.1663 13.0434C20.938 12.8497 20.7582 12.5799 20.6129 12.2618C20.4884 12.0059 20.3916 11.6323 20.3017 11.2312C20.1633 10.6709 20.1702 10.083 20.2602 9.4743C20.3362 9.0178 20.4677 8.58203 20.6544 8.167C20.855 7.72435 21.0971 7.30932 21.3945 6.93584C21.7127 6.56232 22.0585 6.23722 22.4389 6.00897C22.764 5.8084 23.1168 5.63545 23.5318 5.49713C23.9814 5.3173 24.4794 5.18585 25.1019 5.17203L29.9437 5.1582C30.082 5.1582 30.1581 5.24813 30.1512 5.42105ZM26.4922 9.23219L26.7689 7.97334C26.7896 7.85576 26.7274 7.79351 26.6167 7.7866H26.2294H25.095C24.8045 7.75892 24.5417 7.82116 24.2857 7.91801C23.8361 8.08401 23.5664 8.33995 23.3589 8.62354C23.0476 9.11461 22.9854 9.50887 23.013 9.87547C23.0615 10.2559 23.1929 10.581 23.5041 10.7816C23.6632 10.913 23.9468 10.9891 24.3895 11.0029L24.8322 11.0098C25.0258 10.996 25.1988 11.0444 25.3232 11.1689C25.4962 11.328 25.6207 11.577 25.7314 11.8744C25.8005 11.9367 25.8559 11.9436 25.8905 11.8537L26.0703 11.0513L26.4922 9.23219Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.6732 11.7014L10.189 13.6242L10.7976 13.6173C11.0674 13.6311 11.3371 13.5966 11.6138 13.5136C11.9942 13.4029 12.3263 13.2092 12.6237 12.9533C12.8934 12.7181 13.1009 12.4276 13.267 12.0887C13.3499 11.9227 13.4399 11.6322 13.5367 11.2518L13.7442 10.3457C14.4082 10.3872 15.0861 10.3872 15.7777 10.3388C16.4833 10.3111 17.1542 10.1935 17.7975 9.97909C18.0534 9.90299 18.3301 9.77849 18.6067 9.64017C19.1116 9.37732 19.5543 9.04531 19.914 8.63031C20.2045 8.2983 20.4397 7.93861 20.6126 7.53744C20.7509 7.25385 20.8616 6.86651 20.9584 6.40309C21.0207 6.05725 21.0484 5.71833 21.0484 5.38632C21.0484 5.06122 21.0068 4.74307 20.9239 4.43871C20.8132 4.02371 20.6541 3.64328 20.412 3.32513C20.2598 3.13144 20.0938 2.97236 19.9278 2.84094C19.7134 2.68877 19.4921 2.58502 19.2638 2.4951C18.9318 2.3706 18.5168 2.30834 18.0741 2.27376L15.9299 2.24609L13.2531 2.27376C13.1217 2.31526 13.0525 2.40518 13.0179 2.52277L12.6168 4.07905L11.8974 6.90802L11.1435 9.84765L10.6732 11.7014ZM16.7392 7.78646L14.3736 7.76569L15.0307 4.98515C15.0376 4.9229 15.0722 4.87448 15.1829 4.87448L17.2164 4.89522C17.5623 4.89522 17.832 5.06122 18.0395 5.35867C18.1848 5.71833 18.2055 6.09876 18.0949 6.49993C17.9911 6.95644 17.7352 7.24694 17.4447 7.50979C17.2234 7.68962 16.9882 7.75878 16.7392 7.78646Z" fill="black" />
      <path d="M58.5028 6.1333L58.6826 5.49697C58.731 5.3863 58.8001 5.28945 58.9802 5.33096C59.492 5.49005 59.9484 5.69757 60.3219 5.97424C60.5017 6.11948 60.6403 6.27166 60.737 6.43075C60.8616 6.63823 60.9307 6.85957 60.9307 7.10859L60.6886 8.51271L60.4949 9.60555C60.4533 9.90991 60.4465 10.1796 60.5157 10.3872C60.564 10.65 60.6747 10.8783 60.896 11.0512C61.1657 11.2863 61.574 11.3832 62.141 11.3278C62.4039 11.2863 62.6736 11.1826 62.9712 10.8367C63.2477 10.4287 63.4622 9.8684 63.6076 9.12831L64.1606 6.76276C64.2577 6.46531 64.4027 6.18864 64.6588 5.96733C64.9217 5.7529 65.2122 5.55922 65.6065 5.43472C65.9314 5.33788 66.2565 5.2618 66.5886 5.24106L67.1419 5.21338C67.2802 5.25489 67.3288 5.33096 67.2941 5.43472L66.4088 9.36347L65.6272 12.5452L65.0739 14.7378C64.9425 15.1528 64.8042 15.5263 64.6448 15.8722C64.479 16.1973 64.306 16.4809 64.1262 16.716C63.9188 16.9927 63.7043 17.2002 63.4761 17.3455C63.2548 17.4976 63.0263 17.6152 62.805 17.6982C62.4315 17.8435 62.0582 17.9334 61.6847 17.9749C61.4286 18.0164 61.1657 18.0302 60.9028 18.0164L58.4476 18.0233H56.082C56.1927 17.4008 56.3449 16.8336 56.6075 16.391C56.8567 16.0036 57.2095 15.6508 57.8943 15.4295C58.2883 15.3189 58.7173 15.2566 59.1808 15.2566H60.6954C61.1034 15.2773 61.5117 15.2566 61.892 15.1044C62.2588 14.9453 62.5629 14.6963 62.7427 14.2467C62.8189 14.0807 62.8673 13.8663 62.8673 13.5896L61.6775 13.6381C61.0898 13.6588 60.5572 13.6242 60.0798 13.5343C59.5956 13.4375 59.1529 13.2299 58.7518 12.9256C58.3782 12.6282 58.1017 12.2616 57.9079 11.8189C57.6243 11.1757 57.5691 10.4701 57.6729 9.71622C57.7421 9.26663 57.8388 8.76861 57.9563 8.24295L58.5028 6.1333Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M58.1779 5.50406L56.1099 13.7074L51.8353 13.7143C51.3787 13.7074 50.8808 13.6797 50.3622 13.6313C49.9332 13.5829 49.5389 13.41 49.1932 13.1264C48.9647 12.9327 48.7849 12.663 48.6399 12.3448C48.5152 12.0889 48.4185 11.7153 48.3286 11.3142C48.19 10.7539 48.1972 10.166 48.2871 9.55731C48.363 9.1008 48.4945 8.66504 48.6814 8.25004C48.882 7.80736 49.1241 7.39236 49.4214 7.01884C49.7394 6.64532 50.0854 6.32026 50.4658 6.09201C50.7909 5.8914 51.1437 5.71849 51.5588 5.58013C52.0083 5.4003 52.5062 5.26889 53.1287 5.25507L57.9705 5.24121C58.1088 5.24121 58.1851 5.33114 58.1779 5.50406ZM54.5122 9.32214L54.7887 8.0633C54.8095 7.93877 54.7472 7.88343 54.6365 7.86961H54.2493H53.115C52.8245 7.84196 52.5617 7.9042 52.3056 8.00102C51.8561 8.16702 51.5864 8.42296 51.3787 8.70655C51.0675 9.19765 51.0055 9.59191 51.0331 9.95848C51.0814 10.3389 51.2129 10.664 51.5241 10.8646C51.6831 10.996 51.9668 11.0721 52.4095 11.0859L52.8521 11.0928C53.0459 11.079 53.2186 11.1274 53.3432 11.2519C53.5162 11.411 53.6408 11.66 53.7514 11.9575C53.8206 12.0197 53.8758 12.0266 53.9105 11.9367L54.0903 11.1413L54.5122 9.32214Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M38.6932 11.7844L38.209 13.7073L38.8175 13.7003C39.0872 13.7142 39.3572 13.6796 39.6337 13.5966C40.0141 13.4859 40.3461 13.2922 40.6437 13.0363C40.9134 12.8012 41.1208 12.5107 41.287 12.1717C41.3701 12.0057 41.46 11.7152 41.5567 11.3348L41.7641 10.4287C42.4281 10.4702 43.1061 10.4702 43.7977 10.4218C44.5032 10.3941 45.1741 10.2765 45.8173 10.0621C46.0734 9.98602 46.3499 9.86152 46.6267 9.72317C47.1317 9.46032 47.5744 9.12834 47.934 8.71331C48.2245 8.3813 48.4598 8.02164 48.6324 7.62047C48.771 7.33688 48.8817 6.94953 48.9784 6.48611C49.0407 6.14025 49.0683 5.80132 49.0683 5.46935C49.0683 5.14425 49.0267 4.82606 48.9437 4.52174C48.8333 4.10671 48.674 3.72631 48.4319 3.40812C48.28 3.21447 48.1138 3.05537 47.9479 2.92395C47.7334 2.77178 47.5121 2.66803 47.2839 2.57811C46.9519 2.4536 46.5368 2.39135 46.0941 2.35677L43.9499 2.3291L41.273 2.35677C41.1416 2.39827 41.0725 2.48819 41.038 2.60577L40.6366 4.16205L39.9174 6.99104L39.1635 9.93068L38.6932 11.7844ZM44.7593 7.87637L42.3937 7.85563L43.0369 5.082C43.0437 5.01975 43.0785 4.97133 43.1891 4.97133L45.2364 4.98516C45.5823 4.98516 45.852 5.15116 46.0594 5.44858C46.2048 5.80827 46.2256 6.18867 46.1149 6.58984C46.0111 7.04638 45.7553 7.33688 45.4645 7.5997C45.2432 7.77956 45.0082 7.84872 44.7593 7.87637Z" fill="black" />
      <path d="M39.938 14.8418H55.0443V15.326L39.938 15.3329V14.8418Z" fill="black" />
      <path d="M39.9175 16.2041H55.0306V16.6814L39.9175 16.6745V16.2041Z" fill="black" />
      <path d="M39.9175 17.373H55.0306V17.8572H39.9243L39.9175 17.373Z" fill="black" />
    </g>

    <g>
      <path d="M29.9239 21.3613H24.4476C24.222 21.3613 24.0391 21.5443 24.0391 21.77V21.7705C24.0391 21.9962 24.222 22.1792 24.4476 22.1792H29.9239C30.1495 22.1792 30.3324 21.9962 30.3324 21.7705V21.77C30.3324 21.5443 30.1495 21.3613 29.9239 21.3613Z" fill="#F9C283" />
      <path d="M29.9239 24.1299H24.4476C24.222 24.1299 24.0391 24.3128 24.0391 24.5385V24.5391C24.0391 24.7648 24.222 24.9477 24.4476 24.9477H29.9239C30.1495 24.9477 30.3324 24.7648 30.3324 24.5391V24.5385C30.3324 24.3128 30.1495 24.1299 29.9239 24.1299Z" fill="#EBACCD" />
      <path d="M29.9239 26.8369H24.4476C24.222 26.8369 24.0391 27.0199 24.0391 27.2456V27.2461C24.0391 27.4718 24.222 27.6548 24.4476 27.6548H29.9239C30.1495 27.6548 30.3324 27.4718 30.3324 27.2461V27.2456C30.3324 27.0199 30.1495 26.8369 29.9239 26.8369Z" fill="#B3BBE0" />
      <path d="M22.0732 21.33C21.9979 21.3299 21.9241 21.3507 21.86 21.39C21.7958 21.4293 21.7437 21.4856 21.7095 21.5526C21.6965 21.5696 21.6848 21.5875 21.6746 21.6063L19.3835 25.9203L17.1209 21.6529C17.1015 21.5527 17.0451 21.4633 16.963 21.4027C16.8809 21.342 16.779 21.3143 16.6775 21.325C16.576 21.3358 16.4821 21.3842 16.4146 21.4608C16.347 21.5373 16.3106 21.6365 16.3126 21.7386V27.2715C16.3126 27.3798 16.3555 27.4836 16.432 27.5601C16.5086 27.6366 16.6123 27.6796 16.7205 27.6796C16.8288 27.6796 16.9325 27.6366 17.009 27.5601C17.0855 27.4836 17.1285 27.3798 17.1285 27.2715V23.4179L18.9662 26.8804C19.0059 26.9539 19.0672 27.0134 19.1418 27.051C19.2164 27.0885 19.3008 27.1022 19.3835 27.0903C19.4665 27.1021 19.5512 27.088 19.626 27.0499C19.7008 27.0118 19.762 26.9516 19.8013 26.8775L21.6647 23.3713V27.2715C21.6647 27.3798 21.7076 27.4836 21.7841 27.5601C21.8606 27.6366 21.9644 27.6796 22.0726 27.6796C22.1808 27.6796 22.2846 27.6366 22.3611 27.5601C22.4376 27.4836 22.4806 27.3798 22.4806 27.2715V21.7386C22.4807 21.685 22.4702 21.6319 22.4498 21.5824C22.4293 21.5329 22.3993 21.4878 22.3615 21.4499C22.3237 21.412 22.2787 21.3818 22.2293 21.3612C22.1798 21.3407 22.1268 21.33 22.0732 21.33Z" fill="black" />
      <path d="M37.0686 24.13H32.6322V22.1178H37.0686C37.1768 22.1178 37.2804 22.0748 37.3573 21.9983C37.4335 21.9218 37.4766 21.818 37.4766 21.7098C37.4766 21.6016 37.4335 21.4978 37.3573 21.4213C37.2804 21.3448 37.1768 21.3018 37.0686 21.3018H32.2602C32.1582 21.3021 32.0597 21.3408 31.9846 21.4102C31.9323 21.4478 31.8895 21.4975 31.8597 21.5549C31.8303 21.6124 31.8148 21.6761 31.8145 21.7407V27.2736C31.8145 27.3818 31.8575 27.4856 31.9341 27.5621C32.0104 27.6387 32.1142 27.6817 32.2224 27.6817C32.3306 27.6817 32.4345 27.6387 32.511 27.5621C32.5876 27.4856 32.6304 27.3818 32.6304 27.2736V24.9478H37.0671C37.175 24.9478 37.2788 24.9049 37.3554 24.8283C37.432 24.7518 37.4747 24.648 37.4747 24.5398C37.4751 24.4862 37.4648 24.4331 37.4444 24.3835C37.4242 24.3339 37.3941 24.2888 37.3566 24.2508C37.3188 24.2127 37.2739 24.1825 37.2246 24.1617C37.1753 24.141 37.1223 24.1302 37.0686 24.13Z" fill="black" />
      <path d="M44.8163 21.2998H39.3715C39.1458 21.2998 38.9629 21.4828 38.9629 21.7084V21.709C38.9629 21.9347 39.1458 22.1176 39.3715 22.1176H44.8163C45.0417 22.1176 45.2249 21.9347 45.2249 21.709V21.7084C45.2249 21.4828 45.0417 21.2998 44.8163 21.2998Z" fill="black" />
      <path d="M44.8163 24.1299H39.3715C39.1458 24.1299 38.9629 24.3128 38.9629 24.5385V24.5391C38.9629 24.7648 39.1458 24.9477 39.3715 24.9477H44.8163C45.0417 24.9477 45.2249 24.7648 45.2249 24.5391V24.5385C45.2249 24.3128 45.0417 24.1299 44.8163 24.1299Z" fill="black" />
      <path d="M44.8163 26.8369H39.3715C39.1458 26.8369 38.9629 27.0199 38.9629 27.2456V27.2461C38.9629 27.4718 39.1458 27.6548 39.3715 27.6548H44.8163C45.0417 27.6548 45.2249 27.4718 45.2249 27.2461V27.2456C45.2249 27.0199 45.0417 26.8369 44.8163 26.8369Z" fill="black" />
      <path d="M60.3183 21.2998H54.8734C54.6481 21.2998 54.4648 21.4828 54.4648 21.7084V21.709C54.4648 21.9347 54.6481 22.1176 54.8734 22.1176H60.3183C60.5439 22.1176 60.7268 21.9347 60.7268 21.709V21.7084C60.7268 21.4828 60.5439 21.2998 60.3183 21.2998Z" fill="black" />
      <path d="M60.3183 24.1299H54.8734C54.6481 24.1299 54.4648 24.3128 54.4648 24.5385V24.5391C54.4648 24.7648 54.6481 24.9477 54.8734 24.9477H60.3183C60.5439 24.9477 60.7268 24.7648 60.7268 24.5391V24.5385C60.7268 24.3128 60.5439 24.1299 60.3183 24.1299Z" fill="black" />
      <path d="M60.3183 26.8369H54.8734C54.6481 26.8369 54.4648 27.0199 54.4648 27.2456V27.2461C54.4648 27.4718 54.6481 27.6548 54.8734 27.6548H60.3183C60.5439 27.6548 60.7268 27.4718 60.7268 27.2461V27.2456C60.7268 27.0199 60.5439 26.8369 60.3183 26.8369Z" fill="black" />
      <path d="M52.5665 21.2998H47.1223C47.0144 21.2998 46.9103 21.3428 46.834 21.4193C46.7574 21.4958 46.7144 21.5996 46.7144 21.7079C46.7144 21.816 46.7574 21.9198 46.834 21.9964C46.9103 22.0729 47.0144 22.1159 47.1223 22.1159H49.4355V27.2594C49.4355 27.3677 49.4786 27.4714 49.5552 27.548C49.6315 27.6245 49.7356 27.6675 49.8435 27.6675C49.9517 27.6675 50.0555 27.6245 50.1321 27.548C50.2087 27.4714 50.2518 27.3677 50.2518 27.2594V22.1176H52.5647C52.6729 22.1176 52.7767 22.0746 52.8533 21.9981C52.9299 21.9216 52.9729 21.8178 52.9729 21.7096C52.9729 21.6014 52.9299 21.4976 52.8533 21.4211C52.7767 21.3445 52.6729 21.3016 52.5647 21.3016L52.5665 21.2998Z" fill="black" />
    </g>
  </svg>

);

IconPaypay.propTypes = {
  className: PropTypes.string,
};

IconPaypay.defaultProps = {
  className: '',
};

