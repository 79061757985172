import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderPlatipus = ({ className }) => (
  <svg className={className} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="platiplus-rule-1"
      style={{ 'mask-type': 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="10"
      width="28"
      height="9"
    >
      <path d="M28 10H0V19H28V10Z" fill="white" />
    </mask>
    <g mask="url(#platiplus-rule-1)">
      <path
        d="M28 10V18.2286C28 18.5857 27.6854 18.8857 27.2764 18.8857H0.723596C0.314607 18.8857 0 18.5857 0 18.2286V12.1143C0 11.7571 0.314607 11.4714 0.723596 11.4714H26.364L27.8584 10H28Z"
        fill="#EB5149"
      />
      <path
        d="M5.39576 14.343C5.39576 15.1001 4.76655 15.6573 3.91711 15.6573H3.31935V16.6573H2.21823V13.043H3.91711C4.76655 13.043 5.39576 13.5858 5.39576 14.343ZM4.26318 14.343C4.26318 14.0858 4.07441 13.9144 3.79126 13.9144H3.31935V14.7858H3.79126C4.07441 14.7858 4.26318 14.6001 4.26318 14.343Z"
        fill="white"
      />
      <path d="M5.8362 12.8572H6.90587V16.6572H5.8362V12.8572Z" fill="white" />
      <path
        d="M10.8382 13.8572V16.6572H10.0202L9.92584 16.4572C9.67416 16.6429 9.35955 16.7429 8.99775 16.7429C8.06966 16.7429 7.39326 16.1286 7.39326 15.2572C7.39326 14.3858 8.06966 13.7715 8.99775 13.7715C9.35955 13.7715 9.68989 13.8858 9.94157 14.0715L10.0517 13.8572H10.8382ZM9.83146 15.2572C9.83146 14.9001 9.54831 14.6286 9.15506 14.6286C8.74607 14.6286 8.46292 14.9001 8.46292 15.2572C8.46292 15.6286 8.7618 15.9001 9.15506 15.9001C9.54831 15.9001 9.83146 15.6286 9.83146 15.2572Z"
        fill="white"
      />
      <path
        d="M13.5758 15.8144V16.6573H12.8522C12.1758 16.6573 11.7668 16.2858 11.7668 15.6573V14.6001H11.2005V14.3858L12.5848 13.043H12.7892V13.8715H13.56V14.6001H12.8207V15.5001C12.8207 15.7001 12.9466 15.8144 13.1825 15.8144H13.5758Z"
        fill="white"
      />
      <path
        d="M14.0787 13.8714H15.1326V16.6571H14.0787V13.8714ZM14.0787 12.7H15.1326V13.5142H14.0787V12.7Z"
        fill="white"
      />
      <path
        d="M19.2064 15.2572C19.2064 16.1286 18.5458 16.7572 17.6019 16.7572C17.3188 16.7572 17.0671 16.6858 16.8469 16.5715V17.6715H15.7929V13.8715H16.5165L16.6581 14.0858C16.9098 13.8858 17.2244 13.7715 17.6019 13.7715C18.5458 13.7715 19.2064 14.3858 19.2064 15.2572ZM18.1525 15.2572C18.1525 14.9001 17.8536 14.6286 17.4604 14.6286C17.0671 14.6286 16.7682 14.9001 16.7682 15.2572C16.7682 15.6286 17.0671 15.9001 17.4604 15.9001C17.8536 15.9001 18.1525 15.6286 18.1525 15.2572Z"
        fill="white"
      />
      <path
        d="M19.6629 15.4571V13.8713H20.7169V15.4142C20.7169 15.7142 20.8899 15.8856 21.173 15.8856C21.4562 15.8856 21.6292 15.7142 21.6292 15.4142V13.8713H22.6989V15.4571C22.6989 16.2428 22.0854 16.7571 21.173 16.7571C20.2607 16.7428 19.6629 16.2428 19.6629 15.4571Z"
        fill="white"
      />
      <path
        d="M24.5082 16.7429C23.6587 16.7429 23.1554 16.3429 23.1396 15.7429H24.1621C24.1621 15.9429 24.3194 16.0429 24.5239 16.0429C24.6812 16.0429 24.8228 15.9715 24.8228 15.8286C24.8228 15.6572 24.5868 15.6143 24.288 15.5715C23.816 15.5001 23.1868 15.3715 23.1868 14.7001C23.1868 14.1286 23.7059 13.7715 24.5082 13.7715C25.279 13.7715 25.7981 14.1572 25.7981 14.7143H24.8071C24.8071 14.5429 24.6812 14.4572 24.4767 14.4572C24.3194 14.4572 24.1936 14.5286 24.1936 14.6572C24.1936 14.8143 24.4295 14.8572 24.7284 14.9001C25.216 14.9858 25.861 15.0715 25.861 15.7858C25.861 16.3715 25.3104 16.7429 24.5082 16.7429Z"
        fill="white"
      />
    </g>
  </svg>
);

IconProviderPlatipus.propTypes = {
  className: PropTypes.string,
};

IconProviderPlatipus.defaultProps = {
  className: '',
};
