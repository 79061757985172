import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderNetent = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <circle cx="12.5" cy="12" r="10" fill="#61C100" />
    <path
      d="M14.4109 13.7455L12.6502 10.7484L10.3932 7.03727L10.3708 7H8.5V18H10.576V11.2388L12.1242 13.873L14.6142 18H16.5V7H14.4109V13.7455Z"
      fill="white"
    />
  </svg>
);

IconProviderNetent.propTypes = {
  className: PropTypes.string,
};

IconProviderNetent.defaultProps = {
  className: '',
};
