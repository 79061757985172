import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { RouteLink, FormattedTag } from 'core/components';
import { SkipServerRender } from 'components/skip-server-render/skip-server-render';
import { ProfileModal } from 'components/modals/profile-modal/profile-modal';
import { GameSearch } from 'components/game-search/game-search';

import { CasinoHeader } from './casino-header/casino-header';
import { CasinoHeaderMobile } from './casino-header/casino-header-mobile';
import { StickyHeader } from './sticky-header/sticky-header';
import { menuItems } from './menu-items';
import { PAGE_NAMES } from '../../constants';

import './header.scss';

export const Header = ({
  pageName, mobileBackRoute, params, className
}) => (
  <>
    <CasinoHeader className={classNames('casino-header pl-md-0_5', className)} isSticky={false}>
      {menuItems.map(({
        id, to, pages, Icon, classNameHeader
      }) => {
        const isActive = params ? pages.includes(`${pageName}/${params}`) : pages.includes(pageName);

        return (
          <RouteLink
            key={id}
            to={to}
            className={classNames('header-item mr-md-1_25 mr-xl-3_75 d-flex align-items-center px-0_25', classNameHeader)}
          >
            <Icon
              className={classNames('header-icon', { active: isActive })}
            />
            <FormattedTag
              id={id}
              className={classNames('header-item-title h4 ml-md-0_75 ml-lg-1 letter-spacing-default text-nowrap', { active: isActive })}
            />
          </RouteLink>
        );
      })}
      <GameSearch />
    </CasinoHeader>

    <div className="casino-header-mobile d-flex d-md-none align-items-center justify-content-between">
      <CasinoHeaderMobile pageName={pageName} mobileBackRoute={mobileBackRoute} />
    </div>

    {pageName !== PAGE_NAMES.GAMES ? (
      <StickyHeader>
        <CasinoHeader className="casino-sticky-header h-100" isSticky>
          {menuItems.map(({
            id, to, pages, Icon, classNameHeader
          }) => {
            const isActive = params ? pages.includes(`${pageName}/${params}`) : pages.includes(pageName);

            return (
              <RouteLink
                key={id}
                to={to}
                className={classNames('header-item mr-md-1_25 mr-xl-3_75 d-flex align-items-center px-0_25', classNameHeader)}
              >
                <Icon
                  className={classNames('header-icon', { active: isActive })}
                />
                <FormattedTag
                  id={id}
                  className={classNames('header-item-title h4 ml-md-0_75 ml-lg-1 letter-spacing-default', { active: isActive })}
                />
              </RouteLink>
            );
          })}
          <GameSearch />
        </CasinoHeader>

        <div className="casino-header-mobile casino-sticky-header-mobile d-flex d-md-none align-items-center justify-content-between">
          <CasinoHeaderMobile pageName={pageName} mobileBackRoute={mobileBackRoute} />
        </div>
      </StickyHeader>
    ) : (
      <StickyHeader className="d-md-none">
        <div className="casino-header-mobile casino-sticky-header-mobile d-flex d-md-none align-items-center justify-content-between">
          <CasinoHeaderMobile pageName={pageName} mobileBackRoute={mobileBackRoute} />
        </div>
      </StickyHeader>
    )}

    <SkipServerRender skip>
      <ProfileModal />
    </SkipServerRender>
  </>
);

Header.propTypes = {
  pageName: PropTypes.string,
  mobileBackRoute: PropTypes.string,
  params: PropTypes.string,
  className: PropTypes.string,
};

Header.defaultProps = {
  pageName: null,
  mobileBackRoute: null,
  params: null,
  className: null,
};
