import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconVisa = ({ className }) => (
  <svg className={classNames('footer-icon', className)} width="68" height="22" viewBox="0 0 153 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M111.315 1.9164C107.767 0.623519 104.017 -0.0251278 100.241 0.000720235C88.0303 0.000720235 79.4064 6.15192 79.3452 14.982C79.2689 21.469 85.4736 25.1246 90.1673 27.2921C94.9829 29.5207 96.5933 30.925 96.5779 32.9167C96.5474 35.9467 92.7315 37.3205 89.1828 37.3205C84.306 37.3205 81.6425 36.641 77.5442 34.9392L76.0255 34.2295L74.293 44.3952C77.2618 45.6314 82.6116 46.6922 88.1371 46.7839C101.119 46.7839 109.605 40.6706 109.705 31.2761C109.812 26.094 106.453 22.1789 99.3864 18.943C95.0897 16.8442 92.4262 15.4399 92.4262 13.3106C92.4262 11.4257 94.7081 9.41088 99.4781 9.41088C102.653 9.33408 105.807 9.92952 108.735 11.1586L109.88 11.6698L111.613 1.84776L111.315 1.9164ZM143.002 0.82488H133.463C130.494 0.82488 128.258 1.6416 126.96 4.58736L108.613 46.1503H121.595L124.19 39.3353L140.034 39.3504C140.407 40.9454 141.552 46.1503 141.552 46.1503H153L143.002 0.82488ZM61.7844 0.443279H74.1557L66.4169 45.7918H54.061L61.7844 0.428159V0.443279ZM30.3795 25.4376L31.6615 31.734L43.7503 0.82488H56.8543L37.3855 46.0894H24.3123L13.6277 7.76232C13.4547 7.1304 13.0481 6.58752 12.4906 6.2436C8.63954 4.25424 4.55906 2.74464 0.340576 1.7484L0.508575 0.7944H20.4199C23.1216 0.9012 25.2967 1.7484 26.0218 4.6332L30.3718 25.4604V25.4376H30.3795ZM127.739 30.0624L132.669 17.3784C132.608 17.5157 133.684 14.7607 134.31 13.0589L135.157 16.974L138.019 30.055H127.731V30.0624H127.739Z" fill="#5C6374" />
  </svg>

);

IconVisa.propTypes = {
  className: PropTypes.string,
};

IconVisa.defaultProps = {
  className: '',
};
