import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withLocale
} from 'core/hocs';
import { GamesList } from './games-list';
import { GameCategories } from './game-categories';

// eslint-disable-next-line react/prefer-stateless-function
class GameDropdownUI extends Component {
  static propTypes = {
    games: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    setCategory: PropTypes.func.isRequired,
    filterCategory: PropTypes.string.isRequired,
  };

  render() {
    const {
      filterCategory,
      setCategory,
      games,
    } = this.props;

    return (
      <div>
        <GameCategories setCategory={setCategory} filterCategory={filterCategory} />
        <GamesList games={games} />
      </div>
    );
  }
}

export const GameDropdown = withLocale(
  GameDropdownUI,
);
