import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconCloseWOCircle = ({ className, color, size }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.5723 3.42834L3.42944 8.5712" stroke={color} strokeWidth="2.28571" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.42944 3.42834L8.5723 8.5712" stroke={color} strokeWidth="2.28571" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconCloseWOCircle.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};

IconCloseWOCircle.defaultProps = {
  className: '',
  color: colors.gray30,
  size: 12,
};
