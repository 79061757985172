import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderEvolution = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.579 21.526C17.579 20.7727 18.1897 20.162 18.943 20.162C19.6963 20.162 20.307 20.7727 20.307 21.526C20.307 22.2793 19.6963 22.89 18.943 22.89C18.1897 22.89 17.579 22.2793 17.579 21.526ZM18.943 22.6095C18.3355 22.6095 17.843 22.117 17.843 21.5095C17.843 20.902 18.3355 20.4095 18.943 20.4095C19.5505 20.4095 20.043 20.902 20.043 21.5095C20.043 22.117 19.5505 22.6095 18.943 22.6095ZM19.3225 20.8715C19.4276 20.9552 19.4868 21.0837 19.482 21.218L19.493 21.2455C19.4996 21.4232 19.3837 21.5823 19.2125 21.6305L19.526 22.1805H19.2235L18.943 21.658H18.7065V22.1805H18.437V20.7725H18.888C19.0401 20.7501 19.1951 20.7854 19.3225 20.8715ZM18.7065 21.438H18.9925L18.9705 21.4325C19.0334 21.4404 19.0967 21.4208 19.1442 21.3786C19.1916 21.3365 19.2185 21.2759 19.218 21.2125C19.2218 21.1338 19.1786 21.0604 19.108 21.0255C19.0176 20.9961 18.9219 20.9867 18.8275 20.998H18.7065V21.438Z"
      fill="#A3A3A3"
    />
    <path
      d="M4.522 2.30898C11.0615 -1.18352 19.504 2.50148 20.142 11.3895C8.0145 12.341 6.5515 6.87948 4.522 2.30898Z"
      fill="url(#evolution-rule-0)"
    />
    <path
      d="M4.522 21.3665C11.0615 24.859 19.504 21.174 20.142 12.286C8.0145 11.307 6.5515 16.807 4.522 21.3665Z"
      fill="url(#evolution-rule-1)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 2.33099C9.02256 -0.191979 14.7108 0.977951 17.8705 5.08099V5.09749C18.1736 7.30869 17.7468 9.5584 16.655 11.505C8.34732 11.2452 6.5636 7.10786 4.89932 3.24756C4.76654 2.93957 4.63451 2.63334 4.5 2.33099ZM7.09189 16.8756C6.96264 16.866 6.83407 16.8565 6.7055 16.8565C8.2895 14.4365 10.825 12.4785 16.2095 12.22C14.2708 15.1437 10.9945 16.8996 7.4865 16.895C7.35381 16.895 7.2225 16.8853 7.09189 16.8756Z"
      fill="url(#evolution-rule-2)"
    />
    <defs>
      <linearGradient
        id="evolution-rule-0"
        x1="3.12411"
        y1="7.36377"
        x2="2.5316"
        y2="19.6304"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DADCDF" />
        <stop offset="1" stopColor="#A8A8A8" />
      </linearGradient>
      <linearGradient
        id="evolution-rule-1"
        x1="4.19021"
        y1="7.47907"
        x2="3.5971"
        y2="19.7457"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DADCDF" />
        <stop offset="1" stopColor="#A8A8A8" />
      </linearGradient>
      <radialGradient
        id="evolution-rule-2"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.5645 9.31049) scale(12.4905 14.7383)"
      >
        <stop stopColor="#C2C2C2" />
        <stop offset="0.22" stopColor="#BDBDBD" />
        <stop offset="0.46" stopColor="#B0B0B0" />
        <stop offset="0.72" stopColor="#999999" />
        <stop offset="0.99" stopColor="#7A7A7A" />
        <stop offset="1" stopColor="#787878" />
      </radialGradient>
    </defs>
  </svg>
);

IconProviderEvolution.propTypes = {
  className: PropTypes.string,
};

IconProviderEvolution.defaultProps = {
  className: '',
};
