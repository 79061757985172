import React from 'react';
import PropTypes from 'prop-types';

export const IconRocket = ({ className }) => (
  <svg className={className} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="icon-stroked-path" d="M12.0717 8.80323L11.6253 15.6125M12.0717 8.80323C12.0717 4.97304 10.7324 2.84516 8.50019 1.14285C6.26814 2.84516 4.92885 4.97304 4.92885 8.80323M12.0717 8.80323L13.8574 10.08C14.9815 10.8837 15.6431 12.145 15.6431 13.4846V16.005C15.6431 16.621 14.978 17.033 14.3877 16.7828L11.6253 15.6125M11.6253 15.6125H5.37528M4.92885 8.80323L5.37528 15.6125M4.92885 8.80323L3.14314 10.08C2.019 10.8837 1.35742 12.145 1.35742 13.4846V16.005C1.35742 16.621 2.02247 17.033 2.6129 16.7828L5.37528 15.6125M8.50028 7.10092V8.80323" stroke="#B2B2B2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconRocket.propTypes = {
  className: PropTypes.string,
};

IconRocket.defaultProps = {
  className: null,
};
