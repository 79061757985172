import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderTvbet = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#tvbet-rule-3)">
      <path
        d="M15.771 23.4581H8.66775C8.55162 23.4581 8.41613 23.3613 8.35807 23.2645L0.519361 7.00645C0.461297 6.89032 0.519361 6.8129 0.63549 6.8129H7.73872C7.85484 6.8129 7.99033 6.90967 8.04839 7.00645L15.8871 23.2645C15.9452 23.3613 15.8871 23.4581 15.771 23.4581Z"
        fill="url(#tvbet-rule-1)"
      />
      <path
        d="M19.0225 9.58064L16.9709 14.2645C16.8741 14.4581 16.6419 14.5548 16.4483 14.4774L15.9644 14.2645C15.7709 14.1677 15.6741 13.9355 15.7515 13.7419L18.9064 6.6387C19.1386 6.15483 17.7257 5.69031 17.5709 6.09676L14.9773 11.8839C14.8419 12.1935 14.4741 12.329 14.1644 12.1935L14.0677 12.1548C13.4677 11.9613 13.3709 12.1935 13.3709 12.1935L8.47412 23.1677C8.3967 23.3226 8.43541 23.4581 8.55154 23.4581H15.6548C15.7709 23.4581 15.9257 23.3226 15.9838 23.1677L20.6096 12.6968C20.6096 12.6968 20.6096 12.4064 19.9709 12.1742C19.6419 12.0193 19.6419 11.5935 19.7967 11.2645L20.3193 10.0645C20.3773 9.73547 19.2161 9.19354 19.0225 9.58064Z"
        fill="url(#tvbet-rule-2)"
      />
      <path
        d="M17.1839 5.4L17.0678 5.36129C16.7581 5.22581 16.6226 4.85806 16.7581 4.54839L18.442 0.754838C18.5775 0.445161 18.9452 0.309677 19.2549 0.445161L19.371 0.48387C19.6613 0.638709 19.7968 0.987096 19.6613 1.29677L17.9775 5.09032C17.842 5.4 17.4936 5.53548 17.1839 5.4Z"
        fill="#FCB026"
      />
      <path
        d="M21.7711 1.49033C21.442 1.33549 21.2872 0.948396 21.4227 0.600009V0.580654C21.5775 0.251622 21.9646 0.0967829 22.313 0.232267C22.642 0.387106 22.7969 0.774202 22.6614 1.12259V1.14194C22.5065 1.49033 22.1194 1.64517 21.7711 1.49033Z"
        fill="#FCB026"
      />
      <path
        d="M18.6936 9.09678L18.5774 9.03872C18.2678 8.90323 18.1323 8.53549 18.2678 8.22581L20.5516 3.11614C20.6871 2.80646 21.0549 2.67097 21.3645 2.80646L21.4807 2.86452C21.7903 3.00001 21.9258 3.36775 21.7903 3.67743L19.5065 8.78711C19.371 9.09678 19.0032 9.23227 18.6936 9.09678Z"
        fill="#FCB026"
      />
      <path
        d="M22.6034 6.85163L22.4872 6.79357C22.1776 6.65808 22.0421 6.29034 22.1776 5.98066L23.2034 3.69679C23.3388 3.38712 23.7066 3.25163 24.0163 3.38712L24.1324 3.44518C24.4421 3.58066 24.5775 3.94841 24.4421 4.25808L23.4163 6.54195C23.2614 6.85163 22.8937 6.98712 22.6034 6.85163Z"
        fill="#FCB026"
      />
      <path
        d="M21.2098 10.1226L21.0937 10.0645C20.784 9.92905 20.6485 9.56131 20.784 9.25163L21.2485 8.20647C21.384 7.89679 21.7517 7.76131 22.0614 7.89679L22.1776 7.95485C22.4872 8.09034 22.6227 8.45808 22.4872 8.76776L22.0227 9.81292C21.8679 10.1226 21.5195 10.2581 21.2098 10.1226Z"
        fill="#FCB026"
      />
      <path
        d="M14.9968 10.2968L14.9001 10.2581C14.5904 10.1226 14.4549 9.75486 14.5904 9.44518L15.113 8.28389C15.2485 7.97421 15.6162 7.83873 15.9259 7.97421L16.042 8.01292C16.3517 8.14841 16.4872 8.51615 16.3517 8.82583L15.8291 9.98712C15.6743 10.2968 15.3065 10.4323 14.9968 10.2968Z"
        fill="#FCB026"
      />
    </g>
    <defs>
      <linearGradient
        id="tvbet-rule-1"
        x1="8.20286"
        y1="6.79923"
        x2="8.20286"
        y2="23.4592"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FECF05" />
        <stop offset="1" stopColor="#FCA726" />
      </linearGradient>
      <linearGradient
        id="tvbet-rule-2"
        x1="14.5305"
        y1="23.4592"
        x2="14.5305"
        y2="5.91268"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FECF05" />
        <stop offset="1" stopColor="#FCB026" />
      </linearGradient>
      <clipPath id="tvbet-rule-3">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

IconProviderTvbet.propTypes = {
  className: PropTypes.string,
};

IconProviderTvbet.defaultProps = {
  className: '',
};
