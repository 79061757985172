import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderRedrakeBig = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="90" height="56" viewBox="0 0 90 56" fill="none">
    <path
      d="M2.19309 45.0809C1.87221 45.0809 1.56747 44.9405 1.35924 44.6968C1.15104 44.4532 1.06035 44.1307 1.11108 43.8145L5.46958 16.6476C5.55469 16.1172 6.01327 15.727 6.55159 15.727H86.2149C86.5358 15.727 86.8405 15.8673 87.0487 16.111C87.2569 16.3547 87.3476 16.6771 87.2969 16.9933L82.9384 44.1602C82.8532 44.6907 82.3947 45.0809 81.8563 45.0809H2.19309Z"
      fill="#1F1F1F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.55155 14.6335H86.2149C86.8566 14.6335 87.4661 14.9142 87.8825 15.4015C88.2989 15.8888 88.4803 16.5338 88.3788 17.1661L84.0203 44.333C83.8501 45.3939 82.933 46.1743 81.8563 46.1743H2.19305C1.55129 46.1743 0.941778 45.8936 0.525382 45.4063C0.108986 44.919 -0.0724018 44.274 0.0290309 43.6416L4.38753 16.4748C4.55774 15.4139 5.47491 14.6335 6.55155 14.6335ZM81.8563 43.9874L86.2148 16.8205H6.55155L2.19305 43.9874H81.8563Z"
      fill="#1F1F1F"
    />
    <path
      d="M89.8729 4.12588C89.5358 2.80007 88.4792 1.58966 86.9751 0.805373C85.9878 0.29005 84.9231 0.0177002 83.8965 0.0177002C82.4066 0.0177002 81.1499 0.615945 80.4487 1.65909C80.4479 1.66029 80.447 1.6615 80.4463 1.66274L79.5324 3.02824C79.4506 3.15035 79.3827 3.27839 79.3282 3.41038C79.2077 3.54014 79.097 3.67886 78.9979 3.82692L74.0196 11.2607C73.9376 11.3831 73.8694 11.5116 73.8148 11.6441C73.6945 11.7736 73.584 11.9122 73.4849 12.0601L71.9738 14.3162C71.2784 15.3561 71.2425 16.6438 71.8174 17.8351L57.5079 39.2041L53.1974 37.9018C53.1877 37.8829 53.1779 37.8641 53.1677 37.8455C52.4346 36.5179 51.1252 35.7565 49.575 35.7565C48.4263 35.7565 47.433 36.1734 47.0555 36.3547C47.0293 36.3672 47.0034 36.3802 46.978 36.3936C46.6094 36.5008 46.2684 36.7044 45.9951 36.9943C45.4248 37.5992 45.2471 38.4742 45.5363 39.2529L46.8381 42.7578C47.0265 43.2655 47.3971 43.6853 47.878 43.936L69.8285 55.3836C70.0682 55.5087 70.3236 55.5858 70.5821 55.6167C70.7233 55.6637 70.8704 55.6967 71.0218 55.7142C71.1065 55.724 71.191 55.7288 71.275 55.7288C72.0004 55.7288 72.6861 55.3691 73.0957 54.7584L75.0446 51.8533C75.5008 51.1732 75.5383 50.2958 75.1419 49.5795C74.4079 48.2532 73.0978 47.4926 71.5473 47.4926C71.0471 47.4926 70.5778 47.5715 70.1784 47.6741L63.3686 42.4965L77.6513 21.169C79.0787 21.1319 80.2771 20.5382 80.9553 19.5262L82.463 17.2732C82.5634 17.1236 82.6505 16.9677 82.7242 16.8069C82.825 16.7065 82.9171 16.5955 82.9984 16.4741L87.9795 9.04061C88.0813 8.88829 88.1694 8.72952 88.2438 8.56557C88.3415 8.46734 88.4309 8.3589 88.5101 8.2408L89.4234 6.87883C89.9614 6.07942 90.1211 5.10189 89.8729 4.12588Z"
      fill="#1F1F1F"
    />
    <path
      d="M84.5098 4.90739C83.037 4.13875 81.3845 4.19918 80.8199 5.04208L75.8417 12.4759C76.7195 12.1329 77.9881 12.2554 79.1742 12.8745C80.3626 13.4931 81.0955 14.4144 81.1766 15.2585L86.1565 7.82663C86.7216 6.98181 85.9836 5.67469 84.5098 4.90739Z"
      fill="#DB1D31"
    />
    <path
      d="M85.9594 2.74335C84.4891 1.97598 82.8346 2.03539 82.2687 2.87741L81.3547 4.24292C82.2326 3.90193 83.5021 4.02367 84.6879 4.64091C85.8762 5.25971 86.6075 6.18265 86.6887 7.02456L87.6036 5.66025C88.1718 4.8174 87.4333 3.51192 85.9594 2.74335Z"
      fill="#DB1D31"
    />
    <path
      d="M78.996 13.141C77.5238 12.3711 75.8725 12.4309 75.3069 13.2754L73.7967 15.5301C73.4204 16.0927 73.6241 16.8616 74.2429 17.5405L74.7973 16.7082C75.1188 16.2292 75.9614 16.1586 76.7114 16.5508C77.4659 16.9423 77.7999 17.6274 77.4794 18.1086L76.9221 18.9376C77.8911 19.0868 78.7547 18.8756 79.1326 18.3117L80.6414 16.057C81.2081 15.2135 80.4708 13.9077 78.996 13.141Z"
      fill="#DB1D31"
    />
    <path
      d="M59.0338 41.9502L59.0319 41.9573L59.0338 41.9502L47.5912 38.4929L48.8929 41.9978L70.8434 53.4454L69.5413 49.9392L59.0338 41.9502Z"
      fill="#DB1D31"
    />
    <path
      d="M77.1722 17.9485C77.3928 17.6219 77.1071 17.1134 76.5331 16.8163C75.9635 16.5173 75.3205 16.5408 75.1007 16.8681L58.655 41.4272L59.1124 41.5702L60.5965 42.6999L77.1722 17.9485Z"
      fill="#DB1D31"
    />
    <path
      d="M69.9685 50.0665L71.2745 53.5419L73.2234 50.6368C72.2576 48.8916 69.9685 50.0665 69.9685 50.0665Z"
      fill="#DB1D31"
    />
    <path
      d="M51.2481 38.9008C50.302 37.1871 48.0831 38.2887 48.0002 38.328L51.0297 39.2245L51.2481 38.9008Z"
      fill="#DB1D31"
    />
    <rect x="44.204" y="16.1568" width="40.3711" height="20.9548" fill="#1F1F1F" />
    <path
      d="M4.01969 41.9571C3.90383 42.6104 4.14311 42.901 4.79334 42.901C5.44643 42.9001 5.78882 42.6088 5.90359 41.9571L6.22209 40.155H5.44994L5.36954 40.6106H5.65046L5.41304 41.9571C5.35975 42.2586 5.15327 42.4454 4.87366 42.4454C4.5964 42.4454 4.45701 42.2586 4.51009 41.9571L5.06667 38.8085C5.13052 38.4481 5.38401 38.3199 5.6002 38.3199C5.88357 38.3229 6.02233 38.5107 5.96908 38.8085L5.87123 39.3621H6.36254L6.4605 38.8085C6.57515 38.1571 6.33558 37.867 5.68272 37.867C5.03211 37.867 4.69049 38.1571 4.57543 38.8085L4.01969 41.9571Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2133 42.8175H11.7177L11.7666 41.7256H10.7851L10.445 42.8175H9.95215L10.4647 41.1636L11.5411 37.9484H12.3479L12.2862 41.151L12.2133 42.8175ZM11.9287 38.0218L10.925 41.27H11.7844L11.9287 38.0218Z"
      fill="white"
    />
    <path
      d="M18.1177 42.8175L18.9786 37.9484H18.4562L16.8037 41.8686L16.5249 37.9484H16.0027L15.1422 42.8175H15.6093L16.2282 39.318L16.4888 42.8175H16.7962L18.2828 39.2847L17.6584 42.8175H18.1177Z"
      fill="white"
    />
    <path d="M23.1553 37.9484L22.295 42.8175H22.7859L23.6465 37.9484H23.1553Z" fill="white" />
    <path
      d="M29.3938 42.8175L30.2533 37.9515L29.8166 37.9589L29.1902 41.5036L28.8898 37.9484H28.3699L27.509 42.8175H27.971L28.584 39.3492L28.8733 42.8175H29.3938Z"
      fill="white"
    />
    <path
      d="M33.5711 41.9571C33.4555 42.6104 33.6947 42.901 34.3452 42.901C34.9979 42.9001 35.3405 42.6088 35.4555 41.9571L35.774 40.155H35.0017L34.9213 40.6106H35.202L34.9646 41.9571C34.9114 42.2586 34.7048 42.4454 34.4257 42.4454C34.1483 42.4454 34.0086 42.2586 34.062 41.9571L34.6183 38.8085C34.6824 38.4481 34.9356 38.3199 35.152 38.3199C35.4351 38.3229 35.5741 38.5107 35.5207 38.8085L35.4234 39.3621H35.9145L36.0122 38.8085C36.1271 38.1571 35.8873 37.867 35.2346 37.867C34.584 37.867 34.242 38.1571 34.1273 38.8085L33.5711 41.9571Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7378 27.9952L12.2148 35.2265H17.3146L14.9378 27.9952C15.9378 27.6273 16.7109 27.0515 17.257 26.2682C17.8029 25.4859 18.1532 24.6098 18.3068 23.6431C18.3377 23.3977 18.3648 23.1631 18.3881 22.9402C18.4105 22.7183 18.4228 22.4997 18.4228 22.2842C18.4228 21.409 18.2687 20.688 17.9604 20.1193C17.6526 19.5517 17.2221 19.1023 16.6685 18.7725C16.1148 18.4429 15.4531 18.208 14.6839 18.0702C13.9149 17.9318 13.0762 17.8628 12.1689 17.8628H7.62265L4.922 35.2265H9.62983L10.3457 30.5975C10.4224 30.1681 10.4838 29.7421 10.5299 29.3191C10.566 28.9925 10.6015 28.6631 10.6373 28.3315C10.6478 28.235 10.6582 28.1382 10.6687 28.0413L10.7378 27.9952ZM12.238 25.7158C11.9761 25.8388 11.691 25.8999 11.3838 25.8999L11.0843 25.8771L11.7535 21.5935H11.9375C12.476 21.5935 12.9027 21.7238 13.2185 21.9846C13.5339 22.2461 13.6918 22.6523 13.6918 23.2054C13.6918 23.2818 13.6879 23.3589 13.6804 23.4356C13.6722 23.5123 13.6608 23.5971 13.6457 23.6889C13.5991 23.9652 13.5143 24.2383 13.392 24.5062C13.2688 24.7753 13.111 25.0133 12.9187 25.2207C12.7263 25.4279 12.4994 25.5925 12.238 25.7158Z"
      fill="#DB1D31"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.5585 17.8628H26.7741L24.074 35.2265H28.1815C28.6585 35.2265 29.1887 35.1962 29.7741 35.1339C30.3586 35.0732 30.9588 34.9425 31.574 34.7425C32.1893 34.5433 32.8046 34.2477 33.4205 33.8571C34.035 33.4646 34.6088 32.9465 35.1394 32.3019C35.6705 31.6573 36.1388 30.8592 36.5472 29.907C36.955 28.9548 37.2583 27.8114 37.4591 26.4757C37.52 26.1227 37.5629 25.7846 37.5861 25.4624C37.6086 25.1397 37.6207 24.8255 37.6207 24.518C37.6207 23.4898 37.4702 22.5647 37.1706 21.7435C36.8701 20.9214 36.424 20.2229 35.8318 19.6473C35.2391 19.0721 34.5046 18.6307 33.6279 18.3237C32.7511 18.016 31.7273 17.8628 30.5585 17.8628ZM30.801 30.5862C30.4241 30.886 29.9666 31.0353 29.4279 31.0353L30.8122 22.1921C31.1972 22.1921 31.5165 22.2691 31.7702 22.4223C32.0238 22.5765 32.2239 22.7833 32.3701 23.0435C32.5161 23.3052 32.6162 23.6048 32.6699 23.9424C32.7243 24.2803 32.7512 24.633 32.7512 25.0013C32.7512 25.616 32.7047 26.1844 32.6123 26.7057C32.5506 27.1203 32.4505 27.5771 32.3125 28.076C32.1744 28.5753 31.9811 29.0433 31.7356 29.4807C31.4893 29.9182 31.1773 30.2875 30.801 30.5862Z"
      fill="#DB1D31"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.0164 27.9952L44.4933 35.2265H49.5931L47.2164 27.9952C48.2163 27.6273 48.9895 27.0515 49.5357 26.2682C50.0815 25.4859 50.4315 24.6098 50.5857 23.6431C50.6163 23.3977 50.6434 23.1631 50.6665 22.9402C50.6895 22.7183 50.7015 22.4997 50.7015 22.2842C50.7015 21.409 50.547 20.688 50.2394 20.1193C49.9311 19.5517 49.5007 19.1023 48.9472 18.7725C48.3934 18.4429 47.7317 18.208 46.9625 18.0702C46.1932 17.9318 45.3549 17.8628 44.4473 17.8628H39.901L37.2008 35.2265H41.9086L42.6241 30.5975C42.7006 30.1681 42.7624 29.7421 42.8082 29.3191C42.8489 28.9534 42.8887 28.5843 42.9287 28.2124C42.9349 28.1554 42.941 28.0984 42.9472 28.0413L43.0164 27.9952ZM44.5165 25.7158C44.2545 25.8388 43.9697 25.8999 43.6623 25.8999L43.3627 25.8771L44.0321 21.5935H44.2162C44.755 21.5935 45.1816 21.7238 45.497 21.9846C45.8124 22.2461 45.9703 22.6523 45.9703 23.2054C45.9703 23.2818 45.9665 23.3589 45.9591 23.4356C45.9508 23.5123 45.9394 23.5971 45.9242 23.6889C45.878 23.9652 45.793 24.2383 45.6706 24.5062C45.5477 24.7753 45.3895 25.0133 45.1973 25.2207C45.0046 25.4279 44.7782 25.5925 44.5165 25.7158Z"
      fill="white"
    />
    <path
      d="M75.1486 17.8628L70.0945 26.2454L72.8867 35.2264H67.8325L66.1484 27.2587H66.1253L64.8787 35.2264H60.1713L62.8715 17.8628H67.5793L66.4258 25.255H66.4481L70.1871 17.8628H75.1486V17.8628Z"
      fill="white"
    />
    <path
      d="M76.159 17.8628H84.9056L84.2828 21.9621H80.2441L79.8515 24.3798H83.4747L82.8284 28.4788H79.2055L78.79 31.1271H82.8284L82.2057 35.2265H73.4595L76.159 17.8628Z"
      fill="white"
    />
    <path
      d="M25.4186 21.9621L26.0413 17.8628H17.2951L17.2374 18.2333C17.8239 18.6224 18.2981 19.1288 18.6374 19.754C19.0057 20.4337 19.1919 21.2846 19.1919 22.2842C19.1919 22.5235 19.1782 22.7707 19.1529 23.0195C19.1297 23.2479 19.1016 23.4873 19.0704 23.7371C18.8954 24.8416 18.4986 25.8317 17.8886 26.7066C17.3853 27.4286 16.7137 28.0028 15.8866 28.4196L18.1245 35.2265H23.3415L23.9645 31.1271H19.9259L20.3415 28.4788H23.9645L24.6106 24.3798H20.9872L21.3802 21.9621H25.4186Z"
      fill="url(#redrake-big-rule-1)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.9136 17.8628L59.9059 35.2264H55.0369V32.993H51.8755L51.1372 35.2264H50.4032L48.538 29.5531L49.2775 27.6847C49.616 27.3967 49.9126 27.0709 50.1672 26.7066C50.7773 25.8317 51.1736 24.8415 51.3491 23.7371C51.3806 23.4873 51.4084 23.2478 51.4318 23.0194C51.4572 22.7706 51.4708 22.5234 51.4708 22.2842C51.4708 22.2619 51.4692 22.2407 51.4676 22.2196C51.4663 22.201 51.4649 22.1824 51.4646 22.1631L53.1673 17.8628H58.9136ZM54.3449 25.2775L53.0753 29.3077H55.0596L55.1755 25.2775C55.1906 24.7405 55.2135 24.1952 55.2444 23.6431C55.248 23.5798 55.2515 23.5166 55.255 23.4535C55.2823 22.9657 55.3092 22.484 55.3371 22.0077H55.2902C55.1363 22.5456 54.9825 23.0903 54.8292 23.6431C54.6751 24.1952 54.5135 24.7405 54.3449 25.2775Z"
      fill="url(#redrake-big-rule-2)"
    />
    <defs>
      <linearGradient
        id="redrake-big-rule-1"
        x1="13.7824"
        y1="32.674"
        x2="20.5805"
        y2="32.5617"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#831622" />
        <stop offset="1" stopColor="#DB1D31" />
      </linearGradient>
      <linearGradient
        id="redrake-big-rule-2"
        x1="47.4549"
        y1="38.6138"
        x2="62.2733"
        y2="38.0973"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#403A3B" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);

IconProviderRedrakeBig.propTypes = {
  className: PropTypes.string,
};
IconProviderRedrakeBig.defaultProps = {
  className: null,
};
