import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import classNames from 'classnames';

import { IconArrowCircled } from 'components/icons/icon-arrow-circled/icon-arrow-circled';
import { CustomArrow } from './custom-arrow';

/* eslint-disable import/no-unresolved */
import 'modules/slick-carousel/slick/slick.css';
import 'modules/slick-carousel/slick/slick-theme.css';
/* eslint-enable import/no-unresolved */

import './carousel.scss';

const DEFAULT_SETTINGS = {
  infinite: false,
  arrows: true,
  speed: 500,
  dots: false,
  lazyLoad: 'progressive',
};

export const Carousel = ({
  iconLeft,
  iconRight,
  settings,
  children,
  sliderRef,
  controlsPosition,
  ...restProps
}) => {
  const config = {
    prevArrow: <CustomArrow icon={iconLeft} />,
    nextArrow: <CustomArrow icon={iconRight} />,
  };

  const targetSettings = {
    ...DEFAULT_SETTINGS,
    ...settings,
    ...config,
    ...restProps,
  };

  return (
    <Slider
      ref={sliderRef}
      {...targetSettings}
      className={classNames({
        'top-controls': controlsPosition === 'top',
        'side-controls': controlsPosition === 'side',
      }, targetSettings.className)}
    >
      {children}
    </Slider>
  );
};

Carousel.propTypes = {
  iconRight: PropTypes.node,
  iconLeft: PropTypes.node,
  controlsPosition: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape()),
  ])),
  sliderRef: PropTypes.objectOf(PropTypes.object),
  settings: PropTypes.shape({
    infinite: PropTypes.bool,
    arrows: PropTypes.bool,
    speed: PropTypes.number,
    dots: PropTypes.bool,
    rows: PropTypes.number,
    slidesPerRow: PropTypes.number,
    lazyLoad: PropTypes.oneOf([
      /** @see https://stackoverflow.com/questions/25726554/lazy-loading-progressive-vs-on-demand/25726743#25726743 */
      'progressive',
      'ondemand',
      true, // 'ondemand'
      null,
    ]),
  }),
};

Carousel.defaultProps = {
  iconRight: <IconArrowCircled />,
  iconLeft: <IconArrowCircled />,
  controlsPosition: 'top',
  children: null,
  sliderRef: null,
  settings: {
    infinite: false,
    arrows: true,
    speed: 500,
    dots: false,
    lazyLoad: 'progressive',
  },
};
