import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderAmusnetEgtBig = ({ className }) => (
  <svg className={className} width="141" height="21" viewBox="0 0 141 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#amusnet-egt-big-1)">
      <mask id="mask-amusnet-egt-big-1" style={{ 'mask-type': 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="141" height="21">
        <path d="M141 0.917236H0V20.0836H141V0.917236Z" fill="white" />
      </mask>
      <g mask="url(#mask-amusnet-egt-big-1)">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.57519 10.7204C5.59731 8.15825 7.97971 4.86314 12.0409 3.06247C19.1881 -0.108094 23.2813 2.81544 24.3409 5.57974C25.328 8.15621 23.3759 12.6783 19.9923 16.0489C22.4068 14.2911 24.3791 12.4047 25.7482 10.5714C27.5637 8.13783 28.3156 5.80023 27.626 3.98322C26.6047 1.30059 22.624 0.439048 17.5736 1.16176C12.5254 1.88448 8.64718 3.14414 5.53297 4.75086C2.06894 6.53723 0.281637 8.66047 0.0263081 11.0838C-0.345628 14.6035 3.3677 19.3318 11.8619 19.9157C16.3251 20.2219 20.6075 20.324 28.8966 18.2804C27.9094 18.313 5.48673 21.1406 5.5772 10.7204H5.57519Z" fill="#A6CE39" />
        <path fillRule="evenodd" clipRule="evenodd" d="M13.8221 14.8154C16.4819 14.8154 18.6532 12.6125 18.6532 9.91152C18.6532 7.21049 16.4839 5.00763 13.8221 5.00763C11.1623 5.00763 8.99292 7.21049 8.99292 9.91152C8.99292 12.6125 11.1623 14.8154 13.8221 14.8154Z" fill="#A6CE39" />
        <path d="M40.5814 14.6436H34.936L33.8866 17.2772H30.0345L35.9292 3.72119H39.6486L45.5614 17.2793H41.6329L40.5834 14.6456L40.5814 14.6436ZM39.4756 11.8161L37.7587 7.47769L36.0418 11.8161H39.4756Z" fill="#A6CE39" />
        <path d="M60.3425 17.2793L60.3042 10.0929L56.8704 15.9421H55.1916L51.7778 10.2869V17.2793H48.2877V3.72119H51.3958L56.0883 11.5465L60.6661 3.72119H63.7743L63.8125 17.2793H60.3404H60.3425Z" fill="#A6CE39" />
        <path d="M69.6769 15.9033C68.5771 14.8049 68.0262 13.2492 68.0262 11.2362V3.72119H71.8019V11.1198C71.8019 13.2901 72.6483 14.3742 74.3391 14.3742C76.03 14.3742 76.8562 13.2901 76.8562 11.1198V3.72119H80.5756V11.2362C80.5756 13.2512 80.0247 14.807 78.925 15.9033C77.8253 17.0016 76.2832 17.5488 74.2989 17.5488C72.3146 17.5488 70.7745 16.9996 69.6748 15.9033H69.6769Z" fill="#A6CE39" />
        <path d="M86.3376 17.1439C85.3202 16.8723 84.494 16.5111 83.8586 16.0598L85.0992 13.2323C85.6962 13.6324 86.3899 13.955 87.178 14.2C87.9661 14.4449 88.7421 14.5674 89.5041 14.5674C90.9536 14.5674 91.6774 14.2 91.6774 13.463C91.6774 13.0751 91.4703 12.7893 91.0582 12.6014C90.644 12.4136 89.9806 12.2176 89.0658 12.0114C88.0605 11.7929 87.2221 11.5561 86.5486 11.3051C85.8751 11.0539 85.2962 10.6497 84.8137 10.0944C84.3311 9.53905 84.0899 8.78979 84.0899 7.84659C84.0899 7.01975 84.313 6.27458 84.7574 5.60902C85.2016 4.94347 85.8671 4.41879 86.7518 4.03089C87.6363 3.64299 88.7199 3.44904 90.0027 3.44904C90.8792 3.44904 91.7457 3.54907 92.5961 3.74914C93.4486 3.94923 94.1983 4.24321 94.8478 4.63111L93.6838 7.47911C92.4132 6.78089 91.1788 6.43382 89.9825 6.43382C89.2326 6.43382 88.6858 6.54611 88.342 6.77272C87.9982 6.99933 87.8273 7.29332 87.8273 7.65469C87.8273 8.01604 88.0304 8.28757 88.4385 8.46723C88.8446 8.64893 89.5001 8.83471 90.4027 9.02866C91.42 9.24915 92.2624 9.48393 92.9299 9.73502C93.5973 9.98616 94.1766 10.3863 94.6645 10.9355C95.1533 11.4847 95.3986 12.2298 95.3986 13.1731C95.3986 13.9856 95.1759 14.7226 94.7312 15.38C94.2866 16.0394 93.6195 16.5641 92.7289 16.9582C91.8382 17.3522 90.7586 17.5482 89.4859 17.5482C88.4043 17.5482 87.3569 17.4134 86.3396 17.1419L86.3376 17.1439Z" fill="#A6CE39" />
        <path d="M111.494 3.72119V17.2793H108.387L102.494 10.0542V17.2793H98.7949V3.72119H101.903L107.795 10.9463V3.72119H111.496H111.494Z" fill="#A6CE39" />
        <path d="M126.543 14.3149V17.2772H115.823V3.72119H126.294V6.68352H119.56V8.96804H125.492V11.8344H119.56V14.3129H126.541L126.543 14.3149Z" fill="#A6CE39" />
        <path d="M133.143 6.76109H129.042V3.72119H141V6.76109H136.918V17.2772H133.143V6.76109Z" fill="#A6CE39" />
      </g>
    </g>
    <defs>
      <clipPath id="amusnet-egt-big-1">
        <rect width="141" height="21" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

IconProviderAmusnetEgtBig.propTypes = {
  className: PropTypes.string,
};
IconProviderAmusnetEgtBig.defaultProps = {
  className: null,
};
