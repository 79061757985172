import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { RouteLink } from 'core/components';
import { withUser, withLocale, withModalActions } from 'core/hocs';
import { isEmptyOrNil } from 'core/helpers';
import { USER_FIELDS } from 'core/constants';
import { IconArrowDown } from 'components/icons/icon-arrow-down/icon-arrow-down';
import { IconPlus } from 'components/icons/icon-plus/icon-plus';
import colors from 'customizations/js/color-variables';
import { GA } from 'helpers/ga';
import { YM } from 'helpers/ym';
import { formatAmountWithCurrency } from 'helpers/numbers';
import { MODAL_IDS, PAGE_NAMES } from '../../constants';

import './user-balance.scss';

export class UserBalanceUI extends Component {
  static propTypes = {
    userBalance: PropTypes.shape(),
    className: PropTypes.string,
    pageName: PropTypes.string,
    isSignUpCompleted: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    openModal: PropTypes.func.isRequired,
    openProfileModal: PropTypes.func,
    userData: PropTypes.shape().isRequired,
    isSportsPage: PropTypes.bool,
  };

  static defaultProps = {
    userBalance: null,
    className: null,
    pageName: null,
    openProfileModal: null,
    isSportsPage: false,
  };

  openModal = () => {
    const { openModal, isSignUpCompleted } = this.props;

    openModal(isSignUpCompleted ? MODAL_IDS.DEPOSIT : MODAL_IDS.COMPLETE_SIGN_UP);
  };

  depositClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    GA.event({ category: 'deposit', action: 'click', label: 'DepFirst' });
    YM.event('DepFirst');

    this.openModal();
  };

  render() {
    const {
      userBalance,
      className,
      pageName,
      locale,
      openProfileModal,
      userData,
      isSportsPage,
    } = this.props;

    const isUserBalanceFetched = !isEmptyOrNil(userBalance);
    const isGamePage = pageName === PAGE_NAMES.GAME;
    const { firstName } = userData || {};

    return isUserBalanceFetched && (
      <div className={classNames('user-balance d-flex align-items-center cursor-pointer', className)}>
        {isGamePage ? (
          <Button color="primary" size="sm" onClick={this.openModal}>
            <FormattedMessage id="deposit" />
          </Button>
        ) : (
          <div className="d-flex align-items-center">
            <div
              role="button"
              tabIndex="0"
              className="d-flex flex-column align-items-center user-balance mr-0_5"
              onClick={openProfileModal}
              onKeyPress={openProfileModal}
            >
              <span className="text-capitalize h6 font-weight-normal text-gray-20 user-name">{firstName}</span>
              <span className="font-weight-bold text-gray-40">
                {formatAmountWithCurrency({
                  amount: userBalance.totalBalance,
                  currency: userBalance.currency,
                  locale,
                })}
              </span>
            </div>

            <IconArrowDown
              onClick={openProfileModal}
              className="icon-arrow-down ml-0-75 mr-3_25"
              color={colors.gray20}
            />

            <Button
              tag={RouteLink}
              onClick={this.depositClick}
              color="primary"
              size="sm"
              className="btn-deposit-mobile d-sm-none btn-rounded d-flex align-items-center justify-content-center px-0"
              to="/wallet?tab=deposit"
            >
              <IconPlus />
            </Button>

            <Button
              tag={RouteLink}
              onClick={this.depositClick}
              color="primary"
              size="sm"
              className={classNames('d-none d-sm-block py-1 py-lg-1_25 px-2', {
                'd-lg-none': isSportsPage,
              })}
              to="/wallet?tab=deposit"
            >
              <FormattedMessage id="deposit" />
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export const UserBalance = withModalActions(withLocale(
  withUser(UserBalanceUI,
    [
      USER_FIELDS.USER_BALANCE,
      USER_FIELDS.USER_DATA,
      USER_FIELDS.IS_SIGN_UP_COMPLETED
    ])
));
