import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconStatusApproved = ({ className }) => (
  <svg className={className} width="20" height="21" viewBox="0 0 20 21" fill="none">
    <rect x="0.5" y="1" width="19" height="19" rx="9.5" fill={colors.statusGreen} />
    <rect x="0.5" y="1" width="19" height="19" rx="9.5" stroke={colors.statusGreen} />
    <path fillRule="evenodd" clipRule="evenodd" d="M15.3482 8.72047C15.8405 8.22815 15.8405 7.42993 15.3482 6.9376C14.8559 6.44528 14.0577 6.44528 13.5653 6.9376L9.05336 11.4496L6.79277 9.18884C6.30046 8.6965 5.50224 8.69647 5.0099 9.18878C4.51756 9.68109 4.51753 10.4793 5.00984 10.9717L8.16153 14.1236C8.48569 14.4477 8.94248 14.5585 9.35724 14.4558C9.57273 14.4026 9.77692 14.2918 9.94531 14.1234L15.3482 8.72047Z" fill={colors.black} />
  </svg>
);

IconStatusApproved.propTypes = {
  className: PropTypes.string,
};
IconStatusApproved.defaultProps = {
  className: null,
};
