import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderSpribe = ({ className }) => (
  <svg
    className={classNames('icon-spribe', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="24"
    viewBox="0 0 10 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.78186 15.0198V13.8402H3.89502V15.213C3.89502 16.4695 4.38049 17.098 5.35075 17.098H5.36306C6.32442 17.098 6.80579 16.4715 6.80579 15.2191C6.80579 14.5035 6.59861 13.8327 6.18289 13.2062C5.76784 12.5797 5.27143 12.0069 4.69366 11.4878L3.13331 9.87763C2.48853 9.31436 1.94904 8.6164 1.51485 7.78443C1.07998 6.95177 0.862544 6.03001 0.862544 5.01912C0.862544 3.46334 1.2728 2.27014 2.09331 1.43817C2.91314 0.606196 4.02767 0.19055 5.43553 0.19055C6.8434 0.19055 7.92442 0.606196 8.6793 1.43749C9.43417 2.26878 9.8116 3.46198 9.8116 5.01708V5.63409H6.94049V4.83001C6.94049 3.56946 6.43314 2.93885 5.41776 2.93885C4.40237 2.93885 3.89502 3.57014 3.89502 4.83137C3.89502 5.35926 4.00579 5.86266 4.22665 6.34157C4.4475 6.82048 4.734 7.25177 5.08613 7.63681C5.43827 8.02116 5.82665 8.40143 6.25058 8.77694C6.67451 9.15314 7.09366 9.54701 7.50665 9.95858C7.91964 10.3701 8.30186 10.8103 8.65263 11.2803C9.00272 11.7497 9.28784 12.3048 9.50801 12.9443C9.72818 13.5844 9.83827 14.2797 9.83827 15.0314C9.83827 16.5831 9.45195 17.7735 8.6793 18.6028C7.90665 19.432 6.81605 19.847 5.4075 19.847C3.99895 19.847 2.87554 19.4314 2.03793 18.6001C1.20032 17.7688 0.78186 16.5749 0.78186 15.0198Z"
      fill="white"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M0.699951 23.4H9.69995V21H0.699951V23.4Z" fill="white" />
  </svg>
);

IconProviderSpribe.propTypes = {
  className: PropTypes.string,
};

IconProviderSpribe.defaultProps = {
  className: '',
};
