import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';

// import store from 'store';
import { DEVICES_TYPES } from 'core/constants';
import { withUserAgent, withModalActions } from 'core/hocs';

// import { IS_PWA_INSTALL_GUIDE_CLOSED_LS_KEY } from '../constants';

export const PwaInstallContext = createContext({});

// const setLocalStorageStatus = storageData => storageData
//   && store.set(IS_PWA_INSTALL_GUIDE_CLOSED_LS_KEY, storageData);

export const withPwaInstall = (WrappedComponent) => {
  class withPwaInstallUI extends Component {
    static propTypes = {
      openModal: PropTypes.func.isRequired,
      closeModal: PropTypes.func.isRequired,
      os: PropTypes.string,
      isPwa: PropTypes.bool,
      browserName: PropTypes.string,
      deviceType: PropTypes.string,
    };

    static defaultProps = {
      os: null,
      browserName: null,
      isPwa: false,
      deviceType: DEVICES_TYPES.DESKTOP,
    };

    state = {
      isDeferredPromptReady: false,
      isPwaGuideReady: false,
      isIncognito: false,
      isGuideClosed: false,
    };

    deferredPrompt;

    componentDidMount() {
      const { os, browserName } = this.props;

      window.addEventListener('beforeinstallprompt', (e) => {
        this.deferredPrompt = e;
        this.setState({ isDeferredPromptReady: true });
      });

      window.onload = () => {
        if ((os === 'ios' && browserName !== 'Mobile Safari') || (os === 'android' && browserName !== 'Chrome')) {
          this.setState({ isPwaGuideReady: true });
        } else if ('serviceWorker' in navigator) {
          this.setState({ isPwaGuideReady: true });
        }

        if ('storage' in navigator && 'estimate' in navigator.storage) {
          navigator.storage.estimate().then(({ quota }) => (
            this.setState({ isIncognito: quota < 120000000 })
          ));
        } else {
          console.error('Incognito cannot be detected');
        }
      };
    }

    onInstallClick = (modalId) => {
      const {
        os,
        browserName,
        openModal,
        closeModal,
      } = this.props;

      const { isIncognito } = this.state;

      if (os === 'android' && browserName === 'Chrome') {
        if (this.deferredPrompt) {
          this.deferredPrompt.prompt();
          closeModal(modalId);

          return;
        }

        if (!isIncognito) {
          return;
        }
      }

      openModal(modalId);
    };

    hidePwaInstallGuide =() => {
      const { browserName, os } = this.props;
      const isChromeBrowser = browserName === 'Chrome';
      const isAndroid = os === 'android';

      if (!(isChromeBrowser && isAndroid)) {
        this.onCloseGuide();
      }
    }

    onCloseGuide = () => {
      this.setState({ isGuideClosed: true });
      // setLocalStorageStatus(true);
    };

    render() {
      const {
        openModal,
        os,
        browserName,
        isPwa,
      } = this.props;

      const {
        isDeferredPromptReady,
        isPwaGuideReady,
        isIncognito,
        isGuideClosed,
      } = this.state;

      return (
        <PwaInstallContext.Provider
          value={{
            openModal,
            os,
            browserName,
            isDeferredPromptReady,
            isPwaGuideReady,
            isIncognito,
            isPwa,
            isGuideClosed,
            onInstallClick: this.onInstallClick,
            onCloseGuide: this.onCloseGuide,

            hidePwaInstallGuide: this.hidePwaInstallGuide,
          }}
        >
          <WrappedComponent {...this.props} />
        </PwaInstallContext.Provider>
      );
    }
  }

  return withUserAgent(withModalActions(withPwaInstallUI));
};
