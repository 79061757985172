import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconMinus = ({ className, color }) => (
  <svg className={className} width="16" height="2">
    <path d="M1 1h14" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconMinus.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};
IconMinus.defaultProps = {
  className: '',
  color: colors.gray40,
};
