import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import Input from 'reactstrap/lib/Input';
import { withInfo } from 'core/hocs';
import { IconArrowDown } from 'components/icons/icon-arrow-down/icon-arrow-down';
import { withDropdownAlignment } from '../../../hocs/with-dropdown-alignment';

import './tel-input.scss';

export class TelInputInnerUI extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    formattedValue: PropTypes.string,
    onChange: PropTypes.func,
    onFormat: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func.isRequired,
    updateFormattedNumber: PropTypes.func.isRequired,
    setRef: PropTypes.func.isRequired,
    selectedCountry: PropTypes.shape(),
    error: PropTypes.shape(),
    countryInfo: PropTypes.shape().isRequired,
    size: PropTypes.string,
    countries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    dropdownMenuAlignmentSettings: PropTypes.shape().isRequired,
    updateDropdownAlignment: PropTypes.func.isRequired,
    rightContent: PropTypes.node,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    wrapperClassName: '',
    formattedValue: '',
    placeholder: null,
    onChange: null,
    error: null,
    selectedCountry: {},
    size: '',
    rightContent: null,
    disabled: false,
  };

  state = {
    isDropdownOpen: false,
  };

  inputRef = React.createRef();

  componentDidMount() {
    const { setRef } = this.props;
    setRef(this.inputRef.current);
  }

  onChange = (e) => {
    const { onFormat, onChange } = this.props;
    onFormat(e, onChange);
  };

  onCountryClick = ({ currentTarget: { dataset: { code, country: currentIso2 } } }) => {
    const { updateFormattedNumber } = this.props;

    updateFormattedNumber(`+${code}`, currentIso2);
  };

  toggle = () => {
    const { updateDropdownAlignment, disabled } = this.props;

    if (!disabled) {
      this.setState(({ isDropdownOpen }) => ({
        isDropdownOpen: !isDropdownOpen,
      }),
      updateDropdownAlignment);
    }
  };

  render() {
    const {
      name, placeholder, size, countryInfo, dropdownMenuAlignmentSettings,
      onClick, onFocus, onBlur, error, onFormat,
      onKeyDown, formattedValue, className, countries,
      wrapperClassName, selectedCountry, disabled, rightContent,
    } = this.props;

    const { isDropdownOpen } = this.state;

    const selectedIso2Code = selectedCountry.iso2 || (countryInfo.iso2Code || '').toLowerCase();

    return (
      <div className={classNames(wrapperClassName, 'tel-input position-relative')}>
        <Dropdown
          isOpen={isDropdownOpen}
          toggle={this.toggle}
          className="tel-input-dropdown position-absolute h-100 casino-sidebar-dropdown"
        >
          <DropdownToggle tag="div" role="button" className="d-flex align-items-center pl-2 h-100">
            <div className={`flag flag-${selectedIso2Code} mr-0_5`} />
            <IconArrowDown />
          </DropdownToggle>
          <DropdownMenu
            className={`${name}-dropdown tel-input-dropdown-menu`}
            style={dropdownMenuAlignmentSettings}
          >
            {countries.map(({ iso2Code, dialCode, name: countryName }) => (
              <DropdownItem
                key={iso2Code}
                data-country={iso2Code}
                data-code={dialCode}
                onClick={this.onCountryClick}
                className="d-flex align-items-center"
              >
                <div className={`flag flag-${(iso2Code || '').toLowerCase()} mr-2`} />
                <div className={classNames('text-wrap', { 'font-weight-medium': iso2Code === selectedCountry.iso2 })}>
                  {countryName}
                  <span className="ml-1 text-white-65">+{dialCode}</span>
                </div>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>

        <FormattedMessage id={placeholder}>
          {txt => (
            <>
              <Input
                id={`tel-${name}`}
                disabled={disabled}
                type="tel"
                name={name}
                size={size}
                placeholder={txt}
                value={formattedValue}
                innerRef={this.inputRef}
                onChange={onFormat}
                onClick={onClick}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                className={classNames(className, 'pl-8_5 w-100', {
                  'border-danger': !!error,
                  'pr-5': !!rightContent,
                })}
              />
              {rightContent && (
                <span className="position-absolute right-content">
                  {rightContent}
                </span>
              )}
            </>
          )}
        </FormattedMessage>
      </div>
    );
  }
}

export const TelInputInner = withInfo(withDropdownAlignment(TelInputInnerUI));
