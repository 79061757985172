/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedTag } from 'core/components';
import { withLocale } from 'core/hocs';
import { isEmptyOrNil } from 'core/helpers';
import { PAYMENT_ITEM_KEYS } from 'core/constants';

import { PaymentMethodCard } from 'components/payment-method-card/payment-method-card';

import './payments-menu.scss';

const {
  PAYMENT_METHOD,
  MINIMUM_WITHDRAWAL,
  MINIMUM_DEPOSIT,
} = PAYMENT_ITEM_KEYS;

export const PaymentsMenuUI = ({
  locale,
  otherItems,
  recentlyItems,
  handleSelectedCard,
  currency,
  isWithdrawal,
  isModal,
  activePaymentMethod,
  toggleDepositNew,
  step,
  isTabs,
  activeBonuses,
  setActiveWithdrawalMethod,
}) => (
  <>
    {!isTabs && (
      <FormattedTag className="h3 d-sm-none mb-2_5" id={isWithdrawal ? 'withdrawal' : 'deposit'} />
    )}

    <FormattedTag
      className="h4 mb-1 mb-sm-3 d-block font-weight-bold"
      id={`profile-wallet.${isWithdrawal ? 'withdrawal' : 'deposit'}.select-method`}
    />

    <div className="d-flex flex-column">
      {!isEmptyOrNil(recentlyItems) && (
        <>
          <FormattedTag id="profile-wallet.withdrawal.recently-used" className="text-uppercase label mb-1" />

          <div className="mx-n0_5 mb-2 payments-items-container">
            {recentlyItems.map(method => (
              <PaymentMethodCard
                isWithdrawal={isWithdrawal}
                isModal={isModal}
                activePaymentMethod={activePaymentMethod}
                toggleDepositNew={toggleDepositNew}
                locale={locale}
                key={method[PAYMENT_METHOD]}
                paymentMethod={method[PAYMENT_METHOD]}
                currency={currency}
                onClick={handleSelectedCard}
                minimum={method[isWithdrawal ? MINIMUM_WITHDRAWAL : MINIMUM_DEPOSIT]}
                activeBonuses={activeBonuses}
                setActiveWithdrawalMethod={setActiveWithdrawalMethod}
              />
            ))}
          </div>
        </>
      )}
    </div>
    <div className="d-flex flex-column">
      {!isEmptyOrNil(otherItems) && (
        <>
          {!isEmptyOrNil(recentlyItems) && !isWithdrawal && (
            <FormattedTag
              id="profile-wallet.withdrawal.other-methods"
              className="text-uppercase label mb-1"
            />
          )}

          <div className="mx-n0_5 payments-items-container">
            {otherItems.map(method => (
              <PaymentMethodCard
                isWithdrawal={isWithdrawal}
                step={step}
                isModal={isModal}
                activePaymentMethod={activePaymentMethod}
                toggleDepositNew={toggleDepositNew}
                locale={locale}
                key={method[PAYMENT_METHOD]}
                paymentMethod={method[PAYMENT_METHOD]}
                currency={currency}
                onClick={handleSelectedCard}
                minimum={method[isWithdrawal ? MINIMUM_WITHDRAWAL : MINIMUM_DEPOSIT]}
                disabled={isWithdrawal}
                activeBonuses={activeBonuses}
                setActiveWithdrawalMethod={setActiveWithdrawalMethod}
              />
            ))}
          </div>
        </>
      )}
    </div>
  </>
);

PaymentsMenuUI.propTypes = {
  locale: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  otherItems: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  recentlyItems: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  isWithdrawal: PropTypes.bool,
  handleSelectedCard: PropTypes.func.isRequired,
  activePaymentMethod: PropTypes.string,
  toggleDepositNew: PropTypes.func,
  isModal: PropTypes.bool,
  step: PropTypes.number,
  isTabs: PropTypes.bool,
  activeBonuses: PropTypes.arrayOf(PropTypes.shape()),
  setActiveWithdrawalMethod: PropTypes.func,
};

PaymentsMenuUI.defaultProps = {
  isTabs: false,
  isWithdrawal: false,
  recentlyItems: [],
  otherItems: [],
  activePaymentMethod: null,
  toggleDepositNew: () => {},
  isModal: false,
  step: null,
  activeBonuses: {},
  setActiveWithdrawalMethod: () => {},
};

export const PaymentsMenu = withLocale(PaymentsMenuUI);
