import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withLocale } from 'core/hocs';
import { filterValuesInTheInterval, getRemoteConfig } from 'core/helpers';
import { FormattedTag } from 'core/components';

import './deposit-sum.scss';

const DEFAULT_PRESET = [20, 50, 100, 200, 500];

export class DepositSumUI extends Component {
  static propTypes = {
    currency: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    className: PropTypes.string,
    sumChangeHandler: PropTypes.func.isRequired,
    minimumDeposit: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
    maximumDeposit: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
    depositSumValue: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    currency: '',
    minimumDeposit: null,
    maximumDeposit: null,
    depositSumValue: null,
  };

  state = {
    stateCurrency: '',
    minDeposit: null,
    maxDeposit: null,
    depositValues: [],
  };

  static getDerivedStateFromProps(props, prevState) {
    const {
      currency,
      minimumDeposit,
      maximumDeposit,
    } = props;
    const { stateCurrency, minDeposit, maxDeposit } = prevState;

    if (stateCurrency !== currency || minimumDeposit !== minDeposit || maximumDeposit !== maxDeposit) {
      const depositPresets = getRemoteConfig('DEPOSIT.PRESETS') || {};
      const depositAmounts = depositPresets[currency] || depositPresets.EUR || DEFAULT_PRESET;

      return {
        stateCurrency: currency,
        minDeposit: minimumDeposit,
        maxDeposit: maximumDeposit,
        depositValues: filterValuesInTheInterval(depositAmounts, minimumDeposit, maximumDeposit),
      };
    }

    return null;
  }

  changeDepositSumValue = (event) => {
    const { sumChangeHandler } = this.props;
    sumChangeHandler(event.target.id);
  };

  render() {
    const { className, depositSumValue } = this.props;
    const { depositValues } = this.state;

    return (
      <div
        className={classNames('deposit-sum text-gray-40', className)}
      >
        <FormattedTag className="font-weight-semi-bold" id="payments.amount" />
        <div className="d-flex flex-nowrap w-100 justify-content-between text-center my-1_5 rounded-lg overflow-hidden">
          {depositValues.map(value => (
            <div
              key={value}
              id={value}
              className={classNames('deposit-value-cell flex-grow-1 py-1_5 position-relative bg-gray-5-50 h4 font-weight-semi-bold', {
                'deposit-cell-active text-primary border-bottom border-primary': value === Number(depositSumValue)
              })}
              tabIndex="0"
              role="button"
              onClick={this.changeDepositSumValue}
              onKeyPress={this.changeDepositSumValue}
            >
              {value}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export const DepositSum = withLocale(DepositSumUI);
