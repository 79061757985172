import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconJeton = ({ className }) => (
  <svg className={classNames('footer-icon icon-jeton', className)} width="66" height="21" viewBox="0 0 66 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="icon-switch-dark" fillRule="evenodd" clipRule="evenodd" d="M66.0008 19.7018L62.4901 19.6655C61.8837 19.6655 61.8837 19.6655 61.8837 19.0591V11.6678C61.8836 11.4364 61.8654 11.2052 61.8291 10.9766C61.8102 10.4849 61.5962 10.021 61.2343 9.68759C60.8724 9.35417 60.3926 9.17871 59.901 9.20004H59.7555C58.6984 9.13272 57.7681 9.89129 57.6211 10.9402C57.5593 11.2843 57.5309 11.6336 57.5363 11.9831V19.2289C57.5363 19.5806 57.4635 19.714 57.0815 19.7018H53.9649C53.6496 19.7018 53.5344 19.6291 53.5344 19.2895V6.27749C53.5344 5.97432 53.6193 5.87124 53.9346 5.8773H56.9663C57.3362 5.8773 57.512 5.96219 57.4514 6.35631C57.4566 6.44444 57.4708 6.5318 57.4938 6.61704L57.894 6.36844C59.3369 5.3676 61.1961 5.18803 62.8039 5.89421C64.4116 6.6004 65.5372 8.09097 65.7764 9.83063C65.8128 10.1096 65.8977 10.3824 65.9583 10.6613L66.0008 19.7018Z" fill="#272727" fillOpacity="0.4" />
    <path className="icon-switch-dark" fillRule="evenodd" clipRule="evenodd" d="M18.5972 14.0689C18.7314 14.7365 19.0936 15.3367 19.6219 15.7666C20.7197 16.6445 22.2618 16.7035 23.4236 15.9121C23.5257 15.8524 23.6394 15.8152 23.7571 15.803C23.8387 15.8102 23.9186 15.8307 23.9936 15.8636L27.3648 16.8338C26.8913 17.6688 26.2263 18.3796 25.4245 18.9074C21.9684 21.254 15.6625 20.0534 14.6438 14.1537C14.1484 11.9257 14.7101 9.59376 16.1657 7.8357C17.5514 6.09361 19.7302 5.17909 21.9441 5.41034C23.4728 5.48169 24.925 6.09917 26.0369 7.15053C27.1952 8.32058 27.8799 9.87772 27.959 11.5222C28.0015 12.14 28.0015 12.7599 27.959 13.3776C27.959 13.9415 27.8196 14.0689 27.2678 14.0689H18.5972ZM23.8364 10.8553C23.6727 9.70928 22.5874 8.95742 21.2353 8.99987C20.0226 8.99987 18.9008 9.86087 18.8099 10.8553H23.8364Z" fill="#272727" fillOpacity="0.4" />
    <path className="icon-switch-dark" fillRule="evenodd" clipRule="evenodd" d="M45.0324 20.0411C41.1466 20.1155 37.9357 17.0267 37.8594 13.141V12.6741C37.983 8.80193 41.1637 5.73025 45.0378 5.74173C48.9119 5.75322 52.0744 8.8437 52.175 12.7165C52.3186 16.5771 49.3166 19.8285 45.4568 19.9926L45.0324 20.0411ZM48.1671 12.6862C48.1186 12.3224 48.1065 11.9465 48.0276 11.5887C47.7664 10.2772 46.6767 9.29174 45.3456 9.16324C44.0146 9.03474 42.7565 9.79356 42.2492 11.0309C41.8931 11.9106 41.8231 12.8801 42.0491 13.8019C42.2084 14.9106 42.9661 15.8411 44.0195 16.2219C45.0729 16.6026 46.2504 16.3715 47.0817 15.6209C47.8717 14.8591 48.2713 13.7787 48.1671 12.6862Z" fill="#272727" fillOpacity="0.4" />
    <path className="icon-switch-dark" fillRule="evenodd" clipRule="evenodd" d="M13.0909 7.35656V13.3775C13.2609 15.7525 12.1296 18.0329 10.1356 19.3343C8.14173 20.6358 5.59887 20.7535 3.49319 19.642C1.38751 18.5304 0.0502477 16.3644 0 13.9838C0 13.6443 0.0606339 13.517 0.418374 13.523H4.46872C4.687 13.523 4.80827 13.5655 4.88103 13.808C5.03284 14.7161 5.88743 15.3327 6.79706 15.1905C7.67428 15.0481 8.29622 14.2575 8.22802 13.3714V0.723215C8.22307 0.641772 8.25115 0.56177 8.30592 0.501293C8.36069 0.440815 8.43753 0.404969 8.51906 0.401855H12.7998C13.1333 0.401855 13.0909 0.608011 13.0909 0.820229V7.35656Z" fill="#272727" fillOpacity="0.4" />
    <path className="icon-switch-dark" fillRule="evenodd" clipRule="evenodd" d="M36.9069 15.9849V19.0166C36.9314 19.2259 36.7963 19.4208 36.5916 19.4713C35.2744 19.9562 33.8237 19.9324 32.5231 19.4046C31.1438 18.8611 30.2438 17.5219 30.2614 16.0394C30.2008 14.0567 30.2614 12.074 30.2614 10.0852C30.2614 9.51524 30.2614 9.51524 29.6915 9.47886C28.6667 9.47886 28.6667 9.47886 28.6667 8.42989V6.27739C28.6667 5.96815 28.7516 5.81657 29.0912 5.84082C29.3374 5.85901 29.5847 5.85901 29.8309 5.84082C30.1947 5.84082 30.2796 5.71349 30.2735 5.38607V2.35437C30.2735 2.02089 30.3584 1.89356 30.7101 1.89962H33.9116C34.2208 1.89962 34.3118 2.01482 34.3057 2.31193V5.45883C34.3057 5.74987 34.3906 5.84689 34.6816 5.84082H36.6462C36.8948 5.84082 36.9918 5.91965 36.9857 6.17431V9.206C36.9857 9.46673 36.8887 9.53343 36.6462 9.53343H34.6877C34.4027 9.53343 34.3118 9.63651 34.3118 9.92148V15.1603C34.3118 15.809 34.5543 16.088 35.197 16.1122C35.7689 16.1023 36.3398 16.0598 36.9069 15.9849Z" fill="#272727" fillOpacity="0.4" />
  </svg>
);

IconJeton.propTypes = {
  className: PropTypes.string,
};

IconJeton.defaultProps = {
  className: '',
};
