import React from 'react';
import PropTypes from 'prop-types';

export const IconLogo = ({ className }) => (
  <svg className={className} width="223" height="68" viewBox="0 0 223 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#logo-clip)">
      <path fillRule="evenodd" clipRule="evenodd" d="M58.4935 43.0138L58.6041 33.6873C63.0099 32.9399 67.155 34.2681 67.4302 38.3272C67.7522 43.077 62.696 43.4353 58.4935 43.0138ZM58.6806 22.6839C62.7498 22.5828 67.0051 22.8958 66.5896 27.4904C66.2158 31.6142 62.8368 32.1258 58.6112 32.0676L58.6675 25.7224L58.6806 22.6839ZM52.4961 44.5022C56.765 44.4774 64.1795 45.5527 66.909 43.089C70.3318 40.0002 69.5093 36.0829 65.8033 33.0728L65.2701 32.6065C66.3119 31.4291 67.7169 30.4413 68.057 28.0567C69.2346 19.8041 58.414 20.9108 52.7008 21.1268L52.4961 44.5027V44.5022Z" fill="#FFC328" />
      <path fillRule="evenodd" clipRule="evenodd" d="M136.978 36.9463L143.177 37.0159L143.332 45.4472L144.88 45.4905C145.003 42.6481 145.41 23.282 144.849 22.1081L143.339 22.0832L143.209 35.2115L136.971 35.2404L137.029 22.0469L131.019 22.0966L130.971 45.4044L136.906 45.4014L136.978 36.9463Z" fill="#DEDEDD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M175.909 24.3514C179.734 23.6826 183.002 27.7671 182.916 31.3729C182.828 35.0493 179.606 38.1903 175.88 38.1177L175.854 32.0078L175.909 24.3514ZM169.734 45.7652L175.763 45.8552L175.799 39.9076C186.522 39.1806 187.255 26.6056 179.521 23.2098C176.77 22.0021 173.141 22.4539 169.958 22.4017L169.734 45.7652Z" fill="#DEDEDD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M210.684 33.0046L222.556 47.1909C222.588 47.1336 222.689 47.2973 222.83 47.3411L222.998 22.9635L221.435 23.053C220.819 24.3817 221.203 33.7485 221.081 36.151C220.027 35.2454 218.991 33.7132 218.106 32.5975L212.24 25.2341C211.399 24.2065 209.883 22.5609 209.356 21.4373L208.873 33.8032C208.875 37.8032 208.546 42.3201 208.849 46.2722L210.528 46.3001L210.684 33.0041V33.0046Z" fill="#DEDEDD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M76.6385 23.1008L83.6476 23.0705L83.4318 21.3815L70.4912 21.3074L70.4238 44.7117L83.4383 44.756L83.475 43.064L76.3764 42.9496L76.3674 38.3281L81.6736 38.2022L81.6001 36.581L76.4081 36.4914L76.6385 23.1008Z" fill="#FFC328" />
      <path fillRule="evenodd" clipRule="evenodd" d="M155.053 29.5042L158.844 38.7824L151.504 38.7376L154.594 30.0919C154.846 29.6844 154.81 29.7545 155.053 29.5042H155.053ZM146.857 45.5681C149.207 45.6626 148.816 45.6785 149.509 43.6457C149.884 42.548 150.324 41.4353 150.746 40.45L159.52 40.542L161.765 45.6437L167.695 45.7144L156.386 20.6594L146.858 45.5681H146.857Z" fill="#DEDEDD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M128.06 43.5076L128.071 41.2509C122.917 43.5932 123.91 44.9572 115.988 42.8532L116.076 24.2535C123.387 21.954 123.771 23.7365 128.173 26.0021L128.227 23.8285C126.212 22.9278 125.282 21.8495 122.149 21.5608C115.936 20.9886 108.652 25.0761 108.577 33.5063C108.503 41.7336 115.686 45.9166 121.487 45.6354C124.799 45.4752 125.822 44.3874 128.061 43.5081L128.06 43.5076Z" fill="#DEDEDD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M102.766 21.8304C101.324 21.1934 87.3029 21.4423 84.502 21.4766L84.6212 23.1416L90.6588 23.2924L90.5346 44.87C92.4094 44.9033 94.7698 45.2402 96.5309 44.7764L96.7191 23.2775L102.813 23.3223L102.766 21.8299L102.766 21.8304Z" fill="#FFC328" />
      <path fillRule="evenodd" clipRule="evenodd" d="M186.193 45.9778L198.569 46.0788L198.479 44.3376L192.117 44.2724C192.221 41.3474 192.567 24.1674 192.089 22.574L186.254 22.6093L186.193 45.9778Z" fill="#DEDEDD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M200.074 46.1465L206.108 46.1778L206.265 22.7939L200.192 22.7427L200.074 46.1465Z" fill="#DEDEDD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M40.3786 14.5452C40.3786 13.5714 39.9344 10.6841 39.5733 9.82225L38.8031 8.06613L35.7143 3.98013C35.162 3.37651 34.4864 2.94208 33.8032 2.42107L31.2276 1.05907C30.4997 0.699283 29.7431 0.587815 28.987 0.285757L28.8215 0.219573C28.7928 0.210118 28.7627 0.200663 28.733 0.192203L28.2465 0.0986499C27.2817 -0.0401875 23.0093 -0.0456614 22.1259 0.16981C21.5745 0.304169 21.978 0.208625 21.6988 0.322581C21.6002 0.362889 21.3824 0.442509 21.2702 0.473362C20.6872 0.633597 20.1444 0.722672 19.6841 0.973972L18.6372 1.43328C18.5693 1.46115 18.3339 1.57212 18.2499 1.62536L13.9568 5.08286C13.9417 5.10177 13.9221 5.13014 13.9075 5.14905C13.8929 5.16846 13.8753 5.19831 13.8607 5.21772L11.9129 8.2174C11.9079 8.23184 11.8993 8.26468 11.8938 8.27911C11.8883 8.29404 11.8757 8.32539 11.8692 8.34032C11.8576 8.36669 11.8143 8.43935 11.8002 8.46771C11.6388 8.78918 11.6166 8.94692 11.4712 9.30123L11.105 10.1885C11.095 10.2228 11.0658 10.3318 11.0552 10.3686L10.7388 11.9033C10.6392 12.3646 10.6734 12.1173 10.5995 12.3407C10.353 13.0832 10.3821 14.1899 10.3862 15.0045C10.4023 18.1077 10.3867 21.2154 10.3867 24.3191C10.8666 24.4912 10.4143 24.3484 10.685 24.4843C11.433 24.8585 14.2245 24.9729 15.1863 25.0909L16.6663 25.3521C17.2579 25.4213 17.8208 25.3258 18.4566 25.4208L27.1257 25.5845C29.2868 25.5845 31.6069 25.374 33.759 25.3616C34.3103 25.3586 34.6675 25.1839 35.4749 25.1043C35.8144 25.0705 35.8396 25.0307 36.31 25.0103C36.9207 24.9844 37.3779 24.9112 37.9871 24.8291C38.9022 24.7052 40.3776 24.7794 40.3776 23.6298V14.5457L40.3786 14.5452Z" fill="#FFC328" />
      <path fillRule="evenodd" clipRule="evenodd" d="M32.4087 49.9368C32.5204 50.1159 32.6623 50.2503 32.7926 50.4076C33.3349 50.2961 36.0352 48.7574 36.7657 48.3096C38.1637 47.4527 40.0561 47.2874 41.4194 48.2479L41.6971 48.4599C42.3913 49.0057 42.8747 49.8059 43.0654 50.7335C43.1147 50.9729 43.1303 51.1202 43.1424 51.2918L43.1358 51.7954C43.004 52.9753 42.4064 54.189 41.3475 54.8195L40.8238 55.1673C39.8675 55.9899 40.0179 57.8053 41.7861 58.067L41.9109 58.0879C42.1307 58.1058 42.3989 58.0416 42.586 57.9371C44.2159 57.0295 44.2154 56.6075 44.9408 55.977C45.2104 55.7426 45.798 54.5582 45.9786 54.1208C46.0752 53.8874 46.1466 53.6949 46.2085 53.4416C46.4952 52.2737 46.4967 52.1682 46.4665 51.1202C46.4625 50.9793 46.4962 50.8997 46.4746 50.7136C46.3976 50.0503 46.0862 48.9465 45.7839 48.4151C45.5138 47.9398 45.3055 47.5044 44.9252 47.0526L44.6415 46.7102C43.7475 45.6433 42.1981 44.7814 40.8635 44.4868C40.6155 44.4321 40.4234 44.3838 40.1603 44.3534C40.0048 44.3355 39.9535 44.3475 39.8046 44.3281C39.6341 44.3057 39.71 44.2922 39.459 44.2878C37.8503 44.2589 36.5267 44.6127 35.1765 45.4219C35.1051 45.4646 35.0342 45.4985 34.9839 45.5264C34.7087 45.6756 34.4692 45.815 34.2036 45.9827C33.7589 46.2633 31.713 47.3472 31.5078 47.5815C31.5002 47.59 31.4902 47.6024 31.4831 47.6114C31.3845 47.7408 31.3398 47.9667 31.3755 48.1404L32.4092 49.9368H32.4087Z" fill="#FFC328" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1.34687 67.9702C1.4686 67.9459 1.73069 67.7657 1.84137 67.695C1.93242 67.6373 2.029 67.591 2.1105 67.5488L8.04551 64.1544C8.99577 63.5543 10.7162 62.6123 11.8134 61.9679L14.6541 60.3262C14.9771 60.1297 15.2603 59.9689 15.5853 59.7838C15.7568 59.6863 15.8685 59.6141 16.0682 59.5007L22.4615 55.8073C22.551 55.758 22.6023 55.7431 22.7029 55.6888C22.8393 55.6157 22.9746 55.5226 23.1451 55.4251C23.4776 55.235 23.7936 55.0594 24.0778 54.8837C24.2061 54.8046 24.2493 54.7722 24.3323 54.7279C24.4324 54.6747 24.4973 54.6543 24.5758 54.611C25.5734 54.0631 27.4809 52.8748 28.3497 52.4279L31.6397 50.527L30.6119 48.7943C30.2895 48.9381 29.8584 49.1928 29.5495 49.3869C28.3316 50.1513 26.5362 51.0933 25.2182 51.8959L14.806 57.8879C13.9181 58.4477 12.1741 59.3877 11.2278 59.9226L10.1508 60.4616C9.70809 60.6551 9.44852 60.889 9.15172 61.098L0.437851 66.1748C-0.477201 66.9317 0.14608 68.2111 1.34737 67.9702H1.34687Z" fill="#FFC328" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.11133 26.3892C4.11133 26.861 4.24967 27.066 4.45441 27.3143C4.69185 27.6024 4.82868 27.6457 5.22961 27.8124C5.2447 27.8189 5.44945 27.906 5.47208 27.9174L7.00287 28.4728C8.56435 28.9032 9.43664 29.1048 11.1224 29.3416C11.9715 29.4611 11.6823 29.4974 12.1531 29.5875C12.4871 29.6512 12.9852 29.6979 13.2986 29.7183L17.8235 30.0711C18.2491 30.0766 18.6873 30.0562 19.1123 30.0637C19.6028 30.0726 19.7849 30.1821 20.1532 30.1826H30.6151C30.9361 30.1826 31.0352 30.0677 31.3124 30.0677C33.7456 30.0662 36.0813 29.8223 38.4029 29.5561C38.6579 29.5267 38.3833 29.5939 38.7002 29.5213L42.2014 28.8803C43.0129 28.6295 44.5869 28.2369 45.0935 27.9468C45.42 27.7602 45.6795 27.5701 46.0744 27.5377C46.1283 26.9033 46.423 27.1496 46.4236 26.5032C46.4236 25.698 46.5433 25.2586 45.8984 24.8376C45.0034 24.2544 45.2394 24.8356 45.0281 24.3186H44.5638C44.4269 24.654 44.5924 24.3893 43.7911 24.7043L42.3197 25.2029C42.2945 25.2133 42.2653 25.2288 42.2402 25.2392L42.0777 25.3084C41.8131 25.4024 41.5193 25.4407 41.222 25.498C40.5741 25.6239 39.7038 25.6786 39.0684 25.8976C37.8656 26.3116 33.0736 26.4345 32.5484 26.5893C31.6021 26.8679 31.3043 26.6938 30.0673 26.756C27.1672 26.9028 24.5961 26.8485 21.6638 26.8485C21.3207 26.8485 20.8871 26.7296 19.804 26.7341C17.9035 26.741 18.9282 26.5415 17.2521 26.5062C16.2555 26.4853 12.4801 26.1514 11.6994 25.8971L5.97161 24.5485C4.41266 24.5485 4.11183 24.9202 4.11183 26.3882L4.11133 26.3892Z" fill="#FFC328" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.2685 43.092C20.529 43.092 20.4752 42.7844 20.5411 42.557L21.1091 40.8193C21.265 40.3247 21.6026 38.9761 22.1283 38.8378C22.1283 39.5658 22.1916 40.5267 22.0966 41.2214C22.0211 41.7738 21.8958 42.7849 21.8958 43.4373C22.8114 43.4373 23.2033 43.6672 23.8718 43.6672C27.0793 43.6672 26.4646 43.6836 29.2022 43.4204C29.7364 43.3691 30.5851 43.193 31.1384 43.0362L32.7427 42.3236C34.1935 41.3473 33.5601 40.2505 32.7894 38.1804C32.5339 37.4942 32.2698 36.9498 31.812 36.3875C30.3743 34.6204 28.1367 34.8214 25.3825 34.8214C23.1243 34.8214 21.3601 34.5691 19.6849 35.9615C18.8041 36.6935 18.5787 37.3678 18.2029 38.4048C17.9725 39.0408 17.3613 40.4391 17.3613 41.0223C17.3613 42.1659 19.375 43.0925 20.2675 43.092H20.2685Z" fill="#FFC328" />
    </g>
  </svg>
);

IconLogo.propTypes = {
  className: PropTypes.string,
};

IconLogo.defaultProps = {
  className: '',
};
