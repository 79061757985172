import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderWazdan = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path id="icon-producer-wazdan-a" d="M0 0h24v24H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="icon-producer-wazdan-b" fill="#fff">
        <use xlinkHref="#icon-producer-wazdan-a" />
      </mask>
      <path d="M0 0h24v24H0z" />
      <g mask="url(#icon-producer-wazdan-b)">
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M10.241 17.067L.346 7.191a.593.593 0 01.423-1.018h9.895c.327 0 .596.269.596.596v9.876c0 .518-.635.787-1.019.422m2.229-.422V6.769c0-.327.269-.596.596-.596h9.895c.538 0 .807.634.423 1.018l-9.896 9.876c-.384.365-1.018.096-1.018-.422"
        />
      </g>
    </g>
  </svg>
);

IconProviderWazdan.propTypes = {
  className: PropTypes.string,
};
IconProviderWazdan.defaultProps = {
  className: null,
};
