import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconCrypto = ({ className }) => (
  <svg className={classNames('footer-icon icon-crypto', className)} width="32" height="31" viewBox="0 0 32 31" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M24.1078 10.2627C22.3056 7.23251 19.0129 5.38005 15.2489 5.38005C9.55064 5.38005 5.10761 9.76469 5.10761 15.5C5.10761 21.2353 9.55064 26.0612 15.2489 26.0612C19.0129 26.0612 22.3056 23.8558 24.1078 20.8255H29.2726C27.1382 26.4806 21.6162 30.5001 15.2489 30.5001C7.01805 30.5001 0.169189 23.7844 0.169189 15.5C0.169189 7.21565 7.01805 0.499878 15.2489 0.499878C21.6162 0.499878 27.1382 4.60763 29.2726 10.2627H24.1078V10.2627ZM31.8308 13.7353L26.6273 17.4412H20.0991C19.3388 19.4028 17.4355 20.7942 15.2062 20.7942C12.3081 20.7942 9.95871 18.4437 9.95871 15.5441C9.95871 12.6446 12.3081 10.2941 15.2062 10.2941C17.469 10.2941 19.3971 11.727 20.1339 13.7353H31.8308Z" fill="#FF0000" />
  </svg>
);

IconCrypto.propTypes = {
  className: PropTypes.string,
};

IconCrypto.defaultProps = {
  className: '',
};
