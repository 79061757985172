import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { FormattedMessage } from 'react-intl';
import { withUser, withModalActions, withCoreComponent } from 'core/hocs';
import { RecommendedGames as RecommendedGamesCore } from 'core/components';
import { USER_FIELDS } from 'core/constants';
import { Logo } from 'components/logo/logo';
import { IconArrowCircled } from 'components/icons/icon-arrow-circled/icon-arrow-circled';
import { UserTabs } from 'components/user-tabs/user-tabs';
// import { Greeting } from 'components/sidebar/greeting/greeting';
import { SidebarMenu } from 'components/sidebar/sidebar-menu/sidebar-menu';
import { GA } from 'helpers/ga';
import { YM } from 'helpers/ym';

import { MODAL_IDS } from '../../constants';

import './sidebar-mobile.scss';
import { GameSearch } from '../game-search/game-search';

const { IS_USER_LOGGED_IN } = USER_FIELDS;

export class SidebarMobileUI extends Component {
  static propTypes = {
    isUserLoggedIn: PropTypes.bool.isRequired,
    openModalAndCloseOthers: PropTypes.func.isRequired,
    closeSideMenu: PropTypes.func.isRequired,
    pageName: PropTypes.string,
    params: PropTypes.string,
  };

  static defaultProps = {
    pageName: null,
    params: null,
  };

  openSignInModal = () => {
    const { openModalAndCloseOthers } = this.props;
    openModalAndCloseOthers(MODAL_IDS.SIGN_IN);
  };

  openSignUpModal = () => {
    const { openModalAndCloseOthers } = this.props;
    openModalAndCloseOthers(MODAL_IDS.SIGN_UP);

    GA.event({ category: 'signup', action: 'click', label: 'SignFirst' });
    YM.event('SignFirst');
  };

  render() {
    const {
      isUserLoggedIn, pageName, params, closeSideMenu,
    } = this.props;
    // const isSportsbookPage = [PAGE_NAMES.SPORTSBOOK, PAGE_NAMES.IN_PLAY].includes(pageName);

    return (
      <div className={classNames('sidebar-mobile flex-column align-items-center pt-0_75 bg-default position-relative', {
        'pb-14': !isUserLoggedIn,
      })}
      >
        <div className="w-100 d-flex justify-content-between align-items-center pb-1_25 px-1_5">
          <IconArrowCircled
            className="ml-n0_5 cursor-pointer"
            onClick={closeSideMenu}
          />

          <Logo iconClassName="icon-logo" />

          <div
            className="icon-search cursor-pointer"
          />
        </div>

        {/* {!isUserLoggedIn && !isSportsbookPage && ( */}
        {/* <Greeting /> */}
        {/* )} */}

        <div className="mx-1">
          <GameSearch />
        </div>

        <SidebarMenu pageName={pageName} params={params} />

        <UserTabs
          className={classNames('px-1_5', {
            'mt-1 pr-2_25': isUserLoggedIn
          })}
          isUserLoggedIn={isUserLoggedIn}
          isInSidebar
          coreProps={{ size: 99 }}
        />

        {!isUserLoggedIn && (
          <div className="auth-buttons w-100 position-fixed px-2 pt-1_25">
            <Button
              color="primary"
              onClick={this.openSignUpModal}
              className="no-effects"
              block
            >
              <FormattedMessage id="sign-up" />
            </Button>

            <Button
              outline
              color="white-20"
              onClick={this.openSignInModal}
              className="no-effects mt-1_25 mb-2 btn-outline-gray-5"
              block
            >
              <FormattedMessage id="login" />
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export const SidebarMobile = withCoreComponent(
  RecommendedGamesCore,
  withModalActions(
    withUser(SidebarMobileUI, [IS_USER_LOGGED_IN])
  )
);
