import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedTag } from 'core/components';

import './greeting.scss';

export const Greeting = ({ className }) => (
  <div className={classNames('greeting mt-n0_75 mt-md-2_5', className)}>
    <div className="image position-absolute" />

    <FormattedTag
      id="sidebar.not-logged.title" // TODO: change text to 'welcome bonus'
      className="h2 d-block mb-1_25 text-uppercase text-gray-40 title-welcome"
    />

    <FormattedTag
      id="sidebar.not-logged.sub-title"
      className="d-block title-secondary px-2 px-md-0 text-gray-30"
    />
  </div>
);

Greeting.propTypes = {
  className: PropTypes.string,
};

Greeting.defaultProps = {
  className: '',
};
