import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withCoreComponent } from 'core/hocs';
import { ModalWindow as ModalWindowCore } from 'core/components';

import './side-modal.scss';

export class SideModalUI extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    closeWithActions: PropTypes.func,
    children: PropTypes.node.isRequired,
    id: PropTypes.string,
    className: PropTypes.string,
    overlayClassName: PropTypes.string,
    closeClassName: PropTypes.string,
    isOpen: PropTypes.bool,
  };

  static defaultProps = {
    id: null,
    className: '',
    overlayClassName: '',
    closeClassName: '',
    isOpen: false,
    closeWithActions: () => {},
  };

  state = {
    isRendered: false,
    isAnimated: false
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;
    const { isRendered, isAnimated } = this.state;

    if (isOpen && !isRendered) {
      this.setRender(true);

      setTimeout(() => {
        this.setAnimation(true);
        document.body.classList.add('side-modal-open');
      }, 0);
    }

    if (!isOpen && isRendered && isAnimated) {
      this.setAnimation(false);

      setTimeout(() => this.setRender(false), 500);
    }

    if (!isOpen && prevProps.isOpen) {
      document.body.classList.remove('side-modal-open');
    }
  }

  setRender = isRendered => this.setState({ isRendered });

  setAnimation = isAnimated => this.setState({ isAnimated });

  closeSideMenu = () => {
    const { closeModal, id, closeWithActions } = this.props;

    document.body.classList.remove('side-modal-open');

    if (closeWithActions) {
      closeWithActions();
    }

    closeModal(id);
  };

  renderChildren() {
    const { children } = this.props;

    return React.Children.map(children, child => React.cloneElement(child, {
      closeSideMenu: this.closeSideMenu,
    }));
  }

  render() {
    const {
      className,
      overlayClassName,
      closeClassName,
    } = this.props;

    const { isRendered, isAnimated } = this.state;

    if (!isRendered) {
      return null;
    }

    return (
      <>
        <div
          role="presentation"
          onClick={this.closeSideMenu}
          onKeyPress={this.closeSideMenu}
          className={classNames('side-modal-overlay position-fixed', overlayClassName,
            { active: isAnimated })}
        />
        <div className={classNames('side-modal position-fixed', className,
          { active: isAnimated })}
        >
          <button
            type="button"
            onClick={this.closeSideMenu}
            className={classNames('close-button text-light-gray-40', closeClassName)}
          >
            &times;
          </button>

          {this.renderChildren()}
        </div>
      </>
    );
  }
}

export const SideModal = withCoreComponent(ModalWindowCore, SideModalUI);
