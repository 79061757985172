import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderBGAMING = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <g clipPath="url(#bgaming-clip-0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1911 2H4.5V22.929H21.1911V2ZM12.7522 17.2516C13.3324 17.2516 13.7671 17.0861 14.0571 16.7762C14.3261 16.4452 14.4715 15.9902 14.4715 15.3493C14.4715 14.6874 14.3472 14.1708 14.1191 13.8188C13.8704 13.4466 13.477 13.2812 12.939 13.2812H10.8266V17.2516H12.7522ZM12.2139 7.65646H10.8266V11.3789H12.3382C12.8973 11.3579 13.3324 11.1924 13.6224 10.8824C13.9121 10.5721 14.0571 10.1377 14.0571 9.53808C14.0571 8.89685 13.9121 8.42147 13.6014 8.11083C13.291 7.80089 12.8353 7.65646 12.2139 7.65646ZM15.2166 12.2268C15.9624 12.3923 16.5008 12.7645 16.8529 13.3435C17.2049 13.9224 17.3913 14.5839 17.3913 15.3699C17.3913 16.6933 16.9979 17.7067 16.2314 18.4102C15.4653 19.0924 14.3885 19.4439 12.9596 19.4439H8.29991V5.48478H12.4006C13.8501 5.48478 14.9682 5.79507 15.7757 6.43588C16.5838 7.07711 16.9979 8.02864 16.9979 9.2901C16.9979 9.95157 16.8529 10.5516 16.5628 11.0682C16.2525 11.5856 15.8174 11.9575 15.2166 12.2268Z"
        fill="#FACA29"
      />
    </g>
    <defs>
      <clipPath id="bgaming-clip-0">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

IconProviderBGAMING.propTypes = {
  className: PropTypes.string,
};

IconProviderBGAMING.defaultProps = {
  className: '',
};
