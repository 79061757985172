import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconArrowCircled = ({ className, onClick, color }) => (
  <svg className={className} onClick={onClick} width="30" height="31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#icon-arrow-circled)"><path d="M29.42 15.5c0 7.964-6.456 14.42-14.42 14.42C7.036 29.92.58 23.464.58 15.5.58 7.536 7.036 1.08 15 1.08c7.964 0 14.42 6.456 14.42 14.42z" stroke={color} strokeWidth="1.161" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.717 14.512a1.612 1.612 0 00-.025 2.489c1.546 1.29 4.12 3.202 6.534 3.91.893.262 1.661-.529 1.661-1.51v-7.237c0-1.014-.814-1.81-1.727-1.508-2.358.782-4.9 2.618-6.443 3.856z" fill={color} />
    </g>
  </svg>
);

IconArrowCircled.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
};
IconArrowCircled.defaultProps = {
  className: '',
  onClick: null,
  color: colors.gray40,
};
