import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderKironInteractive = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="127"
    height="37"
    viewBox="0 0 127 37"
    fill="none"
  >
    <path d="M113.634 20.4274V6.7907H119.24V26.117C119.062 26.117 118.884 26.117 118.706 26.117C116.036 26.117 113.367 26.117 110.697 26.117C110.341 26.117 109.985 25.9363 109.807 25.6654C106.782 21.4209 103.756 17.2666 100.731 13.0221L100.286 12.3899V26.117H95.2136V6.7907C95.4806 6.7907 95.6585 6.7907 95.8365 6.7907C98.3281 6.7907 100.82 6.7907 103.311 6.7907C103.667 6.7907 104.023 6.97132 104.201 7.24225C107.138 11.4868 110.163 15.641 113.1 19.8856C113.189 20.0662 113.367 20.2468 113.456 20.4274H113.634Z" fill="white" />
    <path d="M81.421 6.97134C83.5566 6.97134 85.6033 6.97134 87.7389 6.97134C88.3618 6.97134 88.8957 7.06165 89.5186 7.24227C91.4763 7.69382 92.9 9.4097 92.9 11.4868C92.989 13.293 92.989 15.1895 92.989 17.086C92.989 18.7116 92.989 20.3372 92.9 21.9627C92.9 23.6786 91.7432 25.1236 90.1415 25.5751C88.8957 26.0267 87.5609 26.2073 86.2261 26.2976C82.0439 26.2976 77.8616 26.2073 73.5903 26.117C72.7894 26.117 71.9886 25.846 71.1877 25.4848C69.497 24.672 68.4292 22.9561 68.5182 21.1499C68.4292 18.1697 68.4292 15.1895 68.5182 12.2093C68.6071 9.59032 70.5648 7.5132 73.1454 7.24227C74.7471 7.06165 76.3488 6.97134 77.9506 6.97134C79.1074 6.97134 80.2642 6.97134 81.421 6.97134ZM80.7091 11.6674H76.7938C75.8149 11.4868 74.8361 12.119 74.5691 13.1124C74.3912 13.5639 74.3022 14.0155 74.3022 14.5573C74.3022 16.0926 74.3022 17.6279 74.3022 19.1631C74.3022 20.5178 74.5691 20.9693 75.9929 21.0596C77.7726 21.1499 79.5523 21.1499 81.243 21.1499C82.4888 21.1499 83.8236 21.1499 85.0694 21.1499C86.4931 21.0596 87.205 20.5178 87.294 19.0728C87.383 17.4473 87.383 15.7314 87.294 14.1058C87.205 12.4802 86.4041 11.7578 84.7134 11.6674C83.4676 11.6674 82.0439 11.6674 80.7091 11.6674Z" fill="white" />
    <path d="M50.3653 20.6984V26.117H44.9373V6.88102C45.1152 6.88102 45.2932 6.88102 45.4712 6.88102C50.4543 6.88102 55.5265 6.88102 60.5096 6.88102C61.6664 6.88102 62.7342 7.06164 63.891 7.24226C65.4038 7.42288 66.5606 8.5969 66.7386 10.1322C66.9165 11.7577 67.0055 13.293 66.8275 14.9186C66.7386 16.7248 65.7597 17.4472 63.98 17.7182C63.802 17.7182 63.5351 17.8085 63.3571 17.8988C63.7131 17.9891 64.158 18.0794 64.5139 18.1697C65.9377 18.531 67.0055 19.7953 67.0055 21.2402C67.0945 22.6852 67.0055 24.0398 67.0055 25.4848V26.2073H61.3105V23.1367C61.4884 22.053 60.7766 21.1499 59.7088 20.9693C59.6198 20.9693 59.5308 20.9693 59.5308 20.9693C58.9079 20.879 58.285 20.7887 57.6621 20.7887C55.3485 20.7887 53.1239 20.7887 50.8103 20.7887C50.6323 20.6081 50.5433 20.6081 50.3653 20.6984ZM49.9204 16.3635H50.3653H59.3528C59.6198 16.3635 59.8867 16.3635 60.0647 16.2732C60.5986 16.2732 61.1325 15.8217 61.2215 15.2798C61.3995 14.467 61.3995 13.7445 61.3995 12.9318C61.3105 11.7577 60.9545 11.4868 59.8867 11.3062C59.5308 11.3062 59.1748 11.2159 58.8189 11.2159H50.4543C50.2763 11.2159 50.0984 11.2159 49.9204 11.2159V16.3635Z" fill="white" />
    <path d="M27.4964 0.0175033C26.6066 1.914 25.7167 3.7202 24.8269 5.52639C21.5344 12.2093 18.242 18.8922 14.9495 25.6654C14.7716 26.0267 14.4156 26.2976 14.0597 26.2073C12.369 26.2073 10.6783 26.2073 8.89856 26.2073C8.98754 25.9363 9.07653 25.7557 9.16552 25.5751C13.2588 17.2666 17.3521 8.95815 21.4454 0.559361C21.6234 0.198123 21.9793 -0.0728063 22.3353 0.0175033C24.026 0.0175033 25.7167 0.0175033 27.4964 0.0175033Z" fill="white" />
    <path d="M18.5979 0.215601C17.708 2.1121 16.8182 3.9183 15.9283 5.72449C12.6359 12.4074 9.34342 19.0903 6.05098 25.8635C5.87301 26.2248 5.51707 26.4957 5.16113 26.4054C3.47041 26.4054 1.7797 26.4054 0 26.4054C0.0889849 26.1344 0.177973 25.9538 0.266957 25.7732C4.36026 17.4647 8.45357 9.15625 12.5469 0.757458C12.7248 0.39622 13.0808 0.125291 13.4367 0.215601C15.1274 0.215601 16.8182 0.215601 18.5979 0.215601Z" fill="#269B44" />
    <path d="M19.8433 18.0794C20.5551 16.6345 21.267 15.2798 21.8899 13.9252C21.9789 13.7446 22.1569 13.5639 22.3348 13.3833C26.6061 11.1256 30.7884 8.86785 35.0597 6.5198C35.1487 6.5198 35.2376 6.42949 35.3266 6.42949C35.3266 8.32599 35.3266 10.2225 35.3266 12.0287C35.3266 12.2093 35.0597 12.3899 34.7927 12.4802C32.3901 13.4736 29.8986 14.467 27.496 15.5508C27.229 15.6411 26.7841 15.7314 26.7841 16.2732C26.7841 16.8151 27.14 16.8151 27.407 16.9054C29.8096 17.8085 32.2122 18.8019 34.6148 19.705C34.9707 19.7953 35.2376 20.1565 35.1487 20.5178C35.1487 22.1434 35.1487 23.8592 35.1487 25.4848C35.1487 25.6654 35.1487 25.846 35.1487 26.117L19.8433 18.0794Z" fill="white" />
    <path d="M42.8019 26.117H36.662V13.9252H42.8019V26.117Z" fill="white" />
    <path d="M36.662 6.70042H42.8019V12.3899H36.662V6.70042Z" fill="white" />
    <path d="M126.358 25.6654H121.286C121.286 23.9495 121.286 22.3239 121.286 20.6984C121.286 20.6081 121.553 20.4274 121.642 20.4274C123.244 20.4274 124.757 20.4274 126.358 20.4274V25.6654Z" fill="#269B44" />
    <path d="M78.3957 32.529C78.3957 31.8065 78.8407 31.1743 79.5525 30.9937C80.6203 30.6325 81.6882 30.6325 82.756 30.9034C83.5568 30.9937 84.0908 31.7162 84.0018 32.4387C84.0018 33.1611 84.0018 33.7933 84.0018 34.5158C84.0018 35.0576 84.0908 35.5995 84.1797 36.1414C83.7348 36.1414 83.1119 36.5026 82.934 35.7801C82.0441 35.9607 81.1543 36.1414 80.2644 36.322C80.0864 36.322 79.9085 36.322 79.7305 36.322C78.7517 36.1414 78.0398 35.5995 78.0398 34.877C78.0398 34.1545 78.6627 33.5224 79.6415 33.3418C80.4424 33.1611 81.3322 33.1611 82.2221 32.9805C82.4 32.9805 82.756 32.7999 82.756 32.7096C82.756 32.4387 82.667 32.1677 82.489 31.8968C81.7772 31.2646 80.1754 31.5356 79.5525 32.258C79.2856 32.4387 78.7517 32.4387 78.3957 32.529ZM82.756 33.6127C82.0441 33.703 81.3322 33.7933 80.6204 33.8836C80.3534 33.8836 79.9975 33.9739 79.7305 34.0642C79.3746 34.1545 79.1966 34.5158 79.2856 34.877C79.3746 35.0576 79.4636 35.2383 79.6415 35.3286C80.3534 35.6898 81.2433 35.6898 81.9551 35.2383C82.578 34.9673 82.934 34.2449 82.756 33.6127Z" fill="#269B44" />
    <path d="M66.5605 33.7933H61.5773C61.4884 34.5158 61.9333 35.1479 62.5562 35.4189C63.357 35.7801 64.2469 35.6898 64.8698 35.0576C65.3147 34.4255 65.7596 34.6061 66.3825 34.6964C66.2046 35.4189 65.6706 35.9607 65.0478 36.1413C63.9799 36.5929 62.8231 36.5929 61.7553 36.1413C60.7765 35.7801 60.1536 34.7867 60.2426 33.703C60.1536 32.6193 60.7765 31.6259 61.7553 31.1743C62.6452 30.8131 63.624 30.7228 64.5138 30.9937C65.8486 31.1743 66.7385 32.4387 66.5605 33.7933ZM65.3147 32.9805C65.3147 32.0774 64.4249 31.4452 63.446 31.4452C62.5562 31.3549 61.7553 31.9871 61.5773 32.9805H65.3147Z" fill="#269B44" />
    <path d="M126.359 33.7029H121.376C121.376 34.5157 121.909 35.1479 122.621 35.3285C123.333 35.6898 124.223 35.5091 124.757 34.9673C125.113 34.5157 125.736 34.3351 126.27 34.5157C126.181 34.9673 125.914 35.4188 125.469 35.6898C124.134 36.4122 122.532 36.4122 121.198 35.5994C120.041 34.6963 119.774 32.9805 120.664 31.8064C120.931 31.4452 121.287 31.1743 121.643 30.9936C122.621 30.5421 123.778 30.4518 124.846 30.9033C125.914 31.4452 126.537 32.6192 126.359 33.7029ZM125.113 32.9805C125.024 32.0774 124.223 31.3549 123.333 31.4452C123.244 31.4452 123.244 31.4452 123.155 31.4452C122.087 31.5355 121.465 32.0774 121.465 32.9805H125.113Z" fill="#269B44" />
    <path d="M49.2087 36.1413H48.0519C48.0519 35.0576 48.0519 34.0642 48.0519 33.0708C48.0519 32.0774 47.696 31.5356 46.7171 31.5356C45.5603 31.5356 44.9374 32.0774 44.9374 33.0708C44.9374 33.6127 44.9374 34.0642 44.9374 34.6061V36.1413H43.8696V30.9034H44.8485L44.9374 31.4452C45.0264 31.4452 45.1154 31.3549 45.1154 31.3549C46.0053 30.6325 47.2511 30.5422 48.3189 30.9937C48.8528 31.1743 49.2087 31.6259 49.2977 32.258C49.2087 33.5224 49.2087 34.7867 49.2087 36.1413Z" fill="#269B44" />
    <path d="M93.167 34.2448L94.1458 34.3352C93.9679 35.6898 92.7221 36.4123 91.0314 36.322C89.6076 36.322 88.3618 35.2383 88.2728 33.703C88.0949 32.258 89.1627 30.9937 90.5864 30.8131C90.5864 30.8131 90.5864 30.8131 90.6754 30.8131C92.3661 30.5422 93.8789 31.2646 94.0569 32.4387L93.078 32.529C92.3661 31.5356 91.5653 31.2646 90.5864 31.6259C89.6076 31.9871 89.3407 32.7096 89.5186 33.9739C89.5186 34.7867 90.1415 35.4189 90.9424 35.5092C91.9212 35.8704 92.9 35.2383 93.167 34.2448Z" fill="#269B44" />
    <path d="M116.481 30.9033C116.125 31.6258 115.769 32.3483 115.502 32.9805C115.058 33.8836 114.613 34.877 114.168 35.7801C113.99 36.2316 113.011 36.3219 112.833 35.8704C112.032 34.2448 111.231 32.5289 110.43 30.9033C111.32 30.5421 111.498 30.6324 111.943 31.4452C112.477 32.6192 112.922 33.7029 113.545 34.9673C114.079 33.7933 114.613 32.6192 115.146 31.5355C115.324 30.7227 115.324 30.7227 116.481 30.9033Z" fill="#269B44" />
    <path d="M98.5059 31.6259L97.7051 31.5356V30.9034L98.5059 30.8131C98.5059 30.4518 98.417 30.0003 98.5949 29.6391C98.7729 29.2778 99.2178 29.1875 99.6627 28.9166V30.8131L100.82 30.9034V31.6259H99.6627C99.6627 32.7999 99.5738 33.9739 99.6627 35.0576C99.6627 35.2383 100.286 35.4189 100.642 35.5995L100.731 35.4189L100.909 36.2317C100.286 36.2317 99.7517 36.1414 99.1288 36.1414C98.6839 36.051 98.417 35.6898 98.417 35.2383C98.417 34.3352 98.417 33.3417 98.417 32.4387L98.5059 31.6259Z" fill="#269B44" />
    <path d="M54.2811 31.5355H53.4802C53.3022 30.9033 53.3022 30.9034 54.1921 30.813C54.1031 29.9099 54.1921 29.1875 55.3489 29.0972V30.813L56.5057 30.9034V31.5355H55.4379C55.4379 32.6192 55.4379 33.7933 55.4379 34.877C55.5269 35.1479 55.7938 35.2382 55.9718 35.3285C56.5057 35.3285 56.6837 35.5995 56.5947 36.1413C56.1498 36.1413 55.7938 36.1413 55.3489 36.1413C54.548 36.051 54.2811 35.6898 54.1921 34.877C54.1921 33.8836 54.2811 32.7999 54.2811 31.5355Z" fill="#269B44" />
    <path d="M72.0781 31.4453C73.0569 30.5422 73.5908 30.5422 74.6586 30.9937L74.3027 31.7162C74.2137 31.7162 74.2137 31.8065 74.1247 31.8065C72.701 31.4453 72.0781 31.8968 72.0781 33.4321C72.0781 34.3352 72.0781 35.2383 72.0781 36.1414H71.0103V30.9034H71.9891L72.0781 31.4453Z" fill="#269B44" />
    <path d="M37.8188 30.9034H38.8867V36.1413H37.8188V30.9034Z" fill="#269B44" />
    <path d="M105.091 30.9034H106.159V36.1413H105.091V30.9034Z" fill="#269B44" />
    <path d="M105.091 28.8263H106.159V29.8197H105.091V28.8263Z" fill="#269B44" />
    <path d="M38.8867 28.8263V29.8197H37.8188V28.8263H38.8867Z" fill="#269B44" />
  </svg>
);

IconProviderKironInteractive.propTypes = {
  className: PropTypes.string,
};

IconProviderKironInteractive.defaultProps = {
  className: '',
};
