import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  injectIntl,
  intlShape,
  // FormattedMessage
} from 'react-intl';
import * as R from 'ramda';
import classNames from 'classnames';

import { withModalActions, withCoreComponent } from 'core/hocs';
import { withPixelRatio } from 'hocs/with-pixel-ratio';
import {
  // addScriptElementToDOM,
  // getEnv,
  // getStaticUrl,
  isEmptyOrNil
} from 'core/helpers';
import { RouteLink, FormattedTag, Producers as ProducersCore } from 'core/components';
import { LangSwitcher } from 'components/lang-switcher/lang-switcher';
import { producerIconsBig } from 'components/games-filters/producer-icons';
// import { IconLineApp } from 'components/icons/icon-line-app/icon-line-app';
// import { FooterLicense } from 'components/footer/footer-license/footer-license';
import { LicenseSeal, LICENSE_TYPES } from 'components/license-seal/license-seal';

import {
  footerMenu,
  footerPaymentIcons,
  // footerIconsWithLink,
  footerInfo,
  getFooterSocialIconsWithLink
} from './footer-menu';
import { FooterCollapse } from './footer-collapse/footer-collapse';
import { MODAL_IDS } from '../../constants';

import './footer.scss';

// const staticUrl = getStaticUrl();

export class FooterUI extends PureComponent {
  static propTypes = {
    openModal: PropTypes.func,
    className: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    intl: intlShape.isRequired,
    // pixelRatio: PropTypes.string.isRequired,
  };

  static defaultProps = {
    openModal: null,
    className: null,
  };

  state = {
    liveChatIncBadgeContent: '',
  };

  // liveChatIncBadgeOriginalRef = React.createRef();

  // componentDidMount() {
  //   this.loadQualityBadgeScript();
  // }

  // loadQualityBadgeScript = () => {
  //   const licenseKey = getEnv('LIVECHATINC_API_LICENSE_KEY');

  //   if (isEmptyOrNil(licenseKey)) {
  //  throw new Error('`LIVECHATINC_API_LICENSE_KEY` env\'s key is REQUIRED for adding the LiveChatInc quality badge');
  //   }

  //   addScriptElementToDOM({
  //     src: `https://cdn.livechatinc.com/qb/qb-${licenseKey}-light-160.js`,
  //     async: true,
  //     attributes: {
  //       'data-script-livechatincqualtybadge': '',
  //       // Disables Cloudflare Rocket Loader script
  //       'data-cfasync': 'false',
  //     },
  //     onLoadEventListener: () => setTimeout(() => this.handleLiveChatBadgeContent(), 1000),
  //     onErrorEventListener: () => console.log('Failed to load live chat quality badge script.'),
  //   });
  // }

  // handleLiveChatBadgeContent = () => {
  //   const { current } = this.liveChatIncBadgeOriginalRef;

  //   if (isEmptyOrNil(current)) {
  //     return;
  //   }

  //   this.setState({ liveChatIncBadgeContent: current?.innerHTML || '' });
  // };

  getLiveChatBadgeElement = (className = '') => {
    const { liveChatIncBadgeContent: content } = this.state;

    if (isEmptyOrNil(content)) {
      return null;
    }

    return (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: content }}
        className={classNames('live-chat-inc-badge-custom', className)}
      />
    );
  };

  openModalAction = (e) => {
    const { openModal } = this.props;

    e.preventDefault();
    openModal(MODAL_IDS.SUPPORT);
  };

  render() {
    const {
      className, intl: { formatMessage }, items: producers
    } = this.props;
    const footerSocialIconsWithLink = getFooterSocialIconsWithLink(formatMessage);
    // const liveChatBadgeElMobile = this.getLiveChatBadgeElement('d-sm-none mt-2_5');
    // const liveChatBadgeElDesktop = this.getLiveChatBadgeElement('d-none d-sm-block mt-2_5');

    return (
      <div className={classNames('footer-wrapper', className)}>
        <div className="footer border-top border-gray-10-20 pt-sm-4_5">
          <div className="footer-top d-flex flex-column flex-sm-row justify-content-sm-between pb-2  mb-1">
            {/* <div ref={this.liveChatIncBadgeOriginalRef} id="lcqualitybadge" className="d-none" /> */}
            <div className="flex-grow-1 d-flex justify-content-center justify-content-sm-between flex-column flex-sm-row font-weight-medium align-items-sm-start">
              {/* <FooterLicense className="d-none d-sm-flex" liveChatQualityBadge={liveChatBadgeElDesktop} /> */}
              <FooterCollapse togglerTitle="menu">
                <div className="d-flex flex-column mx-sm-3 mr-md-5">
                  {footerMenu.map(({
                    title, link, modalId, isExternal,
                  }) => (
                    <FormattedTag
                      tag={isExternal ? 'a' : RouteLink}
                      key={title}
                      to={link || '/'}
                      href={link}
                      onClick={modalId && this.openModalAction}
                      id={title}
                      className="mb-0_5 footer-menu-item"
                    />
                  ))}
                </div>
              </FooterCollapse>
              <FooterCollapse customClass="d-none d-sm-flex d-lg-none" togglerTitle="info">
                <div className="footer-info d-flex flex-column d-lg-none mx-sm-2_5">
                  {footerInfo.map(column =>
                    column.map(({
                      title, link, modalId, isExternal,
                    }) => (
                      <FormattedTag
                        tag={isExternal ? 'a' : RouteLink}
                        key={title}
                        to={link || '/'}
                        href={link}
                        onClick={modalId && this.openModalAction}
                        id={title}
                        className="font-weight-normal mb-0_5"
                      />
                    )))}
                </div>
              </FooterCollapse>
              {footerInfo.map((column, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`key-${idx}`} className="flex-column d-none d-lg-flex mr-sm-2_5">
                  {column.map(({
                    title, link, modalId, isExternal,
                  }) => (
                    <FormattedTag
                      tag={isExternal ? 'a' : RouteLink}
                      key={title}
                      to={link || '/'}
                      href={link}
                      onClick={modalId && this.openModalAction}
                      id={title}
                      className="font-weight-normal text-gray-40 mb-0_5"
                    />
                  ))}
                </div>
              ))}
              <div className="d-flex justify-content-center d-sm-block flex-column align-items-center">
                <div className="license-seal-wrap d-flex">
                  <LicenseSeal licenseType={LICENSE_TYPES.PAGCOR} />
                  <div className="d-flex flex-column ml-1_5 ml-md-2_5">
                    <FormattedTag
                      id="footer.seo.text"
                      className="license-seal-text text-gray-40"
                    />
                  </div>
                </div>

                <LangSwitcher withText className="mb-2_5 mt-5 ml-8 mt-sm-4" />
                <div className="footer-social-links flex-grow-1 d-flex align-items-center justify-content-center ml-8 mb-3 mb-sm-0 justify-content-sm-start align-items-sm-start">
                  {!R.all(R.propEq('link', 'none'), footerSocialIconsWithLink)
                  && footerSocialIconsWithLink.map(({ id, Icon, link }) => link !== 'none' && (
                    <a
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={id}
                      className="d-inline footer-social-link text-center d-sm-flex align-items-center mb-sm-0_5 mr-3 mr-sm-0"
                    >
                      <Icon className="footer-social-icon" />
                      {/* <span className="d-sm-inline ml-1 text-capitalize font-weight-normal">{id}</span> */}
                    </a>
                  ))}
                </div>
              </div>
              {/* <div className="d-flex flex-column footer-license-wrapper"> */}
              {/* <LangSwitcher withText className="d-none d-sm-block mb-sm-2_5" /> */}

              {/* <div className="d-flex justify-content-center d-sm-block"> */}
              {/* <div */}
              {/* onClick={this.openModalAction} */}
              {/* onKeyPress={this.openModalAction} */}
              {/* className="support-button" */}
              {/* role="button" */}
              {/* tabIndex={0} */}
              {/* > */}

              {/* DESKTOP */}
              {/* <img */}
              {/* src={`${staticUrl}/images/support/image${pixelRatio}.png`} */}
              {/* className="cursor-pointer w-100 d-none d-md-block" */}
              {/* alt="support" */}
              {/* /> */}

              {/* MOBILE */}
              {/* <img */}
              {/* src={`${staticUrl}/images/support/image-m${pixelRatio}.png`} */}
              {/* className="cursor-pointer w-100 d-block d-md-none" */}
              {/* alt="support" */}
              {/* /> */}
              {/* </div> */}
              {/* </div> */}

              {/* eslint-disable-next-line max-len */}
              {/* <div className="flex-grow-1 d-flex align-items-center justify-content-start mb-3 mb-sm-0 mx-auto mx-sm-0 flex-sm-column justify-content-sm-start align-items-sm-start"> */}
              {/* {!R.all(R.propEq('link', 'none'), footerSocialIconsWithLink) */}
              {/* && footerSocialIconsWithLink.map(({ id, Icon, link }) => link !== 'none' && ( */}
              {/* <a */}
              {/* href={link} */}
              {/* target="_blank" */}
              {/* rel="noopener noreferrer" */}
              {/* key={id} */}
              {/* className="d-inline footer-social-link text-center d-sm-flex align-items-center mb-sm-0_5" */}
              {/* > */}
              {/* <Icon className="mx-1_5 mx-sm-0 footer-social-icon" /> */}
              {/* <span className="d-none d-sm-inline ml-1 text-capitalize font-weight-normal">{id}</span> */}
              {/* </a> */}
              {/* ))} */}

              {/* <a */}
              {/* href={formatMessage({ id: 'footer.social.url.line', defaultMessage: 'none' })} */}
              {/* className="d-inline text-center d-sm-flex align-items-center mb-sm-0_5" */}
              {/* > */}
              {/* <IconLineApp className="mr-1" /> */}
              {/* <FormattedMessage id="footer.social.line" /> */}
              {/* </a> */}

              {/* </div> */}
              {/* <FooterLicense className="d-sm-none mb-2" liveChatQualityBadge={liveChatBadgeElMobile} /> */}
            </div>
          </div>
          <div className="footer-icons footer-producers d-flex flex-wrap justify-content-center align-items-baseline mt-0_5 pb-2">
            {!isEmptyOrNil(producers) && R.pluck('slug', producers).map(
              (producer) => {
                const Icon = producerIconsBig[producer];

                return (
                  <div
                    key={producer}
                    className={classNames('footer-producer-icons-container flex-grow-1 text-center mx-1 mx-md-2 my-1_5', producer === '1x2-gaming' ? 'gaming-1x2' : producer)}
                  >
                    {!!Icon && <Icon className="p-0_25 p-md-0 footer-producer" />}
                  </div>
                );
              }
            )}
          </div>
          {/* eslint-disable-next-line max-len */}
          <div className="footer-icons footer-payment-icons d-flex flex-wrap justify-content-center align-items-baseline mt-0_5 mb-3">
            {footerPaymentIcons.map(({ title, icon: Icon }) => (
              <span key={title} className="d-inline footer-icons-container text-center mx-1 mx-md-2 mt-0_5">
                <Icon className="p-0_25 p-md-0" />
              </span>
            ))}
            {/* {footerIconsWithLink.map(({ title, link, Icon }) => ( */}
            {/* <a href={link}  */}
            {/* target="_blank"  */}
            {/* rel="noopener noreferrer"  */}
            {/* key={title}  */}
            {/* className="d-inline footer-icons-container text-center mx-1 mx-md-2 mt-0_5"> */}
            {/* <Icon className="p-0_25 p-md-0" /> */}
            {/* </a> */}
            {/* ))} */}
          </div>
        </div>
      </div>
    );
  }
}

export const Footer = withModalActions(
  withPixelRatio(
    withCoreComponent(ProducersCore, injectIntl(FooterUI))
  )
);
