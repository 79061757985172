import React, { Component, Fragment } from 'react';
import * as R from 'ramda';
import Button from 'reactstrap/lib/Button';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import colors from 'customizations/js/color-variables';
import { FormattedMessage } from 'react-intl';
import { parse as parseQueryString } from 'qs';
import { withRouter } from 'react-router-dom';
import { Deposit as DepositCore, FormattedTag } from 'core/components';
import {
  withCoreComponent, withModalActions, withUser, withGlobalEvents, withUserAgent,
} from 'core/hocs';
import { USER_FIELDS, GLOBAL_EVENTS } from 'core/constants';
import { isEmptyOrNil, isPaymentMethodIFrame } from 'core/helpers';
import { PaymentsPreloader } from 'components/payments-preloader/payments-preloader';
import { DepositOptions } from './deposit-options/deposit-options';
import { MODAL_IDS } from '../../constants';
import { GA } from '../../helpers/ga';
import { IconRedirect } from '../icons/icon-redirect/icon-redirect';
import { IconSuccess } from '../icons/icon-notification/icon-success';
import { withModalAlignment } from '../../hocs/with-modal-alignment';
import { YM } from '../../helpers/ym';

import './deposit.scss';

const STEPS = {
  START: 0,
  FINISH: 1,
  PAYMENT_REDIRECT: 2,
  PAYMENT_NO_REDIRECT: 3,
};

const PAYMENTS_WRAPPER_CLASS = 'payments-wrapper';

const mediaQueryString = '(max-width: 719px)';

export class DepositUI extends Component {
  state = {
    step: STEPS.START,
    depositNewIsAvailable: true,
    depositMethod: null,
    isMobileSize: false,
    isMounted: false,
    redirectUrl: '',
    isUserChangeMethod: false,
    paymentId: '',
  };

  static getDerivedStateFromProps(props, prevState) {
    const {
      location: { search },
      setActiveDepositMethod,
      isModal,
    } = props;

    const queryParams = parseQueryString(search, { ignoreQueryPrefix: true });

    if (Object.keys(queryParams).includes('payment_id')) {
      GA.event({ category: 'deposit', action: 'click', label: 'DepComplete' });
      YM.event('DepComplete');
    }

    const {
      activeDepositMethod,
      items,
    } = props;

    const {
      depositMethod,
      step,
      isUserChangeMethod,
    } = prevState;

    if (isEmptyOrNil(items)) {
      return null;
    }

    if (items.lastRecentlyMethod) {
      if (!isUserChangeMethod && !activeDepositMethod && isModal) {
        setActiveDepositMethod(items.lastRecentlyMethod);
      }
    }

    if (depositMethod !== activeDepositMethod && step !== STEPS.PAYMENT_REDIRECT) {
      const isActiveDepositMethodEmpty = isEmptyOrNil(activeDepositMethod);

      return {
        depositMethod: activeDepositMethod,
        ...(isActiveDepositMethodEmpty ? {
          step: STEPS.START,
          depositNewIsAvailable: true,
        } : {
          step: STEPS.FINISH,
        }),
      };
    }

    return null;
  }

  componentDidMount() {
    const mql = window.matchMedia(mediaQueryString);

    this.setState({ isMounted: true });

    mql.addListener(this.updateDeviceType);
    this.updateDeviceType();

    const { globalEvents } = this.props;

    globalEvents.on(GLOBAL_EVENTS.PAYMENT_REDIRECT, this.goToPaymentRedirect);
    globalEvents.on(GLOBAL_EVENTS.PAYMENT_NO_REDIRECT, this.goToPaymentNoRedirect);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isMobileSize: prevIsMobileSize, isMounted: prevIsMounted
    } = prevState;

    const {
      isMobileSize, step, isMounted
    } = this.state;

    const { isModal, isInProgress: prevIsInProgress } = prevProps;

    const { isInProgress, updateModalAlignment } = this.props;

    if (isModal && (!isInProgress && isInProgress !== prevIsInProgress) || (isMounted && isMounted !== prevIsMounted)) {
      updateModalAlignment();
    }

    if (isMobileSize !== prevIsMobileSize || (isMobileSize && step === STEPS.START)) {
      this.applyMobileDesign(step);
    }
  }

  componentWillUnmount() {
    const mql = window.matchMedia(mediaQueryString);
    mql.removeListener(this.updateDeviceType);
    const { globalEvents } = this.props;

    globalEvents.off(GLOBAL_EVENTS.PAYMENT_REDIRECT, this.goToPaymentRedirect);
    globalEvents.off(GLOBAL_EVENTS.PAYMENT_NO_REDIRECT, this.goToPaymentNoRedirect);
  }

  goToPaymentRedirect = ({ payload: { url } }) => {
    const { isPwa, activeDepositMethod } = this.props;
    const isPaymentMethodInIFrame = isPaymentMethodIFrame(activeDepositMethod);

    if ((!isPaymentMethodInIFrame && isPwa) || !isPwa) {
      this.setState({ step: STEPS.PAYMENT_REDIRECT, redirectUrl: url });
    }
  };

  goToPaymentNoRedirect = ({ payload: { paymentId } }) => {
    this.setState({ step: STEPS.PAYMENT_NO_REDIRECT, paymentId });
  };

  goToFinish = () => {
    this.setState({ step: STEPS.FINISH });

    this.applyMobileDesign(STEPS.FINISH);
  };

  goToStart = () => {
    const { setActiveDepositMethod } = this.props;

    this.setState({
      step: STEPS.START,
      isUserChangeMethod: true,
    });

    setActiveDepositMethod(null);
  };

  handleDepositOptions = (event) => {
    const { step } = this.state;
    const { setActiveDepositMethod, isSignUpCompleted, openModal } = this.props;

    if (!isSignUpCompleted) {
      openModal(MODAL_IDS.COMPLETE_SIGN_UP);

      return;
    }

    if (step === STEPS.START) {
      this.goToFinish();
    } else {
      this.toggleDepositNew();
    }

    const item = event.currentTarget;

    setActiveDepositMethod(item.dataset.name);
  };

  toggleDepositNew = () => {
    this.setState(prevState => ({
      depositNewIsAvailable: !prevState.depositNewIsAvailable,
    }));
  };

  updateDeviceType = (e) => {
    const isMobile = e ? e.target.matches : window.matchMedia(mediaQueryString).matches;

    this.setState({ isMobileSize: isMobile });
  };

  applyMobileDesign = (step) => {
    const { isMobileSize } = this.state;
    const { isModal } = this.props;
    const bodyClassesList = document.body.classList;

    if (isModal) {
      return;
    }

    if (step === STEPS.START || !isMobileSize) {
      if (bodyClassesList.contains(PAYMENTS_WRAPPER_CLASS)) {
        bodyClassesList.remove(PAYMENTS_WRAPPER_CLASS);
      }

      return;
    }

    if (isMobileSize) {
      if (bodyClassesList.contains(PAYMENTS_WRAPPER_CLASS)) {
        return;
      }

      bodyClassesList.add(PAYMENTS_WRAPPER_CLASS);
    }
  };

  handleModalClose = () => {
    const { closeModal } = this.props;

    closeModal(MODAL_IDS.DEPOSIT);
  };

  render() {
    const {
      step,
      isMounted,
      redirectUrl,
      paymentId,
    } = this.state;

    const {
      items,
      currency,
      isModal,
      activeDepositMethod,
      isInProgress,
      isTabs,
      isPwa,
    } = this.props;

    return (
      <div
        className={classNames('deposit d-flex flex-column mt-sm-3_25', { 'mt-md-0': isModal })}
      >
        {(isInProgress || !isMounted)
          ? <PaymentsPreloader />
          : R.includes(step, [STEPS.START, STEPS.FINISH]) && !isEmptyOrNil(items) && (
            <DepositOptions
              className={classNames({ 'deposit-options-bg pt-2 py-sm-3': isModal }, { 'deposit-options-bg pt-1_5 pt-sm-2_5 pb-3': !isModal })}
              items={items}
              isModal={isModal}
              activeDepositMethod={activeDepositMethod}
              toggleDepositNew={this.toggleDepositNew}
              handleDepositOptions={this.handleDepositOptions}
              currency={currency}
              handleModalClose={this.handleModalClose}
              isTabs={isTabs}
            />
          )}

        {step === STEPS.PAYMENT_REDIRECT && (
          <Fragment>
            <div
              className={classNames('d-flex flex-column align-items-center justify-content-center card-width text-center rounded py-2_5 mx-2 mx-sm-0 mt-13 mt-sm-0 px-2 py-sm-6 px-sm-3 py-md-6 bg-black', {
                'bg-transparent ': isModal,
                'redirect-step': !isModal
              })}
            >
              <IconRedirect color={colors.primary} />
              <FormattedTag className="h4 font-weight-bold text-center mt-4" tag="div" id="profile.deposit-redirect.message" />
              <Button
                tag="a"
                color="primary"
                href={redirectUrl}
                className="mt-6 w-100"
                target={isPwa ? '_self' : '_blank'}
                onClick={isModal ? this.handleModalClose : this.goToStart}
              >
                <FormattedMessage id="continue" />
              </Button>
            </div>
          </Fragment>
        )}

        {step === STEPS.PAYMENT_NO_REDIRECT && (
          <div className="d-flex flex-column align-items-center justify-content-center rounded mx-2 mx-sm-0 mt-2 mt-sm-0 pt-2 px-2 pb-5 px-sm-3 pt-sm-3 bg-white no-redirect-step">
            <IconSuccess color={colors.primary} />
            <FormattedTag
              tag="div"
              id="confirmed"
              className="text-large text-capitalize font-weight-bold mt-3 mb-2"
            />

            <FormattedTag
              tag="div"
              id="payment.no-redirect.ref-number"
              values={{ paymentId }}
              className="text-center mb-1"
            />

            <FormattedTag
              tag="div"
              id="payment.no-redirect.support"
              className="text-center mb-5"
            />

            <FormattedTag
              tag={Button}
              color="primary"
              id="continue"
              className="w-100"
              onClick={this.goToStart}
            />
          </div>
        )}

      </div>
    );
  }
}

DepositUI.propTypes = {
  isTabs: PropTypes.bool,
  isInProgress: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
  setActiveDepositMethod: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  activeDepositMethod: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  items: PropTypes.shape({
    recently: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        paymentMethod: PropTypes.string,
        minimumDeposit: PropTypes.number,
        feeRate: PropTypes.number,
        conversionRequired: PropTypes.bool,
        maximumDeposit: PropTypes.number,
        popupRequired: PropTypes.bool,
        method: PropTypes.string,
      }),
    ),
    other: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  currency: PropTypes.string.isRequired,
  isModal: PropTypes.bool,
  isSignUpCompleted: PropTypes.bool.isRequired,
  globalEvents: PropTypes.shape({
    on: PropTypes.func,
    off: PropTypes.func,
  }).isRequired,
  isPwa: PropTypes.bool.isRequired,
  updateModalAlignment: PropTypes.func,
};

DepositUI.defaultProps = {
  isTabs: false,
  activeDepositMethod: '',
  items: {
    recently: [],
    other: [],
  },
  isModal: false,
  updateModalAlignment: null,
};

export const Deposit = withCoreComponent(DepositCore, withModalActions(
  withUser(withRouter(withGlobalEvents(withUserAgent(withModalAlignment(DepositUI)))),
    [USER_FIELDS.IS_SIGN_UP_COMPLETED])
));
