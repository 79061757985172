import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconClose = ({ className, color }) => (
  <svg className={className} width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity=".4" r="10" transform="matrix(-1 0 0 1 14 14.5)" fill="#C7D0E2" />
    <path d="M17 11.5l-6 6M11 11.5l6 6" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconClose.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconClose.defaultProps = {
  className: null,
  color: colors.gray80,
};
