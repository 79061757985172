import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderGameArt = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <rect x="0.500244" width="24" height="24" fill="#D8D8D8" fillOpacity="0.01" />
    <path d="M0.499756 12.7357V18H22.0345L24.4998 17.9949V7.47657V7.47143H0.499756V12.7357ZM20.504 12.4327C21.8907 14.4562 23.0617 16.1768 23.1131 16.2589L23.2106 16.4079H22.3632H21.5107L21.0587 15.7402L20.6068 15.0777L17.8591 15.0725H15.1114L14.6748 15.7402L14.2383 16.4079H13.4319C12.9851 16.4079 12.6205 16.3925 12.6205 16.3771C12.6205 16.3617 13.7709 14.6411 15.173 12.5508C17.4533 9.156 17.7358 8.7554 17.8539 8.7554C17.972 8.76054 18.2853 9.19195 20.504 12.4327ZM8.41416 8.90948C9.37457 9.00706 10.2117 9.25358 10.8023 9.60796C11.2594 9.88016 11.4495 10.0394 11.4187 10.1215C11.3724 10.2345 10.6534 11.1179 10.602 11.1179C10.5764 11.1179 10.4634 11.0409 10.3555 10.9433C10.0782 10.707 9.58 10.4708 9.0921 10.3424C8.52715 10.1883 6.94529 10.1472 6.26736 10.2654C4.81904 10.517 4.09488 11.236 4.00757 12.5046C3.90485 13.9375 4.6136 14.8312 6.09274 15.1342C6.51902 15.2215 6.72445 15.2318 7.63864 15.2112C8.79421 15.1855 9.19995 15.1136 9.8368 14.8158L10.1552 14.6668V14.0762V13.4804H8.40902H6.66282V12.8128V12.1451H9.15373H11.6446V13.7064V15.2728L11.4289 15.4475C10.6329 16.0946 9.33862 16.49 7.8184 16.5465C6.44198 16.5979 5.34803 16.4027 4.42871 15.9508C3.797 15.6375 3.35531 15.2831 3.03688 14.8414C2.54384 14.1481 2.36922 13.4907 2.41544 12.5097C2.45653 11.5647 2.73387 10.9022 3.35017 10.2756C4.4133 9.19195 6.26736 8.69377 8.41416 8.90948Z" fill="white" />
    <path d="M16.9398 12.3711C16.457 13.0953 16.0615 13.6962 16.0615 13.7116C16.0615 13.727 16.8576 13.7372 17.8334 13.7372C19.0609 13.7372 19.6053 13.7218 19.6053 13.6807C19.6053 13.6345 17.9772 11.2258 17.8591 11.0974C17.8334 11.0768 17.4225 11.6469 16.9398 12.3711Z" fill="white" />
  </svg>
);

IconProviderGameArt.propTypes = {
  className: PropTypes.string,
};

IconProviderGameArt.defaultProps = {
  className: '',
};
