import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconCheckedFilled = ({ className }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.428571" y="0.428571" width="23.1429" height="23.1429" rx="11.5714" fill={colors.black} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1108 9.55895C18.5328 9.13695 18.5328 8.45277 18.1108 8.03077C17.6888 7.60878 17.0046 7.60878 16.5826 8.03077L10.8628 13.7505L7.84469 10.7322C7.42272 10.3102 6.73853 10.3102 6.31652 10.7321C5.89451 11.1541 5.89449 11.8383 6.31647 12.2603L10.0985 16.0426C10.3936 16.3377 10.8168 16.4264 11.189 16.3089C11.3493 16.2583 11.5002 16.1695 11.6273 16.0424L18.1108 9.55895Z"
      fill={colors.gray30}
    />
    <rect
      x="0.428571"
      y="0.428571"
      width="23.1429"
      height="23.1429"
      rx="11.5714"
      stroke={colors.black}
      strokeWidth="0.857143"
    />
  </svg>
);

IconCheckedFilled.propTypes = {
  className: PropTypes.string,
};

IconCheckedFilled.defaultProps = {
  className: '',
};
