import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderGamzix = ({ className }) => (
  <svg className={className} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="gamzix-rule-1"
      maskUnits="userSpaceOnUse"
      x="1"
      y="9"
      width="23"
      height="6"
    >
      <path d="M23.0315 9H1.5V14.0541H23.0315V9Z" fill="white" />
    </mask>
    <g mask="url(#gamzix-rule-1)">
      <path
        d="M6.20202 11.4956H3.92855V12.3537H5.23506C5.10958 12.6371 4.94719 12.8497 4.74051 12.9756C4.54121 13.1094 4.29024 13.1803 3.99498 13.1803C3.53734 13.1803 3.15351 13.015 2.84349 12.6922C2.53347 12.3694 2.37846 11.9758 2.37846 11.5114C2.37846 11.0626 2.52609 10.6769 2.82872 10.3699C3.13136 10.055 3.50043 9.89751 3.94332 9.89751C4.45263 9.89751 4.9029 10.1101 5.27935 10.543L5.88462 9.90539C5.61151 9.59049 5.32364 9.36219 5.03576 9.22836C4.68884 9.07091 4.31238 8.99219 3.93593 8.99219C3.28637 8.99219 2.74753 9.19687 2.31203 9.61411C2.31203 9.62985 2.31203 9.6456 2.30464 9.65347C2.30464 9.66921 2.29726 9.68496 2.28988 9.69283C2.2825 9.7007 2.26774 9.70858 2.25298 9.70858C2.25298 9.70858 2.25298 9.70858 2.24559 9.70858C2.17916 9.76368 2.12749 9.83453 2.07582 9.90539C2.06844 10.0156 1.9651 10.0943 1.89867 10.1809C1.88391 10.2045 1.87652 10.236 1.86176 10.2596C1.82485 10.3305 1.80271 10.4013 1.78057 10.4801C1.75842 10.5509 1.73628 10.6139 1.71413 10.6848C1.66246 10.8265 1.6477 10.976 1.60341 11.1177C1.58127 11.1965 1.55174 11.2673 1.54436 11.3539C1.53698 11.4326 1.54436 11.5114 1.5296 11.5901C1.5296 11.5979 1.5296 11.5979 1.52222 11.6058C1.59603 12.1175 1.75104 12.6214 2.03891 13.0386C2.29726 13.4165 2.6811 13.6684 3.07969 13.8495C3.49305 14.0305 3.97284 14.062 4.40834 13.9833C4.83646 13.9124 5.24982 13.7235 5.5377 13.3692C5.83295 13.015 6.03225 12.582 6.13559 12.1254C6.13559 12.1175 6.14297 12.1097 6.15035 12.1018C6.15773 12.0939 6.16512 12.086 6.1725 12.086C6.19464 11.9601 6.20202 11.8262 6.20202 11.7003V11.4956Z"
        fill="white"
      />
      <path
        d="M6.18734 12.0073C6.15043 12.3222 6.04709 12.6214 5.89946 12.8969C5.69278 13.2748 5.42705 13.5661 5.10227 13.7629C4.77749 13.9597 4.39366 14.062 3.95816 14.062C3.49313 14.062 3.07239 13.9518 2.70332 13.7393C2.49664 13.6133 2.30472 13.4559 2.14233 13.2748C2.10542 13.2354 2.45235 13.3614 2.99119 13.4086C3.41931 13.448 3.98768 13.4244 4.46009 13.2827C4.93988 13.141 5.39015 12.8418 5.69278 12.5663C6.02495 12.2593 6.18734 11.9837 6.18734 12.0073Z"
        fill="url(#gamzix-rule-2)"
      />
      <path
        d="M3.30104 13.9754C3.06483 13.9282 2.83601 13.8337 2.62933 13.6999C2.35622 13.5188 2.18645 13.3142 2.07573 13.1803C1.79523 12.8497 1.6107 12.4403 1.54427 12.0073C1.47045 11.5665 1.49998 11.1099 1.64761 10.6848C1.74356 10.3935 1.89119 10.118 2.08311 9.8818C2.15692 9.78733 2.2455 9.70073 2.33408 9.62201C2.33408 9.62201 2.2455 10.055 2.17169 10.6139C2.06835 11.3933 2.2455 12.1018 2.2455 12.1018C2.32669 12.4639 2.45956 12.8025 2.64409 13.1252C2.80649 13.4401 3.03531 13.7235 3.30104 13.9754Z"
        fill="url(#gamzix-rule-3)"
      />
      <path
        d="M7.52318 9.70074H8.29823L9.84094 13.9755H9.05113L8.73373 13.0937H7.09506L6.77028 13.9755H5.98047L7.52318 9.70074ZM7.9144 10.8344L7.37555 12.2986H8.44586L7.9144 10.8344Z"
        fill="white"
      />
      <path
        d="M10.8227 9.70074H11.5682L12.4983 12.6844L13.4357 9.70074H14.1812L14.8529 13.9755H14.1148L13.6867 11.2752L12.8378 13.9755H12.1661L11.3246 11.2752L10.8818 13.9755H10.1362L10.8227 9.70074Z"
        fill="white"
      />
      <path
        d="M15.2369 9.70074H17.6654L16.2408 13.1961H17.5768V13.9755H15.1262L16.5434 10.5037H15.2369V9.70074Z"
        fill="white"
      />
      <path d="M18.1892 9.70074H18.9495V13.9755H18.1892V9.70074Z" fill="white" />
      <path
        d="M19.5476 9.70074H20.4038L21.2453 11.0705L22.0868 9.70074H22.943L21.6734 11.7712L23.0242 13.9755H22.168L21.2453 12.4718L20.3226 13.9755H19.459L20.8172 11.7712L19.5476 9.70074Z"
        fill="white"
      />
      <path
        d="M19.23 9.62192C19.2226 9.52745 19.1193 9 18.5583 9C18.0195 9 17.9014 9.62192 17.9014 9.62192C17.953 9.55107 18.1966 9.38575 18.5657 9.38575C18.9348 9.38575 19.1783 9.55107 19.23 9.62192Z"
        fill="#DEA329"
      />
    </g>
    <defs>
      <linearGradient
        id="gamzix-rule-2"
        x1="2.35788"
        y1="13.7303"
        x2="6.34242"
        y2="12.8757"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.01" stopColor="#352B19" />
        <stop offset="1" stopColor="#DEA329" />
      </linearGradient>
      <linearGradient
        id="gamzix-rule-3"
        x1="258.575"
        y1="615.376"
        x2="609.437"
        y2="1341.9"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.01" stopColor="#352B19" />
        <stop offset="1" stopColor="#DEA329" />
      </linearGradient>
    </defs>
  </svg>
);

IconProviderGamzix.propTypes = {
  className: PropTypes.string,
};

IconProviderGamzix.defaultProps = {
  className: '',
};
