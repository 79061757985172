import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderBetsoft = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.5 14.9715V7.45459L4.58093 11.2476L0.5 14.9715Z" fill="#FAE307" />
    <path d="M24.5001 15.0181V7.50043L20.4192 11.2589L24.5001 15.0181Z" fill="#FAE307" />
    <path
      d="M11.5235 11.5857C11.8923 11.0615 12.0808 10.4765 12.0856 9.82825L12.0776 5.26365C12.0832 5.16213 12.0824 5.06813 12.0743 4.9809C11.9959 4.14092 11.6238 3.43405 10.9572 2.86103C10.2907 2.28877 9.50039 2.0015 8.58551 2.0015L5.32886 2L5.3329 11.5857H11.5235ZM9.3564 4.45902C9.57481 4.64777 9.69695 4.8869 9.72445 5.17642C9.72688 5.20575 9.73012 5.23432 9.73254 5.26365L9.74063 9.82825C9.7374 10.1328 9.62253 10.39 9.39523 10.5998C9.16793 10.8096 8.89209 10.9149 8.56772 10.9141L7.64152 10.9134L7.6399 4.17552L8.5661 4.17627C8.8751 4.17627 9.138 4.27027 9.3564 4.45902Z"
      fill="white"
    />
    <path
      d="M12.3024 13.8935C12.2514 13.3475 12.0727 12.8542 11.7669 12.4136H5.33447L5.33852 21.9977L8.82733 21.9992C9.7883 22 10.6077 21.6811 11.2848 21.0442C11.9618 20.4073 12.3048 19.6395 12.3137 18.7408L12.3056 14.1762C12.3113 14.0747 12.3105 13.9807 12.3024 13.8935ZM9.96707 18.7393C9.96465 19.0439 9.84978 19.3011 9.62248 19.5109C9.39518 19.7207 9.12662 19.826 8.81843 19.826L7.66088 19.8252L7.66412 13.0874L8.83137 13.0881C9.12743 13.0881 9.38466 13.1829 9.60468 13.3709C9.82471 13.5596 9.94766 13.7987 9.97516 14.0883C9.97759 14.1176 9.98082 14.1462 9.98325 14.1755L9.96707 18.7393Z"
      fill="white"
    />
    <path
      d="M14.2665 11.5638H18.0683C17.6501 11.2382 17.2295 10.9126 16.8048 10.587C16.0534 9.9493 15.6335 9.1379 15.5454 8.15279C15.5308 7.99337 15.5235 7.82642 15.5235 7.65271L15.5413 5.26137C15.5446 4.95682 15.661 4.69964 15.8891 4.48983C16.1173 4.28002 16.385 4.17475 16.694 4.17475C17.0022 4.17475 17.2659 4.26874 17.4835 4.45674C17.7003 4.64549 17.8224 4.88463 17.8483 5.17414C17.8507 5.20347 17.854 5.23205 17.8564 5.26137L17.8532 6.78266L20.1683 6.78191L20.1715 5.26062C20.178 5.1591 20.1772 5.0651 20.1699 4.97787C20.0947 4.1379 19.7266 3.43102 19.0665 2.85876C18.4057 2.28649 17.6202 2.00073 16.7102 2.00073C15.7532 2.00073 14.9363 2.32033 14.2608 2.95802C13.5846 3.59646 13.2408 4.36425 13.2278 5.26288L13.2214 8.3062C13.2125 8.55285 13.2181 8.79198 13.2392 9.0236C13.3298 10.0358 13.6719 10.8833 14.2665 11.5638Z"
      fill="#FAE307"
    />
    <path
      d="M19.0512 12.3918H15.238C15.6773 12.7324 16.1197 13.0723 16.5654 13.413C17.321 14.0507 17.7052 14.8621 17.7934 15.8472C17.8079 16.0066 17.8031 16.3578 17.8055 16.3473L17.8103 18.7386C17.8063 19.0432 17.689 19.3003 17.4585 19.5102C17.2279 19.7207 16.9569 19.8252 16.6447 19.826C16.3325 19.826 16.0672 19.732 15.8479 19.544C15.6287 19.356 15.5058 19.1169 15.4799 18.8266C15.4775 18.798 15.4742 18.7687 15.4718 18.7394L15.4985 17.2173L13.1834 17.2181L13.1802 18.7401C13.1737 18.8416 13.1745 18.9356 13.1818 19.0229C13.257 19.8636 13.6243 20.5697 14.2851 21.142C14.946 21.7142 15.7306 22 16.6415 22C17.5984 22 18.4146 21.6812 19.0908 21.0427C19.7671 20.405 20.1117 19.6365 20.1238 18.7379L20.1303 15.6945C20.1392 15.4486 20.1327 15.2088 20.1125 14.9771C20.0211 13.9416 19.6668 13.0799 19.0512 12.3918Z"
      fill="#FAE307"
    />
  </svg>
);

IconProviderBetsoft.propTypes = {
  className: PropTypes.string,
};

IconProviderBetsoft.defaultProps = {
  className: '',
};
