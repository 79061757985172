import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderGamomat = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <rect x="0.5" width="24" height="24" fill="#D8D8D8" fillOpacity="0.01" />
    <mask id="gamomat-small-rule-1" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
      <rect x="0.5" width="24" height="24" fill="white" />
    </mask>
    <g mask="url(#gamomat-small-rule-1)">
      <path fillRule="evenodd" clipRule="evenodd" d="M22.2859 9.75667H12.4931V14.1383H16.3304C15.574 15.4975 14.1596 16.4083 12.4931 16.4083C10.0719 16.4083 8.10231 14.4417 8.10231 12.0225C8.10364 10.8606 8.56667 9.74666 9.38982 8.92507C10.213 8.10347 11.329 7.64132 12.4931 7.64C13.1494 7.64 13.8056 7.7925 14.3609 8.04333L16.9358 3.05667C15.5723 2.40333 14.1087 2 12.4931 2C6.99103 2.04917 2.5 6.48 2.5 12.0225C2.5 17.565 6.99103 22 12.4931 22C18.0445 22 22.4863 17.5133 22.4863 12.0225C22.5381 11.2683 22.437 10.5108 22.2859 9.75667Z" fill="#A6CE3D" />
    </g>
  </svg>
);

IconProviderGamomat.propTypes = {
  className: PropTypes.string,
};
IconProviderGamomat.defaultProps = {
  className: null,
};
