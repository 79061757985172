import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderQuickSpin = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5554 4.88973C10.5554 5.71816 9.87194 6.40515 9.04779 6.40515C8.20354 6.40515 7.5402 5.71816 7.5402 4.88973C7.5402 2.04076 9.75133 0 12.8268 0H12.9072C15.9626 0 18.1737 2.06096 18.1737 4.88973C18.1737 5.71816 17.4702 6.40515 16.646 6.40515C15.8219 6.40515 15.1384 5.71816 15.1384 4.88973C15.1384 3.59658 14.0128 3.03083 12.8871 3.03083H12.8067C11.681 3.03083 10.5554 3.59658 10.5554 4.88973ZM16.6661 14.2853C17.4903 14.2853 18.1737 14.9723 18.1737 15.8007C18.1737 17.1545 17.6511 18.3466 16.7666 19.2154L18.0531 21.822C18.3144 22.3271 17.9325 23.0545 17.1888 23.4384C16.4651 23.8223 15.641 23.7011 15.3797 23.1959L14.053 20.5894C13.6912 20.65 13.2891 20.6904 12.8871 20.6904H12.8067C9.75133 20.6904 7.5402 18.6295 7.5402 15.8007C7.5402 14.9521 8.24374 14.2853 9.06789 14.2853C9.89204 14.2853 10.5755 14.9723 10.5755 15.8007C10.5755 17.0939 11.7011 17.6596 12.8268 17.6596H12.9072C14.0329 17.6596 15.1585 17.0939 15.1585 15.8007C15.1585 14.9521 15.842 14.2853 16.6661 14.2853ZM10.5353 14.3055V6.38494C10.1534 6.76885 9.63072 7.01131 9.02769 7.01131C8.42465 7.01131 7.88192 6.74864 7.5 6.34453V14.3257C7.88192 13.9216 8.42465 13.6589 9.02769 13.6589C9.63072 13.6791 10.1534 13.9014 10.5353 14.3055ZM15.1384 14.3257V6.34453C15.5204 6.74864 16.0631 7.01131 16.646 7.01131C17.2491 7.01131 17.7717 6.76885 18.1536 6.38494V14.2853C17.7717 13.9014 17.2491 13.6589 16.6661 13.6589C16.0631 13.6589 15.5204 13.9216 15.1384 14.3257Z"
      fill="#F26822"
    />
  </svg>
);

IconProviderQuickSpin.propTypes = {
  className: PropTypes.string,
};

IconProviderQuickSpin.defaultProps = {
  className: '',
};
