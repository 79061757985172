import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconUser = ({ className, color }) => (
  <svg className={className} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.333 4.667A11.708 11.708 0 0 0 13 1.215a11.707 11.707 0 0 0-8.333 3.452A11.707 11.707 0 0 0 1.215 13c0 3.148 1.226 6.107 3.452 8.333A11.708 11.708 0 0 0 13 24.785c3.148 0 6.107-1.226 8.333-3.452A11.708 11.708 0 0 0 24.785 13c0-3.148-1.226-6.107-3.452-8.333ZM7.123 21.58A5.95 5.95 0 0 1 13 16.65a5.95 5.95 0 0 1 5.877 4.931A10.345 10.345 0 0 1 13 23.404c-2.179 0-4.203-.674-5.877-1.824Zm2.13-10.06A3.751 3.751 0 0 1 13 7.775a3.751 3.751 0 0 1 3.747 3.747A3.751 3.751 0 0 1 13 15.268a3.751 3.751 0 0 1-3.747-3.747Zm10.82 9.102a7.34 7.34 0 0 0-2.145-3.456 7.342 7.342 0 0 0-2.134-1.347 5.128 5.128 0 0 0 2.334-4.298c0-2.828-2.3-5.128-5.128-5.128a5.134 5.134 0 0 0-5.128 5.128c0 1.797.93 3.382 2.334 4.298-.777.32-1.5.772-2.134 1.347a7.342 7.342 0 0 0-2.145 3.456A10.377 10.377 0 0 1 2.597 13C2.596 7.263 7.262 2.596 13 2.596S23.404 7.263 23.404 13c0 3.008-1.284 5.721-3.331 7.622Z" fill={color} stroke={color} strokeWidth=".738" />
  </svg>
);

IconUser.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconUser.defaultProps = {
  className: '',
  color: colors.gray40,
};
