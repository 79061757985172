import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'reactstrap/lib/Button';
import PropTypes from 'prop-types';
import { IconSuccess } from 'components/icons/icon-notification/icon-success';
import { IconError } from 'components/icons/icon-notification/icon-error';
import { FormattedTag } from 'core/components';

import './status-notification-content.scss';

export class StatusNotificationContent extends Component {
  static propTypes = {
    headerIntlKey: PropTypes.string.isRequired,
    descIntlKey: PropTypes.string.isRequired,
    buttonIntlKey: PropTypes.string,
    email: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    withCloseButton: PropTypes.bool,
    // buttonType: PropTypes.string,
    // buttonOutline: PropTypes.bool,
    isSuccess: PropTypes.bool,
  };

  static defaultProps = {
    email: '',
    withCloseButton: false,
    buttonIntlKey: 'got-it',
    // buttonType: 'gray-10',
    // buttonOutline: true,
    isSuccess: true,
  };

  componentDidMount() {
    const { withCloseButton } = this.props;

    if (!withCloseButton) {
      const closeButton = document.querySelector('.modal-body .close-button');

      if (closeButton) {
        closeButton.classList.add('invisible');
      }
    }
  }

  render() {
    const {
      headerIntlKey,
      descIntlKey,
      buttonIntlKey,
      email,
      // buttonType,
      onClick,
      isSuccess,
      // buttonOutline
    } = this.props;

    const StatusIcon = isSuccess ? IconSuccess : IconError;

    return (
      <div className="status-notification-content d-flex flex-column align-items-center text-center">
        <StatusIcon className="status-notification-content-icon mb-2 mb-sm-1_5" />

        <FormattedTag
          id={headerIntlKey}
          className="status-notification-content-header mt-0_5 text-center mb-1 modal-custom-title"
          isHtml
        />

        <div className="status-notification-content-desc text-gray-20 text-center letter-spacing-default mt-0_25 mb-3">
          <FormattedMessage
            id={descIntlKey}
            values={{ email: <span className="text-gray-40">{email}</span> }}
          />
        </div>

        <FormattedTag
          id={buttonIntlKey}
          tag={Button}
          onClick={onClick}
          className="status-notification-content-button mx-auto mb-sm-1"
          color="primary"
        />
      </div>
    );
  }
}
