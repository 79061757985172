import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderGamzixBig = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="103"
    height="24"
    viewBox="0 0 103 24"
    fill="none"
  >
    <mask
      id="gamzix-big-rule-1"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="103"
      height="24"
    >
      <path d="M102.93 0.555664H0.105469V23.4444H102.93V0.555664Z" fill="white" />
    </mask>
    <g mask="url(#gamzix-big-rule-1)">
      <path
        d="M22.5597 11.8574H11.7027V15.7435H17.9419C17.3427 17.027 16.5672 17.9896 15.5802 18.56C14.6284 19.1661 13.4299 19.487 12.0199 19.487C9.83443 19.487 8.00143 18.7383 6.52093 17.2765C5.04043 15.8148 4.30018 14.0322 4.30018 11.9287C4.30018 9.89654 5.00518 8.14958 6.45043 6.75915C7.89568 5.33306 9.65818 4.62002 11.7732 4.62002C14.2054 4.62002 16.3557 5.58263 18.1534 7.5435L21.0439 4.65567C19.7397 3.22958 18.3649 2.19567 16.9902 1.58958C15.3334 0.876541 13.5357 0.52002 11.7379 0.52002C8.63593 0.52002 6.06268 1.44697 3.98293 3.33654C3.98293 3.40784 3.98293 3.47915 3.94768 3.5148C3.94768 3.5861 3.91243 3.65741 3.87718 3.69306C3.84193 3.72871 3.77143 3.76437 3.70093 3.76437C3.70093 3.76437 3.70093 3.76437 3.66568 3.76437C3.34843 4.01393 3.10168 4.3348 2.85493 4.65567C2.81968 5.1548 2.32618 5.51132 2.00893 5.9035C1.93843 6.01045 1.90318 6.15306 1.83268 6.26002C1.65643 6.58089 1.55068 6.90176 1.44493 7.25828C1.33918 7.57915 1.23343 7.86437 1.12768 8.18524C0.880931 8.82698 0.810431 9.50437 0.598931 10.1461C0.493181 10.5026 0.352181 10.8235 0.316931 11.2157C0.281681 11.5722 0.316931 11.9287 0.246431 12.2852C0.246431 12.3209 0.246432 12.3209 0.211182 12.3565C0.563682 14.6739 1.30393 16.9557 2.67868 18.8452C3.91243 20.5565 5.74543 21.6974 7.64893 22.5174C9.62293 23.3374 11.9142 23.48 13.9939 23.1235C16.0384 22.8026 18.0124 21.947 19.3872 20.3426C20.7972 18.7383 21.7489 16.7774 22.2424 14.7096C22.2424 14.6739 22.2777 14.6383 22.3129 14.6026C22.3482 14.567 22.3834 14.5313 22.4187 14.5313C22.5244 13.9609 22.5597 13.3548 22.5597 12.7844V11.8574Z"
        fill="white"
      />
      <path
        d="M22.489 14.1748C22.3127 15.6009 21.8192 16.9557 21.1142 18.2035C20.1272 19.9148 18.8582 21.2339 17.3072 22.1252C15.7562 23.0165 13.9232 23.48 11.8435 23.48C9.62274 23.48 7.61349 22.9809 5.85099 22.0183C4.86399 21.4479 3.94749 20.7348 3.17199 19.9148C2.99574 19.7365 4.65249 20.307 7.22574 20.5209C9.27024 20.6992 11.9845 20.5922 14.2405 19.9505C16.5317 19.3087 18.682 17.9539 20.1272 16.7061C21.7135 15.3157 22.489 14.0678 22.489 14.1748Z"
        fill="url(#gamzix-big-rule-2)"
      />
      <path
        d="M8.70635 23.0878C7.57835 22.8739 6.4856 22.4461 5.4986 21.84C4.19435 21.02 3.3836 20.0931 2.85485 19.487C1.51535 17.9896 0.634098 16.1357 0.316848 14.1748C-0.0356519 12.1783 0.105349 10.1105 0.810349 8.18524C1.2686 6.86611 1.9736 5.61828 2.8901 4.54871C3.2426 4.12089 3.6656 3.72871 4.0886 3.37219C4.0886 3.37219 3.6656 5.33306 3.3131 7.86437C2.8196 11.3939 3.6656 14.6026 3.6656 14.6026C4.05335 16.2426 4.68785 17.7757 5.5691 19.2374C6.3446 20.6635 7.43735 21.947 8.70635 23.0878Z"
        fill="url(#gamzix-big-rule-3)"
      />
      <path
        d="M28.8694 3.7287H32.5707L39.9379 23.0878H36.1662L34.6504 19.0948H26.8249L25.2739 23.0878H21.5022L28.8694 3.7287ZM30.7377 8.86261L28.1644 15.4939H33.2757L30.7377 8.86261Z"
        fill="white"
      />
      <path
        d="M44.6262 3.7287H48.1864L52.6279 17.2409L57.1046 3.7287H60.6649L63.8727 23.0878H60.3477L58.3032 10.8591L54.2494 23.0878H51.0416L47.0232 10.8591L44.9082 23.0878H41.3479L44.6262 3.7287Z"
        fill="white"
      />
      <path
        d="M65.7055 3.7287H77.3028L70.4995 19.5583H76.8798V23.0878H65.1768L71.9448 7.36522H65.7055V3.7287Z"
        fill="white"
      />
      <path d="M79.8057 3.7287H83.4364V23.0878H79.8057V3.7287Z" fill="white" />
      <path
        d="M86.2917 3.7287H90.3806L94.3992 9.93217L98.4177 3.7287H102.507L96.4437 13.1052L102.894 23.0878H98.8054L94.3992 16.2783L89.9929 23.0878H85.8687L92.3547 13.1052L86.2917 3.7287Z"
        fill="white"
      />
      <path
        d="M84.7759 3.37219C84.7407 2.94436 84.2472 0.555664 81.5682 0.555664C78.9949 0.555664 78.4309 3.37219 78.4309 3.37219C78.6777 3.05132 79.8409 2.30262 81.6034 2.30262C83.3659 2.30262 84.5292 3.05132 84.7759 3.37219Z"
        fill="#DEA329"
      />
    </g>
    <defs>
      <linearGradient
        id="gamzix-big-rule-2"
        x1="4.20136"
        y1="21.9776"
        x2="23.1364"
        y2="17.6954"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.01" stopColor="#352B19" />
        <stop offset="1" stopColor="#DEA329" />
      </linearGradient>
      <linearGradient
        id="gamzix-big-rule-3"
        x1="1227.77"
        y1="2746.69"
        x2="2763.88"
        y2="6100.78"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.01" stopColor="#352B19" />
        <stop offset="1" stopColor="#DEA329" />
      </linearGradient>
    </defs>
  </svg>
);

IconProviderGamzixBig.propTypes = {
  className: PropTypes.string,
};

IconProviderGamzixBig.defaultProps = {
  className: '',
};
