import React from 'react';
import PropTypes from 'prop-types';

export const IconVip = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="icon-filled-path" fillRule="evenodd" clipRule="evenodd" d="M9.99981 0.268768C10.3691 0.268768 10.705 0.482204 10.8619 0.816459L13.3589 6.13566L18.9233 6.99089C19.2761 7.04511 19.5689 7.29197 19.682 7.63046C19.795 7.96894 19.7094 8.34224 19.4601 8.59759L15.4066 12.7491L16.3651 18.6256C16.4238 18.9855 16.2718 19.3475 15.9736 19.5575C15.6754 19.7675 15.2834 19.7888 14.9642 19.6123L9.99981 16.8671L5.03537 19.6123C4.71618 19.7888 4.32422 19.7675 4.02603 19.5575C3.72784 19.3475 3.5758 18.9855 3.63452 18.6256L4.59301 12.7491L0.53953 8.59759C0.290215 8.34224 0.204574 7.96894 0.317654 7.63046C0.430735 7.29197 0.723559 7.04511 1.07629 6.99089L6.64073 6.13566L9.13768 0.816459C9.29459 0.482204 9.63056 0.268768 9.99981 0.268768ZM9.99981 3.46244L8.14928 7.40459C8.01445 7.69181 7.74544 7.89303 7.43183 7.94123L3.19642 8.59219L6.29183 11.7625C6.50309 11.9788 6.59904 12.2827 6.55036 12.5811L5.83024 16.9961L9.53892 14.9453C9.82572 14.7867 10.1739 14.7867 10.4607 14.9453L14.1694 16.9962L13.4493 12.5811C13.4006 12.2827 13.4965 11.9788 13.7078 11.7625L16.8032 8.59219L12.5678 7.94123C12.2542 7.89303 11.9852 7.69181 11.8503 7.40459L9.99981 3.46244Z" fill="#A3ACB3" />
  </svg>
);

IconVip.propTypes = {
  className: PropTypes.string
};

IconVip.defaultProps = {
  className: ''
};
