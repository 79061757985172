import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderGalaxsys = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="20"
    height="20"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.9192 4C23.7216 4 7 10.2298 7 30.5C7 50.7702 23.7216 57 37.9192 57H53.5523V30.4225H43.0776V46.7874H37.9192C24.3999 46.7874 18.153 40.976 18.153 30.4225C18.153 21.6977 24.3211 14.2281 39.7806 14.2281H53.5523V4H37.9192Z"
      fill="white"
      className="icon-switch-dark"
    />
  </svg>
);

IconProviderGalaxsys.propTypes = {
  className: PropTypes.string,
};

IconProviderGalaxsys.defaultProps = {
  className: '',
};
