import React, { Component, createContext } from 'react';

export const DomainBarContext = createContext({});

export const withDomainBar = WrappedComponent => (
  class withDomainBarUI extends Component {
    state = {
      isDomainBarOpen: true,
    }

    closeDomainBar = () => {
      this.setState({ isDomainBarOpen: false });
    }

    render() {
      const { isDomainBarOpen } = this.state;

      return (
        <DomainBarContext.Provider
          value={{
            isDomainBarOpen,
            closeDomainBar: this.closeDomainBar,
          }}
        >
          <WrappedComponent {...this.props} />
        </DomainBarContext.Provider>
      );
    }
  }
);

