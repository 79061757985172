import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderOryxBig = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="141"
    height="24"
    viewBox="0 0 141 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M131.884 16.5955C130.966 15.5537 130.507 14.1276 130.507 12.3171C130.507 10.2585 130.945 8.65862 131.819 7.51777C132.693 6.37625 134.085 5.806 135.995 5.806C137.272 5.806 138.413 5.97332 139.418 6.30797V7.21963C138.376 6.87248 137.272 6.69873 136.106 6.69873C134.445 6.69873 133.263 7.17975 132.563 8.14008C131.862 9.10177 131.512 10.4938 131.512 12.3171C131.512 15.6284 133.049 17.2837 136.125 17.2837C136.695 17.2837 137.266 17.2344 137.837 17.135C138.407 17.036 138.897 16.8997 139.306 16.7257V12.4658H135.902V11.6471H140.237V17.2466C139.84 17.5072 139.294 17.7242 138.599 17.8976C137.905 18.071 137.043 18.1582 136.014 18.1582C134.178 18.1582 132.802 17.6373 131.884 16.5955Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93.2462 5.99188H94.5486L99.0691 17.9719H98.0273L96.7063 14.4747H91.0882L89.7675 17.9719H88.7072L93.2462 5.99188ZM96.4646 13.6377L93.8786 6.81025L91.311 13.6377H96.4646Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.022 5.99188H102.213L106.547 14.8655L110.882 5.99188H112.072V17.9719H111.068V7.47988L106.957 15.8883H106.157L102.027 7.47988V17.9719H101.022V5.99188Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.3082 16.5955C77.3901 15.5537 76.9318 14.1276 76.9318 12.3171C76.9318 10.2585 77.3688 8.65862 78.2433 7.51777C79.1175 6.37625 80.5098 5.806 82.4193 5.806C83.6967 5.806 84.8376 5.97332 85.8422 6.30797V7.21963C84.8007 6.87248 83.6967 6.69873 82.5309 6.69873C80.8691 6.69873 79.6877 7.17975 78.9873 8.14008C78.2866 9.10177 77.936 10.4938 77.936 12.3171C77.936 15.6284 79.4737 17.2837 82.5498 17.2837C83.1197 17.2837 83.6906 17.2344 84.2612 17.135C84.8318 17.036 85.3213 16.8997 85.7306 16.7257V12.4658H82.3267V11.6471H86.6612V17.2466C86.2637 17.5072 85.7185 17.7242 85.0238 17.8976C84.3292 18.071 83.4675 18.1582 82.4382 18.1582C80.6024 18.1582 79.226 17.6373 78.3082 16.5955Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.4357 13.5447L53.1383 5.99179H55.7614L58.7006 11.4057L61.6401 5.99179H64.2446L59.9472 13.5447V17.9722H57.4357V13.5447Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.8719 11.9635L63.6489 5.99188H66.6067L69.3788 9.99143L72.1503 5.99188H75.1084L70.8854 11.9635L75.22 17.9719H72.225L69.3788 13.9724L66.5323 17.9719H63.5377L67.8719 11.9635Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25059 18.6286C2.03155 9.18513 2.54806 5.26028 3.09161 3.97476C1.17025 6.10603 0 8.92789 0 12.0232C0 18.1922 4.64687 23.2755 10.631 23.9654C10.631 22.5261 9.46344 20.5283 8.25059 18.6286Z"
      fill="#2F80F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0231 19.1037C18.9682 9.91673 20.3964 4.55527 20.6073 3.60575C18.4247 1.38085 15.3855 0 12.0231 0C8.66107 0 5.62152 1.38085 3.43921 3.60575C3.65014 4.55527 5.07763 9.91673 12.0231 19.1037Z"
      fill="#2F80F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.954 3.97427C21.4922 5.24458 22.0053 9.09844 15.9945 18.3253C15.9881 18.3357 15.982 18.3466 15.9742 18.358C14.7019 20.3372 13.4211 22.4577 13.4204 23.9649C19.4022 23.2723 24.046 18.1907 24.046 12.0231C24.046 8.92706 22.8757 6.10554 20.954 3.97427Z"
      fill="#2F80F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.8286 12.0378C29.8286 9.8798 30.309 8.30188 31.2706 7.30334C32.2313 6.30515 33.6919 5.80554 35.6518 5.80554C37.6107 5.80554 39.0713 6.30515 40.0327 7.30334C40.9933 8.30188 41.474 9.8798 41.474 12.0378C41.474 16.1181 39.5327 18.1581 35.6518 18.1581C31.7692 18.1581 29.8286 16.1181 29.8286 12.0378ZM38.1259 15.0422C38.6586 14.3911 38.9256 13.3895 38.9256 12.0378C38.9256 10.5619 38.6653 9.51067 38.1444 8.88464C37.6235 8.25861 36.7927 7.94492 35.6518 7.94492C34.5106 7.94492 33.6798 8.25861 33.1589 8.88464C32.638 9.51067 32.377 10.5619 32.377 12.0378C32.377 13.3895 32.6437 14.3911 33.1771 15.0422C33.7102 15.6929 34.5357 16.0191 35.6518 16.0191C36.7673 16.0191 37.5924 15.6929 38.1259 15.0422Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.2227 5.99188H48.9712C50.3108 5.99188 51.3123 6.34546 51.9759 7.05228C52.6388 7.75943 52.9707 8.74512 52.9707 10.01C52.9707 10.8166 52.7632 11.5109 52.3474 12.0936C51.9323 12.6764 51.3458 13.0982 50.5893 13.3585C50.7759 13.5201 50.9273 13.6905 51.0456 13.8703C51.1629 14.0501 51.29 14.3013 51.4269 14.6234L52.8778 17.9719H50.2732L48.8596 14.7539C48.7356 14.4686 48.596 14.2736 48.4411 14.1681C48.286 14.0626 48.0409 14.0099 47.7063 14.0099H45.7345V17.9719H43.2227V5.99188ZM48.3198 11.9266C49.0023 11.9266 49.5232 11.7745 49.8825 11.4703C50.2425 11.1668 50.422 10.68 50.422 10.01C50.422 9.34039 50.2607 8.85059 49.9383 8.54028C49.6158 8.23064 49.126 8.07549 48.4692 8.07549H45.7345V11.9266H48.3198Z"
      fill="white"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M114.863 17.972H115.886V5.99194H114.863V17.972Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.676 5.99188H119.643L127.066 16.4468V5.99188H128.089V17.9719H127.104L119.699 7.536V17.9719H118.676V5.99188Z"
      fill="white"
    />
  </svg>
);

IconProviderOryxBig.propTypes = {
  className: PropTypes.string,
};

IconProviderOryxBig.defaultProps = {
  className: '',
};
