import React from 'react';

export const IconProviderTomHornGaming = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <mask
      id="tom-horn-gaming-rule-1"
      maskUnits="userSpaceOnUse"
      x="2"
      y="2"
      width="19"
      height="17"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.43394 4.88619C1.54078 8.75316 1.51933 15.0438 5.38618 18.937C4.66407 18.21 4.66802 17.0351 5.39499 16.313C6.12183 15.5915 7.29565 15.595 8.01789 16.3209C5.59631 13.8817 5.60999 9.94145 8.04881 7.51922C10.4876 5.09685 14.4279 5.11001 16.8508 7.54803C16.851 7.54816 16.8514 7.54869 16.852 7.54922C17.5739 8.27619 18.7488 8.28014 19.4758 7.55803C20.2028 6.83593 20.2068 5.66119 19.4847 4.93422C17.5426 2.97869 14.9893 2.00001 12.4353 2.00014C9.90394 2.00014 7.37183 2.96158 5.43394 4.88619Z"
        fill="white"
      />
    </mask>
    <g mask="url(#tom-horn-gaming-rule-1)">
      <path
        d="M14.9591 -6.39815L-6.09204 6.75619L6.76717 27.335L27.8184 14.1809L14.9591 -6.39815Z"
        fill="url(#tom-horn-gaming-rule-3)"
      />
    </g>
    <mask
      id="tom-horn-gaming-rule-2"
      maskUnits="userSpaceOnUse"
      x="4"
      y="4"
      width="19"
      height="18"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4758 7.55791C18.7491 8.27962 17.5753 8.27593 16.8531 7.55014C19.2746 9.98935 19.2608 13.9297 16.8221 16.352C14.3833 18.7744 10.443 18.7615 8.02012 16.3232C8.01973 16.3228 8.01946 16.3224 8.01907 16.322C8.01302 16.3159 8.00618 16.3107 7.99999 16.3046C7.96025 16.2657 7.9196 16.2279 7.87736 16.193C7.87618 16.1921 7.87473 16.1913 7.87354 16.1903C7.82631 16.1517 7.77762 16.1157 7.72762 16.0825C7.7246 16.0803 7.72118 16.0791 7.71815 16.0767C7.66973 16.045 7.62052 16.0154 7.56999 15.9887C7.56591 15.9866 7.5617 15.985 7.55749 15.9829C7.02249 15.7049 6.38025 15.7045 5.84512 15.9833C5.82696 15.9928 5.80973 16.0045 5.7917 16.0147C5.70328 16.0646 5.61736 16.1205 5.53631 16.1861C5.48762 16.2255 5.44052 16.2678 5.39499 16.3129C4.66802 17.035 4.66407 18.2099 5.38618 18.9369C9.25289 22.8301 15.5437 22.8515 19.437 18.9846C23.3301 15.1176 23.3516 8.82712 19.4847 4.93396C20.2068 5.66107 20.2028 6.8358 19.4758 7.55791Z"
        fill="white"
      />
    </mask>
    <g mask="url(#tom-horn-gaming-rule-2)">
      <path
        d="M9.91193 31.2496L31.4038 17.8202L18.1039 -3.46393L-3.38794 9.96568L9.91193 31.2496Z"
        fill="url(#tom-horn-gaming-rule-4)"
      />
    </g>
    <defs>
      <linearGradient
        id="tom-horn-gaming-rule-3"
        x1="14.3515"
        y1="-3.26893"
        x2="-2.28212"
        y2="7.23445"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7E3F8" />
        <stop offset="0.28" stopColor="#26B1E7" />
        <stop offset="1" stopColor="#223B86" />
      </linearGradient>
      <linearGradient
        id="tom-horn-gaming-rule-4"
        x1="10.7259"
        y1="27.5232"
        x2="27.388"
        y2="17.0647"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7E3F8" />
        <stop offset="0.28" stopColor="#26B1E7" />
        <stop offset="1" stopColor="#223B86" />
      </linearGradient>
    </defs>
  </svg>
);
