import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderEzugi = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M18.1505 7.97162L17.9962 15.0996C17.9962 15.0996 17.7657 12.2879 18.1505 7.97162Z"
      fill="#272727"
      fillOpacity="0.04"
    />
    <path
      d="M5.55536 5.75086L19.5249 2L19.0163 21.9958L3.5 19.0554L5.55536 5.75086Z"
      fill="url(#ezugi-provider-rule-1)"
    />
    <path
      d="M19.5249 2L20.2806 2.5481L19.7892 21.1183L19.0162 21.9958L19.5249 2Z"
      fill="url(#ezugi-provider-rule-2)"
    />
    <path
      opacity="0.15"
      d="M5.55539 5.75086L5.35331 6.70173C5.35331 6.74602 5.34985 6.79031 5.34985 6.83529C5.34985 12.6256 9.97546 17.3197 16.062 17.3197C17.1041 17.3198 18.1414 17.1787 19.1457 16.9003L19.5249 2L5.55539 5.75086Z"
      fill="#272727"
      fillOpacity="0.04"
    />
    <path
      d="M17.8059 15.8146C17.5554 16.5938 16.7762 18.7654 16.4627 19.4582L4.91113 17.7786L4.95266 17.326C7.07999 17.4644 7.28207 17.3433 7.42601 15.9011L8.13674 8.85124C8.29937 7.25954 8.10629 7.23324 6.18103 7.54743L6.22601 7.07961L17.7132 4.35297C17.644 4.93428 17.5817 6.48861 17.516 7.663L16.7402 7.88238C16.6191 6.96542 16.4156 6.51629 16.1291 6.11283C15.8184 5.6976 15.1264 5.70314 13.2475 6.11767L11.2918 6.54605C10.5014 6.71767 10.4447 6.78618 10.3852 7.33982L9.92013 11.6748L12.2613 11.5073C14.5651 11.3419 14.7132 11.1613 15.1949 9.66162L15.9457 9.57442L15.4343 13.8554H14.7001C14.5527 12.3128 14.39 12.1945 12.1976 12.2976L9.8447 12.4125L9.46269 15.9793C9.36926 16.8637 9.46892 17.2941 9.87791 17.571C10.3063 17.8755 11.1485 17.98 12.361 18.1315C14.2232 18.3675 14.9374 18.2782 15.5547 17.7578C16.0357 17.3426 16.6122 16.5779 17.0772 15.6879L17.8059 15.8146Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="ezugi-provider-rule-1"
        x1="10.0534"
        y1="-4.5131"
        x2="-2.76759"
        y2="13.1459"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#70ABDC" />
        <stop offset="0.17" stopColor="#67A6DA" />
        <stop offset="0.46" stopColor="#4D9AD4" />
        <stop offset="0.6" stopColor="#3E93D0" />
        <stop offset="0.99" stopColor="#1B7CC1" />
      </linearGradient>
      <linearGradient
        id="ezugi-provider-rule-2"
        x1="19.2255"
        y1="-1.22175"
        x2="15.0609"
        y2="-0.406215"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3E93D0" />
        <stop offset="0.99" stopColor="#1B7CC1" />
      </linearGradient>
    </defs>
  </svg>
);

IconProviderEzugi.propTypes = {
  className: PropTypes.string,
};

IconProviderEzugi.defaultProps = {
  className: '',
};
