import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderTomHornGamingBig = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="110"
    height="35"
    viewBox="0 0 110 35"
    fill="none"
  >
    <path
      d="M43.044 19.7352C43.044 20.7831 43.8601 21.6067 44.8743 21.6067C45.097 21.6067 45.3195 21.5568 45.8636 21.3574L46.6304 23.2789C45.8885 23.5785 45.3937 23.6782 44.8743 23.6782C42.7224 23.6782 40.9663 21.931 40.9663 19.7352V6.98204H43.044V10.1765H46.6304V12.2479H43.044V19.7352Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.9985 13.3461V19.7352C47.9985 21.931 49.7549 23.6782 51.9065 23.6782C54.0583 23.6782 55.8145 21.931 55.8145 19.7352V13.3461C55.8145 11.1498 54.0583 9.40303 51.9065 9.40303C49.7547 9.40303 47.9985 11.1498 47.9985 13.3461ZM50.0762 13.3461C50.0762 12.3228 50.9172 11.4742 51.9065 11.4742C52.9207 11.4742 53.7368 12.2979 53.7368 13.3461V19.7352C53.7368 20.7831 52.9207 21.6067 51.9065 21.6067C50.9172 21.6067 50.0762 20.758 50.0762 19.7352V13.3461Z"
      fill="white"
    />
    <path
      d="M67.5714 12.9219C67.5714 12.1482 66.9285 11.4745 66.1368 11.4745C65.3702 11.4745 64.702 12.1482 64.702 12.9219V23.4035H62.6495V12.9219C62.6495 12.1482 62.0063 11.4745 61.2147 11.4745C60.4481 11.4745 59.7801 12.1482 59.7801 12.9219V23.4035H57.7273V12.9219C57.7273 10.9753 59.2856 9.40303 61.2147 9.40303C62.1796 9.40303 63.0452 9.77721 63.6883 10.4261C64.3065 9.77721 65.1724 9.40303 66.1368 9.40303C68.0908 9.40303 69.6493 10.9753 69.6493 12.9219V23.4035H67.5716V12.9219H67.5714Z"
      fill="white"
    />
    <path
      d="M80.706 13.3461C80.706 12.3228 79.8897 11.4991 78.8755 11.4991C77.8862 11.4991 77.0452 12.3228 77.0452 13.3461V23.4035H74.9673V4.38652H77.0452V9.87691C77.5893 9.57757 78.2077 9.40303 78.8755 9.40303C81.0276 9.40303 82.7834 11.1498 82.7834 13.3461V23.4035H80.7058V13.3461H80.706Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.6262 13.3461V19.7352C84.6262 21.931 86.3826 23.6782 88.5342 23.6782C90.6863 23.6782 92.4422 21.931 92.4422 19.7352V13.3461C92.4422 11.1498 90.6863 9.40303 88.5342 9.40303C86.3823 9.40303 84.6262 11.1498 84.6262 13.3461ZM86.7039 13.3461C86.7039 12.3228 87.5449 11.4742 88.5342 11.4742C89.5486 11.4742 90.3649 12.2979 90.3647 13.3461V19.7352C90.3647 20.7831 89.5484 21.6067 88.5342 21.6067C87.5449 21.6067 86.7039 20.758 86.7039 19.7352V13.3461Z"
      fill="white"
    />
    <path
      d="M98.1885 11.4742C97.1992 11.4742 96.3582 12.3228 96.3582 13.3461V23.4035H94.3052V13.3461C94.3052 11.1498 96.0366 9.40303 98.1885 9.40303C99.5489 9.40303 100.835 10.1265 101.676 11.5742L99.9197 12.6223C99.4252 11.8236 98.8565 11.4742 98.1885 11.4742Z"
      fill="white"
    />
    <path
      d="M107.922 13.3461C107.922 12.3228 107.081 11.4742 106.092 11.4742C105.078 11.4742 104.261 12.2979 104.261 13.3461V23.4035H102.183V13.3461C102.183 11.1498 103.939 9.40303 106.092 9.40303C108.244 9.40303 109.975 11.1498 109.975 13.3461V23.4035H107.922V13.3461Z"
      fill="white"
    />
    <path
      d="M58.2666 33.3451V29.7072C58.2666 28.8568 58.9442 28.1732 59.787 28.1732C60.3952 28.1732 60.9424 28.5413 61.2291 29.1987L60.5599 29.488C60.3949 29.0935 60.0996 28.9095 59.7867 28.9095C59.3436 28.9095 58.9875 29.2687 58.9875 29.7072V33.3451C58.9875 33.7833 59.3522 34.1517 59.7867 34.1517C60.221 34.1517 60.586 33.7833 60.586 33.3451V32.5562H59.4305V31.8198H61.3073V33.3451C61.3073 34.1955 60.6295 34.8791 59.787 34.8791C58.9442 34.8791 58.2666 34.1955 58.2666 33.3451Z"
      fill="#21B3E8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.2693 32.7404H66.8593V34.7916H67.5891V29.7247C67.5891 28.8745 66.9113 28.1907 66.0687 28.1907C65.2259 28.1907 64.5483 28.8745 64.5483 29.7247V34.7916H65.2693V32.7404ZM65.2693 29.7247C65.2693 29.2777 65.6342 28.9183 66.0687 28.9183C66.503 28.9183 66.8593 29.2777 66.8593 29.7247V32.0128H65.2693V29.7247Z"
      fill="#21B3E8"
    />
    <path
      d="M74.0877 30.4786L73.0799 33.4066H72.6803L71.6809 30.4786V34.7914H70.96V28.2519H71.6896L72.8799 31.7584L74.079 28.2519H74.8088V34.7914H74.0877V30.4786Z"
      fill="#21B3E8"
    />
    <rect x="78.7095" y="28.2521" width="0.721162" height="6.53947" fill="#21B3E8" />
    <path
      d="M84.0004 30.3998V34.7914H83.2708V28.2521H83.983L85.5903 32.6612V28.2521H86.3115V34.7914H85.599L84.0004 30.3998Z"
      fill="#21B3E8"
    />
    <path
      d="M89.6475 33.3451V29.7072C89.6475 28.8568 90.325 28.1732 91.1678 28.1732C91.776 28.1732 92.3235 28.5413 92.6099 29.1987L91.9408 29.488C91.7758 29.0935 91.4805 28.9095 91.1676 28.9095C90.7244 28.9095 90.3684 29.2687 90.3684 29.7072V33.3451C90.3684 33.7833 90.7331 34.1517 91.1676 34.1517C91.6019 34.1517 91.967 33.7833 91.967 33.3451V32.5562H90.8116V31.8198H92.6882V33.3451C92.6882 34.1955 92.0104 34.8791 91.1678 34.8791C90.325 34.8791 89.6475 34.1955 89.6475 33.3451Z"
      fill="#21B3E8"
    />
    <mask
      id="tom-horn-gaming-big-rule-1"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="31"
      height="30"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.08874 5.05082C-1.66371 11.818 -1.70091 22.8267 5.0059 29.6397C3.75345 28.3675 3.7603 26.3115 5.02119 25.0478C6.28185 23.785 8.31777 23.7912 9.57044 25.0616C5.37036 20.793 5.39409 13.8975 9.62407 9.65862C13.8541 5.41947 20.6882 5.4425 24.8906 9.70904C24.891 9.70927 24.8917 9.7102 24.8926 9.71112C26.1448 10.9833 28.1826 10.9902 29.4435 9.72654C30.7044 8.46286 30.7114 6.40707 29.459 5.13487C26.0905 1.7127 21.662 -1.38133e-06 17.2321 0.000228882C12.8417 0.000228882 8.4499 1.68276 5.08874 5.05082Z"
        fill="white"
      />
    </mask>
    <g mask="url(#tom-horn-gaming-big-rule-1)">
      <path
        d="M21.6094 -14.6968L-14.9026 8.32332L7.40094 44.3363L43.913 21.3166L21.6094 -14.6968Z"
        fill="url(#tom-horn-gaming-big-rule-3)"
      />
    </g>
    <mask
      id="tom-horn-gaming-big-rule-2"
      maskUnits="userSpaceOnUse"
      x="4"
      y="5"
      width="31"
      height="30"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4435 9.72632C28.1831 10.9893 26.1471 10.9829 24.8947 9.71274C29.0946 13.9814 29.0706 20.877 24.8408 25.116C20.6109 29.3551 13.7767 29.3325 9.57434 25.0655C9.57366 25.0648 9.5732 25.0642 9.57252 25.0635C9.56202 25.0529 9.55015 25.0437 9.53943 25.0331C9.47051 24.9649 9.39999 24.8988 9.32673 24.8378C9.32468 24.8362 9.32217 24.8348 9.32011 24.833C9.23818 24.7655 9.15374 24.7024 9.06702 24.6444C9.06177 24.6405 9.05584 24.6384 9.05059 24.6343C8.96661 24.5788 8.88125 24.5269 8.79362 24.4802C8.78654 24.4765 8.77924 24.4738 8.77194 24.4701C7.84401 23.9835 6.73009 23.9828 5.80194 24.4708C5.77044 24.4873 5.74055 24.5078 5.70928 24.5258C5.55592 24.6131 5.4069 24.7109 5.26631 24.8256C5.18187 24.8947 5.10017 24.9686 5.02121 25.0476C3.76032 26.3113 3.75347 28.3673 5.00592 29.6395C11.7125 36.4527 22.6235 36.49 29.3762 29.7231C36.1286 22.9559 36.1658 11.9474 29.459 5.13441C30.7115 6.40685 30.7044 8.46264 29.4435 9.72632Z"
        fill="white"
      />
    </mask>
    <g mask="url(#tom-horn-gaming-big-rule-2)">
      <path
        d="M12.8554 51.1868L50.1317 27.6852L27.0639 -9.56189L-10.2124 13.9399L12.8554 51.1868Z"
        fill="url(#tom-horn-gaming-big-rule-4)"
      />
    </g>
    <defs>
      <linearGradient
        id="tom-horn-gaming-big-rule-3"
        x1="20.5556"
        y1="-9.22065"
        x2="-8.44085"
        y2="8.92644"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7E3F8" />
        <stop offset="0.28" stopColor="#26B1E7" />
        <stop offset="1" stopColor="#223B86" />
      </linearGradient>
      <linearGradient
        id="tom-horn-gaming-big-rule-4"
        x1="14.2672"
        y1="44.6657"
        x2="43.3119"
        y2="26.5968"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7E3F8" />
        <stop offset="0.28" stopColor="#26B1E7" />
        <stop offset="1" stopColor="#223B86" />
      </linearGradient>
    </defs>
  </svg>
);

IconProviderTomHornGamingBig.propTypes = {
  className: PropTypes.string,
};
IconProviderTomHornGamingBig.defaultProps = {
  className: null,
};
