import React from 'react';
import PropTypes from 'prop-types';

import colors from 'customizations/js/color-variables';

export const IconTournaments = ({ className, color }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21 20" fill="none">
    <path
      className="icon-filled-path"
      d="M17.5833 13.3335H14.6667V10.4168C14.6667 8.80847 13.3583 7.50013 11.75 7.50013H9.25C7.64167 7.50013 6.33333 8.80847 6.33333 10.4168V10.8335H3.41667C1.80833 10.8335 0.5 12.1418 0.5 13.7501V17.0835C0.5 18.6918 1.80833 20.0001 3.41667 20.0001H17.5833C19.1917 20.0001 20.5 18.6918 20.5 17.0835V16.2501C20.5 14.6418 19.1917 13.3335 17.5833 13.3335ZM8 10.4168C8 9.72763 8.56083 9.1668 9.25 9.1668H11.75C12.4392 9.1668 13 9.72763 13 10.4168V18.3335H8V10.4168ZM2.16667 17.0835V13.7501C2.16667 13.061 2.7275 12.5001 3.41667 12.5001H6.33333V18.3335H3.41667C2.7275 18.3335 2.16667 17.7726 2.16667 17.0835ZM18.8333 17.0835C18.8333 17.7726 18.2725 18.3335 17.5833 18.3335H14.6667V15.0001H17.5833C18.2725 15.0001 18.8333 15.561 18.8333 16.2501V17.0835ZM7.20083 2.38096C7.28083 2.16263 7.48917 2.0168 7.7225 2.0168H9.39L9.98 0.360965C10.0608 0.143465 10.2683 -0.000701904 10.5008 -0.000701904C10.7333 -0.000701904 10.9408 0.143465 11.0217 0.360965L11.6117 2.0168H13.2792C13.5125 2.0168 13.7208 2.16263 13.8008 2.3818C13.8808 2.60096 13.815 2.8468 13.6367 2.99763L12.3225 4.06846L12.8658 5.7243C12.94 5.94846 12.865 6.19513 12.6775 6.3393C12.49 6.48346 12.2325 6.49346 12.035 6.36513L10.505 5.36846L9.00083 6.37513C8.9075 6.43763 8.79917 6.4693 8.69167 6.4693C8.57417 6.4693 8.45667 6.4318 8.35833 6.35847C8.16917 6.2168 8.09083 5.9718 8.16167 5.7468L8.68417 4.07097L7.36333 2.99596C7.18583 2.84513 7.12 2.5993 7.20083 2.38096Z"
      fill={color}
    />
  </svg>
);

IconTournaments.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconTournaments.defaultProps = {
  className: null,
  color: colors.gray20,
};
