import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderEgt = ({ className }) => (
  <svg className={className} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.29997 12.4C5.29997 10.336 7.26797 7.64798 10.628 6.20798C16.484 3.66398 19.844 6.01598 20.708 8.22398C21.524 10.288 19.892 13.984 17.156 16.672C19.124 15.232 20.756 13.744 21.86 12.256C23.348 10.288 23.972 8.41598 23.396 6.92798C22.58 4.76798 19.268 4.04798 15.14 4.62398C11.012 5.19998 7.84397 6.25598 5.29997 7.59998C2.41997 9.03998 0.97997 10.72 0.73997 12.688C0.45197 15.52 3.52397 19.36 10.484 19.84C14.132 20.08 17.684 20.176 24.452 18.496C23.636 18.544 5.25197 20.848 5.29997 12.4Z"
      fill="url(#egt-paint-0)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.068 15.712C14.228 15.712 16.052 13.936 16.052 11.728C16.052 9.56796 14.276 7.74396 12.068 7.74396C9.90798 7.74396 8.08398 9.51996 8.08398 11.728C8.13198 13.936 9.90798 15.712 12.068 15.712Z"
      fill="#A5CD39"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.068 15.616C14.276 15.616 16.052 13.84 16.052 11.632C16.052 9.42401 14.276 7.64801 12.068 7.64801C9.85998 7.64801 8.08398 9.42401 8.08398 11.632C8.13198 13.84 9.90798 15.616 12.068 15.616Z"
      fill="#A5CD39"
    />
    <mask
      id="egt-mask-0"

      maskUnits="userSpaceOnUse"
      x="8"
      y="7"
      width="9"
      height="9"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.068 15.616C14.276 15.616 16.052 13.84 16.052 11.632C16.052 9.42401 14.276 7.64801 12.068 7.64801C9.85998 7.64801 8.08398 9.42401 8.08398 11.632C8.13198 13.84 9.90798 15.616 12.068 15.616Z"
        fill="white"
      />
    </mask>
    <g mask="url(#egt-mask-0)">
      <path
        opacity="0.36"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.292 13.792C12.836 13.792 14.948 11.68 14.948 9.13598C14.948 6.59198 12.836 4.47998 10.292 4.47998C7.74799 4.47998 5.63599 6.59198 5.63599 9.13598C5.63599 11.68 7.69999 13.792 10.292 13.792Z"
        fill="url(#egt-paint-1)"
      />
      <path
        opacity="0.71"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.172 13.984C15.188 13.984 16.82 12.352 16.82 10.288C16.82 8.22398 15.188 6.59198 13.172 6.59198C11.156 6.59198 9.52399 8.22398 9.52399 10.288C9.52399 12.352 11.156 13.984 13.172 13.984Z"
        fill="url(#egt-paint-2)"
      />
    </g>
    <defs>
      <linearGradient
        id="egt-paint-0"
        x1="6.05085"
        y1="0.506615"
        x2="1.45356"
        y2="19.076"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00A48B" />
        <stop offset="0.9995" stopColor="#A5CD39" />
      </linearGradient>
      <linearGradient
        id="egt-paint-1"
        x1="5.62759"
        y1="13.8002"
        x2="14.9478"
        y2="13.8002"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0092C0" />
        <stop offset="0.9995" stopColor="#A5CD39" />
      </linearGradient>
      <linearGradient
        id="egt-paint-2"
        x1="18.1628"
        y1="11.6219"
        x2="14.4182"
        y2="5.30347"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#91C73E" />
        <stop offset="1" stopColor="#0092C0" />
      </linearGradient>
    </defs>
  </svg>
);

IconProviderEgt.propTypes = {
  className: PropTypes.string,
};

IconProviderEgt.defaultProps = {
  className: '',
};
