import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconPep = ({ className }) => (
  <svg className={classNames('footer-icon', className)} width="31" height="31" viewBox="0 0 31 31" fill="none">
    <g clipPath="url(#icon-pep-clip)">
      <path d="M30.1541 15.4708C30.1541 23.7394 23.5659 30.4434 15.4404 30.4434C7.31372 30.4434 0.726807 23.7399 0.726807 15.4708C0.727222 7.20352 7.31372 0.5 15.4404 0.5C23.5659 0.5 30.1541 7.20352 30.1541 15.4708Z" fill="#00977B" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.33398 15.4707V21.3841H7.7355V15.4707C7.73605 14.904 7.95758 14.3606 8.35145 13.9599C8.74532 13.5592 9.27936 13.3339 9.83632 13.3335C10.5136 13.3335 11.1494 13.6652 11.5435 14.2246H11.6037C11.7518 13.7525 11.9838 13.312 12.288 12.9249C11.6325 12.2737 10.7525 11.9094 9.83632 11.91C7.90244 11.91 6.33398 13.5055 6.33398 15.4707ZM17.8916 12.9249C17.2369 12.2739 16.3578 11.9096 15.4424 11.91C14.7289 11.9096 14.0324 12.131 13.4459 12.5445C12.8595 12.958 12.4112 13.5439 12.161 14.2238H13.7344C13.9294 13.9483 14.186 13.724 14.4829 13.5693C14.7799 13.4146 15.1088 13.3339 15.4424 13.3339C16.1446 13.3339 16.8015 13.691 17.1902 14.2863C17.3373 13.7914 17.5753 13.3295 17.8916 12.9249ZM19.4704 16.8857V18.6524C19.9595 18.9031 20.4995 19.034 21.0472 19.0343C22.9815 19.0343 24.5495 17.4372 24.5495 15.4703C24.5495 13.5055 22.9819 11.9095 21.0472 11.9095C19.1125 11.9095 17.5432 13.505 17.5432 15.4703V21.3295H18.9447V15.4707C18.9452 14.9036 19.1669 14.36 19.5611 13.9592C19.9554 13.5584 20.4899 13.3334 21.0472 13.3335C21.6044 13.3337 22.1387 13.5589 22.5329 13.9596C22.9271 14.3603 23.1489 14.9037 23.1497 15.4707C23.149 15.9049 23.0186 16.3286 22.7757 16.6856C22.5328 17.0426 22.1889 17.316 21.7898 17.4696C21.3906 17.6231 20.955 17.6495 20.5407 17.5452C20.1265 17.4409 19.7532 17.2109 19.4704 16.8857ZM15.442 14.7595H9.8359V16.1835H15.442V14.7595ZM12.2864 18.019C11.9838 17.6306 11.7524 17.1899 11.6033 16.718H11.5431C11.3485 16.9938 11.0922 17.2186 10.7954 17.3735C10.4985 17.5285 10.1696 17.6093 9.8359 17.6091C9.53785 17.609 9.24323 17.5445 8.97154 17.4198C8.69985 17.2951 8.45731 17.113 8.25998 16.8857V18.6524C8.91223 18.9871 9.65102 19.1061 10.373 18.9925C11.0949 18.8789 11.7639 18.5386 12.2864 18.019Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.008 16.8803C16.7994 17.1218 16.5398 17.3123 16.2488 17.4375C15.9577 17.5627 15.6427 17.6195 15.327 17.6035C15.0114 17.5875 14.7034 17.4993 14.426 17.3453C14.1486 17.1913 13.9089 16.9756 13.7249 16.7142H12.1506C12.4007 17.3943 12.849 17.9804 13.4354 18.3941C14.0218 18.8079 14.7184 19.0295 15.432 19.0293C15.9793 19.029 16.5189 18.8988 17.008 18.649V16.8803Z" fill="#E30918" />
    </g>
  </svg>
);

IconPep.propTypes = {
  className: PropTypes.string,
};

IconPep.defaultProps = {
  className: '',
};
