import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderEndorphina = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="endorphina_small">
      <g id="Rectangle">
        <rect x="0.5" width="24" height="24" fill="#D8D8D8" fillOpacity="0.01" />
        <rect x="0.5" width="24" height="24" stroke="#979797" />
      </g>
      <mask
        id="endorphina-mask-0"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24"
      >
        <g id="Rectangle_2">
          <rect x="0.5" width="24" height="24" fill="white" />
          <rect x="0.5" width="24" height="24" stroke="white" />
        </g>
      </mask>
      <g mask="url(#endorphina-mask-0)">
        <g id="Group">
          <path
            id="Path"
            d="M6.8573 7.72277L12.3433 10.2787C12.2143 10.1927 9.60533 8.43569 8.8014 7.04525C7.97471 5.62193 8.03539 4.56772 8.73314 3.5489C9.43089 2.53008 11.1601 1.88794 13.7893 2.05732C16.4185 2.22671 19.3107 3.02305 21.4798 4.97474C21.4798 4.97474 19.6292 1.68569 14.9194 0.76547C10.2095 -0.154755 6.58426 1.70339 4.49353 3.60704C3.93988 4.11266 3.65421 4.593 3.56067 5.05311C3.60365 6.45367 6.8573 7.72277 6.8573 7.72277Z"
            fill="#2F98D5"
          />
          <path
            id="Path_2"
            d="M8.73314 20.4467C8.03286 19.4278 7.97471 18.3736 8.8014 16.9503C9.62808 15.527 12.3483 13.7144 12.3483 13.7144L6.8573 16.2728C6.8573 16.2728 3.60112 17.5419 3.56067 18.9424C3.65421 19.4026 3.93988 19.8829 4.49353 20.3885C6.58426 22.2922 10.207 24.1478 14.9168 23.2276C19.6292 22.3099 21.4798 19.0208 21.4798 19.0208C19.3107 20.9725 16.4185 21.7714 13.7893 21.9382C11.1601 22.1076 9.43089 21.468 8.73314 20.4467Z"
            fill="#F9AE00"
          />
          <path
            id="Path_3"
            d="M6.8573 16.2728L12.3483 13.7144C12.3483 13.7144 13.9081 12.8801 13.9081 12.0534C13.9081 12.0357 13.9081 12.0155 13.9056 11.9978C13.9056 11.9801 13.9081 11.9599 13.9081 11.9422C13.9081 11.1155 12.3483 10.2812 12.3483 10.2812C12.3483 10.2812 12.3458 10.2787 12.3433 10.2787L6.8573 7.7228C6.8573 7.7228 3.60365 6.45623 3.56067 5.05566C3.30028 6.32729 4.50871 7.42701 5.50477 8.36999C6.83708 9.62645 7.42865 10.8753 7.41854 11.9978C7.42865 13.1203 6.83708 14.3691 5.50477 15.6281C4.50871 16.5686 3.30028 17.6683 3.56067 18.9425C3.60112 17.5419 6.8573 16.2728 6.8573 16.2728Z"
            fill="#E40E20"
          />
        </g>
      </g>
    </g>
  </svg>
);

IconProviderEndorphina.propTypes = {
  className: PropTypes.string,
};

IconProviderEndorphina.defaultProps = {
  className: '',
};
