import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderOnlyplay = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#icon-onlyplay-rule-2)">
      <path
        d="M30.1 60C13.4296 60 -0.100006 46.4704 -0.100006 29.8C-0.100006 13.1296 13.4296 -0.399994 30.1 -0.399994C46.7704 -0.399994 60.3 13.1296 60.3 29.8C60.3 46.4704 46.7704 60 30.1 60ZM30.1 7.65334C17.8589 7.65334 7.95333 17.5589 7.95333 29.8C7.95333 42.0411 17.8589 51.9467 30.1 51.9467C42.3411 51.9467 52.2467 42.0411 52.2467 29.8C52.2467 17.5589 42.3411 7.65334 30.1 7.65334Z"
        fill="url(#icon-onlyplay-rule-3)"
      />
      <path
        d="M30.1 39.8667C35.6597 39.8667 40.1667 35.3597 40.1667 29.8C40.1667 24.2403 35.6597 19.7333 30.1 19.7333C24.5403 19.7333 20.0333 24.2403 20.0333 29.8C20.0333 35.3597 24.5403 39.8667 30.1 39.8667Z"
        fill="url(#icon-onlyplay-rule-1)"
      />
    </g>
    <linearGradient
      id="icon-onlyplay-rule-3"
      x1="30.0958"
      y1="-0.374223"
      x2="30.0958"
      y2="60.0262"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#F79F2D" />
      <stop offset="1" stopColor="#FF4D4D" />
    </linearGradient>
    <linearGradient
      id="icon-onlyplay-rule-1"
      x1="30.0958"
      y1="19.7593"
      x2="30.0958"
      y2="39.8926"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#F79F2D" />
      <stop offset="1" stopColor="#FF4D4D" />
    </linearGradient>
    <clipPath id="icon-onlyplay-rule-2">
      <rect width="60" height="60" fill="white" />
    </clipPath>
  </svg>
);

IconProviderOnlyplay.propTypes = {
  className: PropTypes.string,
};

IconProviderOnlyplay.defaultProps = {
  className: '',
};
