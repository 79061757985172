module.exports = {
  email: {
    type: 'text',
    label: 'form.fields.email',
    validation: [
      { type: 'required' },
    ],
  },
  firstName: {
    type: 'text',
    label: 'form.fields.first-name',
    validation: [
      { type: 'required' },
      { type: 'latin-and-turkey-words-with-spaces-and-dashes' },
      { type: 'max-length', value: 70 },
    ],
  },
  lastName: {
    type: 'text',
    label: 'form.fields.last-name',
    validation: [
      { type: 'required' },
      { type: 'latin-and-turkey-words-with-spaces-and-dashes' },
      { type: 'max-length', value: 70 },
    ],
  },
  // fullName: {
  //   type: 'text',
  //   label: 'form.fields.full-name',
  //   placeholder: 'form.fileds.full-name.placeholder',
  //   validation: [
  //     { type: 'required' },
  //     { type: 'max-length', value: 70 },
  //   ],
  // },
  gender: {
    type: 'select',
    label: 'form.fields.gender',
    validation: [
      { type: 'required' },
    ],
  },
  birthDate: {
    type: 'date',
    label: 'form.fields.birth-date',
    placeholder: 'form.fields.birth-date.mask',
    validation: [
      { type: 'required' },
      { type: 'birth-date' },
    ],
  },
  country: {
    type: 'select',
    label: 'form.fields.country',
    isOptionsLocalized: true,
    validation: [
      { type: 'required' },
    ],
  },
  city: {
    type: 'text',
    label: 'form.fields.city',
    validation: [
      { type: 'required' },
      { type: 'latin-and-turkey-words-with-spaces-and-dashes' },
      { type: 'max-length', value: 30 },
    ],
  },
  address: {
    type: 'text',
    label: 'form.fields.address',
    validation: [
      { type: 'required' },
      { type: 'max-length', value: 75 },
    ],
  },
  postalCode: {
    type: 'text',
    label: 'form.fields.postal-code',
    validation: [
      { type: 'required' },
      { type: 'latin-and-number' },
      { type: 'max-length', value: 10 },
    ],
  },
  mobile: {
    type: 'tel',
    label: 'form.fields.mobile',
    placeholder: 'form.fields.mobile',
    validation: [
      { type: 'required' },
    ],
  },
  // accepted: {
  //   type: 'checkbox',
  //   label: 'form.profile-general.data-is-valid',
  //   validation: [
  //     { type: 'required' },
  //   ],
  // },
};
