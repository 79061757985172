import React from 'react';
import PropTypes from 'prop-types';

import colors from '../../../customizations/js/color-variables';

export const IconInfo = ({ color, className }) => (
  <svg className={className} width="18" height="18" viewBox="0 0 18 18">
    <path fill={color} d="M9 0c4.965 0 9 4.035 9 9s-4.035 9-9 9-9-4.035-9-9 4.035-9 9-9zm0 8a1 1 0 0 0-1 1v4a1 1 0 1 0 2 0V9a1 1 0 0 0-1-1zm0-3a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
  </svg>
);

IconInfo.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

IconInfo.defaultProps = {
  color: colors.white40,
  className: '',
};
