import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProvider3oaksGamingBig = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="130"
    height="23"
    viewBox="0 0 130 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#3oaks-gaming-clip-0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.0048 0H45.9158C45.6338 0.240504 45.4044 0.544814 45.2754 0.976741C44.3243 1.02091 43.3684 1.15834 42.4173 1.39885C41.3515 1.6688 40.4243 2.3216 40.4243 3.44558C40.4243 3.80879 40.5199 4.15237 40.6824 4.45177L40.6728 4.21618L40.8162 4.14255C40.9453 4.07383 41.0838 4.01494 41.2177 3.95604C41.5618 3.80879 41.9203 3.68608 42.2787 3.58301C44.3816 2.97439 47.4787 2.97439 49.5816 3.58301C49.9401 3.68608 50.2985 3.8137 50.6427 3.95604C50.7765 4.01003 50.9151 4.07383 51.0441 4.14255L51.1875 4.21618L51.178 4.45177C51.3405 4.15237 51.436 3.80879 51.436 3.44558C51.436 2.3216 50.5088 1.65898 49.443 1.39885C48.4298 1.15344 47.4166 1.00619 46.4033 0.97183C46.6136 0.696969 46.9386 0.520273 47.2636 0.348485C47.4691 0.235595 47.6747 0.127614 47.861 0H52.8555C53.5342 0 54.0886 0.569355 54.0886 1.26632V21.7288C54.0886 22.4257 53.5342 22.9951 52.8555 22.9951H39.0048C38.3261 22.9951 37.7717 22.4257 37.7717 21.7288V1.26632C37.7669 0.569355 38.3213 0 39.0048 0ZM46.9625 9.96371C48.7166 8.93791 49.5195 7.75502 49.6199 5.79663C48.9078 8.19185 47.8658 9.35019 46.9625 9.96371ZM41.0456 6.90098C41.2941 10.3368 44.9074 10.6263 45.9302 12.57C46.953 10.6263 50.5662 10.3318 50.8147 6.90098L50.9294 4.36833C48.4584 3.04802 43.4066 3.04802 40.9356 4.36833L41.0456 6.90098Z"
        fill="url(#3oaks-gaming-paint-0)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1165 0H33.9673C34.6459 0 35.2003 0.569355 35.2003 1.26632V21.7288C35.2003 22.4257 34.6459 22.9951 33.9673 22.9951H20.1165C19.4378 22.9951 18.8834 22.4257 18.8834 21.7288V1.26632C18.8834 0.569355 19.4378 0 20.1165 0ZM28.1507 15.7407C29.9048 14.7149 30.7077 13.532 30.808 11.5736C30.0959 13.9688 29.054 15.1272 28.1507 15.7407ZM22.1621 12.8645C22.4106 16.3003 26.0239 16.5947 27.0467 18.5335C28.0695 16.5898 31.6827 16.2953 31.9312 12.8645L32.0459 10.3318C29.575 9.01151 24.5231 9.01151 22.0522 10.3318L22.1621 12.8645ZM26.3919 6.94025C25.4408 6.98441 24.4849 7.12183 23.5338 7.36236C22.468 7.63232 21.5408 8.28512 21.5408 9.40907C21.5408 9.7723 21.6364 10.1159 21.7989 10.4153L21.7893 10.1797L21.9327 10.1061C22.0618 10.0374 22.2003 9.97843 22.3342 9.91955C22.6783 9.7723 23.0368 9.64959 23.3952 9.54649C25.4981 8.93791 28.5952 8.93791 30.6981 9.54649C31.0566 9.64959 31.4151 9.77718 31.7592 9.91955C31.893 9.97355 32.0316 10.0374 32.1606 10.1061L32.304 10.1797L32.2945 10.4153C32.457 10.1159 32.5526 9.7723 32.5526 9.40907C32.5526 8.28512 31.6253 7.62249 30.5596 7.36236C29.5463 7.11695 28.533 6.96969 27.5199 6.93536C27.7302 6.66045 28.0552 6.48376 28.3802 6.312C29.0971 5.92422 29.7949 5.54631 29.8808 4.46159C28.5522 4.63338 29.2213 4.56957 27.9118 5.39906C27.2808 5.74264 26.6356 6.09115 26.3919 6.94025Z"
        fill="url(#3oaks-gaming-paint-1)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.23309 0H3.22611L3.27389 1.07491C3.52242 4.51067 7.13568 4.80025 8.15848 6.74394C9.18122 4.80025 12.7945 4.50576 13.043 1.07491L13.0908 0H15.0838C15.7625 0 16.3169 0.569355 16.3169 1.26632V21.7288C16.3169 22.4257 15.7625 22.9951 15.0838 22.9951H13.0956L13.1577 21.6355C10.6867 20.3152 5.63492 20.3152 3.16397 21.6355L3.22611 22.9951H1.23309C0.554412 23 0 22.4257 0 21.7288V1.26632C0 0.569355 0.554412 0 1.23309 0ZM11.9437 0C11.2412 2.04674 10.2901 3.08237 9.4537 3.64682C11.0452 2.71425 11.853 1.64917 12.0633 0H11.9437ZM7.50848 18.2488C6.55735 18.293 5.60147 18.4304 4.65036 18.6709C3.58456 18.9409 2.65736 19.5937 2.65736 20.7177C2.65736 21.0809 2.75294 21.4244 2.91544 21.7238L2.90588 21.4883L3.04927 21.4147C3.17831 21.3459 3.31691 21.287 3.45074 21.2281C3.79486 21.0809 4.15331 20.9582 4.51177 20.8551C6.6147 20.2465 9.71175 20.2465 11.8147 20.8551C12.1732 20.9582 12.5316 21.0858 12.8758 21.2281C13.0095 21.2821 13.1482 21.3459 13.2772 21.4147L13.4206 21.4883L13.4111 21.7238C13.5736 21.4244 13.6691 21.0809 13.6691 20.7177C13.6691 19.5937 12.7419 18.9311 11.6761 18.6709C10.6629 18.4255 9.64962 18.2783 8.63637 18.2439C8.84667 17.969 9.17167 17.7923 9.49667 17.6206C10.2136 17.2328 10.9114 16.8549 10.9974 15.7702C9.66872 15.9419 10.3379 15.8782 9.02833 16.7077C8.39742 17.0463 7.7522 17.3948 7.50848 18.2488Z"
        fill="url(#3oaks-gaming-paint-2)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.466 13.2279C70.2431 13.2279 71.9493 11.456 71.9493 9.04607V9.01174C71.9493 6.63613 70.2955 5.72321 68.6038 5.42871L71.7537 2.34143V0.221069H63.337V2.63102H68.231L65.2583 5.69866L65.6834 7.49017H66.8212C68.3507 7.49017 69.2584 8.07426 69.2584 9.11478V9.14916C69.2584 10.1014 68.5273 10.7002 67.4947 10.7002C66.2333 10.7002 65.344 10.1504 64.4886 9.16389L62.6105 11.0192C63.6764 12.3149 65.2249 13.2279 67.466 13.2279Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.8621 13.2277C89.7046 13.2277 92.4962 10.2484 92.4962 6.61141V6.57702C92.4962 2.94003 89.738 0 85.8955 0C82.053 0 79.2615 2.9793 79.2615 6.61141V6.64574C79.2615 10.2877 82.0197 13.2277 85.8621 13.2277ZM85.8955 10.6312C83.6872 10.6312 82.1244 8.80538 82.1244 6.61141V6.57702C82.1244 4.38306 83.6539 2.59646 85.8621 2.59646C88.0704 2.59646 89.6333 4.42232 89.6333 6.6163V6.65068C89.6333 8.84465 88.1038 10.6312 85.8955 10.6312Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.0651 13.0069H95.856L96.9933 10.1405H102.261L103.398 13.0069H106.261L100.932 0.127686H98.4037L93.0651 13.0069ZM97.978 7.6569L99.6318 3.50946L101.286 7.6569H97.978Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.187 13.007H110.93V9.10006L112.369 7.56378L116.23 13.007H119.519L114.218 5.64467L119.289 0.221069H115.982L110.93 5.81158V0.221069H108.187V13.007Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M125.426 13.1933C128.112 13.1933 130 11.7699 130 9.22739V9.19306C130 6.96471 128.576 6.03217 126.052 5.3548C123.901 4.79036 123.366 4.5155 123.366 3.67619V3.63692C123.366 3.01358 123.916 2.52275 124.967 2.52275C126.019 2.52275 127.104 2.99885 128.203 3.78417L129.627 1.66381C128.365 0.623266 126.817 0.0391846 125.001 0.0391846C122.458 0.0391846 120.642 1.57546 120.642 3.89215V3.92651C120.642 6.46405 122.262 7.18068 124.771 7.83836C126.855 8.38812 127.28 8.75129 127.28 9.46302V9.49735C127.28 10.2483 126.607 10.7048 125.483 10.7048C124.059 10.7048 122.888 10.101 121.765 9.15379L120.145 11.1465C121.636 12.5159 123.543 13.1933 125.426 13.1933Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.344 23C66.2954 23 67.0311 22.6172 67.5666 22.1509V19.8342H65.306V20.8502H66.4576V21.611C66.166 21.8269 65.7984 21.93 65.3825 21.93C64.4599 21.93 63.8099 21.2134 63.8099 20.2367V20.2219C63.8099 19.314 64.4696 18.5581 65.3014 18.5581C65.9037 18.5581 66.262 18.7544 66.6584 19.098L67.3803 18.2047C66.8401 17.7335 66.2712 17.4733 65.3394 17.4733C63.7667 17.4733 62.6151 18.7152 62.6151 20.2367V20.2514C62.6105 21.8318 63.7242 23 65.344 23Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.3522 22.9067H76.5182L76.9916 21.7091H79.1901L79.6635 22.9067H80.8583L78.631 17.5273H77.5749L75.3522 22.9067ZM77.4029 20.6735L78.0955 18.9409L78.7886 20.6735H77.4029Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.8061 22.9069H89.9243V19.4417L91.3727 21.7044H91.4061L92.8689 19.422V22.9069H94.0061V17.5667H92.7734L91.4061 19.8245L90.0394 17.5667H88.8061V22.9069Z"
        fill="white"
      />
      <path d="M103.584 17.5667H102.442V22.9069H103.584V17.5667Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.02 22.9069H113.152V19.5104L115.671 22.9069H116.642V17.5667H115.514V20.8552L113.076 17.5667H112.02V22.9069Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.529 23C128.48 23 129.217 22.6172 129.752 22.1509V19.8342H127.491V20.8502H128.643V21.611C128.351 21.8269 127.983 21.93 127.567 21.93C126.645 21.93 125.99 21.2134 125.99 20.2367V20.2219C125.99 19.314 126.65 18.5581 127.482 18.5581C128.084 18.5581 128.442 18.7544 128.839 19.098L129.561 18.2047C129.02 17.7335 128.452 17.4733 127.52 17.4733C125.948 17.4733 124.795 18.7152 124.795 20.2367V20.2514C124.795 21.8318 125.914 23 127.529 23Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="3oaks-gaming-paint-0"
        x1="53.2868"
        y1="0.901543"
        x2="38.215"
        y2="21.4857"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8B900" />
        <stop offset="1" stopColor="#E67508" />
      </linearGradient>
      <linearGradient
        id="3oaks-gaming-paint-1"
        x1="34.4028"
        y1="0.901594"
        x2="19.331"
        y2="21.4857"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8B900" />
        <stop offset="1" stopColor="#E67508" />
      </linearGradient>
      <linearGradient
        id="3oaks-gaming-paint-2"
        x1="15.5188"
        y1="0.901542"
        x2="0.446932"
        y2="21.4857"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8B900" />
        <stop offset="1" stopColor="#E67508" />
      </linearGradient>
      <clipPath id="3oaks-gaming-clip-0">
        <rect width="130" height="23" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconProvider3oaksGamingBig.propTypes = {
  className: PropTypes.string,
};

IconProvider3oaksGamingBig.defaultProps = {
  className: '',
};
