import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconMobileSearch = ({ className, color, onClick }) => (
  <svg className={className} onClick={onClick} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.7416 6.22199C11.7416 8.50963 9.88715 10.3641 7.59951 10.3641C5.31187 10.3641 3.45737 8.50963 3.45737 6.22199C3.45737 3.93435 5.31187 2.07985 7.59951 2.07985C9.88715 2.07985 11.7416 3.93435 11.7416 6.22199ZM13.3131 6.22199C13.3131 9.37751 10.755 11.9356 7.59951 11.9356C6.39679 11.9356 5.28087 11.5639 4.36031 10.9293L2.08425 13.2053C1.77741 13.5122 1.27992 13.5122 0.973081 13.2053C0.66624 12.8985 0.66624 12.401 0.973081 12.0942L3.19981 9.86744C2.37924 8.87819 1.88594 7.6077 1.88594 6.22199C1.88594 3.06647 4.44399 0.508423 7.59951 0.508423C10.755 0.508423 13.3131 3.06647 13.3131 6.22199Z" fill={color} />
  </svg>
);

IconMobileSearch.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

IconMobileSearch.defaultProps = {
  className: null,
  color: colors.gray40,
  onClick: () => {},
};
