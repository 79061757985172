import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Form from 'reactstrap/lib/Form';
import {
  withCoreComponent,
  withLocale,
  withFormDataActions,
  withModalActions,
} from 'core/hocs';
// import { PAYMENT_ITEM_KEYS, FORM_ACTIONS, FORM_FIELDS } from 'core/constants';
import { FORM_ACTIONS, FORM_FIELDS } from 'core/constants';
import { hasActiveBonus } from 'core/helpers';

import { FormProcessWithdrawal as FormProcessWithdrawalCore, FormattedTag } from 'core/components';

import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { DepositSum } from 'components/deposit/deposit-new/deposit-sum/deposit-sum';
import { FormElement } from 'components/form-element/form-element';
import { formatAmountWithCurrency } from '../../../helpers/numbers';
// import { formatAmountWithCurrency, formatFloatAmountWithLocale } from '../../../helpers/numbers';
import { MODAL_IDS } from '../../../constants';

// const {
//   MINIMUM_WITHDRAWAL,
//   MAXIMUM_WITHDRAWAL,
// } = PAYMENT_ITEM_KEYS;

export const FormProcessWithdrawalUI = ({
  locale,
  selectedMethodData,
  fields,
  onSubmit,
  userCurrency,
  isInProgress,
  currentAmount,
  setFormData,
  handleChange,
  activeBonuses,
  setActiveWithdrawalMethod,
  openModal,
  clearFormFieldError,
}) => {
  const activeBonusesItems = activeBonuses?.items;

  const closeWithdrawalForm = useCallback(() => {
    setActiveWithdrawalMethod(null);
    openModal(MODAL_IDS.WITHDRAWAL_ALERT);
  }, [setActiveWithdrawalMethod, openModal]);

  const handleAmount = useCallback((e) => {
    clearFormFieldError(FORM_ACTIONS.PROCESS_WITHDRAWAL, FORM_FIELDS.AMOUNT);
    setFormData(FORM_ACTIONS.PROCESS_WITHDRAWAL, { [FORM_FIELDS.AMOUNT]: e });
  }, [setFormData]);

  const handleClick = useCallback((e) => {
    if (hasActiveBonus(activeBonusesItems)) {
      closeWithdrawalForm();
    } else {
      onSubmit(e);
    }
  }, [activeBonusesItems, onSubmit, closeWithdrawalForm]);

  useEffect(() => {
    if (hasActiveBonus(activeBonusesItems)) {
      closeWithdrawalForm();
    }
  }, [activeBonusesItems, closeWithdrawalForm]);

  const { minimumWithdrawal, maximumWithdrawal } = selectedMethodData;

  return (
    <Form
      className="withdrawal-form"
      onSubmit={handleClick}
    >
      <div className="pt-2 pt-sm-2_5 px-1_5 px-sm-3 mb-1_5">
        <DepositSum
          currency={userCurrency}
          sumChangeHandler={handleAmount}
          depositSumValue={currentAmount}
          minimumDeposit={minimumWithdrawal}
          maximumDeposit={maximumWithdrawal}
        />
        {Object.values(fields).map(item => (
          item.name !== 'amount' && (
            <FormElement key={item.name} coreProps={{ item, handleChange }} />
          )
        ))}

        {/* <FormattedTag */}
        {/* className="text-white mr-1 font-weight-medium" */}
        {/* id="profile-wallet.withdrawal.sum.title" */}
        {/* /> */}

        {/* <span className="caption text-white-45"> */}
        {/* <FormattedMessage id="min" /> */}
        {/* {` ${formatFloatAmountWithLocale(selectedMethodData[MINIMUM_WITHDRAWAL], locale)} ∙ `} */}

        {/* <FormattedMessage id="max" /> */}
        {/* {` ${formatFloatAmountWithLocale(selectedMethodData[MAXIMUM_WITHDRAWAL], locale)}`} */}
        {/* </span> */}

        <FormElement
          coreProps={{
            item: fields.amount,
            handleChange,
          }}
          rightContent={userCurrency}
        />

        <FormattedTag
          tag={ButtonWithLoader}
          isLoading={isInProgress}
          id="profile-wallet.withdrawal.form.withdraw"
          className="font-weight-semi-bold button-submit w-100"
          color="primary"
          type="submit"
        />
      </div>
      <div className="d-inline caption pl-1_5 pl-sm-3 font-weight-normal text-gray-40">
        <FormattedMessage id="min" />:
        <span className="mr-2 text-white font-weight-semi-bold">
          &#32;{formatAmountWithCurrency({ amount: minimumWithdrawal, currency: userCurrency, locale })}
        </span>
        <FormattedMessage id="max" />:
        <span className="text-white font-weight-semi-bold">
          &#32;{formatAmountWithCurrency({ amount: maximumWithdrawal, currency: userCurrency, locale })}
        </span>
      </div>
    </Form>
  );
};

FormProcessWithdrawalUI.propTypes = {
  locale: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isInProgress: PropTypes.bool.isRequired,
  fields: PropTypes.shape().isRequired,
  userCurrency: PropTypes.string.isRequired,
  selectedMethodData: PropTypes.shape({
    code: PropTypes.string,
    paymentMethod: PropTypes.string,
    minimumWithdrawal: PropTypes.number,
    maximumWithdrawal: PropTypes.number,
  }).isRequired,
  currentAmount: PropTypes.number.isRequired,
  setFormData: PropTypes.func.isRequired,
  activeBonuses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setActiveWithdrawalMethod: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  clearFormFieldError: PropTypes.func.isRequired,
};

export const FormProcessWithdrawal = withCoreComponent(
  FormProcessWithdrawalCore,
  withLocale(
    withModalActions(
      withFormDataActions(FormProcessWithdrawalUI)
    )
  )
);
