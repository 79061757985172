import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderISoftbet = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <circle cx="12.5" cy="12" r="10" fill="#F5B41A" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 8.1688C10.5 6.97406 11.3929 6 12.5 6C13.5952 6 14.4881 6.97406 14.5 8.1688C14.5 9.37668 13.6071 10.3506 12.5 10.3506C11.3929 10.3506 10.5 9.37668 10.5 8.1688ZM11.869 19V11.3507C12.0595 11.4156 12.2738 11.4546 12.4762 11.4546C12.6786 11.4546 12.8809 11.4156 13.0714 11.3507V19H11.869Z"
      fill="white"
    />
  </svg>
);

IconProviderISoftbet.propTypes = {
  className: PropTypes.string,
};

IconProviderISoftbet.defaultProps = {
  className: '',
};
