import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderReevoBig = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="108"
    height="36"
    viewBox="0 0 108 36"
    fill="none"
  >
    <path
      d="M58.9968 7.72281H44.7983V12.5951C44.7983 12.7384 44.9136 12.8541 45.0563 12.8541H58.9968C59.1395 12.8541 59.2547 12.7384 59.2547 12.5951V7.98179C59.2547 7.83849 59.1378 7.72281 58.9968 7.72281Z"
      fill="white"
    />
    <path
      d="M62.0218 8.00596L63.8637 28.0131C63.8758 28.146 63.9875 28.2479 64.12 28.2479H70.8924C71.0438 28.2479 71.1624 28.1167 71.1487 27.9647L69.3068 7.95762C69.2948 7.82467 69.183 7.72281 69.0505 7.72281H62.2781C62.1267 7.72281 62.0081 7.85403 62.0218 8.00596Z"
      fill="white"
    />
    <path
      d="M72.4816 28.2479H79.254C79.3865 28.2479 79.4982 28.146 79.5103 28.0131L81.3522 8.00596C81.3659 7.85403 81.2473 7.72281 81.0959 7.72281H74.3235C74.191 7.72281 74.0792 7.82467 74.0672 7.95762L72.2253 27.9647C72.2116 28.1167 72.3302 28.2479 72.4816 28.2479Z"
      fill="white"
    />
    <path
      d="M17.5382 17.9801V27.9837C17.5382 28.127 17.6534 28.2427 17.7962 28.2427H24.5704C24.7131 28.2427 24.8283 28.127 24.8283 27.9837V23.1425C24.8283 20.2902 22.5255 17.9801 19.6862 17.9801H19.1479H18.9381V17.9611C22.3587 17.8765 24.83 17.3586 24.83 12.6503C24.83 7.72797 21.6657 7.76595 17.5399 7.72797C17.1237 7.72452 16.6972 7.72452 16.2621 7.72452C15.827 7.72452 15.4005 7.72452 14.9843 7.72797V7.72452H7.95047C7.80773 7.72452 7.6925 7.8402 7.6925 7.9835V27.9906C7.6925 28.1339 7.80773 28.2496 7.95047 28.2496L14.7247 28.2461C14.8674 28.2461 14.9826 28.1304 14.9826 27.9871V11.6248C14.9826 10.9221 15.5209 10.3091 16.2209 10.2884C16.9449 10.266 17.5382 10.8495 17.5382 11.5712V17.9801Z"
      fill="white"
    />
    <path
      d="M93.0173 7.72626C92.6011 7.72281 92.1746 7.72281 91.7395 7.72281C91.3062 7.72281 90.8779 7.72281 90.4617 7.72626C86.336 7.76425 83.1716 8.17516 83.1716 12.6503V22.9129C83.1716 27.7593 86.336 28.203 90.4617 28.2444C90.8779 28.2479 91.3044 28.2479 91.7395 28.2479C92.1746 28.2479 92.6011 28.2479 93.0173 28.2444C97.1431 28.203 100.307 27.7593 100.307 22.9129V12.6503C100.307 8.17516 97.1431 7.76425 93.0173 7.72626ZM93.0173 14.1369V24.3977C93.0173 25.1056 92.4446 25.6805 91.7395 25.6805C91.387 25.6805 91.0671 25.5372 90.8367 25.3041C90.6062 25.0728 90.4617 24.7516 90.4617 24.3977V14.1369V11.5713C90.4617 10.8634 91.0344 10.2884 91.7395 10.2884C92.0921 10.2884 92.412 10.4317 92.6424 10.6648C92.8729 10.8962 93.0173 11.2173 93.0173 11.5713V14.1369Z"
      fill="white"
    />
    <path
      d="M27.6006 7.72281H41.799C41.9418 7.72281 42.057 7.83849 42.057 7.98179V12.5951C42.057 12.7384 41.9418 12.8541 41.799 12.8541H32.7118V15.4197H41.799C41.9418 15.4197 42.057 15.5354 42.057 15.6787V20.292C42.057 20.4353 41.9418 20.551 41.799 20.551H27.8586C27.7158 20.551 27.6006 20.4353 27.6006 20.292V7.72281Z"
      fill="white"
    />
    <path
      d="M27.6006 28.2478H41.799C41.9418 28.2478 42.057 28.1322 42.057 27.9889V23.3756C42.057 23.2323 41.9418 23.1166 41.799 23.1166H27.8586C27.7158 23.1166 27.6006 23.2323 27.6006 23.3756V28.2478Z"
      fill="white"
    />
    <path
      d="M44.7983 28.2478H58.9968C59.1395 28.2478 59.2547 28.1322 59.2547 27.9889V23.3756C59.2547 23.2323 59.1395 23.1166 58.9968 23.1166H49.9095V20.5509H58.9968C59.1395 20.5509 59.2547 20.4353 59.2547 20.292V15.6787C59.2547 15.5354 59.1395 15.4197 58.9968 15.4197H45.0563C44.9136 15.4197 44.7983 15.5354 44.7983 15.6787V28.2478Z"
      fill="white"
    />
  </svg>
);

IconProviderReevoBig.propTypes = {
  className: PropTypes.string,
};

IconProviderReevoBig.defaultProps = {
  className: '',
};
