import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderRelax = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.32 14.8732C12.4751 14.8732 14.2221 13.1262 14.2221 10.9712C14.2221 8.81611 12.4751 7.06909 10.32 7.06909C8.16498 7.06909 6.41797 8.81611 6.41797 10.9712C6.41797 13.1262 8.16498 14.8732 10.32 14.8732Z"
      fill="white"
    />
    <path
      d="M34.5815 10.9709C34.5815 11.7427 34.3526 12.4971 33.9239 13.1388C33.4951 13.7805 32.8857 14.2806 32.1727 14.576C31.4597 14.8713 30.6751 14.9486 29.9182 14.798C29.1612 14.6475 28.4659 14.2758 27.9202 13.7301C27.3745 13.1844 27.0029 12.4891 26.8523 11.7322C26.7018 10.9753 26.779 10.1907 27.0744 9.47769C27.3697 8.76468 27.8698 8.15526 28.5115 7.72649C29.1532 7.29773 29.9077 7.06887 30.6794 7.06887C31.1924 7.067 31.7006 7.16666 32.1749 7.36209C32.6491 7.55753 33.08 7.84488 33.4428 8.2076C33.8055 8.57031 34.0928 9.00121 34.2883 9.47548C34.4837 9.94974 34.5834 10.458 34.5815 10.9709Z"
      fill="white"
    />
    <path
      d="M10.32 33.9311C12.4751 33.9311 14.2221 32.1841 14.2221 30.029C14.2221 27.874 12.4751 26.127 10.32 26.127C8.16498 26.127 6.41797 27.874 6.41797 30.029C6.41797 32.1841 8.16498 33.9311 10.32 33.9311Z"
      fill="white"
    />
    <path
      d="M34.5815 30.029C34.5815 30.8008 34.3526 31.5552 33.9239 32.1969C33.4951 32.8386 32.8857 33.3388 32.1727 33.6341C31.4597 33.9294 30.6751 34.0067 29.9182 33.8561C29.1612 33.7056 28.4659 33.3339 27.9202 32.7882C27.3745 32.2425 27.0029 31.5472 26.8523 30.7903C26.7018 30.0334 26.779 29.2488 27.0744 28.5358C27.3697 27.8228 27.8698 27.2134 28.5115 26.7846C29.1532 26.3558 29.9077 26.127 30.6794 26.127C31.1924 26.1251 31.7006 26.2248 32.1749 26.4202C32.6491 26.6156 33.08 26.903 33.4428 27.2657C33.8055 27.6284 34.0928 28.0593 34.2883 28.5336C34.4837 29.0078 34.5834 29.5161 34.5815 30.029Z"
      fill="white"
    />
    <path
      d="M24.4721 20.5C24.4721 21.2718 24.2433 22.0262 23.8145 22.6679C23.3857 23.3096 22.7763 23.8097 22.0633 24.105C21.3503 24.4004 20.5657 24.4777 19.8088 24.3271C19.0519 24.1765 18.3566 23.8049 17.8109 23.2592C17.2651 22.7135 16.8935 22.0182 16.7429 21.2613C16.5924 20.5043 16.6697 19.7197 16.965 19.0067C17.2603 18.2937 17.7605 17.6843 18.4022 17.2555C19.0439 16.8268 19.7983 16.5979 20.57 16.5979C21.083 16.5961 21.5912 16.6957 22.0655 16.8911C22.5398 17.0866 22.9707 17.3739 23.3334 17.7366C23.6961 18.0994 23.9835 18.5303 24.1789 19.0045C24.3743 19.4788 24.474 19.987 24.4721 20.5Z"
      fill="white"
    />
  </svg>
);

IconProviderRelax.propTypes = {
  className: PropTypes.string,
};

IconProviderRelax.defaultProps = {
  className: '',
};
