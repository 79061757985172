import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderPlaytechLiveBig = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="119"
    height="39"
    viewBox="0 0 119 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.811 16.0967C21.7123 13.4595 17.1453 12.8867 12.1945 14.5917L9.99709 13.2464C9.30875 12.8601 8.52771 13.3263 8.50119 14.0988V16.2699C4.23874 18.7607 1.77656 22.3436 2.82233 24.8611C4.04018 27.7781 9.50727 28.1776 15.0141 25.7535C20.5341 23.3426 24.0289 19.0138 22.811 16.0967ZM10.6589 14.938L15.901 18.1613C16.2849 18.3877 16.3378 18.9738 15.9275 19.2402L10.6192 22.3969C10.2089 22.6234 9.65292 22.357 9.65292 21.7842L9.62641 15.524C9.6661 14.9913 10.1956 14.6849 10.6589 14.938ZM7.8923 26.6859C5.49631 26.5127 4.52996 24.9676 5.16536 22.8632C5.16536 23.2094 5.21832 23.5424 5.3507 23.8621C6.29056 26.1131 10.5001 26.4195 14.7493 24.5414C14.842 24.5015 14.9214 24.4615 15.0141 24.4215C12.6181 25.9399 9.95732 26.8457 7.8923 26.6859ZM17.8337 20.9984C15.7024 23.6357 11.8636 25.4605 9.25573 25.274C6.64797 25.0875 6.25084 22.7832 8.38205 20.1459C8.42182 20.0927 8.47476 20.0394 8.51445 19.9861L8.52771 23.2894C8.51445 24.1285 9.34844 24.5281 9.94415 24.1951L17.7675 19.5465C18.3764 19.1603 18.3102 18.2945 17.741 17.9615L14.5772 16.1767C15.4377 15.9236 16.2585 15.8038 16.9865 15.8571C19.5678 16.0568 19.9649 18.3478 17.8337 20.9984ZM20.7857 17.189C20.7724 17.1623 20.7724 17.1357 20.7592 17.0957C20.0046 15.2842 17.1321 14.7382 13.8095 15.564L13.2668 15.231C14.895 14.5384 16.5232 14.1788 17.8867 14.2853C19.912 14.4452 20.9048 15.5773 20.7857 17.189Z"
      fill="white"
    />
    <path
      d="M24.2011 15.9236C24.9424 15.8704 25.644 15.7905 26.3191 15.7105C26.9942 15.6306 27.6296 15.524 28.2651 15.4042L27.7356 17.2423C28.2651 16.5363 28.7548 16.0169 29.2314 15.6973C29.7079 15.3642 30.1845 15.2044 30.7008 15.2044C31.4024 15.2044 31.9451 15.4175 32.3157 15.8438C32.6997 16.27 32.8849 16.896 32.8849 17.6819C32.8849 18.3478 32.7526 19.0671 32.5011 19.7997C32.2363 20.5455 31.8657 21.2249 31.4024 21.8642C30.8596 22.6101 30.2904 23.1695 29.6814 23.5291C29.0726 23.9021 28.4239 24.0886 27.7223 24.0886C27.4443 24.0886 27.1796 24.0486 26.928 23.9687C26.6766 23.8888 26.4647 23.7689 26.2529 23.6091L25.7102 25.5937C25.6705 25.7136 25.644 25.8468 25.6308 25.9666C25.6043 26.0865 25.6043 26.2064 25.6043 26.3396C25.6043 26.5793 25.6705 26.7525 25.8161 26.8457C25.9617 26.9389 26.2529 27.0056 26.703 27.0322V27.4984H21.6992V27.0189C21.7125 27.0189 21.7389 27.0189 21.7787 27.0056C21.8051 26.9922 21.8316 26.9922 21.8448 26.9922C22.1625 26.9922 22.3876 26.9124 22.5464 26.7525C22.7053 26.5927 22.8641 26.1797 23.0362 25.5005L25.0351 17.5886C25.0748 17.4688 25.1013 17.3355 25.1145 17.2157C25.1277 17.0957 25.141 17.0026 25.141 16.9359C25.141 16.7228 25.0748 16.5763 24.9424 16.4964C24.8101 16.4032 24.5585 16.3632 24.2011 16.3632V15.9236ZM27.1928 19.8929L26.7162 21.5978C26.65 21.8642 26.5971 22.0907 26.5442 22.3038C26.5045 22.5169 26.478 22.6767 26.478 22.7699C26.478 22.9564 26.5309 23.0896 26.6368 23.1962C26.7428 23.3028 26.8883 23.356 27.0737 23.356C27.3516 23.356 27.6429 23.1962 27.9473 22.8898C28.2518 22.5834 28.5298 22.1439 28.8078 21.5578C29.1388 20.8253 29.4167 20.0927 29.6285 19.3601C29.8271 18.6409 29.9462 18.0815 29.9462 17.6686C29.9462 17.3755 29.88 17.1357 29.7609 16.9626C29.6417 16.7894 29.4829 16.6962 29.2976 16.6962C28.8872 16.6962 28.5033 16.9626 28.1591 17.4688C27.8149 18.0148 27.484 18.814 27.1928 19.8929Z"
      fill="white"
    />
    <path
      d="M33.3876 11.5548C34.1422 11.5016 34.8569 11.4216 35.5454 11.3151C36.2337 11.2218 36.8955 11.102 37.5045 10.9688L34.7511 21.5845C34.7378 21.6378 34.7114 21.7177 34.6849 21.8109C34.6452 21.9442 34.6319 22.0374 34.6319 22.0773C34.6319 22.1972 34.6584 22.3038 34.7114 22.3704C34.7643 22.4369 34.8438 22.4769 34.9364 22.4769C35.029 22.4769 35.1482 22.4103 35.2806 22.2771C35.413 22.144 35.6512 21.7976 36.0218 21.2249L36.4587 21.5446C35.9027 22.4503 35.3733 23.1029 34.8702 23.5025C34.3672 23.9021 33.851 24.1152 33.3082 24.1152C32.8316 24.1152 32.4609 23.9821 32.1962 23.729C31.9314 23.4626 31.8124 23.0896 31.8124 22.5968C31.8124 22.3571 31.9447 21.7177 32.2095 20.6788L32.2359 20.5723L34.0627 13.6593C34.076 13.5927 34.1024 13.4995 34.1289 13.3796C34.2481 12.9268 34.301 12.6337 34.301 12.5006C34.301 12.3274 34.2481 12.1942 34.1157 12.1143C33.9965 12.0343 33.798 11.9944 33.5465 11.9944H33.3876L33.3876 11.5548Z"
      fill="white"
    />
    <path
      d="M45.3143 15.4975L43.8449 20.7454V20.7587C43.6199 21.5712 43.514 22.0374 43.514 22.1706C43.514 22.2905 43.5405 22.3971 43.5934 22.4769C43.6464 22.5569 43.6993 22.5835 43.792 22.5835C44.0303 22.5835 44.3348 22.3038 44.7054 21.7444C44.8245 21.5846 44.904 21.4646 44.9702 21.3714L45.3938 21.7044C44.9172 22.5302 44.4407 23.1296 43.9508 23.5159C43.4611 23.9021 42.9316 24.0886 42.3756 24.0886C41.9123 24.0886 41.5548 23.9821 41.3166 23.7823C41.0651 23.5692 40.946 23.2761 40.946 22.8898C40.946 22.77 40.9591 22.6235 40.9856 22.4503C41.0122 22.2771 41.0386 22.0374 41.0915 21.7577C40.6415 22.5436 40.1649 23.1429 39.6487 23.5159C39.1324 23.9021 38.55 24.0886 37.9013 24.0886C37.2394 24.0886 36.7231 23.8888 36.3393 23.4892C35.9554 23.0763 35.77 22.5302 35.77 21.8242C35.77 21.0118 35.9554 20.1594 36.326 19.2669C36.6966 18.3745 37.1865 17.5887 37.8086 16.896C38.2984 16.3499 38.8014 15.9237 39.3309 15.6306C39.8604 15.3377 40.3767 15.1912 40.8665 15.1912C41.3298 15.1912 41.7005 15.2977 41.9652 15.5241C42.23 15.7506 42.4153 16.0835 42.4947 16.5364L42.7595 15.4841H45.3143V15.4975ZM42.005 17.0558C42.005 16.8028 41.9519 16.603 41.8328 16.4431C41.7137 16.2966 41.5681 16.2167 41.3827 16.2167C41.118 16.2167 40.8665 16.3366 40.6282 16.5897C40.3899 16.8427 40.0987 17.269 39.7811 17.855C39.4104 18.561 39.1191 19.2403 38.9074 19.9196C38.6955 20.5989 38.5896 21.1716 38.5896 21.6511C38.5896 21.9442 38.6558 22.1706 38.7617 22.3304C38.8809 22.4903 39.0397 22.5702 39.2383 22.5702C39.5295 22.5702 39.834 22.3971 40.1517 22.0507C40.4694 21.7044 40.7606 21.2116 41.0386 20.5856C41.3298 19.8796 41.5681 19.2004 41.7402 18.5477C41.9123 17.9083 42.005 17.4022 42.005 17.0558Z"
      fill="white"
    />
    <path
      d="M44.4673 16.2167V15.7372C45.1821 15.6573 45.9102 15.5641 46.6383 15.4575C47.3664 15.3509 47.7635 15.2976 47.8297 15.2976C48.1209 15.2976 48.5047 16.563 48.9813 19.0937C49.1667 20.106 49.3255 20.9185 49.4446 21.5179C50.0006 20.4123 50.3845 19.6132 50.5831 19.107C50.7949 18.6142 50.8876 18.2412 50.8876 18.0015C50.8876 17.815 50.7155 17.5886 50.3712 17.3356C50.0271 17.0825 49.855 16.7761 49.855 16.4564C49.855 16.0835 49.9741 15.7771 50.2124 15.5374C50.4507 15.311 50.7684 15.191 51.1655 15.191C51.5494 15.191 51.8671 15.311 52.0789 15.5374C52.2908 15.7639 52.4099 16.0968 52.4099 16.5097C52.4099 17.0292 52.1584 17.8683 51.6421 19.0138C51.139 20.1593 50.4374 21.4513 49.5638 22.8898C48.5709 24.5415 47.6708 25.7269 46.8501 26.4861C46.0426 27.2453 45.2483 27.6182 44.5071 27.6182C44.004 27.6182 43.6201 27.5117 43.3289 27.2986C43.0509 27.0855 42.9053 26.7792 42.9053 26.4061C42.9053 26.0599 43.0244 25.7802 43.276 25.5537C43.5275 25.3273 43.8319 25.2207 44.2158 25.2207C44.5071 25.2207 44.838 25.3406 45.1689 25.5671C45.5131 25.7935 45.7382 25.9134 45.8573 25.9134C46.0558 25.9134 46.2941 25.7402 46.5589 25.4073C46.8236 25.0742 46.956 24.7546 46.956 24.4482C46.956 24.2217 46.9295 23.9421 46.8898 23.5958C46.8501 23.2494 46.7839 22.8232 46.7045 22.3171C46.5324 21.2382 46.3603 20.2525 46.1749 19.3735C46.0029 18.4943 45.8308 17.7884 45.6984 17.2823C45.5661 16.8427 45.4469 16.563 45.3145 16.4298C45.1821 16.2966 44.9836 16.23 44.6791 16.23H44.6526C44.5864 16.2167 44.5203 16.2167 44.4673 16.2167Z"
      fill="white"
    />
    <path
      d="M55.9048 21.731C55.3488 22.4903 54.806 23.0763 54.2766 23.4759C53.7471 23.8888 53.2705 24.0886 52.8336 24.0886C52.4762 24.0886 52.2115 23.9687 52.0129 23.7556C51.8144 23.5291 51.7085 23.2361 51.7085 22.8632C51.7085 22.7299 51.7217 22.5834 51.7482 22.4236C51.7746 22.2638 51.8144 22.0773 51.8673 21.8775L53.4426 15.8438H51.7879L51.9467 15.3242C52.6615 15.1778 53.2969 14.8448 53.8397 14.3253C54.3825 13.8058 54.8855 13.0999 55.3223 12.1808H55.8915L55.1502 15.2044H56.6725L56.5137 15.8438H54.9517L53.4426 21.6511C53.4028 21.7843 53.3764 21.9042 53.3631 22.0107C53.3499 22.1172 53.3367 22.2105 53.3367 22.2905C53.3367 22.4769 53.3764 22.6234 53.469 22.7299C53.5485 22.8365 53.6809 22.8898 53.8264 22.8898C54.025 22.8898 54.2633 22.7832 54.528 22.5435C54.806 22.3171 55.1503 21.9308 55.5738 21.4113L55.9048 21.731Z"
      fill="white"
    />
    <path
      d="M57.3079 20.3858C57.255 20.5988 57.2152 20.8119 57.1888 21.0117C57.1622 21.2115 57.1491 21.3848 57.1491 21.5446C57.1491 22.0507 57.2682 22.4369 57.5329 22.7034C57.7845 22.9698 58.1551 23.1696 58.6449 23.1696C59.1214 23.1696 59.6774 22.9431 60.2863 22.6234C60.8952 22.3038 61.5968 21.8242 62.3514 21.1716L62.7485 21.771C61.8351 22.5702 60.9879 23.1562 60.1805 23.5425C59.3862 23.9288 58.5919 24.1152 57.8241 24.1152C57.0696 24.1152 56.4739 23.8888 56.0635 23.4492C55.6399 23.0097 55.4414 22.3837 55.4414 21.5712C55.4414 20.7986 55.6002 20.0128 55.9311 19.2136C56.2621 18.4144 56.6989 17.7085 57.2682 17.0825C57.8109 16.4964 58.4199 16.0435 59.0552 15.7239C59.7171 15.4175 60.379 15.2577 61.0674 15.2577C61.7028 15.2577 62.1925 15.4042 62.5632 15.6839C62.9339 15.9637 63.1192 16.3633 63.1192 16.856C63.1192 17.7618 62.5765 18.5343 61.5174 19.2003C60.4452 19.8529 59.042 20.2525 57.3079 20.3858ZM57.4535 19.7464C58.6714 19.6931 59.7039 19.3602 60.5246 18.7741C61.3586 18.1747 61.7689 17.5087 61.7689 16.7495C61.7689 16.4964 61.7028 16.31 61.5704 16.1767C61.438 16.0569 61.2395 15.9902 60.9747 15.9902C60.2334 15.9902 59.5451 16.3233 58.9228 16.9892C58.3007 17.6552 57.8109 18.5743 57.4535 19.7464Z"
      fill="white"
    />
    <path
      d="M69.2481 20.5056L69.2216 21.6378C68.6524 22.4636 68.0302 23.0763 67.3418 23.4759C66.6667 23.8755 65.9255 24.0886 65.1179 24.0886C64.2442 24.0886 63.5162 23.7956 62.9734 23.2228C62.4307 22.6501 62.1527 21.8909 62.1527 20.9452C62.1527 20.2525 62.3115 19.5466 62.616 18.8273C62.9205 18.1081 63.3573 17.4554 63.9001 16.8827C64.4031 16.3633 64.9723 15.9637 65.5945 15.6839C66.2167 15.4042 66.8521 15.2577 67.5007 15.2577C68.1891 15.2577 68.7317 15.4175 69.1421 15.7239C69.5525 16.0302 69.7511 16.4298 69.7511 16.9493C69.7511 17.2556 69.6452 17.522 69.4333 17.7352C69.2216 17.9483 68.97 18.0548 68.6788 18.0548C68.4405 18.0548 68.2553 17.9882 68.0963 17.8284C67.9508 17.6819 67.8713 17.4821 67.8713 17.229C67.8713 17.0558 67.9243 16.856 68.0434 16.6296C68.1625 16.4032 68.2155 16.27 68.2155 16.2034C68.2155 16.0968 68.1625 16.0169 68.0301 15.9503C67.911 15.8837 67.7522 15.8571 67.5404 15.8571C67.1168 15.8571 66.6932 15.9769 66.2431 16.2433C65.8062 16.5098 65.4224 16.856 65.0915 17.3089C64.6943 17.855 64.3766 18.4411 64.1648 19.0538C63.953 19.6665 63.8471 20.2659 63.8471 20.8519C63.8471 21.5179 64.0192 22.0507 64.3634 22.4369C64.7208 22.8232 65.1841 23.0896 65.7798 23.0896C66.2431 23.0896 66.7064 22.8765 67.1697 22.5835C67.6728 22.3171 68.4273 21.7444 69.2481 20.5056Z"
      fill="white"
    />
    <path
      d="M68.2158 24.1019L71.3267 13.1265C71.3398 13.06 71.3531 12.9933 71.3664 12.9134C71.3796 12.8468 71.3796 12.7802 71.3796 12.7136C71.3796 12.5138 71.3267 12.3806 71.234 12.314C71.1413 12.2475 70.8501 12.2075 70.4 12.2075H69.6985L69.8043 11.7013C70.3735 11.6747 70.903 11.6081 71.406 11.5148C71.8959 11.4083 72.3724 11.2885 72.8092 11.1152H73.3652L71.2207 18.6942C72.3195 17.6019 73.3387 16.7494 74.2653 16.1367C75.192 15.5373 75.9201 15.231 76.476 15.231C76.7672 15.231 76.9791 15.2977 77.1114 15.4308C77.2438 15.564 77.3232 15.7771 77.3232 16.0569C77.3232 16.1501 77.31 16.2433 77.2836 16.3765C77.257 16.5097 77.2305 16.6562 77.1908 16.8027L75.6156 21.9707C75.5891 22.064 75.5626 22.1838 75.5361 22.3038C75.5097 22.4236 75.5097 22.5435 75.5097 22.6501C75.5097 22.7167 75.5361 22.7699 75.6023 22.8232C75.6553 22.8765 75.7215 22.9697 75.8009 22.9697C75.9597 22.9697 76.1716 22.8099 76.4363 22.5968C76.701 22.397 76.9923 22.1173 77.2967 21.7576L77.6145 22.024C77.0717 22.6767 76.5555 23.1828 76.0525 23.5292C75.5494 23.8755 75.0861 24.0486 74.6624 24.0486C74.3845 24.0486 74.1727 23.982 74.0139 23.8488C73.855 23.7156 73.7756 23.5425 73.7756 23.3161C73.7756 23.2361 73.7888 23.1429 73.802 23.0496C73.8153 22.9564 73.855 22.8632 73.8815 22.7433L75.4964 17.4021C75.523 17.2823 75.5494 17.2023 75.5494 17.1357C75.5626 17.0825 75.5626 17.0159 75.5626 16.9759C75.5626 16.8427 75.523 16.7361 75.4435 16.6562C75.364 16.5896 75.2582 16.5496 75.0993 16.5496C74.8743 16.5496 74.5301 16.6829 74.08 16.9759C73.63 17.2556 73.1137 17.6552 72.5312 18.188C72.0017 18.6542 71.6178 19.0404 71.3664 19.3734C71.1148 19.7064 70.9427 20.0394 70.8368 20.3857L69.7646 24.0753C69.6454 24.0619 69.2086 24.0486 69.0895 24.0353C68.9703 24.022 69.1424 24.022 69.0233 24.022C68.8909 24.022 68.7586 24.022 68.6394 24.0353C68.4674 24.0753 68.335 24.0886 68.2158 24.1019Z"
      fill="white"
    />
    <path
      d="M49.7493 27.272C49.7493 27.3519 49.7625 27.4185 49.7758 27.4718C49.8155 27.6449 49.9611 27.7382 50.2127 27.7382C50.3053 27.7382 50.3848 27.7249 50.4641 27.6982C50.6362 27.6449 50.7157 27.5251 50.7289 27.3386C50.7289 27.2054 50.676 27.0989 50.5568 27.0322C50.4774 26.979 50.3848 26.939 50.2788 26.8857L49.9479 26.7392C49.8155 26.6859 49.7096 26.6326 49.6302 26.5661C49.4449 26.4462 49.3654 26.273 49.3654 26.0466C49.3654 25.7936 49.4713 25.6203 49.6699 25.5138C49.8155 25.4339 50.0141 25.394 50.2788 25.394C50.5965 25.394 50.8216 25.4472 50.954 25.5671C51.0598 25.6603 51.126 25.8468 51.126 26.1132V26.1665H50.676V26.0999L50.6627 25.9667C50.6362 25.7669 50.5039 25.6736 50.2656 25.6736C49.9611 25.6736 49.8155 25.7935 49.8155 26.0199C49.8155 26.1665 49.9082 26.2864 50.0935 26.3796C50.2127 26.4328 50.345 26.4995 50.4907 26.5528C50.6362 26.606 50.7554 26.6726 50.8481 26.7259C51.0598 26.8591 51.1657 27.0456 51.1657 27.2986C51.1657 27.7649 50.8481 28.0045 50.2127 28.0045C49.9479 28.0045 49.7361 27.9646 49.5905 27.8714C49.4184 27.7649 49.3258 27.6183 49.3125 27.4185V27.1122H49.7493V27.272Z"
      fill="white"
    />
    <path
      d="M51.9201 27.0988C51.9201 27.312 51.9334 27.4584 51.9731 27.5251C51.9996 27.5917 52.0658 27.645 52.1452 27.6716C52.2246 27.7115 52.3173 27.7249 52.41 27.7249C52.4893 27.7249 52.5688 27.7115 52.6482 27.6716C52.7938 27.6183 52.8733 27.5251 52.8865 27.3786C52.8997 27.2986 52.8997 27.2054 52.8997 27.0855V26.2198C52.8997 26.0333 52.8864 25.9001 52.8335 25.8335C52.7674 25.7136 52.6217 25.647 52.4231 25.647C52.3438 25.647 52.2643 25.6603 52.1981 25.687C52.0525 25.7403 51.9731 25.8335 51.9598 25.98C51.9467 26.0466 51.9467 26.1265 51.9467 26.2198V27.0988H51.9201ZM51.4701 26.2464C51.4701 26.0333 51.4965 25.8868 51.5363 25.7802C51.6025 25.6204 51.7216 25.5138 51.9334 25.4472C52.079 25.394 52.2379 25.3806 52.41 25.3806C52.5821 25.3806 52.7409 25.4073 52.8865 25.4472C53.085 25.5138 53.2174 25.6204 53.2836 25.7802C53.3233 25.8734 53.3366 26.0333 53.3498 26.2464V27.1122C53.3498 27.272 53.3366 27.4052 53.3233 27.5117C53.2704 27.8448 52.9792 28.0179 52.4364 28.0179C52.0393 28.0179 51.7746 27.938 51.6422 27.7782C51.5363 27.6582 51.4833 27.4319 51.4965 27.0988V26.2464H51.4701Z"
      fill="white"
    />
    <path
      d="M53.7338 25.4205H54.1839V27.3519C54.1971 27.6049 54.3427 27.7249 54.634 27.7249C54.9119 27.7249 55.0707 27.6049 55.0973 27.3786C55.1105 27.2986 55.1105 27.2187 55.1105 27.1255V25.4205H55.5606V27.1521C55.5606 27.2986 55.5606 27.4051 55.5473 27.4851C55.5341 27.6049 55.4811 27.6982 55.4017 27.7782C55.2428 27.938 54.9914 28.0046 54.6472 28.0046C54.303 28.0046 54.0515 27.9247 53.8926 27.7782C53.8132 27.6849 53.7603 27.5917 53.747 27.4718C53.7338 27.4052 53.7338 27.2986 53.7338 27.1388V25.4205Z"
      fill="white"
    />
    <path
      d="M56.4208 25.7136V26.5394H56.7385C56.8311 26.5394 56.9106 26.5394 56.99 26.5261C57.1224 26.4994 57.215 26.4462 57.268 26.3663C57.3077 26.313 57.3209 26.2197 57.3209 26.0865C57.3209 25.94 57.268 25.8468 57.1753 25.7935C57.1091 25.7536 56.9635 25.7269 56.7649 25.7269H56.4208V25.7136ZM56.9768 25.4205C57.0827 25.4205 57.1753 25.4339 57.2547 25.4472C57.5857 25.5005 57.7445 25.7003 57.7577 26.0599C57.7577 26.2464 57.7181 26.3929 57.6253 26.4861C57.546 26.5794 57.3739 26.6459 57.1356 26.6726C57.2812 26.6859 57.4003 26.7126 57.4665 26.7392C57.5592 26.7792 57.6253 26.8591 57.6519 26.9657C57.6651 27.0322 57.6784 27.1122 57.6784 27.2054L57.6915 27.5117C57.7048 27.6316 57.7181 27.7115 57.7313 27.7782C57.7445 27.8314 57.7577 27.898 57.7974 27.978H57.3341L57.2944 27.8181C57.2812 27.7782 57.268 27.7115 57.268 27.6449L57.2547 27.4185L57.2415 27.1788C57.215 26.9923 57.1356 26.8857 56.9635 26.8457C56.8973 26.8324 56.8179 26.8191 56.7252 26.8191H56.4208V27.978H55.9707V25.4205H56.9768Z"
      fill="white"
    />
    <path
      d="M59.4393 26.0999V25.98C59.4261 25.7802 59.3069 25.6736 59.0686 25.6736C58.8569 25.6736 58.7113 25.7269 58.6583 25.8335C58.6186 25.8868 58.6053 25.9401 58.6053 25.9934V27.2187C58.6053 27.4052 58.6318 27.5251 58.6583 27.5784C58.7377 27.6849 58.8569 27.7382 59.0422 27.7382C59.2805 27.7382 59.4129 27.6316 59.4393 27.4052L59.4526 27.2453V27.1521H59.9026V27.4052C59.8894 27.5917 59.8233 27.7382 59.7041 27.8447C59.6247 27.9113 59.5452 27.9513 59.4393 27.978C59.3202 28.0046 59.1878 28.0312 59.0555 28.0312C58.9363 28.0312 58.8172 28.0179 58.7113 28.0046C58.3406 27.938 58.1553 27.6982 58.1553 27.2853V26.2597C58.1553 26.1532 58.1553 26.0466 58.1818 25.9534C58.195 25.8335 58.2347 25.7403 58.3009 25.6603C58.4465 25.4872 58.698 25.394 59.0686 25.394C59.585 25.394 59.8629 25.5538 59.9026 25.8734C59.9159 25.9401 59.9159 26.0067 59.9159 26.0732V26.1665H59.4658V26.0999H59.4393Z"
      fill="white"
    />
    <path
      d="M60.2729 25.4205H61.7423V25.7136H60.723V26.5261H61.6761V26.8058H60.723V27.6982H61.7688V27.978H60.2729V25.4205Z"
      fill="white"
    />
    <path
      d="M63.3705 27.0988C63.3705 27.3119 63.3837 27.4584 63.4234 27.525C63.4499 27.5917 63.5161 27.645 63.5955 27.6715C63.6749 27.7115 63.7676 27.7248 63.8603 27.7248C63.9397 27.7248 64.0191 27.7115 64.0986 27.6715C64.2441 27.6183 64.3103 27.525 64.3368 27.3786C64.35 27.2986 64.35 27.2054 64.35 27.0855V26.2198C64.35 26.0333 64.3368 25.9 64.2838 25.8335C64.2176 25.7136 64.0721 25.647 63.8735 25.647C63.7941 25.647 63.7146 25.6603 63.6484 25.6869C63.5029 25.7402 63.4234 25.8335 63.4101 25.98C63.3969 26.0465 63.3969 26.1265 63.3969 26.2198V27.0988H63.3705ZM62.9204 26.2463C62.9204 26.0333 62.9468 25.8867 62.9734 25.7802C63.0263 25.6204 63.1587 25.5138 63.3705 25.4472C63.5161 25.3939 63.6749 25.3806 63.847 25.3806C64.0191 25.3806 64.1779 25.4073 64.3236 25.4472C64.5221 25.5138 64.6545 25.6204 64.7207 25.7802C64.7604 25.8734 64.7736 26.0333 64.7869 26.2463V27.1121C64.7869 27.272 64.7736 27.4052 64.7604 27.5117C64.7074 27.8448 64.4162 28.0179 63.8735 28.0179C63.4763 28.0179 63.2116 27.938 63.0792 27.7781C62.9734 27.6582 62.9336 27.4319 62.9336 27.0988V26.2463H62.9204Z"
      fill="white"
    />
    <path
      d="M66.6138 25.4205V25.7136H65.6342V26.5128H66.5741V26.8058H65.6342V27.978H65.1841V25.4205H66.6138Z"
      fill="white"
    />
    <path
      d="M68.0966 27.272C68.0966 27.3519 68.0966 27.4185 68.123 27.4718C68.1627 27.6449 68.3083 27.7382 68.5599 27.7382C68.6525 27.7382 68.732 27.7248 68.8113 27.6982C68.9834 27.6449 69.0629 27.5251 69.0761 27.3386C69.0761 27.2053 69.0232 27.0988 68.904 27.0322C68.8246 26.979 68.732 26.939 68.6261 26.8857L68.2951 26.7392C68.1628 26.6859 68.0568 26.6326 67.9774 26.5661C67.8053 26.4461 67.7126 26.273 67.7126 26.0466C67.7126 25.7935 67.8185 25.6203 68.0171 25.5138C68.1627 25.4338 68.3613 25.394 68.6261 25.394C68.9437 25.394 69.1688 25.4472 69.3012 25.5671C69.407 25.6603 69.46 25.8468 69.46 26.1132V26.1665H69.0099V26.0998L68.9967 25.9667C68.9702 25.7669 68.8378 25.6736 68.5996 25.6736C68.2951 25.6736 68.1495 25.7935 68.1495 26.0199C68.1495 26.1665 68.2421 26.2863 68.4275 26.3796C68.5466 26.4328 68.6789 26.4994 68.8246 26.5527C68.9702 26.606 69.0893 26.6726 69.182 26.7259C69.3938 26.859 69.4997 27.0455 69.4997 27.2986C69.4997 27.7648 69.182 28.0045 68.5466 28.0045C68.2687 28.0045 68.0701 27.9646 67.9244 27.8713C67.7523 27.7648 67.6597 27.6182 67.6464 27.4185L67.6332 27.2187V27.1121H68.0966V27.272Z"
      fill="white"
    />
    <path
      d="M69.8306 25.4205H70.2807V27.3519C70.2939 27.6049 70.4527 27.7249 70.7308 27.7249C71.0087 27.7249 71.1675 27.6049 71.1941 27.3786C71.2073 27.2986 71.2073 27.2187 71.2073 27.1255V25.4205H71.6574V27.1521C71.6574 27.2986 71.6441 27.4051 71.6441 27.4851C71.6309 27.6049 71.5779 27.6982 71.4985 27.7782C71.3396 27.938 71.0882 28.0046 70.744 28.0046C70.3998 28.0046 70.1483 27.9247 69.9894 27.7782C69.91 27.6849 69.8571 27.5917 69.8438 27.4718C69.8306 27.4052 69.8306 27.2986 69.8306 27.1388V25.4205Z"
      fill="white"
    />
    <path
      d="M73.3915 26.0998V25.98C73.3783 25.7802 73.2591 25.6736 73.0077 25.6736C72.7958 25.6736 72.6635 25.7269 72.5973 25.8334C72.5576 25.8867 72.5444 25.94 72.5444 25.9933V27.2187C72.5444 27.4052 72.5708 27.5251 72.5973 27.5784C72.6635 27.6849 72.7958 27.7382 72.9812 27.7382C73.2195 27.7382 73.3519 27.6316 73.3784 27.4052L73.3915 27.2453V27.1521H73.8417V27.4052C73.8284 27.5917 73.7622 27.7382 73.6431 27.8447C73.5636 27.9113 73.4843 27.9513 73.3784 27.9779C73.2592 28.0046 73.1268 28.0312 72.9944 28.0312C72.8753 28.0312 72.7561 28.0179 72.6503 28.0046C72.2796 27.938 72.0942 27.6982 72.0942 27.2853V26.2597C72.0942 26.1531 72.0942 26.0466 72.1208 25.9534C72.134 25.8334 72.1737 25.7402 72.2399 25.6603C72.3855 25.4871 72.637 25.394 73.0077 25.394C73.5239 25.394 73.8019 25.5538 73.8417 25.8734C73.8549 25.94 73.8549 26.0067 73.8549 26.0732V26.1665H73.4048V26.0998H73.3915Z"
      fill="white"
    />
    <path
      d="M75.5361 26.0998V25.98C75.5229 25.7802 75.4038 25.6736 75.1655 25.6736C74.9537 25.6736 74.8213 25.7269 74.7551 25.8334C74.7155 25.8867 74.7022 25.94 74.7022 25.9933V27.2187C74.7022 27.4052 74.7286 27.5251 74.7551 27.5784C74.8345 27.6849 74.9537 27.7382 75.139 27.7382C75.3773 27.7382 75.5097 27.6316 75.5361 27.4052L75.5494 27.2453V27.1521H75.9995V27.4052C75.9862 27.5917 75.92 27.7382 75.8009 27.8447C75.7214 27.9113 75.6421 27.9513 75.5361 27.9779C75.417 28.0046 75.2847 28.0312 75.1523 28.0312C75.0331 28.0312 74.914 28.0179 74.8081 28.0046C74.4374 27.938 74.2521 27.6982 74.2521 27.2853V26.2597C74.2521 26.1531 74.2521 26.0466 74.2786 25.9534C74.2918 25.8334 74.3315 25.7402 74.3977 25.6603C74.5433 25.4871 74.7948 25.394 75.1655 25.394C75.6818 25.394 75.9597 25.5538 75.9995 25.8734C76.0127 25.94 76.0127 26.0067 76.0127 26.0732V26.1665H75.5626V26.0998H75.5361Z"
      fill="white"
    />
    <path
      d="M76.3701 25.4205H77.8395V25.7136H76.8202V26.5261H77.7733V26.8058H76.8202V27.6982H77.8659V27.978H76.3701V25.4205Z"
      fill="white"
    />
    <path
      d="M78.5145 27.272C78.5145 27.3519 78.5278 27.4185 78.541 27.4718C78.5807 27.6449 78.7264 27.7382 78.9778 27.7382C79.0705 27.7382 79.1499 27.7249 79.2293 27.6982C79.4014 27.6449 79.4809 27.5251 79.4941 27.3386C79.4941 27.2054 79.4411 27.0989 79.3221 27.0322C79.2426 26.979 79.15 26.939 79.044 26.8857L78.7264 26.7392C78.594 26.6859 78.4881 26.6326 78.4086 26.5661C78.2365 26.4462 78.1439 26.273 78.1439 26.0466C78.1439 25.7936 78.2498 25.6203 78.4483 25.5138C78.594 25.4339 78.7926 25.394 79.0573 25.394C79.375 25.394 79.6 25.4472 79.7323 25.5671C79.8383 25.6603 79.8913 25.8468 79.8913 26.1132V26.1665H79.4411V26.0999L79.4279 25.9667C79.4014 25.7669 79.269 25.6736 79.0308 25.6736C78.7264 25.6736 78.5807 25.7935 78.5807 26.0199C78.5807 26.1665 78.6734 26.2864 78.8587 26.3796C78.9778 26.4328 79.1102 26.4995 79.2558 26.5528C79.4014 26.606 79.5206 26.6726 79.6132 26.7259C79.8251 26.8591 79.9309 27.0456 79.9309 27.2986C79.9309 27.7649 79.6132 28.0045 78.9778 28.0045C78.6998 28.0045 78.5012 27.9646 78.3557 27.8714C78.1836 27.7649 78.0909 27.6183 78.0777 27.4185L78.0645 27.2187V27.1122H78.5145V27.272Z"
      fill="white"
    />
    <path
      d="M80.5927 27.272C80.5927 27.3519 80.606 27.4185 80.6192 27.4718C80.6589 27.6449 80.8045 27.7382 81.056 27.7382C81.1487 27.7382 81.2281 27.7248 81.3075 27.6982C81.4795 27.6449 81.5592 27.5251 81.5723 27.3386C81.5723 27.2053 81.5194 27.0988 81.3999 27.0322C81.3208 26.979 81.2281 26.939 81.1222 26.8857L80.8046 26.7392C80.6722 26.6859 80.5663 26.6326 80.4868 26.5661C80.3015 26.4461 80.2221 26.273 80.2221 26.0466C80.2221 25.7935 80.328 25.6203 80.5265 25.5138C80.6722 25.4338 80.8707 25.394 81.1355 25.394C81.4535 25.394 81.678 25.4472 81.8105 25.5671C81.9163 25.6603 81.9829 25.8468 81.9829 26.1132V26.1665H81.5064V26.0998L81.4926 25.9667C81.4665 25.7669 81.334 25.6736 81.0958 25.6736C80.7913 25.6736 80.6456 25.7935 80.6456 26.0199C80.6456 26.1665 80.7384 26.2863 80.9237 26.3796C81.0428 26.4328 81.1751 26.4994 81.3208 26.5527C81.4665 26.606 81.5853 26.6726 81.678 26.7259C81.8902 26.859 81.996 27.0455 81.996 27.2986C81.996 27.7648 81.678 28.0045 81.0428 28.0045C80.778 28.0045 80.5663 27.9646 80.4206 27.8713C80.2485 27.7648 80.1559 27.6182 80.1426 27.4185L80.1294 27.2187V27.1121H80.5927V27.272Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M103 36C111.837 36 119 28.8366 119 20C119 11.1634 111.837 4 103 4C94.1634 4 87 11.1634 87 20C87 28.8366 94.1634 36 103 36ZM95.2376 17H94V23H97.041V21.9109H95.2376V17ZM99.6552 17H98.4265V23H99.6552V17ZM101.085 17H102.358L103.993 21.2624L105.651 17H106.924L104.581 23H103.388L101.085 17ZM112 17H108.451V23H112V21.8783H109.68V20.2998H112V19.2026H109.68V18.1176H112V17Z"
      fill="white"
    />
  </svg>
);

IconProviderPlaytechLiveBig.propTypes = {
  className: PropTypes.string,
};

IconProviderPlaytechLiveBig.defaultProps = {
  className: '',
};
