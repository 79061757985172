import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import { withModalActions } from 'core/hocs';
import { FormattedTag } from 'core/components';
import { MODAL_IDS } from '../../../constants';

export class SignInButtonsUI extends Component {
  static propTypes = {
    openModalAndCloseOthers: PropTypes.func.isRequired,
  };

  openSignInModal = () => {
    const { openModalAndCloseOthers } = this.props;
    openModalAndCloseOthers(MODAL_IDS.SIGN_IN);
  };

  openSignUpModal = () => {
    const { openModalAndCloseOthers } = this.props;
    openModalAndCloseOthers(MODAL_IDS.SIGN_UP);
  };

  render() {
    return (
      <>
        <FormattedTag
          tag={Button}
          id="sign-up"
          color="primary"
          className="mr-1_5 letter-spacing-default py-1 py-lg-1_25"
          size="sm"
          onClick={this.openSignUpModal}
        />

        <FormattedTag
          tag={Button}
          id="sign-in"
          color="white-20"
          className="no-effects py-1 py-lg-1_25 btn-outline-gray-5"
          size="sm"
          onClick={this.openSignInModal}
          outline
        />
      </>
    );
  }
}

export const SignInButtons = withModalActions(SignInButtonsUI);
