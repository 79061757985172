import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderFugaso = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M10.5608 16.8361L12.3517 13.7417L14.1259 16.8687L17.7375 10.6391H15.9572L17.7328 7.52576H23L14.112 22.9999L10.5608 16.8361Z"
      fill="white"
    />
    <path
      d="M8.71671 13.6352L10.5606 16.8361L12.3515 13.7417L12.3521 13.7403L10.5924 10.6398H14.1629L15.9385 7.52641H8.74323L7.34474 5.11475L17.3449 5.1317L19.1199 2.01831L2 2L7.01003 10.6398H7.01799L8.71671 13.6352Z"
      fill="#D71A20"
    />
  </svg>
);

IconProviderFugaso.propTypes = {
  className: PropTypes.string,
};

IconProviderFugaso.defaultProps = {
  className: '',
};
