import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  withCoreComponent, withModalActions, withLocale, withUserAgent
} from 'core/hocs';
import { GamesFilters as GamesFiltersCore } from 'core/components';
import { DEVICES_TYPES } from 'core/constants';
import classNames from 'classnames';
import { GameSearchInput } from './game-search-input';
import { GameDropdown } from './game-dropdown';
import './game-search.scss';

class GameSearchUI extends Component {
  static propTypes = {
    filters: PropTypes.shape({
      category: PropTypes.string,
      searchValue: PropTypes.string,
      producers: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    setSearchValue: PropTypes.func.isRequired,
    setCategory: PropTypes.func.isRequired,
    games: PropTypes.arrayOf(PropTypes.shape()),
    deviceType: PropTypes.string.isRequired,
  };

  static defaultProps = {
    games: [],
  };

  state = {
    isDropdownOpen: false,
  };

  wrapperRef = React.createRef();

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('scroll', this.handleCloseDropdown);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('scroll', this.handleCloseDropdown);
  }

  onChange = (e) => {
    const { value } = e.target;
    const { setSearchValue } = this.props;

    setSearchValue(value);
  };
  clearSearchValue = () => {
    const { setSearchValue } = this.props;
    setSearchValue('');
    this.handleCloseDropdown();
  };

  handleOpenDropdown = () =>
    this.setState({ isDropdownOpen: true });

  handleCloseDropdown = () => this.setState({ isDropdownOpen: false })

  handleClickOutside = (event) => {
    event.stopImmediatePropagation();

    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.handleCloseDropdown();
    }
  }
  render() {
    const {
      filters: {
        category: filterCategory,
        searchValue,
      },
      setCategory,
      games,
      deviceType,
    } = this.props;
    const isMobile = deviceType === DEVICES_TYPES.MOBILE;
    const {
      isDropdownOpen,
    } = this.state;

    return (
      <div ref={this.wrapperRef} className="position-relative search-container">
        <GameSearchInput
          onChange={this.onChange}
          clearSearchValue={this.clearSearchValue}
          searchValue={searchValue}
          onClick={this.handleOpenDropdown}
          focused={isDropdownOpen}
        />

        {isDropdownOpen && (
          <div
            className={classNames('position-absolute sticky bg-default dropdown-menu-right rounded mt-2 border border-gray-0', { 'w-100': isMobile })}
          >
            <GameDropdown filterCategory={filterCategory} setCategory={setCategory} games={games} />
            <div className="shadow-top" />
          </div>
        )}

      </div>
    );
  }
}

export const GameSearch = withCoreComponent(
  GamesFiltersCore,
  withRouter(
    withLocale(
      withUserAgent(
        withModalActions(
          GameSearchUI,
        ),
      ),
    ),
  ),
);
