import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withModalActions, withLocale, withUserAgent
} from 'core/hocs';
import { GAME_CATEGORY_FILTER } from 'core/constants';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import { FormattedTag } from 'core/components';

import './game-search.scss';
import { withRouter } from 'react-router-dom';
import { wrapRoute2Locale } from 'core/helpers';

const CATEGORIES = [
  {
    key: 'search.category.slot',
    category: GAME_CATEGORY_FILTER.SLOTS
  },
  {},
  {
    key: 'search.category.live',
    category: GAME_CATEGORY_FILTER.LIVE_CASINO
  }
];

// eslint-disable-next-line react/prefer-stateless-function
class GameCategoriesUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    setCategory: PropTypes.func.isRequired,
    filterCategory: PropTypes.string.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func,
    }).isRequired,
  };

  componentDidMount() {
    this.handleSetCategory(CATEGORIES[0].category);
  }

  handleSetCategory(category) {
    const {
      location: { pathname },
      history,
      locale,
      setCategory,
    } = this.props;

    setCategory(category);

    if (pathname.includes('/games')) {
      history.replace(`${wrapRoute2Locale(`/games/${category}`, locale)}`);
    }
  }

  render() {
    const {
      filterCategory,
    } = this.props;

    return (
      <div className="category-wrapper d-flex justify-content-center align-items-center position-relative">
        {CATEGORIES.map(({
          key,
          category,
        }) => {
          const isActive = category === filterCategory;

          if (!category) {
            return <div className="separator" />;
          }

          return (
            <FormattedTag
              id={key}
              tag={Button}
              color="primary"
              className={classNames(
                'category-item d-flex flex-column align-items-center position-relative h-100 pt-2 rounded-0 bg-transparent text-gray-20 mx-2',
                { active: isActive },
              )}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => this.handleSetCategory(category)}
            />
          );
        })}
      </div>
    );
  }
}

export const GameCategories = withLocale(
  withRouter(
    withUserAgent(
      withModalActions(
        GameCategoriesUI,
      ),
    )
  ),
);
