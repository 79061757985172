import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderPlaysonBig = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="111"
    height="22"
    viewBox="0 0 111 22"
    fill="none"
  >
    <path
      d="M3.14167 20.1735V4.08149L16.6833 10.8855L8.99167 14.6655V10.8855C8.99167 10.0215 8.34167 9.37349 7.475 9.37349C6.60833 9.37349 5.95833 10.0215 5.95833 10.8855V17.2575C5.95833 18.1215 6.60833 18.7695 7.475 18.7695C7.69167 18.7695 7.90833 18.6615 8.125 18.5535L20.8 12.1815C21.5583 11.7495 21.8833 10.8855 21.45 10.1295C21.3417 9.80549 21.0167 9.58949 20.8 9.48149L2.16667 0.193493C1.40833 -0.238507 0.433334 0.0854926 0.108334 0.841492C0.108334 1.16549 0 1.38149 0 1.59749V20.1735C0 21.0375 0.65 21.6855 1.51667 21.6855C2.38333 21.6855 3.14167 21.0375 3.14167 20.1735Z"
      fill="#FF002A"
    />
    <path
      d="M38.1334 7.53748C37.8084 6.67348 36.9417 6.13348 35.9667 6.13348H29.6834C29.1417 6.13348 28.7084 6.56548 28.7084 7.10548V14.7735C28.7084 15.3135 29.1417 15.7455 29.6834 15.7455C30.225 15.7455 30.6584 15.3135 30.6584 14.7735V12.5055H35.9667C37.2667 12.5055 38.2417 11.5335 38.2417 10.2375V8.50948C38.2417 8.07748 38.2417 7.75348 38.1334 7.53748ZM36.4 10.2375V10.3455C36.4 10.4535 36.2917 10.4535 36.2917 10.5615C36.1834 10.5615 36.1834 10.6695 36.075 10.6695H30.7667V8.07748H36.075H36.1834C36.2917 8.07748 36.4 8.18548 36.4 8.29348V8.40148V10.2375Z"
      fill="white"
    />
    <path
      d="M83.85 10.3455C83.525 10.1295 82.9833 9.91348 82.55 9.91348H76.9167H76.8083C76.7 9.91348 76.7 9.80548 76.5917 9.80548C76.5917 9.69748 76.4833 9.69748 76.4833 9.58948V8.40148V8.29348C76.4833 8.18548 76.5917 8.18548 76.5917 8.07748H76.7H76.8083H83.4167C83.9583 8.07748 84.3917 7.64548 84.3917 7.10548C84.3917 6.56548 83.9583 6.13348 83.4167 6.13348H76.8083C75.5083 6.13348 74.5333 7.10548 74.5333 8.40148V9.58948C74.5333 10.8855 75.5083 11.8575 76.8083 11.8575H82.4417H82.55C82.6583 11.8575 82.7667 11.9655 82.7667 12.0735V12.1815V13.3695V13.4775C82.7667 13.5855 82.6583 13.5855 82.6583 13.6935C82.55 13.6935 82.55 13.8015 82.4417 13.8015H75.8333C75.2917 13.8015 74.8583 14.2335 74.8583 14.7735C74.8583 15.3135 75.2917 15.7455 75.8333 15.7455H82.4417C83.7416 15.7455 84.7166 14.7735 84.7166 13.4775V12.2895C84.9333 11.4255 84.5 10.7775 83.85 10.3455Z"
      fill="white"
    />
    <path
      d="M72.3667 6.34948C71.9334 6.02548 71.3917 6.02548 71.0667 6.45748L67.275 10.7775L63.4834 6.45748C63.1584 6.02548 62.5084 6.02548 62.1834 6.34948C61.75 6.67348 61.75 7.32148 62.075 7.64548L66.3 12.5055V14.6655C66.3 15.2055 66.7334 15.6375 67.275 15.6375C67.8167 15.6375 68.25 15.2055 68.25 14.6655V12.5055L72.475 7.64548C72.8 7.32148 72.8 6.67348 72.3667 6.34948Z"
      fill="white"
    />
    <path
      d="M97.5 7.53748C97.175 6.67348 96.3084 6.13348 95.3334 6.13348H89.7001C88.4001 6.13348 87.425 7.10548 87.425 8.40148V13.3695C87.425 14.6655 88.4001 15.6375 89.7001 15.6375H95.3334C96.6334 15.6375 97.6084 14.6655 97.6084 13.3695V8.40148C97.6084 8.07748 97.6084 7.75348 97.5 7.53748ZM95.7667 13.3695V13.4775C95.7667 13.5855 95.6584 13.6935 95.55 13.6935H95.4417H89.8084H89.7001C89.5917 13.6935 89.4834 13.5855 89.4834 13.4775V13.3695V8.40148V8.29348C89.4834 8.18548 89.5917 8.18548 89.5917 8.07748C89.7 8.07748 89.7001 7.96948 89.8084 7.96948H95.3334H95.4417C95.55 7.96948 95.55 8.07748 95.6584 8.07748V8.18548V8.29348V13.3695H95.7667Z"
      fill="white"
    />
    <path
      d="M109.525 6.13348C108.983 6.13348 108.55 6.56548 108.55 7.10548V12.5055L101.833 6.34948C101.4 6.02548 100.858 6.02548 100.533 6.34948C100.317 6.56548 100.317 6.78148 100.317 6.99748V14.6655C100.317 15.2055 100.75 15.6375 101.292 15.6375C101.833 15.6375 102.267 15.2055 102.267 14.6655V9.26548L108.983 15.4215C109.417 15.7455 109.958 15.7455 110.283 15.4215C110.5 15.2055 110.5 14.9895 110.5 14.7735V7.10548C110.5 6.56548 110.067 6.13348 109.525 6.13348Z"
      fill="white"
    />
    <path
      d="M48.4251 13.6935H42.6834V6.99748C42.6834 6.45748 42.2501 6.02548 41.7084 6.02548C41.1667 6.02548 40.7334 6.45748 40.7334 6.99748V14.6655C40.7334 15.2055 41.1667 15.6375 41.7084 15.6375H48.4251C48.9667 15.6375 49.4001 15.2055 49.4001 14.6655C49.4001 14.1255 48.9667 13.6935 48.4251 13.6935Z"
      fill="white"
    />
    <path
      d="M61.425 15.6375C61.1 15.6375 60.775 15.4215 60.5584 15.2055L56.875 8.94148L53.3 15.2055C53.0834 15.6375 52.4334 15.8535 52 15.5295C51.5667 15.2055 51.35 14.6655 51.675 14.2335L56.1167 6.56548C56.3334 6.13348 56.9834 5.91748 57.4167 6.24148C57.525 6.34948 57.6334 6.45748 57.7417 6.56548L62.1834 14.2335C62.4 14.6655 62.2917 15.3135 61.8584 15.5295C61.75 15.6375 61.6417 15.6375 61.425 15.6375Z"
      fill="white"
    />
  </svg>
);

IconProviderPlaysonBig.propTypes = {
  className: PropTypes.string,
};
IconProviderPlaysonBig.defaultProps = {
  className: null,
};
