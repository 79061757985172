import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderPgSoftBig = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="124"
    height="30"
    viewBox="0 0 124 30"
    fill="none"
  >
    <path d="M5.31356 0H2.89832V2.41934H5.31356V0Z" fill="white" />
    <path d="M8.21188 0H5.79663V2.41934H8.21188V0Z" fill="white" />
    <path d="M11.1102 0H8.69495V2.41934H11.1102V0Z" fill="white" />
    <path d="M14.0084 0H11.5931V2.41934H14.0084V0Z" fill="white" />
    <path d="M16.9067 0H14.4915V2.41934H16.9067V0Z" fill="white" />
    <path d="M19.805 0H17.3898V2.41934H19.805V0Z" fill="white" />
    <path d="M22.7033 0H20.2881V2.41934H22.7033V0Z" fill="white" />
    <path d="M25.6017 0H23.1864V2.41934H25.6017V0Z" fill="white" />
    <path d="M5.31356 2.90321H2.89832V5.32256H5.31356V2.90321Z" fill="white" />
    <path d="M8.21188 2.90321H5.79663V5.32256H8.21188V2.90321Z" fill="white" />
    <path d="M11.1102 2.90321H8.69495V5.32256H11.1102V2.90321Z" fill="white" />
    <path d="M14.0084 2.90321H11.5931V5.32256H14.0084V2.90321Z" fill="white" />
    <path d="M16.9067 2.90321H14.4915V5.32256H16.9067V2.90321Z" fill="white" />
    <path d="M19.805 2.90321H17.3898V5.32256H19.805V2.90321Z" fill="white" />
    <path d="M22.7033 2.90321H20.2881V5.32256H22.7033V2.90321Z" fill="white" />
    <path d="M25.6017 2.90321H23.1864V5.32256H25.6017V2.90321Z" fill="white" />
    <path d="M2.41525 2.90321H0V5.32256H2.41525V2.90321Z" fill="white" />
    <path d="M5.31356 5.80642H2.89832V8.22576H5.31356V5.80642Z" fill="white" />
    <path d="M2.41525 5.80642H0V8.22576H2.41525V5.80642Z" fill="white" />
    <path d="M5.31356 8.70963H2.89832V11.129H5.31356V8.70963Z" fill="white" />
    <path d="M2.41525 8.70963H0V11.129H2.41525V8.70963Z" fill="white" />
    <path d="M5.31356 11.6128H2.89832V14.0322H5.31356V11.6128Z" fill="white" />
    <path d="M2.41525 11.6128H0V14.0322H2.41525V11.6128Z" fill="white" />
    <path d="M5.31356 14.5161H2.89832V16.9354H5.31356V14.5161Z" fill="white" />
    <path d="M2.41525 14.5161H0V16.9354H2.41525V14.5161Z" fill="white" />
    <path d="M5.31356 17.4193H2.89832V19.8386H5.31356V17.4193Z" fill="white" />
    <path d="M2.41525 17.4193H0V19.8386H2.41525V17.4193Z" fill="white" />
    <path d="M8.21188 17.4193H5.79663V19.8386H8.21188V17.4193Z" fill="white" />
    <path d="M11.1102 17.4193H8.69495V19.8386H11.1102V17.4193Z" fill="white" />
    <path d="M14.0084 17.4193H11.5931V19.8386H14.0084V17.4193Z" fill="white" />
    <path d="M16.9067 17.4193H14.4915V19.8386H16.9067V17.4193Z" fill="white" />
    <path d="M19.805 17.4193H17.3898V19.8386H19.805V17.4193Z" fill="white" />
    <path d="M22.7033 17.4193H20.2881V19.8386H22.7033V17.4193Z" fill="white" />
    <path d="M22.7033 14.5161H20.2881V16.9354H22.7033V14.5161Z" fill="white" />
    <path d="M25.6017 17.4193H23.1864V19.8386H25.6017V17.4193Z" fill="white" />
    <path d="M25.6017 14.5161H23.1864V16.9354H25.6017V14.5161Z" fill="white" />
    <path d="M28.5 14.5161H26.0847V16.9354H28.5V14.5161Z" fill="white" />
    <path d="M25.6017 11.6128H23.1864V14.0322H25.6017V11.6128Z" fill="white" />
    <path d="M28.5 11.6128H26.0847V14.0322H28.5V11.6128Z" fill="white" />
    <path d="M25.6017 8.70963H23.1864V11.129H25.6017V8.70963Z" fill="white" />
    <path d="M28.5 8.70963H26.0847V11.129H28.5V8.70963Z" fill="white" />
    <path d="M25.6017 5.80642H23.1864V8.22576H25.6017V5.80642Z" fill="white" />
    <path d="M28.5 5.80642H26.0847V8.22576H28.5V5.80642Z" fill="white" />
    <path d="M22.7033 5.80642H20.2881V8.22576H22.7033V5.80642Z" fill="white" />
    <path d="M2.41525 20.3225H0V22.7418H2.41525V20.3225Z" fill="white" />
    <path d="M5.31356 20.3225H2.89832V22.7418H5.31356V20.3225Z" fill="white" />
    <path d="M2.41525 23.2257H0V25.645H2.41525V23.2257Z" fill="white" />
    <path d="M5.31356 23.2257H2.89832V25.645H5.31356V23.2257Z" fill="white" />
    <path d="M2.41525 26.1289H0V28.5482H2.41525V26.1289Z" fill="white" />
    <path d="M5.31356 26.1289H2.89832V28.5482H5.31356V26.1289Z" fill="white" />
    <path d="M8.21188 20.3225H5.79663V22.7418H8.21188V20.3225Z" fill="white" />
    <path d="M11.1102 20.3225H8.69495V22.7418H11.1102V20.3225Z" fill="white" />
    <path d="M14.0084 20.3225H11.5931V22.7418H14.0084V20.3225Z" fill="white" />
    <path d="M16.9067 20.3225H14.4915V22.7418H16.9067V20.3225Z" fill="white" />
    <path d="M19.805 20.3225H17.3898V22.7418H19.805V20.3225Z" fill="white" />
    <path d="M37.1948 5.80642H34.7795V8.22576H37.1948V5.80642Z" fill="white" />
    <path d="M40.0931 5.80642H37.6779V8.22576H40.0931V5.80642Z" fill="white" />
    <path d="M42.9914 5.80642H40.5762V8.22576H42.9914V5.80642Z" fill="white" />
    <path d="M40.0931 2.90321H37.6779V5.32256H40.0931V2.90321Z" fill="white" />
    <path d="M42.9914 2.90321H40.5762V5.32256H42.9914V2.90321Z" fill="white" />
    <path d="M42.9914 0H40.5762V2.41934H42.9914V0Z" fill="white" />
    <path d="M45.8897 2.90321H43.4745V5.32256H45.8897V2.90321Z" fill="white" />
    <path d="M45.8897 0H43.4745V2.41934H45.8897V0Z" fill="white" />
    <path d="M48.7879 2.90321H46.3727V5.32256H48.7879V2.90321Z" fill="white" />
    <path d="M48.7879 0H46.3727V2.41934H48.7879V0Z" fill="white" />
    <path d="M40.0931 8.70963H37.6779V11.129H40.0931V8.70963Z" fill="white" />
    <path d="M37.1948 8.70963H34.7795V11.129H37.1948V8.70963Z" fill="white" />
    <path d="M40.0931 11.6128H37.6779V14.0322H40.0931V11.6128Z" fill="white" />
    <path d="M37.1948 11.6128H34.7795V14.0322H37.1948V11.6128Z" fill="white" />
    <path d="M37.1948 14.5161H34.7795V16.9354H37.1948V14.5161Z" fill="white" />
    <path d="M40.0931 14.5161H37.6779V16.9354H40.0931V14.5161Z" fill="white" />
    <path d="M40.0931 17.4193H37.6779V19.8386H40.0931V17.4193Z" fill="white" />
    <path d="M37.1948 17.4193H34.7795V19.8386H37.1948V17.4193Z" fill="white" />
    <path d="M40.0931 20.3225H37.6779V22.7418H40.0931V20.3225Z" fill="white" />
    <path d="M37.1948 20.3225H34.7795V22.7418H37.1948V20.3225Z" fill="white" />
    <path d="M42.9914 20.3225H40.5762V22.7418H42.9914V20.3225Z" fill="white" />
    <path d="M40.0931 23.2257H37.6779V25.645H40.0931V23.2257Z" fill="white" />
    <path d="M42.9914 23.2257H40.5762V25.645H42.9914V23.2257Z" fill="white" />
    <path d="M45.8897 23.2257H43.4745V25.645H45.8897V23.2257Z" fill="white" />
    <path d="M42.9914 26.1289H40.5762V28.5482H42.9914V26.1289Z" fill="white" />
    <path d="M45.8897 26.1289H43.4745V28.5482H45.8897V26.1289Z" fill="white" />
    <path d="M48.7879 23.2257H46.3727V25.645H48.7879V23.2257Z" fill="white" />
    <path d="M48.7879 26.1289H46.3727V28.5482H48.7879V26.1289Z" fill="white" />
    <path d="M51.6862 23.2257H49.271V25.645H51.6862V23.2257Z" fill="white" />
    <path d="M51.6862 26.1289H49.271V28.5482H51.6862V26.1289Z" fill="white" />
    <path d="M54.5846 23.2257H52.1693V25.645H54.5846V23.2257Z" fill="white" />
    <path d="M54.5846 26.1289H52.1693V28.5482H54.5846V26.1289Z" fill="white" />
    <path d="M57.4829 26.1289H55.0676V28.5482H57.4829V26.1289Z" fill="white" />
    <path d="M57.4829 23.2257H55.0676V25.645H57.4829V23.2257Z" fill="white" />
    <path d="M60.3812 23.2257H57.9659V25.645H60.3812V23.2257Z" fill="white" />
    <path d="M57.4829 20.3225H55.0676V22.7418H57.4829V20.3225Z" fill="white" />
    <path d="M60.3812 20.3225H57.9659V22.7418H60.3812V20.3225Z" fill="white" />
    <path d="M63.2795 20.3225H60.8643V22.7418H63.2795V20.3225Z" fill="white" />
    <path d="M60.3812 17.4193H57.9659V19.8386H60.3812V17.4193Z" fill="white" />
    <path d="M63.2795 17.4193H60.8643V19.8386H63.2795V17.4193Z" fill="white" />
    <path d="M60.3812 14.5161H57.9659V16.9354H60.3812V14.5161Z" fill="white" />
    <path d="M63.2795 14.5161H60.8643V16.9354H63.2795V14.5161Z" fill="white" />
    <path d="M57.4829 14.5161H55.0676V16.9354H57.4829V14.5161Z" fill="white" />
    <path d="M54.5846 14.5161H52.1693V16.9354H54.5846V14.5161Z" fill="white" />
    <path d="M51.6862 14.5161H49.271V16.9354H51.6862V14.5161Z" fill="white" />
    <path d="M51.6862 11.6128H49.271V14.0322H51.6862V11.6128Z" fill="white" />
    <path d="M54.5846 11.6128H52.1693V14.0322H54.5846V11.6128Z" fill="white" />
    <path d="M57.4829 11.6128H55.0676V14.0322H57.4829V11.6128Z" fill="white" />
    <path d="M60.3812 11.6128H57.9659V14.0322H60.3812V11.6128Z" fill="white" />
    <path d="M63.2795 11.6128H60.8643V14.0322H63.2795V11.6128Z" fill="white" />
    <path d="M51.6862 0H49.271V2.41934H51.6862V0Z" fill="white" />
    <path d="M51.6862 2.90321H49.271V5.32256H51.6862V2.90321Z" fill="white" />
    <path d="M54.5846 0H52.1693V2.41934H54.5846V0Z" fill="white" />
    <path d="M54.5846 2.90321H52.1693V5.32256H54.5846V2.90321Z" fill="white" />
    <path d="M57.4829 2.90321H55.0676V5.32256H57.4829V2.90321Z" fill="white" />
    <path d="M60.3812 2.90321H57.9659V5.32256H60.3812V2.90321Z" fill="white" />
    <path d="M57.4829 5.80642H55.0676V8.22576H57.4829V5.80642Z" fill="white" />
    <path d="M60.3812 5.80642H57.9659V8.22576H60.3812V5.80642Z" fill="white" />
    <path d="M81.1524 0H80.1863H79.2202H78.2541H77.288V0.967737H76.3219V1.93547V2.90321V3.87095V4.83868V5.80642V6.77416H77.288V7.74189H78.2541H79.2202H80.1863H81.1524V6.77416H82.1185V5.80642V4.83868V3.87095V2.90321V1.93547V0.967737H81.1524V0ZM80.1863 2.90321V3.87095V4.83868V5.80642H79.2202H78.2541V4.83868V3.87095V2.90321V1.93547H79.2202H80.1863V2.90321Z" fill="white" />
    <path d="M74.3897 0H73.4236H72.4575H71.4914H70.5253H69.5592V0.967737V1.93547V2.90321V3.87095V4.83868V5.80642V6.77416V7.74189H70.5253H71.4914V6.77416V5.80642V4.83868H72.4575H73.4236H74.3897V3.87095H75.3558V2.90321V1.93547V0.967737H74.3897V0ZM73.4236 2.90321H72.4575H71.4914V1.93547H72.4575H73.4236V2.90321Z" fill="white" />
    <path d="M84.0507 7.74189H85.0168H85.9829H86.949H87.9151V6.77416H88.8812V5.80642V4.83868H87.9151H86.949V5.80642H85.9829H85.0168V4.83868V3.87095V2.90321V1.93547H85.9829H86.949V2.90321H87.9151H88.8812V1.93547V0.967737H87.9151V0H86.949H85.9829H85.0168H84.0507V0.967737H83.0846V1.93547V2.90321V3.87095V4.83868V5.80642V6.77416H84.0507V7.74189Z" fill="white" />
    <path d="M91.7795 7.74189V6.77416V5.80642V4.83868H92.7456H93.7117V5.80642V6.77416V7.74189H94.6778H95.6439V6.77416V5.80642V4.83868H94.6778V3.87095V2.90321H95.6439V1.93547V0.967737V0H94.6778H93.7117V0.967737V1.93547V2.90321H92.7456H91.7795V1.93547V0.967737V0H90.8134H89.8473V0.967737V1.93547V2.90321V3.87095V4.83868V5.80642V6.77416V7.74189H90.8134H91.7795Z" fill="white" />
    <path d="M98.5422 7.74189H99.5083H100.474H101.44H102.407V6.77416V5.80642H101.44H100.474H99.5083H98.5422V4.83868H99.5083H100.474H101.44V3.87095V2.90321H100.474H99.5083H98.5422V1.93547H99.5083H100.474H101.44H102.407V0.967737V0H101.44H100.474H99.5083H98.5422H97.5761H96.61V0.967737V1.93547V2.90321V3.87095V4.83868V5.80642V6.77416V7.74189H97.5761H98.5422Z" fill="white" />
    <path d="M107.237 0H106.271H105.305H104.339H103.373V0.967737V1.93547H104.339H105.305V2.90321V3.87095V4.83868V5.80642V6.77416V7.74189H106.271H107.237V6.77416V5.80642V4.83868V3.87095V2.90321V1.93547H108.203H109.169V0.967737V0H108.203H107.237Z" fill="white" />
    <path d="M116.898 0H116.415H115.932H115.449H114.966H114.483V0.483868H114.966H115.449H115.932V0.967737V1.4516V1.93547V2.41934V2.90321V3.38708V3.87095V4.35482V4.83868H116.415V4.35482V3.87095V3.38708V2.90321V2.41934V1.93547V1.4516V0.967737V0.483868H116.898H117.381H117.864V0H117.381H116.898Z" fill="white" />
    <path d="M122.695 0H122.212V0.483868V0.967737H121.729V1.4516V1.93547H121.246V2.41934V2.90321H120.763H120.279V2.41934V1.93547H119.796V1.4516V0.967737H119.313V0.483868H118.83V0H118.347V0.483868V0.967737V1.4516V1.93547V2.41934V2.90321V3.38708V3.87095V4.35482V4.83868H118.83V4.35482V3.87095V3.38708V2.90321V2.41934V1.93547V1.4516H119.313V1.93547V2.41934H119.796V2.90321V3.38708H120.279V3.87095H120.763H121.246V3.38708H121.729V2.90321V2.41934H122.212V1.93547V1.4516H122.695V1.93547V2.41934V2.90321V3.38708V3.87095V4.35482V4.83868H123.178V4.35482V3.87095V3.38708V2.90321V2.41934V1.93547V1.4516V0.967737V0.483868V0H122.695Z" fill="white" />
    <path d="M73.4236 13.5483H72.4575V14.516V15.4838H73.4236V16.4515H72.4575H71.4914V15.4838V14.516V13.5483V12.5806H72.4575H73.4236H74.3897V11.6128V10.6451H73.4236H72.4575H71.4914H70.5253V11.6128H69.5592V12.5806V13.5483V14.516V15.4838V16.4515V17.4193H70.5253V18.387H71.4914H72.4575H73.4236H74.3897V17.4193H75.3558V16.4515V15.4838V14.516V13.5483H74.3897H73.4236Z" fill="white" />
    <path d="M81.1524 10.6451H80.1863H79.2202H78.2541H77.288V11.6128H76.3219V12.5806V13.5483V14.516V15.4838V16.4515V17.4193V18.387H77.288H78.2541V17.4193V16.4515H79.2202H80.1863V17.4193V18.387H81.1524H82.1185V17.4193V16.4515V15.4838V14.516V13.5483V12.5806V11.6128H81.1524V10.6451ZM80.1863 13.5483V14.516H79.2202H78.2541V13.5483V12.5806H79.2202H80.1863V13.5483Z" fill="white" />
    <path d="M89.8473 17.4193V16.4515V15.4838V14.516V13.5483V12.5806V11.6128V10.6451H88.8812V11.6128H87.9151V12.5806H86.949V13.5483H85.9829V12.5806H85.0168V11.6128H84.0507V10.6451H83.0846V11.6128V12.5806V13.5483V14.516V15.4838V16.4515V17.4193V18.387H84.0507H85.0168V17.4193V16.4515V15.4838H85.9829V16.4515H86.949V15.4838H87.9151V16.4515V17.4193V18.387H88.8812H89.8473V17.4193Z" fill="white" />
    <path d="M94.6778 10.6451H93.7117H92.7456H91.7795H90.8134V11.6128V12.5806V13.5483V14.516V15.4838V16.4515V17.4193V18.387H91.7795H92.7456H93.7117H94.6778H95.6439H96.61V17.4193V16.4515H95.6439H94.6778H93.7117H92.7456V15.4838H93.7117H94.6778H95.6439V14.516V13.5483H94.6778H93.7117H92.7456V12.5806H93.7117H94.6778H95.6439H96.61V11.6128V10.6451H95.6439H94.6778Z" fill="white" />
    <path d="M101.44 10.6451H100.474H99.5082H98.5421V11.6128H97.576V12.5806V13.5483V14.516H98.5421V15.4838H99.5082H100.474H101.44V16.4515H100.474H99.5082H98.5421H97.576V17.4193V18.387H98.5421H99.5082H100.474H101.44H102.407V17.4193H103.373V16.4515V15.4838V14.516H102.407V13.5483H101.44H100.474H99.5082V12.5806H100.474H101.44H102.407H103.373V11.6128V10.6451H102.407H101.44Z" fill="white" />
    <path d="M73.4236 21.2902H72.4575H71.4914H70.5253V22.2579H69.5592V23.2257V24.1934V25.1612H70.5253V26.1289H71.4914H72.4575H73.4236V27.0966H72.4575H71.4914H70.5253H69.5592V28.0644V29.0321H70.5253H71.4914H72.4575H73.4236H74.3897V28.0644H75.3558V27.0966V26.1289V25.1612H74.3897V24.1934H73.4236H72.4575H71.4914V23.2257H72.4575H73.4236H74.3897H75.3558V22.2579V21.2902H74.3897H73.4236Z" fill="white" />
    <path d="M81.1524 21.2902H80.1863H79.2202H78.2541H77.288V22.2579H76.3219V23.2257V24.1934V25.1612V26.1289V27.0966V28.0644H77.288V29.0321H78.2541H79.2202H80.1863H81.1524V28.0644H82.1185V27.0966V26.1289V25.1612V24.1934V23.2257V22.2579H81.1524V21.2902ZM80.1863 24.1934V25.1612V26.1289V27.0966H79.2202H78.2541V26.1289V25.1612V24.1934V23.2257H79.2202H80.1863V24.1934Z" fill="white" />
    <path d="M86.949 21.2902H85.9829H85.0168H84.0507H83.0846V22.2579V23.2257V24.1934V25.1612V26.1289V27.0966V28.0644V29.0321H84.0507H85.0168V28.0644V27.0966V26.1289H85.9829H86.949H87.9151V25.1612V24.1934H86.949H85.9829H85.0168V23.2257H85.9829H86.949H87.9151H88.8812V22.2579V21.2902H87.9151H86.949Z" fill="white" />
    <path d="M93.7117 21.2902H92.7456H91.7795H90.8134H89.8473V22.2579V23.2257H90.8134H91.7795V24.1934V25.1612V26.1289V27.0966V28.0644V29.0321H92.7456H93.7117V28.0644V27.0966V26.1289V25.1612V24.1934V23.2257H94.6778H95.6439V22.2579V21.2902H94.6778H93.7117Z" fill="white" />
  </svg>
);

IconProviderPgSoftBig.propTypes = {
  className: PropTypes.string,
};

IconProviderPgSoftBig.defaultProps = {
  className: '',
};
