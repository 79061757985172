import React from 'react';
import PropTypes from 'prop-types';

export const IconRedirect = ({ className, color }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68">
    <path
      fill={color}
      fillRule="evenodd"
      d="M34 0c18.778 0 34 15.222 34 34S52.778 68 34 68 0 52.778 0 34 15.222 0 34 0zm0 4C17.431 4 4 17.431 4 34c0 16.569 13.431 30 30 30 16.569 0 30-13.431 30-30C64 17.431 50.569 4 34 4zm3.287 20.469l.127.117 9 9a2 2 0 0 1 .117 2.701l-.117.127-9 9a2 2 0 0 1-2.945-2.701l.117-.127L40.17 37H23a2 2 0 0 1 0-4h17.171l-5.585-5.586a2 2 0 0 1-.117-2.701l.117-.127a2 2 0 0 1 2.701-.117z"
    />
  </svg>
);

IconRedirect.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconRedirect.defaultProps = {
  className: null,
  color: '#29292e',
};
