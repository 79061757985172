import React from 'react';
import PropTypes from 'prop-types';

export const IconError = ({ className }) => (
  <svg className={className} width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="64" height="64" fill="none" />
    <rect x="-156" y="-32" width="375" height="323" rx="10" fill="none" />
    <circle r="30.0714" transform="matrix(-1 0 0 1 31.9989 32)" stroke="#D4373E" strokeWidth="3" />
    <path d="M32.0034 15.1864C33.8606 15.1864 35.3661 16.692 35.3661 18.5491V32.0001C35.3661 33.8573 33.8606 35.3629 32.0034 35.3629C30.1462 35.3629 28.6406 33.8573 28.6406 32.0001V18.5491C28.6406 16.692 30.1462 15.1864 32.0034 15.1864Z" fill="#D4373E" />
    <path d="M32.0034 42.0879C30.1462 42.0879 28.6406 43.5935 28.6406 45.4507C28.6406 47.3079 30.1462 48.8134 32.0034 48.8134H32.0356C33.8928 48.8134 35.3984 47.3079 35.3984 45.4507C35.3984 43.5935 33.8928 42.0879 32.0356 42.0879H32.0034Z" fill="#D4373E" />
  </svg>
);

IconError.propTypes = {
  className: PropTypes.string,
};

IconError.defaultProps = {
  className: null,
};
