import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderSpribeBig = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="96"
    height="40"
    viewBox="0 0 96 40"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.136597 25.033V23.067H5.3252V25.355C5.3252 27.4491 6.13432 28.4967 7.75141 28.4967H7.77192C9.3742 28.4967 10.1765 27.4525 10.1765 25.3652C10.1765 24.1724 9.83118 23.0545 9.13831 22.0103C8.44657 20.9661 7.61922 20.0114 6.65626 19.1464L4.05569 16.4627C2.98104 15.5239 2.0819 14.3607 1.35825 12.974C0.633463 11.5863 0.27107 10.05 0.27107 8.36519C0.27107 5.77222 0.95483 3.78356 2.32235 2.39694C3.68873 1.01032 5.54628 0.317574 7.89272 0.317574C10.2392 0.317574 12.0409 1.01032 13.299 2.3958C14.5571 3.78129 15.1862 5.76995 15.1862 8.36179V9.39014H10.401V8.05C10.401 5.94909 9.5554 4.89807 7.86309 4.89807C6.17078 4.89807 5.3252 5.95023 5.3252 8.05227C5.3252 8.93209 5.50982 9.77109 5.87791 10.5693C6.246 11.3675 6.72349 12.0863 7.31039 12.728C7.89728 13.3686 8.54457 14.0024 9.25113 14.6282C9.95768 15.2552 10.6563 15.9117 11.3446 16.5976C12.0329 17.2836 12.6699 18.0171 13.2545 18.8006C13.838 19.5829 14.3132 20.508 14.6802 21.5738C15.0471 22.6407 15.2306 23.7994 15.2306 25.0523C15.2306 27.6384 14.5867 29.6226 13.299 31.0046C12.0112 32.3867 10.1936 33.0783 7.846 33.0783C5.49842 33.0783 3.62606 32.3856 2.23004 31.0001C0.834033 29.6146 0.136597 27.6248 0.136597 25.033Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.1595 16.1167H25.5629C26.3857 16.1167 27.0034 15.8854 27.4159 15.4239C27.8273 14.9625 28.0336 14.1802 28.0336 13.077V8.27313C28.0336 7.16995 27.8273 6.39218 27.4159 5.93753C27.0034 5.48288 26.3857 5.25612 25.5629 5.25612H23.1595V16.1167ZM18.1053 32.7198V0.675621H25.5515C30.5749 0.675621 33.0877 3.32301 33.0877 8.61667V12.7788C33.0877 18.0577 30.5771 20.6983 25.5572 20.6983H23.1595V32.7198H18.1053Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.4996 15.1111H42.4756C43.4944 15.1111 44.2546 14.8503 44.756 14.3288C45.2574 13.8073 45.5081 12.9365 45.5081 11.7143V8.56349C45.5229 6.35941 44.6694 5.25623 42.9474 5.25623H40.4996V15.1111ZM35.4454 32.7199V0.674599H43.0716C45.6722 0.674599 47.5719 1.27891 48.7674 2.48639C49.964 3.69387 50.5622 5.54988 50.5622 8.05442V10.5805C50.5622 13.8753 49.4591 16.0442 47.2528 17.0873C49.4887 18.0272 50.6067 20.3004 50.6067 23.9082V28.839V29.0397C50.6067 30.6757 50.7867 31.9025 51.1457 32.7199H46.0221C45.7246 31.8129 45.5685 30.5045 45.5537 28.7936V23.7744C45.5537 22.3175 45.295 21.2721 44.7788 20.6406C44.2614 20.0079 43.4192 19.6927 42.2511 19.6927H40.4996V32.7199H35.4454Z"
      fill="white"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M53.9316 32.7202H58.9858V0.674828H53.9316V32.7202Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.7227 28.1392H70.6207C71.5039 28.1392 72.1626 27.9045 72.5968 27.4352C73.031 26.9658 73.2486 26.1506 73.2486 24.9884V22.1948C73.2486 20.7345 72.9979 19.722 72.4954 19.1562C71.9939 18.5893 71.1597 18.307 69.9917 18.307H67.7227V28.1392ZM67.7227 13.7254H69.7079C70.7301 13.7254 71.4937 13.4646 71.9974 12.9431C72.5022 12.4215 72.754 11.5576 72.754 10.3513V8.56327C72.7689 6.35919 71.9119 5.25601 70.182 5.25601H67.7227V13.7254ZM62.6686 32.7209V0.675514H70.3062C72.9113 0.675514 74.8133 1.27869 76.011 2.48504C77.2087 3.69139 77.8082 5.54626 77.8082 8.04966V9.18912C77.8082 12.4816 76.7153 14.6347 74.5284 15.6472C77.0446 16.6302 78.3016 18.9181 78.3016 22.5077V25.1222C78.3016 27.595 77.6509 29.4805 76.3484 30.7764C75.0446 32.0723 73.1358 32.7209 70.6207 32.7209H62.6686Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.3345 32.7203V0.67495H95.1259V5.25658H86.3886V14.1727H93.3288V18.7532H86.3886V28.1387H95.1259V32.7203H81.3345Z"
      fill="white"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M0 39.2451H95.1966V34.7961H0V39.2451Z" fill="white" />
  </svg>
);

IconProviderSpribeBig.propTypes = {
  className: PropTypes.string,
};

IconProviderSpribeBig.defaultProps = {
  className: '',
};
