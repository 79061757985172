import React from 'react';
import PropTypes from 'prop-types';

export const IconPlayCircleDemo = ({ className }) => (
  <svg className={className} width="16" height="16" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M42.734 36.0493C49.9585 29.8479 49.6908 18.5719 42.2922 12.5854C35.626 7.19146 28.4816 3.29821 20.5592 0.734612C9.73964 -2.7665 0 6.76762 0 18.291V30.7972C0 41.9828 9.20045 51.443 19.818 48.4353C28.3106 46.0296 35.8324 41.9733 42.734 36.0493Z" fill="#FFC328" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.9284 30.9883V17.0335C16.9284 16.6279 17.0919 16.3273 17.264 16.172C17.4096 16.0407 17.5631 15.997 17.7723 16.066C22.0027 17.4619 26.7039 20.813 29.6732 23.1826C30.2006 23.6035 30.2011 24.3425 29.6938 24.7636C26.7165 27.2347 21.9839 30.6938 17.7052 31.9426C17.5182 31.9972 17.3821 31.9575 17.2461 31.8292C17.0835 31.6759 16.9284 31.3808 16.9284 30.9883ZM30.837 21.5613C32.3655 22.7811 32.3928 25.1111 30.8865 26.3613C27.8892 28.849 22.8979 32.5368 18.2197 33.9022C16.4886 34.4075 15 32.8822 15 30.9883V17.0335C15 15.0778 16.578 13.5415 18.3482 14.1255C22.9193 15.6338 27.8459 19.1743 30.837 21.5613Z" fill="black" />
  </svg>

);

IconPlayCircleDemo.propTypes = {
  className: PropTypes.string,
};

IconPlayCircleDemo.defaultProps = {
  className: null,
};
