import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderGGames = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M12.0031 1.02207L1.07165 12.1637C0.898475 12.3402 0.902665 12.6241 1.08098 12.7955L11.7714 23.0667C11.9479 23.2362 12.2281 23.2312 12.3984 23.0556L18.8915 16.3596C19.0626 16.1831 19.058 15.9012 18.8811 15.7305L13.0713 10.121C12.8969 9.9526 12.6197 9.95503 12.4483 10.1264L10.4047 12.17C10.2311 12.3436 10.2311 12.625 10.4047 12.7986L13.554 15.9478C13.7275 16.1214 13.7275 16.4028 13.554 16.5764L12.4047 17.7256C12.2311 17.8992 11.9497 17.8992 11.7762 17.7256L6.40472 12.3541C6.23115 12.1806 6.23115 11.8992 6.40472 11.7256L11.9984 6.13191C12.172 5.95834 12.4534 5.95834 12.6269 6.13191L16.8873 10.3923C17.0609 10.5658 17.3423 10.5658 17.5158 10.3923L19.5492 8.35886C19.7246 8.18346 19.7225 7.89843 19.5445 7.72566L12.6299 1.01441C12.4544 0.844101 12.1743 0.847525 12.0031 1.02207ZM21.4236 14.4843C22.8964 14.4843 24.0903 13.2904 24.0903 11.8176C24.0903 10.3448 22.8964 9.15093 21.4236 9.15093C19.9509 9.15093 18.757 10.3448 18.757 11.8176C18.757 13.2904 19.9509 14.4843 21.4236 14.4843Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.0031 1.02207L1.07165 12.1637C0.898475 12.3402 0.902665 12.6241 1.08098 12.7955L11.7714 23.0667C11.9479 23.2362 12.2281 23.2312 12.3984 23.0556L18.8915 16.3596C19.0626 16.1831 19.058 15.9012 18.8811 15.7305L13.0713 10.121C12.8969 9.9526 12.6197 9.95503 12.4483 10.1264L10.4047 12.17C10.2311 12.3436 10.2311 12.625 10.4047 12.7986L13.554 15.9478C13.7275 16.1214 13.7275 16.4028 13.554 16.5764L12.4047 17.7256C12.2311 17.8992 11.9497 17.8992 11.7762 17.7256L6.40472 12.3541C6.23115 12.1806 6.23115 11.8992 6.40472 11.7256L11.9984 6.13191C12.172 5.95834 12.4534 5.95834 12.6269 6.13191L16.8873 10.3923C17.0609 10.5658 17.3423 10.5658 17.5158 10.3923L19.5492 8.35886C19.7246 8.18346 19.7225 7.89843 19.5445 7.72566L12.6299 1.01441C12.4544 0.844101 12.1743 0.847525 12.0031 1.02207ZM21.4236 14.4843C22.8964 14.4843 24.0903 13.2904 24.0903 11.8176C24.0903 10.3448 22.8964 9.15093 21.4236 9.15093C19.9509 9.15093 18.757 10.3448 18.757 11.8176C18.757 13.2904 19.9509 14.4843 21.4236 14.4843Z" fill="url(#g-games-rule-1)" />
    <defs>
      <linearGradient id="g-games-rule-1" x1="12.8681" y1="-0.462736" x2="12.8681" y2="24.5423" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF155B" />
        <stop offset="1" stopColor="#BF0E92" />
      </linearGradient>
    </defs>
  </svg>
);

IconProviderGGames.propTypes = {
  className: PropTypes.string,
};

IconProviderGGames.defaultProps = {
  className: '',
};
