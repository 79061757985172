import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Button from 'reactstrap/lib/Button';
import { injectIntl, intlShape } from 'react-intl';
import colors from 'customizations/js/color-variables';

import { FormattedTag, LoyaltyProgram as LoyaltyProgramCore } from 'core/components';
import {
  withUser,
  withUserAgent,
  withGameActions,
  withModalActions,
  withCoreComponent,
} from 'core/hocs';
import {
  USER_FIELDS,
  DEVICES_TYPES,
  LOYALTY_POINTS_KEYS,
  LOYALTY_POINTS_STATUS_INDEXES,
} from 'core/constants';
import { isEmptyOrNil } from 'core/helpers';

import { withPixelRatio } from 'hocs/with-pixel-ratio';
import { IconGift } from 'components/icons/icon-gift/icon-gift';

import { getCharacterImageUrl } from '../../../../helpers/characters';
import { FREESPINS_INFO_MOBILE, FREESPINS_INFO_DESKTOP } from '../../../../constants';

const { CURRENT_VIP_LEVEL } = LOYALTY_POINTS_KEYS;

const SORT_ORDER_INDEX = 'sortOrderIndex';
const DEFAULT_CHARACTER_INDEX = 1;

export class NewLevelContentUI extends Component {
  static propTypes = {
    loyaltyPoints: PropTypes.shape().isRequired,
    loyaltyLevels: PropTypes.arrayOf(PropTypes.shape()),
    closeModal: PropTypes.func.isRequired,
    openGame: PropTypes.func.isRequired,
    modalId: PropTypes.string.isRequired,
    userData: PropTypes.shape({
      characterId: PropTypes.number,
    }).isRequired,
    deviceType: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    pixelRatio: PropTypes.string.isRequired,
  };

  static defaultProps = {
    loyaltyLevels: [],
  };

  constructor(props) {
    super(props);

    const {
      deviceType,
      loyaltyLevels,
    } = props;

    const [
      freespinsGameId,
      freespinsGameName,
      freespinsGameProducer
    ] = deviceType === DEVICES_TYPES.MOBILE
      ? FREESPINS_INFO_MOBILE
      : FREESPINS_INFO_DESKTOP;

    const currentLevelInfo = R.find(R.propEq(SORT_ORDER_INDEX, this.getUserLevelIndex()))(loyaltyLevels);

    if (!isEmptyOrNil(loyaltyLevels) && !isEmptyOrNil(currentLevelInfo)
     && R.prop('freespinsAmount', currentLevelInfo)) {
      const {
        [freespinsGameId]: gameId,
        [freespinsGameName]: gameName,
        [freespinsGameProducer]: producer,
        freespinsAmount,
      } = currentLevelInfo;

      this.state = {
        gameId: gameId.intlKey,
        gameName: gameName.intlKey,
        producer: producer.intlKey,
        freespinsAmount,
      };
    } else {
      this.state = {};
    }
  }

  closeModal = () => {
    const { closeModal, modalId } = this.props;
    closeModal(modalId);
  };

  getUserLevelIndex = () => {
    const {
      loyaltyPoints: {
        [CURRENT_VIP_LEVEL]: currentLevel,
      },
    } = this.props;

    return LOYALTY_POINTS_STATUS_INDEXES[currentLevel];
  };

  openGame = () => {
    const { openGame, intl: { formatMessage } } = this.props;
    const { gameId, producer } = this.state;

    this.closeModal();

    openGame({
      gameId: formatMessage({ id: gameId }),
      producer: formatMessage({ id: producer })
    });
  };

  render() {
    const {
      loyaltyPoints: {
        [CURRENT_VIP_LEVEL]: currentLevel,
      },
      userData: { characterId = 1 },
      // intl: { formatMessage },
      pixelRatio,
    } = this.props;

    // const { freespinsAmount, gameName } = this.state;
    const { freespinsAmount } = this.state;
    const currentLevelIndex = LOYALTY_POINTS_STATUS_INDEXES[currentLevel];

    return (
      <div className="new-level-modal-content position-relative d-flex flex-column text-center pt-2 pt-sm-0">
        <FormattedTag id="promo.new-level.title" className="h2 text-gray-40 mb-1" />
        <img
          src={getCharacterImageUrl({
            characterId: characterId || DEFAULT_CHARACTER_INDEX,
            levelId: currentLevelIndex,
            devicePixelRatio: pixelRatio
          })}
          className={`image-level image-level-${characterId}-${currentLevelIndex} mx-auto position-relative mb-2`}
          alt=""
        />

        <FormattedTag
          id={`loyalty-program.level-type.${currentLevel}`}
          className="h2 text-gray-20 pt-0_25 mt-n1 mt-sm-n2"
        />

        {!isEmptyOrNil(this.state) && (
          <Fragment>

            <FormattedTag
              tag="p"
              id="promo.new-level.gift.text"
              className="text-gray-20 mb-0_5 mt-sm-1"
            />

            <div className="font-weight-medium d-flex align-items-center justify-content-center">
              <IconGift className="icon-gift flex-shrink-0" color={colors.gray40} />

              {freespinsAmount && (
                <FormattedTag
                  id="promo.new-level.free-spins"
                  className="mx-0_5 text-nowrap text-gray-40"
                  values={{ value: freespinsAmount }}
                />
              )}

              {/* {gameName && ( */}
              {/* <FormattedTag */}
              {/* tag="div" */}
              {/* role="button" */}
              {/* tabIndex="0" */}
              {/* id={formatMessage({ id: gameName })} */}
              {/* onClick={this.openGame} */}
              {/* onKeyPress={this.openGame} */}
              {/* className="btn-link" */}
              {/* /> */}
              {/* )} */}

            </div>
          </Fragment>
        )}

        <FormattedTag
          tag={Button}
          onClick={this.closeModal}
          id="got-it"
          color="primary"
          className="px-7_25 mt-3 mx-auto"
        />
      </div>
    );
  }
}

export const NewLevelContent = withCoreComponent(
  LoyaltyProgramCore,
  withPixelRatio(
    withModalActions(
      withGameActions(
        withUserAgent(
          withUser(
            injectIntl(NewLevelContentUI),
            [USER_FIELDS.LOYALTY_POINTS, USER_FIELDS.USER_DATA],
          )
        )
      )
    )
  )
);
