import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconAlert = ({ className, color, outlined }) => outlined ? (
  <svg className={className} width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle r="24.0571" transform="matrix(-1 0 0 1 26.0003 26)" stroke={color} strokeWidth="2.4" />
    <g clipPath="url(#icon-alert-outlined)">
      <path d="M26.0027 12.5491C27.4885 12.5491 28.6929 13.7535 28.6929 15.2393V26.0001C28.6929 27.4858 27.4885 28.6903 26.0027 28.6903C24.5169 28.6903 23.3125 27.4858 23.3125 26.0001V15.2393C23.3125 13.7535 24.5169 12.5491 26.0027 12.5491Z" fill={color} />
      <path d="M26.0027 34.0703C24.5169 34.0703 23.3125 35.2747 23.3125 36.7605C23.3125 38.2462 24.5169 39.4507 26.0027 39.4507H26.0285C27.5143 39.4507 28.7187 38.2462 28.7187 36.7605C28.7187 35.2747 27.5143 34.0703 26.0285 34.0703H26.0027Z" fill={color} />
    </g>
  </svg>
) : (
  <svg className={className} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle r="10" transform="matrix(-1 0 0 1 10 10.5)" fill={color} />
    <g clipPath="url(#icon-alert)">
      <path d="M10.0012 4.61523C10.6512 4.61523 11.1781 5.14218 11.1781 5.7922V10.5C11.1781 11.1501 10.6512 11.677 10.0012 11.677C9.35116 11.677 8.82422 11.1501 8.82422 10.5V5.7922C8.82422 5.14218 9.35116 4.61523 10.0012 4.61523Z" fill="white" />
      <path d="M10.0012 14.0308C9.35116 14.0308 8.82422 14.5577 8.82422 15.2077C8.82422 15.8577 9.35116 16.3847 10.0012 16.3847H10.0125C10.6625 16.3847 11.1894 15.8577 11.1894 15.2077C11.1894 14.5577 10.6625 14.0308 10.0125 14.0308H10.0012Z" fill="white" />
    </g>
  </svg>

);

IconAlert.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  outlined: PropTypes.bool,
};

IconAlert.defaultProps = {
  className: null,
  color: colors.statusRed,
  outlined: false,
};
