import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderOryx = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <rect x="0.5" width="24" height="24" fill="#D8D8D8" fillOpacity="0.01" />
    <mask
      id="orix-small-rule-1"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="25"
      height="24"
    >
      <rect x="0.5" width="24" height="24" fill="white" />
    </mask>
    <g mask="url(#orix-small-rule-1)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75059 18.6286C2.53155 9.18513 3.04806 5.26028 3.59161 3.97476C1.67025 6.10603 0.5 8.92789 0.5 12.0232C0.5 18.1922 5.14687 23.2755 11.131 23.9654C11.131 22.5261 9.96344 20.5283 8.75059 18.6286Z"
        fill="#2F80F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5231 19.1037C19.4682 9.91673 20.8964 4.55527 21.1073 3.60575C18.9247 1.38085 15.8855 0 12.5231 0C9.16107 0 6.12152 1.38085 3.93921 3.60575C4.15014 4.55527 5.57763 9.91673 12.5231 19.1037Z"
        fill="#2F80F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.454 3.97427C21.9922 5.24458 22.5053 9.09844 16.4945 18.3253C16.4881 18.3357 16.482 18.3466 16.4742 18.358C15.2019 20.3372 13.9211 22.4577 13.9204 23.9649C19.9022 23.2723 24.546 18.1907 24.546 12.0231C24.546 8.92706 23.3757 6.10554 21.454 3.97427Z"
        fill="#2F80F4"
      />
    </g>
  </svg>
);

IconProviderOryx.propTypes = {
  className: PropTypes.string,
};
IconProviderOryx.defaultProps = {
  className: null,
};
