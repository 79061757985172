import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import { withModalActions } from 'core/hocs';
import { FormattedTag } from 'core/components';
import { ModalWindow } from 'components/modal-window/modal-window';
import { IconErrorCircle } from 'components/icons/icon-error-circle/icon-error-circle';

import { MODAL_IDS } from '../../../constants';

export class LinkErrorModalUI extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
  };

  closeModal = () => {
    const { closeModal } = this.props;
    closeModal(MODAL_IDS.RESET_LINK_ERROR);
  }

  render() {
    return (
      <ModalWindow
        id={MODAL_IDS.RESET_LINK_ERROR}
        coreProps={{ id: MODAL_IDS.RESET_LINK_ERROR }}
        closeButtonClassName="invisible"
        isCentered
        fade
      >
        <div className="d-flex flex-column justify-content-between align-items-center">
          <IconErrorCircle className="mt-1" />
          <FormattedTag id="form.forgot-password.reset-link-error.title" className="h2 text-center mt-3_5" />
          {/* <FormattedTag id="form.forgot-password.reset-link-error.text" className="text-gray-20 text-center" /> */}
          <FormattedTag
            tag={Button}
            color="primary"
            className="px-7_25 mt-3"
            id="got-it"
            onClick={this.closeModal}
          />
        </div>
      </ModalWindow>
    );
  }
}

export const LinkErrorModal = withModalActions(LinkErrorModalUI);
