import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconSearchClose = ({ className, onClick }) => (
  <svg className={className} onClick={onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.4" r="10" transform="matrix(-1 0 0 1 10 10)" fill={colors.gray40} />
    <path d="M13.001 6.99976L7.00098 12.9998" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.00098 6.99976L13.001 12.9998" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconSearchClose.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

IconSearchClose.defaultProps = {
  className: null,
  onClick: null
};
