import React from 'react';

export const IconProviderHacksaw = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4076 2.275C11.4076 2.125 11.3293 2 11.1988 2H6.70884C6.57831 2 6.5 2.1 6.5 2.275V15.7L11.4076 15.825V13.425H14.5402V15.875L19.5 16V2.275C19.5 2.125 19.4217 2 19.2912 2H14.749C14.6185 2 14.5402 2.1 14.5402 2.275V8.775H11.4076V2.275ZM4.5 17L9.5 17.102V21.7449C9.5 21.898 9.39418 22 9.2619 22H4.71164C4.57937 22 4.5 21.898 4.5 21.7449V17ZM11.5 17L16.5 17.133V21.734C16.5 21.8936 16.4211 22 16.2895 22H11.7368C11.6053 22 11.5263 21.8936 11.5263 21.734L11.5 17Z"
      fill="white"
    />
  </svg>
);
