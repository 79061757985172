import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderNetentBig = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="36"
    viewBox="0 0 100 36"
    fill="none"
  >
    <path
      d="M52.1583 0H51.1428V35.6713H52.1583V28.8078H64.9845V24.6522H55.1158V19.8665H63.5521V15.6796H55.1158V11.0504H64.9845V6.86348H52.1583V0Z"
      fill="#61C100"
    />
    <path
      d="M12.2355 20.3204L8.59073 14.3413L3.91892 6.93781L3.87259 6.86346H0V28.8078H4.2973V15.3196L7.50193 20.5748L12.6564 28.8078H16.5598V6.86346H12.2355V20.3204Z"
      fill="white"
    />
    <path
      d="M18.9768 28.8078H33.1699V24.6522H23.3011V19.8665H31.7374V15.6796H23.3011V11.0504H33.1699V6.86346H18.9768V28.8078Z"
      fill="white"
    />
    <path d="M34.9111 11.0817H39.8725V28.8078H44.1968V11.0817H49.1273V6.86346H34.9111V11.0817Z" fill="white" />
    <path
      d="M79.3822 20.3556L75.7375 14.3804L71.0656 6.97694L71.0193 6.89868H67.1467V28.843H71.4402V15.3548L74.6448 20.61L79.8031 28.843H83.7066V6.89868H79.3822V20.3556Z"
      fill="#61C100"
    />
    <path d="M85.4749 6.89868V11.1169H90.4362V28.843H94.7606V11.1169H99.6911V6.89868H85.4749Z" fill="#61C100" />
  </svg>
);

IconProviderNetentBig.propTypes = {
  className: PropTypes.string,
};
IconProviderNetentBig.defaultProps = {
  className: null,
};
