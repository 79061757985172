import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderHabanero = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.473 3.17528C13.7661 3.17528 13.9998 2.93788 13.9998 2.64854V1.12397C13.9998 0.830923 13.7661 0.597229 13.473 0.597229C13.1837 0.597229 12.9463 0.834632 12.9463 1.12397V2.64854C12.9463 2.93788 13.18 3.17528 13.473 3.17528Z"
      fill="url(#habanero-rule-1)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3624 4.57372C11.6555 4.57372 11.8892 4.33632 11.8892 4.04698V2.52241C11.8892 2.22936 11.6555 1.99567 11.3624 1.99567C11.0731 1.99567 10.8357 2.23307 10.8357 2.52241V4.04698C10.8357 4.33632 11.0731 4.57372 11.3624 4.57372Z"
      fill="url(#habanero-rule-2)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3624 1.04977C11.6518 1.04977 11.8892 0.816071 11.8892 0.523026V0.534155C11.8892 0.24111 11.6555 0.00741577 11.3624 0.00741577C11.0694 0.00741577 10.8357 0.244819 10.8357 0.534155V0.519317C10.8357 0.812362 11.0731 1.04977 11.3624 1.04977Z"
      fill="url(#habanero-rule-3)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5801 4.97805C15.8694 4.97805 16.1068 4.74435 16.1068 4.45131V4.46244C16.1068 4.16939 15.8731 3.9357 15.5801 3.9357C15.287 3.9357 15.0533 4.1731 15.0533 4.46244V4.45131C15.0533 4.74065 15.287 4.97805 15.5801 4.97805Z"
      fill="url(#habanero-rule-4)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7977 8.88409C19.5083 8.88409 19.2709 9.11778 19.2709 9.41083V9.64081V9.67419C19.2524 9.94869 19.0224 10.1675 18.7442 10.1675C18.4548 10.1675 18.2174 9.93386 18.2174 9.64081V9.06956V8.32767C18.2174 8.03463 17.98 7.80093 17.6907 7.80093C17.4014 7.80093 17.164 8.03834 17.164 8.32767V8.35735C17.164 8.65039 16.9266 8.88409 16.6372 8.88409C16.3479 8.88409 16.1105 8.65039 16.1105 8.35735V7.85287V6.15024C16.1105 5.85719 15.8731 5.6235 15.5838 5.6235C15.2944 5.6235 15.057 5.85719 15.057 6.15024V6.99228C15.0533 7.29274 14.7936 7.51902 14.5266 7.51902C14.2372 7.51902 13.9998 7.28532 13.9998 6.99228V6.63617V4.62195C13.9998 4.32891 13.7624 4.09521 13.4731 4.09521C13.18 4.09521 12.9463 4.32891 12.9463 4.62195V6.02783V6.6844C12.9463 6.97744 12.7089 7.21113 12.4196 7.21113C12.1303 7.21113 11.8929 6.97373 11.8929 6.6844V6.02412V5.94622C11.8929 5.65317 11.6555 5.41948 11.3661 5.41948C11.0768 5.41948 10.8394 5.65317 10.8394 5.94622V6.63246V7.53015C10.8394 7.82319 10.602 8.05688 10.3127 8.05688C10.0233 8.05688 9.78592 7.81948 9.78592 7.53015V7.24081V5.32675C9.78592 5.0337 9.55222 4.80001 9.25918 4.80001C8.96984 4.80001 8.73244 5.03741 8.73244 5.32675V7.84916V8.69862C8.73244 8.99166 8.49503 9.22535 8.2057 9.22535C7.91265 9.22535 7.67896 8.98795 7.67896 8.69862V8.4575V7.80835C7.67896 7.51531 7.44156 7.28161 7.15222 7.28161C6.86289 7.28161 6.62548 7.51531 6.62548 7.80835V9.06585V10.29C6.62548 10.583 6.39179 10.8167 6.09874 10.8167C5.80941 10.8167 5.572 10.5793 5.572 10.29V9.67419V9.47389C5.572 9.18084 5.33831 8.94715 5.04527 8.94715C4.75593 8.94715 4.51853 9.18455 4.51853 9.47389L4.51111 10.2863V19.4189L12.4196 23.9852L20.3281 19.4189V13.6433C20.3281 13.6359 20.3318 13.6247 20.3318 13.6173V9.41453C20.3244 9.12149 20.087 8.88409 19.7977 8.88409ZM16.1365 17.8424H13.6511V15.9505H11.1658V17.8424H8.68051V11.9221H11.1658V13.9104H13.6511V11.9184H16.1365V17.8424Z"
      fill="url(#habanero-rule-5)"
    />
    <defs>
      <linearGradient
        id="habanero-rule-1"
        x1="4.74162"
        y1="21.0049"
        x2="20.3683"
        y2="21.0049"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FA142E" />
        <stop offset="1" stopColor="#FF870D" />
      </linearGradient>
      <linearGradient
        id="habanero-rule-2"
        x1="5.30909"
        y1="22.2372"
        x2="20.7991"
        y2="22.2372"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FA142E" />
        <stop offset="1" stopColor="#FF870D" />
      </linearGradient>
      <linearGradient
        id="habanero-rule-3"
        x1="4.95819"
        y1="8.22104"
        x2="20.512"
        y2="8.22104"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FA142E" />
        <stop offset="1" stopColor="#FF870D" />
      </linearGradient>
      <linearGradient
        id="habanero-rule-4"
        x1="4.87586"
        y1="12.0804"
        x2="20.2887"
        y2="12.0804"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FA142E" />
        <stop offset="1" stopColor="#FF870D" />
      </linearGradient>
      <linearGradient
        id="habanero-rule-5"
        x1="4.50271"
        y1="23.9852"
        x2="20.3234"
        y2="23.9852"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FA142E" />
        <stop offset="1" stopColor="#FF870D" />
      </linearGradient>
    </defs>
  </svg>
);

IconProviderHabanero.propTypes = {
  className: PropTypes.string,
};

IconProviderHabanero.defaultProps = {
  className: '',
};
