import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderPlankGamingBig = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="141"
    height="78"
    viewBox="0 0 141 78"
  >
    <defs>
      <linearGradient id="icon-plank-gaming-big-c" x1="0%" x2="100%" y1="50.133%" y2="50.133%">
        <stop offset="24%" stopColor="#FFCB05" />
        <stop offset="38%" stopColor="#F8C406" />
        <stop offset="100%" stopColor="#DAAA0C" />
      </linearGradient>
      <linearGradient id="icon-plank-gaming-big-d" x1="50.013%" x2="50.013%" y1="100%" y2="0%">
        <stop offset="24%" stopColor="#FFCB05" />
        <stop offset="38%" stopColor="#F8C406" />
        <stop offset="100%" stopColor="#DAAA0C" />
      </linearGradient>
      <linearGradient id="icon-plank-gaming-big-e" x1="50%" x2="50%" y1="100%" y2="0%">
        <stop offset="24%" stopColor="#FFCB05" />
        <stop offset="38%" stopColor="#F8C406" />
        <stop offset="100%" stopColor="#DAAA0C" />
      </linearGradient>
      <linearGradient id="icon-plank-gaming-big-f" x1="50%" x2="50%" y1="100%" y2="0%">
        <stop offset="24%" stopColor="#FFCB05" />
        <stop offset="38%" stopColor="#F8C406" />
        <stop offset="100%" stopColor="#DAAA0C" />
      </linearGradient>
      <linearGradient id="icon-plank-gaming-big-g" x1="50%" x2="50%" y1="100%" y2="0%">
        <stop offset="24%" stopColor="#FFCB05" />
        <stop offset="38%" stopColor="#F8C406" />
        <stop offset="100%" stopColor="#DAAA0C" />
      </linearGradient>
      <linearGradient id="icon-plank-gaming-big-h" x1="50%" x2="50%" y1="100%" y2="0%">
        <stop offset="24%" stopColor="#FFCB05" />
        <stop offset="38%" stopColor="#F8C406" />
        <stop offset="100%" stopColor="#DAAA0C" />
      </linearGradient>
      <linearGradient id="icon-plank-gaming-big-i" x1="50.011%" x2="50.011%" y1="100%" y2="0%">
        <stop offset="24%" stopColor="#FFCB05" />
        <stop offset="38%" stopColor="#F8C406" />
        <stop offset="100%" stopColor="#DAAA0C" />
      </linearGradient>
      <linearGradient id="icon-plank-gaming-big-j" x1="49.99%" x2="49.99%" y1="100%" y2="0%">
        <stop offset="24%" stopColor="#FFCB05" />
        <stop offset="38%" stopColor="#F8C406" />
        <stop offset="100%" stopColor="#DAAA0C" />
      </linearGradient>
      <linearGradient id="icon-plank-gaming-big-k" x1="50%" x2="50%" y1="100%" y2="0%">
        <stop offset="24%" stopColor="#FFCB05" />
        <stop offset="38%" stopColor="#F8C406" />
        <stop offset="100%" stopColor="#DAAA0C" />
      </linearGradient>
      <linearGradient id="icon-plank-gaming-big-l" x1="50%" x2="50%" y1="100%" y2="0%">
        <stop offset="24%" stopColor="#FFCB05" />
        <stop offset="38%" stopColor="#F8C406" />
        <stop offset="100%" stopColor="#DAAA0C" />
      </linearGradient>
      <path id="icon-plank-gaming-big-a" d="M0 0h141v78H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="icon-plank-gaming-big-b" fill="#fff">
        <use xlinkHref="#icon-plank-gaming-big-a" />
      </mask>
      <path d="M0 0h141v78H0z" />
      <g fillRule="nonzero" mask="url(#icon-plank-gaming-big-b)">
        <path
          fill="#FFF"
          d="M12.556 55.262h2.984v1.806c-.016 1.431-.938 2.675-2.253 3.038-1.316.363-2.7-.244-3.38-1.484-.272-.524-.367-.785-.367-2.911s.095-2.387.367-2.908c.632-1.158 1.886-1.776 3.135-1.545 1.25.23 2.23 1.26 2.45 2.575h-1.345c-.211-.744-.854-1.259-1.59-1.275a1.588 1.588 0 0 0-1.301.647c-.332.449-.424.697-.424 2.512 0 1.816.092 2.064.424 2.513.315.415.796.652 1.3.64.471.006.923-.193 1.25-.55.326-.356.498-.84.475-1.334v-.462h-1.725v-1.262zM36.32 58.2h-3.55l-.725 2.01H30.54l3.409-9h1.182l3.409 9h-1.502l-.717-2.01zm-.408-1.213l-1.355-3.93h-.03l-1.352 3.917 2.737.013zm18.641-5.777h1.329l2.698 5.866 2.631-5.866h1.329v9h-1.422v-5.611h-.027l-1.96 4.322h-1.182l-1.96-4.322h-.027v5.611H54.54zm23.996 0h.991v9h-1zm17.991 0h1.322l4.214 6.383h.026V51.21h1.438v9h-1.322l-4.214-6.383h-.026v6.383h-1.412zm26.016 4.058h2.984v1.804c-.016 1.43-.938 2.671-2.253 3.034-1.316.363-2.7-.244-3.38-1.482-.272-.524-.367-.784-.367-2.908 0-2.123.095-2.384.367-2.904.631-1.163 1.888-1.785 3.143-1.554 1.254.23 2.236 1.263 2.454 2.582h-1.348c-.21-.75-.862-1.269-1.606-1.28a1.59 1.59 0 0 0-1.3.647c-.332.448-.424.696-.424 2.51 0 1.813.092 2.06.424 2.509a1.59 1.59 0 0 0 1.3.646c.471.006.923-.193 1.25-.55.326-.356.498-.838.475-1.332v-.461h-1.713l-.006-1.261z"
        />
        <path fill="url(#icon-plank-gaming-big-c)" d="M.54 51.21h119v1H.54z" transform="translate(7 15)" />
        <path
          fill="url(#icon-plank-gaming-big-d)"
          d="M1.54.21v6.628h12.54a3.892 3.892 0 0 1 1.85.42c.5.251.919.638 1.207 1.116a2.881 2.881 0 0 1 0 2.983 3.01 3.01 0 0 1-1.207 1.107 3.904 3.904 0 0 1-1.85.42H1.54V30.21h7.996V19.512h4.883c2.337 0 4.34-.453 6.01-1.36a9.667 9.667 0 0 0 3.805-3.55 9.265 9.265 0 0 0 0-9.485 9.586 9.586 0 0 0-3.802-3.551c-1.667-.9-3.668-1.35-6.003-1.35L1.54.21z"
          transform="translate(7 15)"
        />
        <path
          fill="url(#icon-plank-gaming-big-e)"
          d="M109.005 19.21h-8.465l9.316 10h9.684z"
          transform="translate(7 15)"
        />
        <path fill="url(#icon-plank-gaming-big-f)" d="M81.127.21H74.54v13h15z" transform="translate(7 15)" />
        <path
          fill="url(#icon-plank-gaming-big-g)"
          d="M99.225 13.21h9.425l9.89-13h-9.402l-9.061 12.838V.21H91.54v13h.897z"
          transform="translate(7 15)"
        />
        <path fill="url(#icon-plank-gaming-big-h)" d="M74.54 19.21h8v10h-8z" transform="translate(7 15)" />
        <path
          fill="url(#icon-plank-gaming-big-i)"
          d="M92.66 19.21h-7.12l7.12 9.898v.102H100.54v-10h-.879z"
          transform="translate(7 15)"
        />
        <path fill="url(#icon-plank-gaming-big-j)" d="M63.561.21h-6.042l-4.979 13h16z" transform="translate(7 15)" />
        <path
          fill="url(#icon-plank-gaming-big-k)"
          d="M70.557 19.21H49.801l-1.492 3.745H35.231V19.21H27.54v10h26.333l1.527-4.255h9.645l1.459 4.255h8.036z"
          transform="translate(7 15)"
        />
        <path fill="url(#icon-plank-gaming-big-l)" d="M27.54.21h8v13h-8z" transform="translate(7 15)" />
        <path
          fill="#DAAA0C"
          d="M128.54 15.435v-.225h2v.225h-.84v1.775h-.333v-1.775zm2.371-.225l.632 1.682.634-1.682h.363v2h-.265v-1.664l-.626 1.664h-.226l-.618-1.664v1.664h-.265v-2z"
        />
      </g>
    </g>
  </svg>
);

IconProviderPlankGamingBig.propTypes = {
  className: PropTypes.string,
};
IconProviderPlankGamingBig.defaultProps = {
  className: null,
};
