import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderEvoplay = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.054 9.15382C14.0279 8.66703 13.9442 8.19259 13.804 7.73121C13.6628 7.26983 13.4447 6.85999 13.1506 6.50063C12.8552 6.14198 12.4835 5.84722 12.0355 5.61635C11.5865 5.38584 11.0291 5.2704 10.3629 5.2704C9.72189 5.2704 9.17084 5.37984 8.70982 5.59729C8.24773 5.81509 7.86331 6.1035 7.55584 6.46215C7.24837 6.82151 7.01115 7.23771 6.84453 7.71179C6.67756 8.18659 6.55648 8.66703 6.47953 9.15382H14.054ZM0.59668 11.3453C0.59668 9.55131 0.872024 7.98114 1.42342 6.63548C1.97446 5.28982 2.69848 4.16867 3.59582 3.27098C4.49281 2.37399 5.52429 1.69445 6.69098 1.23343C7.85696 0.772049 9.05541 0.541182 10.286 0.541182C13.1559 0.541182 15.4251 1.41946 17.0916 3.17496C18.7574 4.93116 19.5905 7.51376 19.5905 10.9224C19.5905 11.256 19.5771 11.6213 19.5524 12.0185C19.5263 12.416 19.5005 12.7683 19.4754 13.0757H6.47953C6.60731 14.2548 7.15836 15.1902 8.13301 15.8825C9.10695 16.5744 10.4138 16.9207 12.0542 16.9207C13.1051 16.9207 14.1369 16.8243 15.1497 16.6319C16.1618 16.4399 16.9885 16.2034 17.6296 15.9209L18.3988 20.5732C18.091 20.7271 17.6808 20.881 17.1686 21.0346C16.6553 21.1885 16.0848 21.323 15.4572 21.4384C14.8288 21.5539 14.156 21.6495 13.4387 21.7265C12.721 21.8034 12.003 21.8419 11.2857 21.8419C9.46525 21.8419 7.88272 21.5729 6.53707 21.0346C5.19141 20.4963 4.07626 19.7595 3.19163 18.8237C2.30805 17.8883 1.65429 16.7798 1.23103 15.498C0.80813 14.2163 0.59668 12.8321 0.59668 11.3453Z"
      fill="#01A3FB"
    />
  </svg>
);

IconProviderEvoplay.propTypes = {
  className: PropTypes.string,
};

IconProviderEvoplay.defaultProps = {
  className: '',
};
