import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconPaybol = ({ className }) => (
  <svg className={classNames('footer-icon', className)} width="99" height="31" viewBox="0 0 99 31" fill="none">
    <g clipPath="url(#icon-paybol-clip)">
      <path d="M32.6129 6.27367L30.9167 21.7827H34.0811L34.6173 16.8642H37.1409C40.0825 16.8642 42.7434 15.0251 43.1249 11.5689C43.5064 8.11277 41.2423 6.27367 38.301 6.27367H32.6129ZM38.2788 9.28694C39.4822 9.28694 40.3754 9.97379 40.2029 11.5689C40.0304 13.164 38.9791 13.851 37.7761 13.851H34.9459L35.4487 9.28694H38.2788ZM48.9441 10.5692C45.6237 10.5692 43.1499 12.8527 42.7775 16.2438C42.4055 19.6317 44.3215 22.1151 47.6418 22.1151C48.667 22.1151 50.2805 21.6941 51.3563 20.4755L51.2192 21.7827H54.0685L55.2801 10.7048H52.4307L52.2929 12.1865C51.4969 10.968 49.9691 10.5692 48.9441 10.5692ZM48.5833 19.4563C47.068 19.4563 45.2987 18.6112 45.549 16.3089C46.02 11.9758 52.3255 12.0419 51.8439 16.3088C51.5913 18.5477 50.0763 19.4563 48.5833 19.4563ZM64.6998 10.7048L61.33 18.1414L59.3515 10.7048H56.098L59.5037 21.4987C58.7724 23.0495 58.0527 23.7411 56.4705 23.7411L56.2136 26.2004C59.1774 26.2004 60.6017 25.1603 62.2841 21.7606L67.7554 10.7048H64.6998ZM75.1756 10.5692C74.1507 10.5692 72.3162 10.7714 71.2849 11.9899L71.9075 6.27367H69.1414L67.4453 21.7827H70.2112L70.3484 20.4755C71.1443 21.6941 72.8484 22.1151 73.8736 22.1151C77.1939 22.1151 79.4919 19.638 79.8645 16.2438C80.2363 12.8587 78.0773 10.7124 75.1756 10.5692ZM73.5225 19.4563C72.0295 19.4563 70.4946 18.4828 70.7474 16.2438C70.9998 14.0078 72.7411 13.0312 74.2342 13.0312C75.7495 13.0312 77.1729 13.9441 76.923 16.2438C76.672 18.5525 75.0379 19.4563 73.5225 19.4563ZM87.3083 10.5692C84.0861 10.5692 81.2255 13.0615 80.8744 16.2438C80.4759 19.8547 82.5549 22.1151 85.8083 22.1151C89.0618 22.1151 92.0636 19.8547 92.4623 16.2438C92.8609 12.6327 90.5619 10.5692 87.3083 10.5692ZM86.3196 19.4218C85.1608 19.4218 83.39 18.5491 83.6403 16.2438C83.8905 13.9416 85.8361 13.1642 86.9949 13.1642C88.1537 13.1642 89.9463 13.9416 89.6963 16.2438C89.4459 18.549 87.4784 19.4218 86.3196 19.4218ZM98.0987 5.94141H95.3099L93.5795 21.7827H96.3684L98.0987 5.94141Z" fill="#3D6B9F" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.3728 8.79918C26.3728 8.79918 19.2426 3.92439 15.3368 1.6546C11.431 -0.61519 6.75507 0.424077 5.43481 5.23702L17.8604 13.6119L26.3728 8.79918Z" fill="#FDC928" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.3729 8.79919L24.4474 17.9603L0.0983887 30.5054C0.0983887 30.5054 1.58371 21.9527 10.468 17.2491C19.3522 12.5455 26.3729 8.79919 26.3729 8.79919Z" fill="#3D6B9F" />
    </g>
  </svg>
);

IconPaybol.propTypes = {
  className: PropTypes.string,
};

IconPaybol.defaultProps = {
  className: '',
};
