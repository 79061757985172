import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import {
  LangSwitcher as LangSwitcherCore,
  FormattedTag,
} from 'core/components';
import { withCoreComponent } from 'core/hocs';
import { LOCALE_KEYS } from 'core/constants';
import { IconLangEn } from 'components/icons/icon-lang-en/icon-lang-en';
import { IconLangRu } from 'components/icons/icon-lang-ru/icon-lang-ru';
import { IconLangDe } from 'components/icons/icon-lang-de/icon-lang-de';
import { IconLangSv } from 'components/icons/icon-lang-sv/icon-lang-sv';
import { IconLangNo } from 'components/icons/icon-lang-no/icon-lang-no';
import { IconLangFi } from 'components/icons/icon-lang-fi/icon-lang-fi';
import { IconLangPl } from 'components/icons/icon-lang-pl/icon-lang-pl';
import { IconLangHu } from 'components/icons/icon-lang-hu/icon-lang-hu';
import { IconLangIt } from 'components/icons/icon-lang-it/icon-lang-it';
import { IconLangTr } from 'components/icons/icon-lang-tr/icon-lang-tr';
import { IconLangTh } from 'components/icons/icon-lang-th/icon-lang-th';
import { InputText } from '../input-text/input-text';
import { IconArrowDown } from '../icons/icon-arrow-down/icon-arrow-down';

import './lang-switcher.scss';

const LANG_ICON = {
  [LOCALE_KEYS.EN]: IconLangEn,
  [LOCALE_KEYS.RU]: IconLangRu,
  [LOCALE_KEYS.SV]: IconLangSv,
  [LOCALE_KEYS.DE]: IconLangDe,
  [LOCALE_KEYS.NO]: IconLangNo,
  [LOCALE_KEYS.FI]: IconLangFi,
  [LOCALE_KEYS.PL]: IconLangPl,
  [LOCALE_KEYS.HU]: IconLangHu,
  [LOCALE_KEYS.IT]: IconLangIt,
  [LOCALE_KEYS.TR]: IconLangTr,
  [LOCALE_KEYS.TH]: IconLangTh,
};

const CASINO_LANGUAGES = {
  EN: LOCALE_KEYS.EN,
  TR: LOCALE_KEYS.TR,
};

export class LangSwitcherUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    changeLocale: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: null
  }

  state = {
    isDropdownOpen: false,
  }

  changeLocale = ({ currentTarget: { dataset: { locale } } }) => {
    const { changeLocale, locale: currentLocale } = this.props;

    if (currentLocale !== locale) {
      changeLocale({ locale });
    }
  }

  toggle = () => {
    this.setState(({ isDropdownOpen }) => ({
      isDropdownOpen: !isDropdownOpen,
    }));
  };

  langImage = () => {
    const { locale } = this.props;

    return (
      <img
        className="lang-switcher-icon rounded-circle m-auto"
        src={`/assets/public/${locale}.png`}
        alt={locale}
      />
    );
  };

  render() {
    const {
      locale, intl, className,
    } = this.props;
    // const CurrentLangIcon = LANG_ICON[locale];

    const { isDropdownOpen } = this.state;

    return (
      <div className={classNames('lang-switcher', className)}>
        <Dropdown className="text-small" isOpen={isDropdownOpen} toggle={this.toggle}>
          <DropdownToggle tag="span" role="button" className="d-flex align-items-center">
            <InputText
              name="lang-switcher"
              wrapperClassName="dropdown-input"
              icon={this.langImage}
              rightContent={<IconArrowDown className={classNames({ rotate: !isDropdownOpen })} />}
              onChange={null}
              value={intl.formatMessage({ id: `locale.${locale}` })}
              readOnly
            />
          </DropdownToggle>
          <DropdownMenu className="lang-switcher-dropdown-menu" right>
            {Object.values(CASINO_LANGUAGES).map((key) => {
              const LangIcon = LANG_ICON[key];

              return (
                <DropdownItem
                  key={key}
                  onClick={this.changeLocale}
                  data-locale={key}
                  className="d-flex align-items-center"
                >
                  <LangIcon className="mr-2" />
                  <FormattedTag
                    id={`locale.${key}`}
                    className={classNames({ 'font-weight-medium': locale === key })}
                  />
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

export const LangSwitcher = withCoreComponent(LangSwitcherCore, injectIntl(LangSwitcherUI));
