import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconMenu = ({ className, color, onClick }) => (
  <svg
    className={className}
    onClick={onClick}
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 8.6C4 7.71634 4.71634 7 5.6 7H22.7429C23.6265 7 24.3429 7.71634 24.3429 8.6C24.3429 9.48366 23.6265 10.2 22.7429 10.2H5.6C4.71634 10.2 4 9.48366 4 8.6ZM4 14.3142C4 13.4305 4.71634 12.7142 5.6 12.7142H19.3143C20.1979 12.7142 20.9143 13.4305 20.9143 14.3142C20.9143 15.1979 20.1979 15.9142 19.3143 15.9142H5.6C4.71634 15.9142 4 15.1979 4 14.3142ZM5.6 18.4284C4.71634 18.4284 4 19.1447 4 20.0284C4 20.9121 4.71634 21.6284 5.6 21.6284H15.8857C16.7694 21.6284 17.4857 20.9121 17.4857 20.0284C17.4857 19.1447 16.7694 18.4284 15.8857 18.4284H5.6Z"
    />
  </svg>
);

IconMenu.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

IconMenu.defaultProps = {
  className: '',
  color: colors.gray80,
  onClick: () => {},
};
