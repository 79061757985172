import { IconCasino } from 'components/icons/icon-casino/icon-casino';
import { IconLiveCasino } from 'components/icons/icon-live-casino/icon-live-casino';
import { IconSports } from 'components/icons/icon-sports/icon-sports';
import { IconInPlay } from 'components/icons/icon-in-play/icon-in-play';
import { IconInPlayRounded } from 'components/icons/icon-in-play/icon-in-play-rounded';
import { IconTournaments } from 'components/icons/icon-tournaments/icon-tournaments';
import { IconPromos } from 'components/icons/icon-promos/icon-promos';
import { IconVip } from 'components/icons/icons-vip/icon-vip';
import { PAGE_NAMES } from '../../constants';
import { IconRocket } from '../icons/icon-rocket/icon-rocket';
// import { IconCards } from '../icons/icon-cards/icon-cards';

const {
  GAMES,
  PROMOS,
  TOURNAMENT,
  SPORTSBOOK,
  TOURNAMENTS,
  VIP,
  IN_PLAY,
} = PAGE_NAMES;

export const menuItems = [
  {
    id: 'header.sports',
    to: '/sportsbook',
    Icon: IconSports,
    pages: [SPORTSBOOK, IN_PLAY],
  },
  {
    id: 'header.in-play',
    to: '/in-play?page=/live',
    Icon: IconInPlay,
    IconMobile: IconInPlayRounded,
    pages: [`/${SPORTSBOOK}?page=/live`, `/${IN_PLAY}?page=/live`],
  },
  {
    id: 'header.casino',
    to: '/games/all',
    Icon: IconCasino,
    pages: [`${GAMES}/all`, `${GAMES}/slot`, `${GAMES}/roulette`, `${GAMES}/sport`],
    // classNameHeader: 'ml-lg-1_75'
  },
  {
    id: 'header.live',
    to: '/games/live',
    Icon: IconLiveCasino,
    pages: [`${GAMES}/live`],
  },
  {
    id: 'header.games',
    to: '/games/games',
    Icon: IconRocket,
    pages: [`${GAMES}/games`],
  },
  // {
  //   id: 'header.card',
  //   to: '/games/card',
  //   Icon: IconCards,
  //   pages: [`${GAMES}/card`],
  // },
  {
    id: 'header.tournaments',
    to: '/tournaments',
    Icon: IconTournaments,
    pages: [TOURNAMENTS, TOURNAMENT],
    classNameSidebar: 'ml-n0_25',
    hiddenOnSportsbook: true,
  },
  {
    id: 'header.promos',
    to: '/promos',
    Icon: IconPromos,
    pages: [PROMOS],
  },
  {
    id: 'header.vip',
    to: '/vip',
    Icon: IconVip,
    pages: [VIP],
    hiddenOnSportsbook: true,
  },
];

export const menuItemsSidebar = [
  {
    id: 'header.casino',
    to: '/games/all',
    Icon: IconCasino,
    pages: [`${GAMES}/all`, `${GAMES}/slot`, `${GAMES}/roulette`, `${GAMES}/card`, `${GAMES}/sport`],
    // classNameHeader: 'ml-lg-1_75'
  },
  {
    id: 'header.sports',
    to: '/sportsbook',
    Icon: IconSports,
    pages: [SPORTSBOOK, IN_PLAY],
  },
  {
    id: 'header.in-play',
    to: '/in-play?page=/live',
    Icon: IconInPlay,
    IconMobile: IconInPlayRounded,
    pages: [`/${SPORTSBOOK}?page=/live`, `/${IN_PLAY}?page=/live`],
  },
  {
    id: 'header.live',
    to: '/games/live',
    Icon: IconLiveCasino,
    pages: [`${GAMES}/live`],
  },
  {
    id: 'header.games',
    to: '/games/games',
    Icon: IconRocket,
    pages: [`${GAMES}/games`],
  },
  // {
  //   id: 'header.card',
  //   to: '/games/card',
  //   Icon: IconCards,
  //   pages: [`${GAMES}/card`],
  // },
  {
    id: 'header.tournaments',
    to: '/tournaments',
    Icon: IconTournaments,
    pages: [TOURNAMENTS, TOURNAMENT],
    classNameSidebar: 'ml-n0_25'
  },
  {
    id: 'header.promos',
    to: '/promos',
    Icon: IconPromos,
    pages: [PROMOS],
  },
  {
    id: 'header.vip',
    to: '/vip',
    Icon: IconVip,
    pages: [VIP],
  },
];
