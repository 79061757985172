import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormProcessDeposit } from 'components/forms/form-process-deposit/form-process-deposit';

export const DepositNew = ({
  className,
  handlerPaymentChangeForMobile,
  handlerPaymentChangeForDesktop,
  activeDepositMethod,
  handlerStep,
  updateModalAlignment,
  isModal,
}) =>
  (
    <div
      className={classNames('deposit-new card-width d-flex flex-column justify-content-between', className)}
    >
      <FormProcessDeposit
        activeDepositMethod={activeDepositMethod}
        updateModalAlignment={updateModalAlignment}
        handlerPaymentChangeForMobile={handlerPaymentChangeForMobile}
        handlerPaymentChangeForDesktop={handlerPaymentChangeForDesktop}
        handlerStep={handlerStep}
        isModal={isModal}
        coreProps={{ withoutFormDataClearOnUnmount: true }}
      />
    </div>
  );

DepositNew.propTypes = {
  className: PropTypes.string,
  activeDepositMethod: PropTypes.string.isRequired,
  handlerPaymentChangeForMobile: PropTypes.func.isRequired,
  handlerPaymentChangeForDesktop: PropTypes.func.isRequired,
  handlerStep: PropTypes.func.isRequired,
  isModal: PropTypes.bool.isRequired,
  updateModalAlignment: PropTypes.func,
};

DepositNew.defaultProps = {
  className: '',
  updateModalAlignment: null,
};
