import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { withCoreComponent } from 'core/hocs';
import { isEmptyOrNil } from 'core/helpers';
import { FavoriteGames as FavoriteGamesCore } from 'core/components';
import { GAME_WINNER_LIST_PERIOD_TYPE } from 'core/constants';
import { News } from 'components/news/news';
import { FavoriteGamesSidebar } from 'components/favorite-games-sidebar/favorite-games-sidebar';
import { GameWinnerList } from 'components/game-winner-list/game-winner-list';
import { IconStar } from 'components/icons/icon-star/icon-star';
import colors from 'customizations/js/color-variables';

import './user-tabs.scss';

const USER_TABS = {
  WINNERS: 'WINNERS',
  NEWS: 'NEWS',
  FAVORITES: 'FAVORITES',
};

const WINNERS_SIZE = 5;

export class UserTabsUI extends Component {
  static propTypes = {
    isUserLoggedIn: PropTypes.bool.isRequired,
    isInSidebar: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape()),
    className: PropTypes.string,
  };

  static defaultProps = {
    isInSidebar: false,
    items: [],
    className: '',
  };

  state = {
    activeTab: USER_TABS.WINNERS,
  };

  componentDidUpdate(prevProps) {
    const { isUserLoggedIn } = this.props;
    const { isUserLoggedIn: prevIsUserLoggedIn } = prevProps;

    if (!isUserLoggedIn && prevIsUserLoggedIn) {
      this.toggleTab(USER_TABS.WINNERS);
    }
  }

  toggleTab = activeTab => this.setState({ activeTab });

  onClickHandler = ({ currentTarget: { dataset: { id } } }) => {
    this.toggleTab(id);
  };

  render() {
    const {
      isUserLoggedIn,
      isInSidebar,
      items: favoriteGames,
      className,

    } = this.props;

    const { activeTab } = this.state;

    const isActiveTabNews = activeTab === USER_TABS.NEWS;
    const isActiveTabWinners = activeTab === USER_TABS.WINNERS;
    const isActiveTabFavorite = activeTab === USER_TABS.FAVORITES;

    return (
      <div className={classNames('w-100', className)}>
        <Nav tabs className="user-tabs d-flex flex-nowrap text-gray-40 justify-content-between position-relative">
          <div className="user-tabs-wrapper d-flex bg-gray-0 rounded-lg flex-grow-1">

            <NavItem
              className={classNames('user-tabs-item cursor-pointer rounded-lg text-gray-40', {
                'bg-black': isActiveTabWinners,
              })}
            >
              <NavLink
                className="user-tabs-link p-0 h-100 d-flex align-items-center justify-content-center font-weight-semi-bold"
                onClick={this.onClickHandler}
                data-id={USER_TABS.WINNERS}
              >
                <FormattedMessage id="sidebar.winners" />
              </NavLink>
            </NavItem>

            <NavItem
              className={classNames('user-tabs-item cursor-pointer rounded-lg text-gray-40', {
                'bg-black': isActiveTabNews,
              })}
            >
              <NavLink
                className="user-tabs-link p-0 h-100 d-flex align-items-center justify-content-center font-weight-semi-bold"
                onClick={this.onClickHandler}
                data-id={USER_TABS.NEWS}
              >
                <FormattedMessage id="sidebar.news" />
              </NavLink>
            </NavItem>
          </div>

          {isUserLoggedIn && (
            <NavItem
              className={classNames('user-tabs-item-favorites cursor-pointer rounded-circle ml-1_5 position-relative', {
                'bg-gray-0': !isActiveTabFavorite,
                'bg-primary': isActiveTabFavorite,
              })}
            >
              <NavLink
                className="user-tabs-link p-0 h-100 d-flex align-items-center justify-content-center"
                onClick={this.onClickHandler}
                data-id={USER_TABS.FAVORITES}
              >
                <IconStar color={isActiveTabFavorite ? colors.black : colors.gray40} />
              </NavLink>

              {!isEmptyOrNil(favoriteGames) && (
                <div className="favorites-label position-absolute bg-primary rounded-circle text-black font-weight-semi-bold d-flex justify-content-center align-items-center">
                  {favoriteGames.length}
                </div>
              )}
            </NavItem>
          )}
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId={USER_TABS.WINNERS}>
            <GameWinnerList
              isInSidebar={isInSidebar}
              coreProps={{
                periodType: GAME_WINNER_LIST_PERIOD_TYPE.DAILY,
                period: 1,
                size: WINNERS_SIZE,
              }}
            />
          </TabPane>

          <TabPane tabId={USER_TABS.NEWS}>
            <News
              coreProps={{ size: 99 }}
              isInSidebar={isInSidebar}
            />
          </TabPane>

          <TabPane tabId={USER_TABS.FAVORITES}>
            <FavoriteGamesSidebar
              coreProps={{ size: 99 }}
              isInSidebar={isInSidebar}
            />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export const UserTabs = withCoreComponent(FavoriteGamesCore, UserTabsUI);
