import React from 'react';

export const IconProviderNolimit = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M7.97926 2.00112C9.12956 2.00112 10.2799 2.00112 11.4302 2.00112C12.1972 1.98976 12.9616 2.09233 13.6989 2.30553C15.0449 2.65481 16.3069 3.27422 17.4096 4.12667C18.9612 5.32742 20.0046 7.075 20.3304 9.01864C20.4152 9.46191 20.447 9.91052 20.5 10.3591C20.5 10.4695 20.5 10.5817 20.5 10.6956C20.5 10.9519 20.5 10.9786 20.235 10.9893H16.5243C16.4757 10.9962 16.4266 10.9801 16.3914 10.9456C16.3562 10.9112 16.3387 10.8623 16.3441 10.8131C16.3423 10.4901 16.3086 10.168 16.2434 9.85177C16.1254 9.13431 15.8402 8.45527 15.4111 7.87042C14.7489 7.01023 13.7857 6.43716 12.7183 6.26824C12.3411 6.19367 11.9576 6.15611 11.5733 6.15609H7.83084C7.74802 6.1618 7.68466 6.23273 7.68771 6.31631C7.68771 6.44448 7.68771 6.57266 7.68771 6.70083V10.5674C7.68771 11.0053 7.68771 11 7.27424 11H3.91347H3.62192C3.55489 10.9946 3.50248 10.9395 3.5 10.8718C3.5 10.7597 3.5 10.6475 3.5 10.5354V2.48711C3.5 1.95305 3.5 2.00112 4.03009 2.00112H7.97926Z"
      fill="#F0D906"
    />
    <path
      d="M7.458 22.9387C7.36295 22.9893 7.25314 23.0093 7.145 22.996H3.67165C3.57068 22.996 3.5 22.9435 3.5 22.8385C3.5 22.7336 3.5 22.6334 3.5 22.5332V13.592C3.5 13.4632 3.5 13.3296 3.5 13.2008C3.5 13.072 3.54039 13.0147 3.72718 13.0004H7.02888H7.35199C7.39034 12.9975 7.42811 13.0106 7.45532 13.0363C7.48253 13.062 7.49643 13.0977 7.49334 13.134C7.49334 13.2485 7.49334 13.363 7.49334 13.4775V22.5427C7.50899 22.6756 7.49699 22.8102 7.458 22.9387Z"
      fill="#F0D906"
    />
    <path
      d="M20.4854 18.0072V22.4511C20.4854 22.5513 20.4854 22.6563 20.4854 22.7566C20.4854 22.9761 20.4551 23 20.2177 23H16.7475H16.6111C16.5474 22.9927 16.4995 22.9414 16.5 22.8807C16.5 22.7804 16.5 22.6802 16.5 22.58V13.3771C16.5 13.0048 16.5 13 16.9041 13H20.2834C20.4501 13 20.4854 13.043 20.4955 13.2196C20.5056 13.3962 20.4955 13.4773 20.4955 13.6062L20.4854 18.0072Z"
      fill="#F0D906"
    />
  </svg>
);
