import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderEgtBig = ({ className }) => (
  <svg
    className={className}
    width="100"
    height="30"
    viewBox="0 0 100 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.92855 15.4412C8.92855 11.6471 12.5893 6.70591 18.8393 4.05885C29.7321 -0.61762 35.9821 3.70591 37.5893 7.76473C39.1071 11.5589 36.0714 18.353 30.9821 23.2941C34.6428 20.6471 37.6786 17.9118 39.7321 15.1765C42.5 11.5589 43.6607 8.11767 42.5893 5.38238C41.0714 1.41179 34.9107 0.0882624 27.2321 1.14709C19.5536 2.20591 13.6607 4.14709 8.92855 6.61767C3.57141 9.26473 0.892839 12.353 0.44641 15.9706C-0.0893041 21.1765 5.62498 28.2353 18.5714 29.1177C25.3571 29.5588 31.9643 29.7353 44.5536 26.6471C43.0357 26.7353 8.83927 30.9706 8.92855 15.4412Z"
      fill="url(#egt-big-paint-0)"
    />
    <path
      d="M61.6964 2.20587V5.73528H52.8571V9.35293H60.8035V12.7059H52.8571V16.3235H61.9642V19.7647H48.8392V2.20587H61.6964Z"
      fill="#A5CD39"
    />
    <mask
      id="egt-big-mask-0"
      maskUnits="userSpaceOnUse"
      x="48"
      y="2"
      width="14"
      height="18"
    >
      <path
        d="M61.6964 2.20587V5.73528H52.8571V9.35293H60.8035V12.7059H52.8571V16.3235H61.9642V19.7647H48.8392V2.20587H61.6964Z"
        fill="white"
      />
    </mask>
    <g mask="url(#egt-big-mask-0)">
      <path
        opacity="0.79"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.1785 20.0294C79.2856 14.0294 72.2321 9.35291 63.7499 9.35291C55.2678 9.35291 48.2142 14.0294 47.3214 20.0294H80.1785Z"
        fill="url(#egt-big-paint-1)"
      />
      <path
        opacity="0.39"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.9285 2.02942C41.2499 6.35295 42.7678 12.6177 47.5892 16.5882C52.7678 20.8235 59.9106 20.8235 63.4821 16.5882C66.7856 12.6177 65.8928 6.35295 61.6071 2.02942L43.9285 2.02942Z"
        fill="url(#egt-big-paint-2)"
      />
    </g>
    <path
      d="M78.3035 10.7647H82.3214V17.0294C80.5357 18.9706 78.1249 19.9412 74.9999 19.9412C72.4107 19.9412 70.1785 19.0588 68.3928 17.3824C66.6071 15.7059 65.7142 13.5 65.7142 10.9412C65.7142 8.29412 66.6071 6.17647 68.3928 4.41177C70.1785 2.64706 72.4107 1.76471 74.9107 1.76471C77.4999 1.76471 79.6428 2.55883 81.5178 4.2353L79.4642 7.14706C78.6607 6.44118 77.9464 6 77.2321 5.7353C76.6071 5.47059 75.8035 5.38236 75.0892 5.38236C73.5714 5.38236 72.3214 5.91177 71.2499 6.88236C70.1785 7.85294 69.7321 9.26471 69.7321 10.8529C69.7321 12.4412 70.2678 13.8529 71.2499 14.8235C72.2321 15.7941 73.3928 16.3235 74.8214 16.3235C76.1607 16.3235 77.3214 16.0588 78.2142 15.5294V10.7647H78.3035Z"
      fill="#A5CD39"
    />
    <mask
      id="egt-big-mask-1"
      maskUnits="userSpaceOnUse"
      x="65"
      y="1"
      width="18"
      height="19"
    >
      <path
        d="M78.3035 10.7647H82.3214V17.0294C80.5357 18.9706 78.1249 19.9412 74.9999 19.9412C72.4107 19.9412 70.1785 19.0588 68.3928 17.3824C66.6071 15.7059 65.7142 13.5 65.7142 10.9412C65.7142 8.29412 66.6071 6.17647 68.3928 4.41177C70.1785 2.64706 72.4107 1.76471 74.9107 1.76471C77.4999 1.76471 79.6428 2.55883 81.5178 4.2353L79.4642 7.14706C78.6607 6.44118 77.9464 6 77.2321 5.7353C76.6071 5.47059 75.8035 5.38236 75.0892 5.38236C73.5714 5.38236 72.3214 5.91177 71.2499 6.88236C70.1785 7.85294 69.7321 9.26471 69.7321 10.8529C69.7321 12.4412 70.2678 13.8529 71.2499 14.8235C72.2321 15.7941 73.3928 16.3235 74.8214 16.3235C76.1607 16.3235 77.3214 16.0588 78.2142 15.5294V10.7647H78.3035Z"
        fill="white"
      />
    </mask>
    <g mask="url(#egt-big-mask-1)">
      <path
        opacity="0.61"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.0892 1.67645H69.1963C65.7142 4.94116 64.4642 9.61763 67.6785 13.5C70.6249 17.1176 76.0713 17.5588 79.8213 14.647C83.6606 11.647 83.3928 5.73528 80.4463 2.11763C80.3571 1.94116 80.1785 1.85292 80.0892 1.67645Z"
        fill="url(#egt-big-paint-3)"
      />
    </g>
    <path d="M94.2857 5.55881V19.7647H90.3571V5.55881H85.3571V2.20587H99.375V5.55881H94.2857Z" fill="#A5CD39" />
    <mask
      id="egt-big-mask-2"
      maskUnits="userSpaceOnUse"
      x="85"
      y="2"
      width="15"
      height="18"
    >
      <path d="M94.2857 5.55881V19.7647H90.3571V5.55881H85.3571V2.20587H99.375V5.55881H94.2857Z" fill="white" />
    </mask>
    <g mask="url(#egt-big-mask-2)">
      <path
        opacity="0.47"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.5178 2.02942C84.5535 5.64707 85.1785 10.8529 88.3928 15C91.3392 18.7059 95.625 20.5588 99.375 20.2059V2.02942H86.5178Z"
        fill="url(#egt-big-paint-4)"
      />
      <path
        opacity="0.66"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.4642 4.2353C96.7857 3.70589 93.5714 4.50001 90.8928 6.61765C86.6071 9.97059 85.3571 15.3529 88.0357 18.7059C90.4464 21.7059 95.3571 21.9706 99.4642 19.5V4.2353Z"
        fill="url(#egt-big-paint-5)"
      />
    </g>
    <path d="M48.9285 24.0883H49.6428V28.5H48.9285V24.0883Z" fill="#40AA8B" />
    <path
      d="M54.9106 24.0883H55.6249V28.5H54.8213L52.3213 25.3236V28.5H51.6071V24.0883H52.3213L54.9106 27.353V24.0883Z"
      fill="#40AA8B"
    />
    <path d="M59.1964 24.7941V28.5H58.4821V24.7941H57.1428V24.0883H60.625V24.7941H59.1964Z" fill="#40AA8B" />
    <path
      d="M65.0892 24.0882V24.7941H62.6785V25.9412H64.8214V26.5588H62.6785V27.7059H65.1785V28.4118H61.9642V24H65.0892V24.0882Z"
      fill="#40AA8B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.3749 27C69.9999 26.7353 70.3571 26.2941 70.3571 25.5882C70.3571 25.0588 70.1785 24.7059 69.9106 24.5294C69.6428 24.2647 69.1964 24.1765 68.4821 24.1765H66.7856V28.5882H67.4999V27.0882H68.4821L69.5535 28.5882H70.5356L69.3749 27ZM69.6428 25.5882C69.6428 25.8529 69.5535 26.1176 69.3749 26.2059C69.1964 26.2941 68.9285 26.3823 68.4821 26.3823H67.5892V24.7941H68.5714C68.9285 24.7941 69.1964 24.8823 69.3749 24.9706C69.5535 25.147 69.6428 25.3235 69.6428 25.5882Z"
      fill="#40AA8B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.2321 28.5L72.6785 27.5294H74.9107L75.3571 28.5H76.1607L74.1964 24.0883H73.3928L71.4285 28.5H72.2321ZM73.8392 24.9706L74.6428 26.8236H73.0357L73.8392 24.9706Z"
      fill="#40AA8B"
    />
    <path
      d="M79.4642 27.8823C79.7321 27.8823 79.9107 27.8823 80.0892 27.7941C80.2678 27.7059 80.4464 27.5294 80.7142 27.3529L81.1607 27.8823C80.7142 28.4117 80.0892 28.6765 79.4642 28.6765C78.75 28.6765 78.2143 28.5 77.7678 28.0588C77.3214 27.6176 77.1428 27.0882 77.1428 26.4706C77.1428 25.8529 77.4107 25.2353 77.8571 24.7941C78.3035 24.3529 78.8392 24.1765 79.5535 24.1765C80.2678 24.1765 80.8035 24.4412 81.25 24.9706L80.8035 25.5C80.625 25.3235 80.3571 25.147 80.1785 25.0588C80 24.9706 79.7321 24.9706 79.5535 24.9706C79.1071 24.9706 78.75 25.147 78.3928 25.4117C78.125 25.6765 77.9464 26.0294 77.9464 26.4706C77.9464 26.9117 78.125 27.2647 78.3928 27.6176C78.6607 27.7059 79.0178 27.8823 79.4642 27.8823Z"
      fill="#40AA8B"
    />
    <path d="M84.4642 24.7941V28.5H83.7499V24.7941H82.4106V24.0883H85.8928V24.7941H84.4642Z" fill="#40AA8B" />
    <path d="M87.2321 24.0883H87.9464V28.5H87.2321V24.0883Z" fill="#40AA8B" />
    <path
      d="M91.8749 28.5H91.0714L89.2856 24.0883H90.0892L91.4285 27.353L92.7678 24.0883H93.5714L91.8749 28.5Z"
      fill="#40AA8B"
    />
    <path
      d="M98.1249 24.0882V24.7941H95.7142V25.9412H97.8571V26.5588H95.7142V27.7059H98.1249V28.4118H94.9106V24H98.1249V24.0882Z"
      fill="#40AA8B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5178 21.5294C25.5357 21.5294 28.9285 18.2647 28.9285 14.2059C28.9285 10.2353 25.625 6.88239 21.5178 6.88239C17.5 6.88239 14.1071 10.1471 14.1071 14.2059C14.1964 18.2647 17.5 21.5294 21.5178 21.5294Z"
      fill="#A5CD39"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5178 21.353C25.625 21.353 28.9285 18.0883 28.9285 14.0295C28.9285 9.97064 25.625 6.70593 21.5178 6.70593C17.4107 6.70593 14.1071 9.97064 14.1071 14.0295C14.1964 18.0883 17.5 21.353 21.5178 21.353Z"
      fill="#A5CD39"
    />
    <mask
      id="egt-big-mask-3"
      maskUnits="userSpaceOnUse"
      x="14"
      y="6"
      width="15"
      height="16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5178 21.353C25.625 21.353 28.9285 18.0883 28.9285 14.0295C28.9285 9.97064 25.625 6.70593 21.5178 6.70593C17.4107 6.70593 14.1071 9.97064 14.1071 14.0295C14.1964 18.0883 17.5 21.353 21.5178 21.353Z"
        fill="white"
      />
    </mask>
    <g mask="url(#egt-big-mask-3)">
      <path
        opacity="0.36"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2142 18C22.9464 18 26.875 14.1177 26.875 9.44121C26.875 4.76474 22.9464 0.882385 18.2142 0.882385C13.4821 0.882385 9.55353 4.76474 9.55353 9.44121C9.55353 14.1177 13.3928 18 18.2142 18Z"
        fill="url(#paint6_linear_9978_146782)"
      />
      <path
        opacity="0.71"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5714 18.353C27.3214 18.353 30.3571 15.353 30.3571 11.5589C30.3571 7.76477 27.3214 4.76477 23.5714 4.76477C19.8214 4.76477 16.7857 7.76477 16.7857 11.5589C16.7857 15.353 19.8214 18.353 23.5714 18.353Z"
        fill="url(#egt-big-paint-7)"
      />
    </g>
    <defs>
      <linearGradient
        id="egt-big-paint-0"
        x1="10.3253"
        y1="-6.4216"
        x2="1.96251"
        y2="27.7595"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00A48B" />
        <stop offset="0.9995" stopColor="#A5CD39" />
      </linearGradient>
      <linearGradient
        id="egt-big-paint-1"
        x1="47.3362"
        y1="20.0405"
        x2="80.2068"
        y2="20.0405"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0092C0" />
        <stop offset="0.9995" stopColor="#A5CD39" />
      </linearGradient>
      <linearGradient
        id="egt-big-paint-2"
        x1="34.7386"
        y1="8.68287"
        x2="48.9087"
        y2="27.5375"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0092C0" />
        <stop offset="0.9995" stopColor="#A5CD39" />
      </linearGradient>
      <linearGradient
        id="egt-big-paint-3"
        x1="73.7647"
        y1="19.5193"
        x2="85.6318"
        y2="7.33157"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0092C0" />
        <stop offset="0.9995" stopColor="#A5CD39" />
      </linearGradient>
      <linearGradient
        id="egt-big-paint-4"
        x1="108.452"
        y1="9.39988"
        x2="91.2067"
        y2="-3.4343"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0092C0" />
        <stop offset="0.9995" stopColor="#A5CD39" />
      </linearGradient>
      <linearGradient
        id="egt-big-paint-5"
        x1="92.2894"
        y1="28.1017"
        x2="108.172"
        y2="20.9376"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0092C0" />
        <stop offset="0.9995" stopColor="#A5CD39" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_9978_146782"
        x1="9.5379"
        y1="18.0152"
        x2="26.8746"
        y2="18.0152"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0092C0" />
        <stop offset="0.9995" stopColor="#A5CD39" />
      </linearGradient>
      <linearGradient
        id="egt-big-paint-7"
        x1="32.8549"
        y1="14.0109"
        x2="26.0109"
        y2="2.32513"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#91C73E" />
        <stop offset="1" stopColor="#0092C0" />
      </linearGradient>
    </defs>
  </svg>
);

IconProviderEgtBig.propTypes = {
  className: PropTypes.string,
};
IconProviderEgtBig.defaultProps = {
  className: null,
};
