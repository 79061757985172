import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedTag } from 'core/components';
import { withModalActions, withUser } from 'core/hocs';
import { USER_FIELDS } from 'core/constants';
import { isEmptyOrNil, convertStringifiedBoolean, getStaticUrl } from 'core/helpers';

import { ImageLazyLoad, LAZY_LOAD_TYPES } from 'components/image-lazy-load/image-lazy-load';
import { IconArrowDown } from 'components/icons/icon-arrow-down/icon-arrow-down';
import colors from 'customizations/js/color-variables';
import { MODAL_IDS } from '../../constants';

const DEFAULT_THUMB_PATH = `${getStaticUrl()}/images/default-game-bg/thumb.jpg`;

export class GamesSearchListUI extends Component {
  static propTypes = {
    isUserLoggedIn: PropTypes.bool.isRequired,
    openGame: PropTypes.func.isRequired,
    // toggleFavoriteGame: PropTypes.func.isRequired,
    onOpenGame: PropTypes.func.isRequired,
    games: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    targetRef: PropTypes.shape(),
    isRecentlySearched: PropTypes.bool,
    isRecommendedGames: PropTypes.bool,
    openModalAndCloseOthers: PropTypes.func.isRequired,
    isUserRegistered: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    targetRef: null,
    isRecentlySearched: false,
    isRecommendedGames: false,
  };

  onGameClick = ({ currentTarget: { dataset: { id: gameId, producer, fun: hasFun } } }) => {
    const {
      isUserLoggedIn,
      openGame,
      onOpenGame,
      openModalAndCloseOthers,
      isUserRegistered,
    } = this.props;

    if (onOpenGame) {
      onOpenGame({ gameId, producer });
    }

    if (!convertStringifiedBoolean(hasFun) && !isUserLoggedIn) {
      openModalAndCloseOthers(isUserRegistered ? MODAL_IDS.SIGN_IN : MODAL_IDS.SIGN_UP);

      return;
    }

    openGame({ gameId, producer, isFun: !isUserLoggedIn });
  };

  render() {
    const {
      games,
      targetRef,
      isRecentlySearched,
      isRecommendedGames,
    } = this.props;

    return (
      <div ref={targetRef} className="games-search-list-wrapper">
        {(isRecentlySearched || isRecommendedGames) && (
          <FormattedTag
            id={isRecentlySearched ? 'games.search.recently-searched' : 'games.search.recommended'}
            className="h5 font-weight-normal text-gray-20 d-block pl-2_5 pr-3_25 mt-2 mb-0_5"
          />
        )}

        <div className="games-search-list">
          {!isEmptyOrNil(games) && games.map(({
            gameId,
            title,
            producer,
            thumbPath,
            hasFun,
          }, idx) => (
            <div
              key={`${gameId}-${producer}`}
              className={classNames('games-search-list-item align-items-center d-flex pb-1_25 pt-1_25 pl-2_5 pr-3_25', {
                'border-bottom border-gray-10-20': idx !== games.length - 1,
              })}
              role="button"
              tabIndex="0"
              title={title}
              data-id={gameId}
              data-producer={producer}
              data-fun={hasFun}
              onClick={this.onGameClick}
              onKeyPress={this.onGameClick}
            >
              <div className="bg-gray-40 rounded">
                <ImageLazyLoad
                  src="/assets/public/images/transparent-pixel.png"
                  className="games-search-list-item-image flex-shrink-0 rounded"
                  classNameLoaded="games-search-list-item-image-loaded"
                  classNameError="games-search-list-item-image-error"
                  srcLazy={thumbPath}
                  type={LAZY_LOAD_TYPES.BACKGROUND}
                  srcFallback={DEFAULT_THUMB_PATH}
                />
              </div>

              <div className="ml-1_75 text-truncate d-flex flex-column justify-content-between flex-grow-1 pr-1 pr-sm-0">
                <span className="text-truncate text-small text-gray-30 text-capitalize mb-0_5">{producer}</span>

                <span className="h4 font-weight-semi-bold text-nowrap text-truncate overflow-hidden text-white">{title}</span>
              </div>

              <IconArrowDown className="games-search-list-item-arrow" color={colors.white} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export const GamesSearchList = withModalActions(
  withUser(GamesSearchListUI, [USER_FIELDS.IS_USER_REGISTERED])
);
