import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from 'reactstrap/lib/Form';
import { withCoreComponent, withModalActions, withRecaptcha } from 'core/hocs';
import { getEnv } from 'core/helpers';
import { LOCAL } from 'core/constants';
import { FormResetPassword as FormResetPasswordCore, FormattedTag } from 'core/components';
import { StatusNotificationContent } from 'components/status-notification-content/status-notification-content';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { FormElement } from 'components/form-element/form-element';
import { IconMail } from 'components/icons/icon-mail/icon-mail';

export class FormResetPasswordUI extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    fields: PropTypes.shape().isRequired,
    isInProgress: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    email: PropTypes.string,
    closeAllModals: PropTypes.func.isRequired,
    executeRecaptcha: PropTypes.func.isRequired,
  };

  static defaultProps = {
    email: null,
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { onSubmit, executeRecaptcha } = this.props;

    const isLocal = getEnv('PROJECT_ENV') === LOCAL;

    onSubmit(e, executeRecaptcha, isLocal);
  }

  render() {
    const {
      fields, isInProgress, isSuccess, closeAllModals,
    } = this.props;

    return isSuccess
      ? (
        <StatusNotificationContent
          headerIntlKey="form.forgot-password.reset-link-is-sent"
          descIntlKey="form.forgot-password.check-email"
          onClick={closeAllModals}
        />
      )

      : (
        <Form onSubmit={this.onSubmit}>
          <FormattedTag tag="div" className="h3 text-center modal-custom-title" id="form.forgot-password.title" />
          <FormattedTag
            tag="div"
            className="text-gray-20 mb-4 text-small text-center"
            id="form.forgot-password.description"
          />

          <FormElement coreProps={{ item: fields.email }} size="lg" icon={IconMail} autoFocus />

          <FormattedTag
            tag={ButtonWithLoader}
            isLoading={isInProgress}
            color="primary"
            className="mt-1 w-100"
            type="submit"
            id="form.forgot-password.send-reset-link"
          />
        </Form>
      );
  }
}

export const FormResetPassword = withCoreComponent(
  FormResetPasswordCore,
  withModalActions(withRecaptcha(FormResetPasswordUI))
);
