import { getConfig } from 'core/helpers';
import { LOCALE_KEYS, FRACTION_DIGITS } from 'core/constants';
import { CURRENCY_SYMBOLS } from '../constants';

const DEFAULT_LOCALE = getConfig('DEFAULT_LOCALE') || LOCALE_KEYS.EN;

export const AMOUNT_THOUSANDS_SEPARATOR = {
  [LOCALE_KEYS.EN]: ',',
  [LOCALE_KEYS.TR]: ',',
  [LOCALE_KEYS.RU]: ' ',
};
export const AMOUNT_DECIMAL_SEPARATOR = {
  [LOCALE_KEYS.EN]: '.',
  [LOCALE_KEYS.RU]: ',',
};

export const formatFloatAmountWithLocale = (val, locale = DEFAULT_LOCALE, decimals = FRACTION_DIGITS) => {
  const parts = Number(val).toFixed(decimals).toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, AMOUNT_THOUSANDS_SEPARATOR[locale] || ' ');

  if (!Number(parts[1])) {
    return parts[0];
  }

  return parts.join(AMOUNT_DECIMAL_SEPARATOR[locale]);
};

export const formatAmountWithCurrency = ({
  amount,
  currency,
  locale = DEFAULT_LOCALE,
  isIsoFormat = false,
  isCurrencyOnSecondPlace = false,
}) => {
  const formattedAmount = formatFloatAmountWithLocale(amount, locale);

  if (isIsoFormat) {
    return `${formattedAmount} ${currency}`;
  }

  if (currency === 'RUB' || currency === 'NOK' || currency === 'SEK') {
    return `${formattedAmount} ${CURRENCY_SYMBOLS[currency]}`;
  }

  if (isCurrencyOnSecondPlace) {
    return `${formattedAmount}${CURRENCY_SYMBOLS[currency] || ''}`;
  }

  return `${CURRENCY_SYMBOLS[currency] || ''}${formattedAmount}`;
};
