import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import * as R from 'ramda';
import { withUser } from 'core/hocs';
import {
  addScriptElementToDOM,
  getEnv,
  isEmptyOrNil,
  convertSnakeCaseToCamelCase,
  getStaticUrl,
} from 'core/helpers';
import { USER_FIELDS } from 'core/constants';

import './live-chat-inc-chat-client-custom.scss';

import { SPORTSBOOK_POST_MESSAGE_TYPES } from '../../../constants';

const staticUrl = getStaticUrl();

const apiCallbacksNames = [
  'on_before_load',
  'on_after_load',
  'on_chat_window_opened',
  'on_chat_window_minimized',
  'on_chat_window_hidden',
  'on_chat_state_changed',
  'on_chat_started',
  'on_chat_ended',
  'on_message',
  'on_ticket_created',
  'on_prechat_survey_submitted',
  'on_postchat_survey_submitted',
  'on_rating_submitted',
  'on_rating_comment_submitted',
];

const iconBasicPath = `${staticUrl}/images/live-chat`;

const icons = [
  {
    id: 'chat.url.whatsapp',
    imgSrc: `${iconBasicPath}/whatsapp.png`,
  },
  {
    id: 'chat.url.telegram',
    imgSrc: `${iconBasicPath}/telegram.png`,
  },
  {
    id: 'chat.url.call',
    imgSrc: `${iconBasicPath}/customer-service.svg`,
  },
  // {
  //   id: 'chat.url.-sans',
  //   imgSrc: `${iconBasicPath}/roulette.png`,
  // }
];

class LiveChatIncChatClientCustomUI extends Component {
  static propTypes = {
    /* eslint-disable react/no-unused-prop-types */
    group: PropTypes.number,
    isSportsbookPage: PropTypes.bool,
    isButtonHidden: PropTypes.bool,
    userData: PropTypes.shape(),
    additionalParams: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
    })),
    chatBetweenGroups: PropTypes.bool,
    onChatScriptLoaded: PropTypes.func,
    onChatScriptLoadingError: PropTypes.func,
    onBeforeLoad: PropTypes.func,
    onAfterLoad: PropTypes.func,
    onChatWindowOpened: PropTypes.func,
    onChatWindowMinimized: PropTypes.func,
    onChatWindowHidden: PropTypes.func,
    onChatStateChanged: PropTypes.func,
    onChatStarted: PropTypes.func,
    onChatEnded: PropTypes.func,
    onMessage: PropTypes.func,
    onTicketCreated: PropTypes.func,
    onPrechatSurveySubmitted: PropTypes.func,
    onPostchatSurveySubmitted: PropTypes.func,
    onRatingSubmitted: PropTypes.func,
    onRatingCommentSubmitted: PropTypes.func,
    /* eslint-enable react/no-unused-prop-types */
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    isButtonHidden: false,
    isSportsbookPage: false,
    group: 0,
    chatBetweenGroups: false,
    additionalParams: null,
    userData: {},
    onChatScriptLoaded: () => {},
    onChatScriptLoadingError: () => {},
    onBeforeLoad: () => {},
    onAfterLoad: () => {},
    onChatWindowOpened: () => {},
    onChatWindowMinimized: () => {},
    onChatWindowHidden: () => {},
    onChatStateChanged: () => {},
    onChatStarted: () => {},
    onChatEnded: () => {},
    onMessage: () => {},
    onTicketCreated: () => {},
    onPrechatSurveySubmitted: () => {},
    onPostchatSurveySubmitted: () => {},
    onRatingSubmitted: () => {},
    onRatingCommentSubmitted: () => {},
  };

  state ={
    isActive: false,
    isChatOpen: false,
  }

  componentDidMount() {
    this.loadChatApi();
    window.addEventListener('message', this.onIframeMessage);
  }

  componentDidUpdate(prevProps) {
    const { userData } = this.props;
    const { userData: prevUserData } = prevProps;

    if (!R.isEmpty(userData) && R.isEmpty(prevUserData) && window.LC_API) {
      if (window.LC_API.set_visitor_name) {
        window.LC_API.set_visitor_name(userData.firstName);
      }

      if (window.LC_API.set_visitor_email) {
        window.LC_API.set_visitor_email(userData.email);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onIframeMessage);
  }

  onIframeMessage = ({ data }) => {
    const { type } = data || {};

    if (type === SPORTSBOOK_POST_MESSAGE_TYPES.OPEN_CHAT) {
      this.openLiveChatIncChatWidget();
    }
  };

  chatScriptIsLoadedWithSuccess = () => {
    if (window.LC_API) {
      this.setApiCallbacks();
      this.props.onChatScriptLoaded(window.LC_API);
    }
  };

  chatScriptIsLoadedWithError = () => {
    this.props.onChatScriptLoadingError();
  };

  loadChatApi = () => {
    const licenseKey = getEnv('LIVECHATINC_API_LICENSE_KEY');

    if (isEmptyOrNil(licenseKey)) {
      throw new Error('`LIVECHATINC_API_LICENSE_KEY` env\'s key is REQUIRED for adding the LiveChatInc widget');
    }

    const {
      group,
      chatBetweenGroups,
      additionalParams,
      userData,
    } = this.props;

    if (!window.LC_API) {
      window.__lc = window.__lc || {};
      window.__lc.license = licenseKey;
      window.__lc.group = group;
      window.__lc.chat_between_groups = chatBetweenGroups;
      window.__lc.params = additionalParams;

      if (!R.isEmpty(userData)) {
        window.__lc.visitor = {
          name: userData.firstName,
          email: userData.email,
        };
      }

      addScriptElementToDOM({
        src: '//cdn.livechatinc.com/tracking.js',
        async: true,
        attributes: {
          'data-script-livechatinc': '',
          // Disables Cloudflare Rocket Loader script
          'data-cfasync': 'false',
        },
        onLoadEventListener: this.chatScriptIsLoadedWithSuccess,
        onErrorEventListener: this.chatScriptIsLoadedWithError,
      });
    }
  };

  setApiCallbacks = () => {
    apiCallbacksNames.forEach((apiCallbacksName) => {
      const propCallbackName = convertSnakeCaseToCamelCase(apiCallbacksName);
      const propCallback = R.propOr(null, propCallbackName, this.props);

      if (!isEmptyOrNil(propCallback) && typeof propCallback === 'function') {
        window.LC_API[apiCallbacksName] = propCallback;
      }
    });

    window.LC_API.on_chat_window_hidden = () => this.setState({ isChatOpen: false });
  };

  onChatToggle = () => {
    const { isChatOpen } = this.state;

    // console.log({isChatOpen})
    if (isChatOpen) {
      this.hideLiveChatIncChatWidget();

      return;
    }

    this.openLiveChatIncChatWidget();
  };

  openLiveChatIncChatWidget = () => {
    if (window.LC_API && window.LC_API.open_chat_window) {
      window.LC_API.open_chat_window();

      this.setState({ isChatOpen: true });
    }
  };

  hideLiveChatIncChatWidget = () => {
    if (window.LC_API && window.LC_API.hide_chat_window) {
      window.LC_API.hide_chat_window();

      this.setState({ isChatOpen: false });
    }
  };

  onActiveToggle = () => {
    const { isChatOpen } = this.state;
    this.setState(({ isActive }) => ({ isActive: !isActive }));

    if (isChatOpen) this.hideLiveChatIncChatWidget();
  };

  renderChatButton = () => (
    <li
      className="ico01"
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="button"
      tabIndex="0"
      onClick={this.onActiveToggle}
      onKeyPress={this.onActiveToggle}
    />
  )

  render() {
    const {
      isButtonHidden,
      isSportsbookPage,
      intl: { formatMessage }
    } = this.props;
    const { isActive } = this.state;

    if (isButtonHidden) {
      return null;
    }

    return (
      <div className={classNames('custom-chat-button custom-chat-button-livechat d-flex justify-content-center align-items-center position-fixed rounded-circle', { 'custom-chat-button-sportsbook': isSportsbookPage })}>
        <div className={classNames('wrapper', { active: isActive })}>
          {/* <div className="menu"> */}
          {/*  <ul className="toggle"> */}
          {/*    {this.renderChatButton()} */}
          {/*    /!*<li className="move-ico ico02" />*!/ */}
          {/*    <li className="move-ico ico03" /> */}
          {/*    <li className="move-ico ico04" /> */}
          {/*  </ul> */}
          {/*  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="0" height="0"> */}
          {/*    <defs> */}
          {/*      <filter id="goo"> */}
          {/*        <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="12" /> */}
          {/*        <feColorMatrix */}
          {/*          in="blur" */}
          {/*          mode="matrix" */}
          {/*          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" */}
          {/*          result="joint" */}
          {/*        /> */}
          {/*      </filter> */}
          {/*    </defs> */}
          {/*  </svg> */}
          {/* </div> */}
          <div className="menu-object">
            <ul className="toggle">
              {this.renderChatButton()}
              <li className="move-ico ico02">
                <button
                  type="button"
                  onClick={this.onChatToggle}
                  className="resetbutton link link01"
                >
                  <i className="far fa-comments" />
                </button>
              </li>
              {icons.map(({ id, imgSrc }, i) => (
                <li className={`move-ico ico0${3 + i}`}>
                  <a
                    href={formatMessage({ id })}
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    <img
                      src={imgSrc}
                      className="list-img"
                      alt="quick-action-icon"
                    />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export const LiveChatIncChatClientCustom = withUser(injectIntl(LiveChatIncChatClientCustomUI), [USER_FIELDS.USER_DATA]);
