import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderReevo = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" fill="none">
    <path
      d="M8.01405 8.34771V16.4902C8.01405 16.6069 8.10784 16.701 8.22402 16.701H13.738C13.8542 16.701 13.9479 16.6069 13.9479 16.4902V12.5497C13.9479 10.2281 12.0736 8.34771 9.76244 8.34771H9.32429H9.15351V8.33225C11.9378 8.26339 13.9493 7.84179 13.9493 4.00943C13.9493 0.00280994 11.3736 0.0337277 8.01545 0.00281021C7.67669 -4.717e-07 7.32953 0 6.97537 0C6.62121 0 6.27405 -4.717e-07 5.93529 0.00281021V0H0.209975C0.0937888 0 0 0.0941577 0 0.210801V16.4959C0 16.6125 0.0937888 16.7067 0.209975 16.7067L5.72392 16.7039C5.84011 16.7039 5.93389 16.6097 5.93389 16.4931V3.17466C5.93389 2.60269 6.37204 2.10379 6.94177 2.08693C7.5311 2.06866 8.01405 2.54366 8.01405 3.13109V8.34771Z"
      fill="white"
    />
  </svg>
);

IconProviderReevo.propTypes = {
  className: PropTypes.string,
};

IconProviderReevo.defaultProps = {
  className: '',
};
