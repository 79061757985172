import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderPgSoft = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.25895 6.62994H2.4054V7.4835H3.25895V6.62994Z" fill="white" />
    <path d="M4.28324 6.62994H3.42969V7.4835H4.28324V6.62994Z" fill="white" />
    <path d="M5.30741 6.62994H4.45386V7.4835H5.30741V6.62994Z" fill="white" />
    <path d="M6.3317 6.62994H5.47815V7.4835H6.3317V6.62994Z" fill="white" />
    <path d="M7.35599 6.62994H6.50244V7.4835H7.35599V6.62994Z" fill="white" />
    <path d="M8.38029 6.62994H7.52673V7.4835H8.38029V6.62994Z" fill="white" />
    <path d="M9.40446 6.62994H8.5509V7.4835H9.40446V6.62994Z" fill="white" />
    <path d="M10.4287 6.62994H9.5752V7.4835H10.4287V6.62994Z" fill="white" />
    <path d="M3.25895 7.65421H2.4054V8.50776H3.25895V7.65421Z" fill="white" />
    <path d="M4.28324 7.65421H3.42969V8.50776H4.28324V7.65421Z" fill="white" />
    <path d="M5.30741 7.65421H4.45386V8.50776H5.30741V7.65421Z" fill="white" />
    <path d="M6.3317 7.65421H5.47815V8.50776H6.3317V7.65421Z" fill="white" />
    <path d="M7.35599 7.65421H6.50244V8.50776H7.35599V7.65421Z" fill="white" />
    <path d="M8.38029 7.65421H7.52673V8.50776H8.38029V7.65421Z" fill="white" />
    <path d="M9.40446 7.65421H8.5509V8.50776H9.40446V7.65421Z" fill="white" />
    <path d="M10.4287 7.65421H9.5752V8.50776H10.4287V7.65421Z" fill="white" />
    <path d="M2.23466 7.65421H1.3811V8.50776H2.23466V7.65421Z" fill="white" />
    <path d="M3.25895 8.67847H2.4054V9.53202H3.25895V8.67847Z" fill="white" />
    <path d="M2.23466 8.67847H1.3811V9.53202H2.23466V8.67847Z" fill="white" />
    <path d="M3.25895 9.70274H2.4054V10.5563H3.25895V9.70274Z" fill="white" />
    <path d="M2.23466 9.70274H1.3811V10.5563H2.23466V9.70274Z" fill="white" />
    <path d="M3.25895 10.727H2.4054V11.5806H3.25895V10.727Z" fill="white" />
    <path d="M2.23466 10.727H1.3811V11.5806H2.23466V10.727Z" fill="white" />
    <path d="M3.25895 11.7513H2.4054V12.6048H3.25895V11.7513Z" fill="white" />
    <path d="M2.23466 11.7513H1.3811V12.6048H2.23466V11.7513Z" fill="white" />
    <path d="M3.25895 12.7755H2.4054V13.6291H3.25895V12.7755Z" fill="white" />
    <path d="M2.23466 12.7755H1.3811V13.6291H2.23466V12.7755Z" fill="white" />
    <path d="M4.28324 12.7755H3.42969V13.6291H4.28324V12.7755Z" fill="white" />
    <path d="M5.30741 12.7755H4.45386V13.6291H5.30741V12.7755Z" fill="white" />
    <path d="M6.3317 12.7755H5.47815V13.6291H6.3317V12.7755Z" fill="white" />
    <path d="M7.35599 12.7755H6.50244V13.6291H7.35599V12.7755Z" fill="white" />
    <path d="M8.38029 12.7755H7.52673V13.6291H8.38029V12.7755Z" fill="white" />
    <path d="M9.40446 12.7755H8.5509V13.6291H9.40446V12.7755Z" fill="white" />
    <path d="M9.40446 11.7513H8.5509V12.6048H9.40446V11.7513Z" fill="white" />
    <path d="M10.4287 12.7755H9.5752V13.6291H10.4287V12.7755Z" fill="white" />
    <path d="M10.4287 11.7513H9.5752V12.6048H10.4287V11.7513Z" fill="white" />
    <path d="M11.453 11.7513H10.5995V12.6048H11.453V11.7513Z" fill="white" />
    <path d="M10.4287 10.727H9.5752V11.5806H10.4287V10.727Z" fill="white" />
    <path d="M11.453 10.727H10.5995V11.5806H11.453V10.727Z" fill="white" />
    <path d="M10.4287 9.70274H9.5752V10.5563H10.4287V9.70274Z" fill="white" />
    <path d="M11.453 9.70274H10.5995V10.5563H11.453V9.70274Z" fill="white" />
    <path d="M10.4287 8.67847H9.5752V9.53202H10.4287V8.67847Z" fill="white" />
    <path d="M11.453 8.67847H10.5995V9.53202H11.453V8.67847Z" fill="white" />
    <path d="M9.40446 8.67847H8.5509V9.53202H9.40446V8.67847Z" fill="white" />
    <path d="M2.23466 13.7998H1.3811V14.6533H2.23466V13.7998Z" fill="white" />
    <path d="M3.25895 13.7998H2.4054V14.6533H3.25895V13.7998Z" fill="white" />
    <path d="M2.23466 14.8241H1.3811V15.6776H2.23466V14.8241Z" fill="white" />
    <path d="M3.25895 14.8241H2.4054V15.6776H3.25895V14.8241Z" fill="white" />
    <path d="M2.23466 15.8483H1.3811V16.7019H2.23466V15.8483Z" fill="white" />
    <path d="M3.25895 15.8483H2.4054V16.7019H3.25895V15.8483Z" fill="white" />
    <path d="M4.28324 13.7998H3.42969V14.6533H4.28324V13.7998Z" fill="white" />
    <path d="M5.30741 13.7998H4.45386V14.6533H5.30741V13.7998Z" fill="white" />
    <path d="M6.3317 13.7998H5.47815V14.6533H6.3317V13.7998Z" fill="white" />
    <path d="M7.35599 13.7998H6.50244V14.6533H7.35599V13.7998Z" fill="white" />
    <path d="M8.38029 13.7998H7.52673V14.6533H8.38029V13.7998Z" fill="white" />
    <path d="M14.5259 8.67847H13.6724V9.53202H14.5259V8.67847Z" fill="white" />
    <path d="M15.5502 8.67847H14.6967V9.53202H15.5502V8.67847Z" fill="white" />
    <path d="M16.5745 8.67847H15.7209V9.53202H16.5745V8.67847Z" fill="white" />
    <path d="M15.5502 7.65421H14.6967V8.50776H15.5502V7.65421Z" fill="white" />
    <path d="M16.5745 7.65421H15.7209V8.50776H16.5745V7.65421Z" fill="white" />
    <path d="M16.5745 6.62994H15.7209V7.4835H16.5745V6.62994Z" fill="white" />
    <path d="M17.5987 7.65421H16.7451V8.50776H17.5987V7.65421Z" fill="white" />
    <path d="M17.5987 6.62994H16.7451V7.4835H17.5987V6.62994Z" fill="white" />
    <path d="M18.623 7.65421H17.7694V8.50776H18.623V7.65421Z" fill="white" />
    <path d="M18.623 6.62994H17.7694V7.4835H18.623V6.62994Z" fill="white" />
    <path d="M15.5502 9.70274H14.6967V10.5563H15.5502V9.70274Z" fill="white" />
    <path d="M14.5259 9.70274H13.6724V10.5563H14.5259V9.70274Z" fill="white" />
    <path d="M15.5502 10.727H14.6967V11.5806H15.5502V10.727Z" fill="white" />
    <path d="M14.5259 10.727H13.6724V11.5806H14.5259V10.727Z" fill="white" />
    <path d="M14.5259 11.7513H13.6724V12.6048H14.5259V11.7513Z" fill="white" />
    <path d="M15.5502 11.7513H14.6967V12.6048H15.5502V11.7513Z" fill="white" />
    <path d="M15.5502 12.7755H14.6967V13.6291H15.5502V12.7755Z" fill="white" />
    <path d="M14.5259 12.7755H13.6724V13.6291H14.5259V12.7755Z" fill="white" />
    <path d="M15.5502 13.7998H14.6967V14.6533H15.5502V13.7998Z" fill="white" />
    <path d="M14.5259 13.7998H13.6724V14.6533H14.5259V13.7998Z" fill="white" />
    <path d="M16.5745 13.7998H15.7209V14.6533H16.5745V13.7998Z" fill="white" />
    <path d="M15.5502 14.8241H14.6967V15.6776H15.5502V14.8241Z" fill="white" />
    <path d="M16.5745 14.8241H15.7209V15.6776H16.5745V14.8241Z" fill="white" />
    <path d="M17.5987 14.8241H16.7451V15.6776H17.5987V14.8241Z" fill="white" />
    <path d="M16.5745 15.8483H15.7209V16.7019H16.5745V15.8483Z" fill="white" />
    <path d="M17.5987 15.8483H16.7451V16.7019H17.5987V15.8483Z" fill="white" />
    <path d="M18.623 14.8241H17.7694V15.6776H18.623V14.8241Z" fill="white" />
    <path d="M18.623 15.8483H17.7694V16.7019H18.623V15.8483Z" fill="white" />
    <path d="M19.6473 14.8241H18.7937V15.6776H19.6473V14.8241Z" fill="white" />
    <path d="M19.6473 15.8483H18.7937V16.7019H19.6473V15.8483Z" fill="white" />
    <path d="M20.6715 14.8241H19.818V15.6776H20.6715V14.8241Z" fill="white" />
    <path d="M20.6715 15.8483H19.818V16.7019H20.6715V15.8483Z" fill="white" />
    <path d="M21.6957 15.8483H20.8422V16.7019H21.6957V15.8483Z" fill="white" />
    <path d="M21.6957 14.8241H20.8422V15.6776H21.6957V14.8241Z" fill="white" />
    <path d="M22.72 14.8241H21.8665V15.6776H22.72V14.8241Z" fill="white" />
    <path d="M21.6957 13.7998H20.8422V14.6533H21.6957V13.7998Z" fill="white" />
    <path d="M22.72 13.7998H21.8665V14.6533H22.72V13.7998Z" fill="white" />
    <path d="M23.7443 13.7998H22.8907V14.6533H23.7443V13.7998Z" fill="white" />
    <path d="M22.72 12.7755H21.8665V13.6291H22.72V12.7755Z" fill="white" />
    <path d="M23.7443 12.7755H22.8907V13.6291H23.7443V12.7755Z" fill="white" />
    <path d="M22.72 11.7513H21.8665V12.6048H22.72V11.7513Z" fill="white" />
    <path d="M23.7443 11.7513H22.8907V12.6048H23.7443V11.7513Z" fill="white" />
    <path d="M21.6957 11.7513H20.8422V12.6048H21.6957V11.7513Z" fill="white" />
    <path d="M20.6715 11.7513H19.818V12.6048H20.6715V11.7513Z" fill="white" />
    <path d="M19.6473 11.7513H18.7937V12.6048H19.6473V11.7513Z" fill="white" />
    <path d="M19.6473 10.727H18.7937V11.5806H19.6473V10.727Z" fill="white" />
    <path d="M20.6715 10.727H19.818V11.5806H20.6715V10.727Z" fill="white" />
    <path d="M21.6957 10.727H20.8422V11.5806H21.6957V10.727Z" fill="white" />
    <path d="M22.72 10.727H21.8665V11.5806H22.72V10.727Z" fill="white" />
    <path d="M23.7443 10.727H22.8907V11.5806H23.7443V10.727Z" fill="white" />
    <path d="M19.6473 6.62994H18.7937V7.4835H19.6473V6.62994Z" fill="white" />
    <path d="M19.6473 7.65421H18.7937V8.50776H19.6473V7.65421Z" fill="white" />
    <path d="M20.6715 6.62994H19.818V7.4835H20.6715V6.62994Z" fill="white" />
    <path d="M20.6715 7.65421H19.818V8.50776H20.6715V7.65421Z" fill="white" />
    <path d="M21.6957 7.65421H20.8422V8.50776H21.6957V7.65421Z" fill="white" />
    <path d="M22.72 7.65421H21.8665V8.50776H22.72V7.65421Z" fill="white" />
    <path d="M21.6957 8.67847H20.8422V9.53202H21.6957V8.67847Z" fill="white" />
    <path d="M22.72 8.67847H21.8665V9.53202H22.72V8.67847Z" fill="white" />
  </svg>

);

IconProviderPgSoft.propTypes = {
  className: PropTypes.string,
};

IconProviderPgSoft.defaultProps = {
  className: '',
};
