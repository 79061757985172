import React from 'react';
import PropTypes from 'prop-types';

import colors from '../../../customizations/js/color-variables';

export const IconMail = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <path fill={color} d="M4 9.869V17a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V9.869l-7.445 4.963a1 1 0 0 1-1.11 0L4 9.87zm0-2.404l8 5.333 8-5.333V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v.465zM19 20H5a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3z" />
  </svg>
);

IconMail.propTypes = {
  color: PropTypes.string,
};

IconMail.defaultProps = {
  color: colors.white40,
};
