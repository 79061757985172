import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconProfile = ({ className, color }) => (
  <svg className={className} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="icon-stroked-path icon-filled-path" d="M23.7221 4.27814C21.1253 1.68133 17.6725 0.251183 14.0001 0.251183C10.3275 0.251183 6.87498 1.68133 4.27818 4.27814C1.68137 6.87494 0.251221 10.3275 0.251221 14.0001C0.251221 17.6725 1.68137 21.1253 4.27818 23.7221C6.87498 26.3189 10.3275 27.749 14.0001 27.749C17.6725 27.749 21.1253 26.3189 23.7221 23.7221C26.3189 21.1253 27.7491 17.6725 27.7491 14.0001C27.7491 10.3275 26.3189 6.87494 23.7221 4.27814ZM7.14393 24.0103C7.71897 20.6994 10.5881 18.2572 14.0001 18.2572C17.4124 18.2572 20.2813 20.6994 20.8563 24.0103C18.9042 25.3515 16.5424 26.1378 14.0001 26.1378C11.4579 26.1378 9.09605 25.3515 7.14393 24.0103ZM9.6285 12.2744C9.6285 9.86364 11.5896 7.90271 14.0001 7.90271C16.4107 7.90271 18.3718 9.86385 18.3718 12.2744C18.3718 14.6849 16.4107 16.646 14.0001 16.646C11.5896 16.646 9.6285 14.6849 9.6285 12.2744ZM22.2519 22.8928C21.8182 21.3514 20.9571 19.9529 19.7499 18.8606C19.0093 18.1903 18.1664 17.6614 17.2601 17.2886C18.8982 16.2201 19.9832 14.3716 19.9832 12.2744C19.9832 8.97538 17.2991 6.29151 14.0001 6.29151C10.7012 6.29151 8.0173 8.97538 8.0173 12.2744C8.0173 14.3716 9.10234 16.2201 10.7402 17.2886C9.83409 17.6614 8.99094 18.1901 8.25038 18.8604C7.04344 19.9527 6.18204 21.3512 5.7484 22.8926C3.35992 20.6744 1.86242 17.5091 1.86242 14.0001C1.86242 7.30732 7.30736 1.86238 14.0001 1.86238C20.6929 1.86238 26.1379 7.30732 26.1379 14.0001C26.1379 17.5093 24.6404 20.6746 22.2519 22.8928Z" fill={color} stroke={color} strokeWidth="0.3" />
  </svg>
);

IconProfile.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconProfile.defaultProps = {
  className: '',
  color: colors.gray30,
};
